import { MaterialApi } from '../api-client/generated/api';

const apiCaller = new MaterialApi();

interface Pagination {
  items: number;
  page: number;
}

const getAllMaterials = async () => {
  const { data } = await apiCaller.materialFindAll();
  return data;
};

const getMaterials = async (params: Pagination) => {
  const { data } = await apiCaller.materialFindAllPaginate(params);
  return data.data;
};

const getMaterial = async (param: any) => {
  if (!param.id) {
    return null;
  }
  const { data } = await apiCaller.materialGetOneById(param);
  return data;
};

const createMaterial = async (params: any) => {
  if (params?.id || params?.material) {
    return null;
  }

  const { data } = await apiCaller.materialCreate({
    createMaterialDTO: {
      description: params?.description,
      name: params?.name
    }
  });
  return data;
};

interface DeleteMaterialDTO {
  id: string;
  url: string;
}

const deleteMaterial = async (params: DeleteMaterialDTO) => {
  if (!params.id) {
    return null;
  }

  const { status, statusText } = await apiCaller.materialDelete({
    id: params.id
  });
  return { status, statusText };
};

interface UpdateMaterialDTO {
  description?: string;
  id: string;
  name: string;
  url: string;
}

const updateMaterial = async (params: UpdateMaterialDTO) => {
  if (!params.id) {
    return null;
  }

  const { status, statusText } = await apiCaller.materialUpdate({
    createMaterialDTO: {
      description: params.description,
      name: params.name
    },
    id: params.id
  });
  return { status, statusText };
};

export default {
  createMaterial,
  deleteMaterial,
  getAllMaterials,
  getMaterial,
  getMaterials,
  updateMaterial
};
