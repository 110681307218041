import React from 'react';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';

const PageNotFound: React.FC = function Welcome() {
  return (
    <Container
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <ReportIcon sx={{ color: '#d3455b', fontSize: '200px' }} />
      <Typography variant="h2">
        <b>Error 404</b>
      </Typography>
      <Typography variant="title">This page doesn't exist</Typography>
    </Container>
  );
};

export default PageNotFound;
