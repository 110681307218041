/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, SliderProps, Typography, Theme, CSSObject } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiDrawer from '@mui/material/Drawer';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {
  sideBarExpandedWidth,
  sideBarNormalWidth
} from '../../../helpers/constants';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none'
  },
  '&:not(:last-child)': {
    borderBottom: 0
  },
  border: `0px solid ${theme.palette.divider}`,
  marginBottom: '8px'
}));

export const TitleTab = styled(Typography)<SliderProps>(() => ({
  fontSize: '15px',
  fontWeight: '700',
  marginLeft: '14px',
  marginTop: '2px'
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: '0px solid rgba(0, 0, 0, .125)'
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  '& .Mui-expanded': {
    alignItems: 'center',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  },
  borderRadius: '8px',
  flexDirection: 'row-reverse',
  height: '56px',
  marginLeft: '16px',
  marginRight: '16px',
  minWidth: '160px',
  padding: '0px'
}));

export const ItemTab = styled(Box)<SliderProps>(() => ({
  fontSize: '16px',
  fontWeight: '700',
  height: '16px',
  justifyContent: 'center',
  textAlign: 'left',
  width: '40%'
}));

type BoxProps = {
  expandSidebarFlag: boolean;
  expanded: string | false;
  tabLabel: string;
};

export const IconBox = styled(Box)<BoxProps>(
  ({ expandSidebarFlag, expanded, tabLabel, theme }) => ({
    backgroundColor:
      expanded === tabLabel && !expandSidebarFlag
        ? theme.palette.primary[400]
        : '',
    borderRadius: expanded === tabLabel && !expandSidebarFlag ? '8px' : '',
    color: expanded === tabLabel ? 'white' : theme.palette.secondary[400],
    display: 'flex',
    height: expanded === tabLabel && !expandSidebarFlag ? '40px' : 'auto',
    justifyContent: 'center',
    padding: '0px',
    width: expanded === tabLabel && !expandSidebarFlag ? '40px' : '30px'
  })
);

export const openedMixin = (theme: Theme): CSSObject => ({
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp
  }),
  width: sideBarExpandedWidth - 1
});

export const closedMixin = (theme: Theme): CSSObject => ({
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp
  }),
  width: sideBarNormalWidth - 1,
  [theme.breakpoints.up('sm')]: {
    width: sideBarNormalWidth - 1
  }
});

export const ExpandableDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  width: sideBarExpandedWidth,
  zIndex: '10',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));
