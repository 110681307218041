/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  CardActions,
  Button,
  CardContent,
  Typography,
  Card as CardInfo,
  Card,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { secondaryColors } from '../../../../theme/themeConfig';
import { getCurrency } from '../../../../utils/general';

import PageNotFound from '../../../../components/shared/PageNotFound';
import Api from '../../../../api';
import logo from '../../../../assets/images/Bluberry_Logo.png';
import { InvoiceByIdResponseDTO } from '../../../../api-client/generated/api';

const ClientInvoiceView: React.FC = function ClientInvoiceView() {
  const [notFound, setNotFound] = useState<boolean>(false);
  const [invoiceClint, setInvoiceClint] =
    useState<InvoiceByIdResponseDTO | null>(null);
  const tokenParam = window.location.pathname.split('/')[2];
  const getInvoiceByTokenAction = Api.InvoicesApi.getInvoiceByToken;
  const { t } = useTranslation();

  const { data: invoiceViewData, error: invoiceViewError } = useSWR(
    tokenParam ? { token: tokenParam } : null,
    getInvoiceByTokenAction
  );

  useEffect(() => {
    if (invoiceViewData && !invoiceViewError) {
      setInvoiceClint(invoiceViewData);
    }
  }, [invoiceViewData]);

  useEffect(() => {
    if (!tokenParam) {
      setNotFound(true);
    }
  }, []);

  const cardContenido = (services: any[]) => {
    if (!services || services.length === 0) {
      return null;
    }

    return services.map((service) => (
      <CardContent key={service.id}>
        <Typography
          gutterBottom
          style={{
            color: secondaryColors.SECONDARY_5,
            fontSize: '16px'
          }}
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          {service.name}
        </Typography>
        {service.tasks.map((task: any) => (
          <Typography
            component="li"
            key={task.id}
            style={{
              color: secondaryColors.SECONDARY,
              fontSize: '13px'
            }}
            variant="primary-bold"
          >
            {task.name}
          </Typography>
        ))}
      </CardContent>
    ));
  };

  return (
    <Box>
      {notFound ? (
        <PageNotFound />
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '90px'
            }}
          >
            <img alt="Logo" src={logo} style={{ width: '12%' }} />{' '}
          </Box>

          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '7px',
              marginTop: '92px'
            }}
          >
            <Grid item md={5} xl={5} xs={5}>
              <Card style={{ padding: '51px' }}>
                <CardContent style={{ marginTop: '10px' }}>
                  <Typography
                    style={{
                      color: secondaryColors.SECONDARY,
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '28px',
                      marginTop: '-58px',
                      textAlign: 'center'
                    }}
                    sx={{ fontSize: 18 }}
                    variant="primary-bold"
                  >
                    Pay Invoice
                  </Typography>

                  <Typography
                    style={{
                      color: secondaryColors.SECONDARY,
                      display: 'flex',
                      fontSize: 13,
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    {`Your invoice for the month ${dayjs(
                      invoiceClint?.dueDate
                    ).format('MMMM')} has
                    been generated for an`}
                  </Typography>
                  <Typography
                    style={{
                      color: secondaryColors.SECONDARY,
                      display: 'flex',
                      fontSize: 13,
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    {`amount of ${getCurrency(invoiceClint?.total)}`}
                  </Typography>
                  <Typography
                    style={{
                      color: secondaryColors.SECONDARY,
                      display: 'flex',
                      fontSize: 13,
                      justifyContent: 'center',
                      marginTop: '9px',
                      textAlign: 'center'
                    }}
                  >
                    {` Your charge will be reflected as  ${invoiceClint?.clientName}`}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      backgroundColor: '#F7F6F9',
                      border: '1px solid #CBCCD3',
                      marginLeft: '58px',
                      marginTop: '13px',
                      width: '77%'
                    }}
                  >
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: 'start' }}
                      xl={6}
                      xs={4}
                    >
                      <Typography
                        style={{
                          backgroundColor: '#F7F6F9',
                          color: secondaryColors.SECONDARY,
                          fontSize: 13,
                          fontWeight: 'bold',
                          lineHeight: 1,
                          marginBottom: '10px'
                        }}
                      >
                        INVOICE NUMBER
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: 'end' }}
                      xl={6}
                      xs={4}
                    >
                      <Typography
                        style={{
                          backgroundColor: '#F7F6F9',
                          color: secondaryColors.SECONDARY,
                          fontSize: 13,
                          fontWeight: 'bold',
                          lineHeight: 1,
                          marginRight: '10px'
                        }}
                      >
                        {`#${invoiceClint?.invoiceNumber}`}
                      </Typography>
                    </Grid>
                    <Divider
                      style={{
                        color: 'rgb(125, 128, 144)',
                        marginBottom: '5px',
                        marginTop: '5px',
                        width: '100%'
                      }}
                    />
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: 'start' }}
                      xl={6}
                      xs={6}
                    >
                      <Typography
                        style={{
                          backgroundColor: '#F7F6F9',
                          color: secondaryColors.SECONDARY,
                          fontSize: 13,
                          fontWeight: 'bold',
                          lineHeight: 1,
                          marginBottom: '11px'
                        }}
                      >
                        PAID AMOUNT
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: 'end' }}
                      xl={6}
                      xs={6}
                    >
                      <Typography
                        style={{
                          backgroundColor: '#F7F6F9',
                          color: secondaryColors.SECONDARY,
                          fontSize: 13,
                          fontWeight: 'bold',
                          lineHeight: 1,
                          marginRight: '10px'
                        }}
                      >
                        {`${getCurrency(invoiceClint?.total)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                {/* <Box style={{ alignItems: 'center' }}>
              <Typography
                style={{
                  alignItems: 'center',
                  color: secondaryColors.SECONDARY,
                  display: 'flex',
                  fontSize: '16px',
                  justifyContent: 'center',
                  marginTop: '13px'
                }}
                variant="primary-bold"
              >
                You can pay here
              </Typography>
            </Box> */}
                <CardActions
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {/* <Button
                onClick={() => {}}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '75%'
                }}
                variant="primary"
              >
                <span style={{ margin: 'auto' }}>
                  {t('GENERAL.payInvoice')}
                </span>
              </Button> */}
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={5} xl={5} xs={5}>
              <Card>
                <CardContent>
                  <Typography
                    style={{ color: '#7D8090' }}
                    variant="primary-bold"
                  >
                    Services:{' '}
                  </Typography>
                  <strong
                    style={{
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#4B4D56',
                      borderRadius: '12px',
                      color: 'white',
                      height: '20px',
                      justifyContent: 'center',
                      marginLeft: '10px',
                      paddingLeft: '15px',
                      paddingRight: '16px',
                      width: '30px'
                    }}
                  >
                    {invoiceClint?.services.length}
                  </strong>
                  <Box sx={{ minWidth: 260 }}>
                    <hr style={{ borderColor: 'rgba(247, 246, 249, 0.2)' }} />
                    <CardInfo
                      style={{
                        marginLeft: '20px',
                        marginRight: '37px',
                        marginTop: '34px'
                      }}
                      variant="outlined"
                    >
                      {invoiceClint?.services &&
                        cardContenido(invoiceClint.services)}
                    </CardInfo>
                  </Box>
                  <hr
                    style={{
                      borderColor: 'rgba(247, 246, 249, 0.2)',
                      marginTop: '33px'
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item md={5} xl={6} xs={4}>
                      <Typography
                        style={{
                          color: secondaryColors.SECONDARY,
                          fontSize: '13px',
                          marginRight: '20px'
                        }}
                        variant="primary-bold"
                      >
                        Subtotal
                      </Typography>
                    </Grid>
                    <Grid item md={5} xl={6} xs={4}>
                      <Box textAlign="right">
                        <Typography
                          style={{
                            color: secondaryColors.SECONDARY,
                            fontSize: '13px'
                          }}
                          variant="primary-bold"
                        >
                          {`${getCurrency(invoiceClint?.subTotal)}`}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={5} xl={6} xs={4}>
                      <Typography
                        style={{
                          color: secondaryColors.SECONDARY,
                          fontSize: '13px',
                          marginRight: '20px'
                        }}
                        variant="primary-bold"
                      >
                        Tax @ 20%
                      </Typography>
                    </Grid>
                    <Grid item md={5} xl={6} xs={4}>
                      <Box textAlign="right">
                        <Typography
                          style={{
                            color: secondaryColors.SECONDARY,
                            fontSize: '13px'
                          }}
                          variant="primary-bold"
                        >
                          {`${getCurrency(invoiceClint?.taxes)}`}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={5} xl={6} xs={4}>
                      <Typography
                        style={{
                          color: secondaryColors.SECONDARY_5,
                          fontSize: '20px',
                          marginRight: '20px'
                        }}
                        variant="primary-bold"
                      >
                        Total
                      </Typography>
                    </Grid>
                    <Grid item md={5} xl={6} xs={4}>
                      <Box textAlign="right">
                        <Typography
                          style={{
                            color: secondaryColors.SECONDARY_5,
                            fontSize: '20px'
                          }}
                          variant="primary-bold"
                        >
                          {`${getCurrency(invoiceClint?.total)}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ClientInvoiceView;
