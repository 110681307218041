import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import RegularInput from '../input/RegularInput';
import api from '../../api';
import { FormLabel } from '../form/parts';

interface Props {
  isMobile: boolean;
}

const ForgotPassword: React.FC<Props> = function ForgotPassword({ isMobile }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [fireForgotPasswordEndpoint, setFireForgotPasswordEndpoint] =
    useState<boolean>(false);

  const { data, error: errorApi } = useSWR(
    fireForgotPasswordEndpoint ? { email } : null,
    api.authApi.forgotPassword
  );

  const getSuccess = () =>
    !success ? (
      false
    ) : (
      <Container
        sx={{
          alignItems: 'flex-start !important',
          backgroundColor: 'success.main',
          borderRadius: 2,
          mt: 2
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="mainText.100"
            sx={{ fontSize: 16 }}
            variant="primary-bold"
          >
            <ErrorOutlineIcon />
            <br />
            {t('LOGIN.forgotPasswordSuccessTitle')}
          </Typography>
          <br />
          <Typography
            color="mainText.100"
            sx={{ fontSize: 16 }}
            variant="primary-semi"
          >
            {t('LOGIN.forgotPasswordSuccessDescription')}
          </Typography>
        </Box>
      </Container>
    );

  const requestEmail = async () => {
    if (email !== '' && !error) {
      setFireForgotPasswordEndpoint(true);
    }
  };

  useEffect(() => {
    setSuccess(false);
    setError(false);
  }, []);

  useEffect(() => {
    if (data && data.status === 201) {
      setSuccess(true);
      setError(false);
      setFireForgotPasswordEndpoint(false);
    }
  }, [data]);

  useEffect(() => {
    if (errorApi) {
      setError(true);
      setSuccess(false);
      setFireForgotPasswordEndpoint(false);
    }
  }, [errorApi]);

  useEffect(() => {
    if (email) {
      setSuccess(false);
    }
  }, [email]);

  return (
    <Container sx={{ alignItems: 'flex-start !important' }}>
      <FormLabel error={false} label={t('GENERAL.email')} />
      <RegularInput
        required
        customStyle={{ width: '100%' }}
        inputInvalid={setError}
        onChange={(value) => setEmail(value)}
        success={success}
        type="email"
      />
      {getSuccess()}
      <Container
        sx={{
          flexDirection: `row ${isMobile ? '' : '!important'}`,
          justifyContent: 'space-between',
          mt: 2
        }}
      >
        {!success && (
          <Button
            color="primary"
            onClick={() => requestEmail()}
            sx={{ width: '100%' }}
            variant="contained"
          >
            <Typography variant="primary-bold">
              {t('LOGIN.sendLinkButton')}
            </Typography>
          </Button>
        )}
        {success && (
          <Button
            color="primary"
            onClick={() => navigate('/login')}
            sx={{ width: '100%' }}
            variant="contained"
          >
            <Typography variant="primary-bold">
              {t('LOGIN.forgotPasswordSuccessButton')}
            </Typography>
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default ForgotPassword;
