/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box, Button, SlideProps, styled, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import useSWR from 'swr';
import 'react-dropzone-uploader/dist/styles.css';
import useSWR, { useSWRConfig } from 'swr';
import RegularInput from '../../input/RegularInput';
import api from '../../../api';
import Dropzone from '../../input/Dropzone';
// import api from '../../../api';
// import { getLocalStorage } from '../../../utils/authHelper';

interface Props {
  isCompany: boolean;
  isMobile: boolean;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | undefined;
}

interface State {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const CustomBottom = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  width: '100%'
}));

const ProfileInfo: React.FC<Props> = function ProfileInfo({
  token,
  isMobile,
  setErrorApi,
  isCompany
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState<State>({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  });
  //   const [fireApiPassword, setFireApiPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [fireProfile, setFireProfile] = useState<boolean>(false);
  const [fireUpdateProfile, setFireUpdateProfile] = useState<boolean>(false);
  const [image, setImage] = useState<Array<any>>([]);
  const { mutate: updateTimezoneForCurrentUserMutate } = useSWRConfig()

  const { data: profileUpdated, error: errorProfileUpdated } = useSWR(
    fireUpdateProfile ? values : null,
    api.authApi.setUpdateProfile
  );

  const {
    data: profileInfo,
    error: errorProfileInfo,
    mutate
  } = useSWR(fireProfile ? '/profile' : null, api.authApi.getProfile);

  const handleChange = (prop: keyof State) => (value: string) => {
    setValues({ ...values, [prop]: value === '' ? undefined : value });
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const next = () => {
    if (values.email === '') {
      setError(true);
      setErrorMessage('Email is required');
      return;
    }
    setFireUpdateProfile(true);
  };

  const shareLabelProps = {
    mt: 1
  };

  useEffect(() => {
    setError(false);
    setErrorMessage('');
    setErrorApi(false);
    setFireProfile(true);
  }, []);

  useEffect(() => {
    if (profileInfo) {
      setFireProfile(false);
      setValues({
        email: profileInfo.email,
        firstName: profileInfo.firstName,
        lastName: profileInfo.lastName,
        phoneNumber: profileInfo.phoneNumber
      });
    }
  }, [profileInfo]);

  useEffect(() => {
    if (errorProfileInfo) {
      setErrorApi(true);
      setFireProfile(false);
    }
  }, [errorProfileInfo]);

  useEffect(() => {
    if (errorProfileUpdated) {
      setError(true);
      setFireUpdateProfile(false);
      const { data } = errorProfileUpdated.response;
      setErrorMessage(data.message);
    }
  }, [errorProfileUpdated]);

  useEffect(() => {
    // Update timezone for user when entering in the screen after set his password
    updateTimezoneForCurrentUserMutate('/profile/timezone/current', api.authApi.updateTimezoneForCurrentUser);
  }, [])

  useEffect(() => {
    if (profileUpdated) {
      setFireUpdateProfile(false);
      if (profileUpdated.error) {
        setError(true);
        setErrorMessage(`${(t('ERROR.updateError'), ['Profile'])}`);
      } else if (isCompany) {
        navigate(`/company/invitation/accept-invitation/${token}/4`, {
          state: { ...profileUpdated }
        });
      } else {
        mutate(profileUpdated);
        navigate(`/invitation/accept-invitation/${token}/4`, {
          state: { ...profileUpdated }
        });
      }
    }
  }, [profileUpdated]);

  return (
    <>
      <Typography
        color="primary.main"
        sx={{ textAlign: 'center', width: '100%' }}
        variant="primary-bold"
      >
        {t('LOGIN.invitationInfoTitle')}
      </Typography>
      <br />
      <Box className="Form" sx={{ textAlign: 'left', width: '100%' }}>
        <Box sx={{ marginBottom: '-12px' }}>
          <Typography
            color={`${error ? 'alert.main' : 'mainText.main'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.firstName')}
          </Typography>
        </Box>
        <RegularInput
          customStyle={{ width: '100%' }}
          error={error}
          onChange={handleChange('firstName')}
          value={values.firstName}
        />
        <Box sx={{ marginBottom: '-12px' }}>
          <Typography
            color={`${error ? 'alert.main' : 'mainText.main'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.lastName')}
          </Typography>
        </Box>
        <RegularInput
          customStyle={{ width: '100%' }}
          error={error}
          onChange={handleChange('lastName')}
          value={values.lastName}
        />
        <Box sx={{ marginBottom: '-12px' }}>
          <Typography
            color={`${error ? 'alert.main' : 'mainText.main'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.email')}
          </Typography>
        </Box>
        <RegularInput
          disabled
          customStyle={{ width: '100%' }}
          value={values.email}
        />
        <Box sx={{ marginBottom: '-12px' }}>
          <Typography
            color={`${error ? 'alert.main' : 'mainText.main'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.phoneNumber')}
          </Typography>
        </Box>
        <RegularInput
          customStyle={{ width: '100%' }}
          error={error}
          onChange={handleChange('phoneNumber')}
          type="phone"
          value={values.phoneNumber}
        />
      </Box>
      {getError()}
      <Container
        sx={{
          backgroundColor: 'input.main',
          display: 'flex',
          height: 217,
          justifyContent: 'center',
          mb: 3,
          mt: 3
        }}
      >
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%'
          }}
        >
          {image && image[1] && image[1].fileUrl && (
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: isMobile ? 4 : 40,
                position: 'absolute',
                zIndex: 100
              }}
            >
              <img
                alt="profile"
                src={image[1].fileUrl}
                style={{
                  borderRadius: 70,
                  height: 140,
                  width: 140
                }}
              />
            </Box>
          )}
          <Dropzone
            multiple
            isMobile={isMobile}
            parent="profile"
            setFiles={setImage}
            type="image"
          />
        </Box>
      </Container>
      <Container
        className="button-accept-container"
        sx={{ mt: isMobile ? 4 : 4 }}
      >
        <CustomBottom
          color="primary"
          onClick={() => {
            next();
          }}
          variant="contained"
        >
          <Typography color="mainText.100" variant="primary-bold">
            {t('GENERAL.nextButton')}
          </Typography>
        </CustomBottom>
      </Container>
    </>
  );
};

export default ProfileInfo;
