/* eslint-disable no-unused-vars */
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';

type ThemeProp = {
  children: JSX.Element;
};

export interface PaletteColor {
  contrastText?: string;
  dark?: string;
  light?: string;
  main: string;
}

export enum mainColors {
  MAIN = '#005DA2',
  MAIN_1 = '#A2CDEC',
  MAIN_2 = '#5195C7',
  MAIN_3 = '#2979B5',
  MAIN_4 = '#005DA2',
  MAIN_5 = '#003861',
  MAIN_6 = '#0EA5E9',
  MAIN_7 = '#F4FFF4',
  MAIN_8 = '#5195c7',
  MAIN_LIGHT = '#F4FBFF'
}

export enum secondaryColors {
  SECONDARY = '#7D8090',
  SECONDARY_1 = '#CBCCD3',
  SECONDARY_2 = '#B1B3BC',
  SECONDARY_3 = '#9799A6',
  SECONDARY_4 = '#7D8090',
  SECONDARY_5 = '#4B4D56',
  SECONDARY_6 = '#EBEBEC'
}

export enum accentColors {
  ACCENT = '#189A90',
  ACCENT_1 = '#90D4CE',
  ACCENT_2 = '#68C0BA',
  ACCENT_3 = '#40ADA5',
  ACCENT_4 = '#189A90',
  ACCENT_5 = '#0E5C56',
  ACCENT_6 = '#D9F2F0'
}

export enum textColors {
  DARK_TEXT = '#4B4D56',
  TEXT = '#FFFFFF'
}

export enum surfaceColors {
  SURFACE = '#7D8090',
  SURFACE_1 = '#FDFDFD',
  SURFACE_2 = '#F6F9FB',
  SURFACE_3 = '#EBF2F6',
  SURFACE_4 = '#E1EBF1',
  SURFACE_5 = '#E7F6FD',
  SURFACE_6 = '#F4F4F4'
}

export enum successColors {
  SUCCESS = '#68A550',
  SUCCESS_1 = '#C3DBB9',
  SUCCESS_2 = '#A4C996',
  SUCCESS_3 = '#86B773',
  SUCCESS_4 = '#68A550',
  SUCCESS_5 = '#3E6330',
  SUCCESS_6 = '#E8F9F3',
  SUCCESS_7 = '#10B981',
  SUCCESS_LIGHT = '#FAFFF4'
}

export enum alertColors {
  ALERT = '#CE5A55',
  ALERT_1 = '#EBBDBB',
  ALERT_2 = '#E29C99',
  ALERT_3 = '#D87B77',
  ALERT_4 = '#CE5A55',
  ALERT_5 = '#7C3633',
  ALERT_6 = '#FEF6E7',
  ALERT_7 = '#F59E0B'
}

export enum warningColors {
  ERROR = '#E79137',
  ERROR_1 = '#F5D3AF',
  ERROR_2 = '#F1BD87',
  ERROR_3 = '#ECA75F',
  ERROR_4 = '#E79137',
  ERROR_5 = '#8B5721',
  LIGHT_ERROR = '#FFFFF4'
}

export enum informativeColors {
  INFORMATION = '#59A9CF',
  INFORMATION_1 = '#BDDDEC',
  INFORMATION_2 = '#9BCBE2',
  INFORMATION_3 = '#7ABAD9',
  INFORMATION_4 = '#59A9CF',
  INFORMATION_5 = '#35657C'
}

export enum themePalette {
  ALERT = '#C7373B',
  BACKGROUND = '#F5F7FB',
  BACKGROUND_BOX = '#FDFDFD',
  INFORMATIVE = '#59A9CF',
  INPUT_BG = '#EBF2F6',
  INPUT_BG_2 = '#E1EBF1',
  INPUT_BORDER = '#EBF2F6',
  SUCCESS = '#68A550',
  TITLE = '#1A1A1B',
  WARNING = '#E79137'
}

export enum themeFont {
  FONT_PRIMARY = "'Nunito', sans-serif",
  FONT_SECONDARY = "'Lexend', sans-serif"
}

export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      defaultProps: {
        style: {
          marginBottom: 16,
          marginTop: 16
        }
      }
    },
    MuiButton: {
      defaultProps: {
        style: {
          borderRadius: '0.5em',
          boxShadow: 'none',
          height: 46,
          textTransform: 'none'
        }
      },
      variants: [
        { props: { size: 'large' }, style: { width: 200 } },
        {
          props: { variant: 'cancel' },
          style: {
            backgroundColor: textColors.TEXT,
            border: `1px solid ${alertColors.ALERT}`,
            color: alertColors.ALERT
          }
        },
        {
          props: { variant: 'table' },
          style: {
            ':hover': {
              backgroundColor: 'transparent',
              color: secondaryColors.SECONDARY_3
            },
            backgroundColor: 'transparent',
            color: secondaryColors.SECONDARY_5
          }
        },
        {
          props: { variant: 'solo' },
          style: {
            ':hover': {
              backgroundColor: 'transparent',
              color: mainColors.MAIN_2,
              textDecoration: 'underline'
            },
            backgroundColor: 'transparent',
            color: mainColors.MAIN_3,
            textDecoration: 'underline'
          }
        },
        {
          props: { variant: 'primary' },
          style: {
            ':disabled': {
              backgroundColor: informativeColors.INFORMATION_3,
              color: textColors.TEXT
            },
            ':hover': {
              backgroundColor: mainColors.MAIN_5,
              color: textColors.TEXT
            },
            backgroundColor: mainColors.MAIN,
            color: textColors.TEXT
          }
        },
        {
          props: { variant: 'primary-outlined' },
          style: {
            ':disabled': {
              backgroundColor: informativeColors.INFORMATION_3,
              borderColor: mainColors.MAIN_2,
              color: textColors.TEXT
            },
            ':hover': {
              backgroundColor: textColors.TEXT,
              borderColor: mainColors.MAIN_5,
              color: mainColors.MAIN_5
            },
            backgroundColor: textColors.TEXT,
            borderColor: mainColors.MAIN,
            borderStyle: 'solid',
            borderWidth: '1px',
            color: mainColors.MAIN
          }
        },
        {
          props: { variant: 'secondary' },
          style: {
            ':disabled': {
              backgroundColor: informativeColors.INFORMATION_1,
              color: textColors.TEXT
            },
            ':hover': {
              backgroundColor: informativeColors.INFORMATION_5,
              color: textColors.TEXT
            },
            backgroundColor: informativeColors.INFORMATION,
            color: textColors.TEXT
          }
        },
        {
          props: { variant: 'secondary-outlined' },
          style: {
            ':disabled': {
              backgroundColor: informativeColors.INFORMATION_1,
              borderColor: informativeColors.INFORMATION_2,
              color: textColors.TEXT
            },
            ':hover': {
              backgroundColor: textColors.TEXT,
              borderColor: informativeColors.INFORMATION_5,
              color: informativeColors.INFORMATION_5
            },
            backgroundColor: textColors.TEXT,
            borderColor: informativeColors.INFORMATION,
            borderStyle: 'solid',
            borderWidth: '1px',
            color: informativeColors.INFORMATION
          }
        },
        {
          props: { variant: 'tertiary' },
          style: {
            ':disabled': {
              backgroundColor: successColors.SUCCESS_2,
              color: textColors.TEXT
            },
            ':hover': {
              backgroundColor: successColors.SUCCESS_5,
              color: textColors.TEXT
            },
            backgroundColor: successColors.SUCCESS,
            color: textColors.TEXT
          }
        },
        {
          props: { variant: 'tertiary-outlined' },
          style: {
            ':disabled': {
              backgroundColor: textColors.TEXT,
              borderColor: successColors.SUCCESS_1,
              color: successColors.SUCCESS_1
            },
            ':hover': {
              backgroundColor: textColors.TEXT,
              borderColor: successColors.SUCCESS_5,
              color: successColors.SUCCESS_5
            },
            backgroundColor: textColors.TEXT,
            borderColor: successColors.SUCCESS,
            borderStyle: 'solid',
            borderWidth: '1px',
            color: successColors.SUCCESS
          }
        },
        {
          props: { variant: 'destructive' },
          style: {
            ':disabled': {
              backgroundColor: alertColors.ALERT_1,
              color: textColors.TEXT
            },
            ':hover': {
              backgroundColor: alertColors.ALERT_5,
              color: textColors.TEXT
            },
            backgroundColor: alertColors.ALERT,
            color: textColors.TEXT
          }
        },
        {
          props: { variant: 'destructive-outlined' },
          style: {
            ':disabled': {
              backgroundColor: textColors.TEXT,
              borderColor: alertColors.ALERT_1,
              color: alertColors.ALERT_1
            },
            ':hover': {
              backgroundColor: textColors.TEXT,
              borderColor: alertColors.ALERT_5,
              color: alertColors.ALERT_5
            },
            backgroundColor: textColors.TEXT,
            borderColor: alertColors.ALERT,
            borderStyle: 'solid',
            borderWidth: '1px',
            color: alertColors.ALERT
          }
        }
      ]
    },
    MuiCheckbox: {
      defaultProps: {
        style: {
          borderRadius: 8,
          color: informativeColors.INFORMATION
        }
      }
    },
    MuiChip: {
      defaultProps: {
        style: {
          backgroundColor: themePalette.BACKGROUND,
          borderRadius: '0.5em',
          color: mainColors.MAIN_5,
          padding: '4px'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        style: {
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: '100%',
          padding: 0
        }
      },
      variants: [
        { props: { className: 'Test' }, style: { height: 500, width: 500 } }
      ]
    },
    MuiInput: {
      defaultProps: {
        style: {
          backgroundColor: themePalette.INPUT_BG,
          borderRadius: 8,
          marginBottom: 16,
          marginTop: 16,
          minHeight: 36,
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      variants: [
        {
          props: { disabled: true },
          style: {
            backgroundColor: 'transparent !important',
            border: `1px solid ${themePalette.INPUT_BG}`
          }
        }
      ]
    },
    MuiMenuItem: {
      defaultProps: {
        style: {
          color: textColors.DARK_TEXT,
          fontSize: '15px'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        style: {
          backgroundColor: 'white'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        style: {
          color: textColors.DARK_TEXT,
          fontSize: '15px',
          height: 40,
          marginBottom: 16,
          marginTop: 16
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        style: {
          backgroundColor: themePalette.INPUT_BG,
          borderRadius: 8,
          color: textColors.DARK_TEXT,
          paddingLeft: 8,
          paddingRight: 8
        }
      }
    }
  },
  palette: {
    accent: {
      100: accentColors.ACCENT_1,
      200: accentColors.ACCENT_2,
      300: accentColors.ACCENT_3,
      400: accentColors.ACCENT_4,
      500: accentColors.ACCENT_5,
      main: accentColors.ACCENT
    },
    alert: {
      100: alertColors.ALERT_1,
      200: alertColors.ALERT_2,
      300: alertColors.ALERT_3,
      400: alertColors.ALERT_4,
      500: alertColors.ALERT_5,
      main: alertColors.ALERT
    },
    background: {
      box: themePalette.BACKGROUND_BOX,
      default: themePalette.BACKGROUND
    },
    informative: {
      100: informativeColors.INFORMATION_1,
      200: informativeColors.INFORMATION_2,
      300: informativeColors.INFORMATION_3,
      400: informativeColors.INFORMATION_4,
      500: informativeColors.INFORMATION_5,
      main: informativeColors.INFORMATION
    },
    input: {
      100: themePalette.INPUT_BG_2,
      200: themePalette.TITLE,
      main: themePalette.INPUT_BG
    },
    mainText: {
      100: textColors.TEXT,
      200: secondaryColors.SECONDARY_3,
      300: secondaryColors.SECONDARY_4,
      400: mainColors.MAIN_4,
      500: mainColors.MAIN_5,
      main: textColors.DARK_TEXT
    },
    primary: {
      100: mainColors.MAIN_1,
      200: mainColors.MAIN_2,
      300: mainColors.MAIN_3,
      400: mainColors.MAIN_4,
      500: mainColors.MAIN_5,
      main: mainColors.MAIN
    },
    primaryText: {
      100: mainColors.MAIN_1,
      200: mainColors.MAIN_2,
      300: mainColors.MAIN_3,
      400: mainColors.MAIN_4,
      500: mainColors.MAIN_5,
      main: mainColors.MAIN
    },
    secondary: {
      100: secondaryColors.SECONDARY_1,
      200: secondaryColors.SECONDARY_2,
      300: secondaryColors.SECONDARY_3,
      400: secondaryColors.SECONDARY_4,
      500: secondaryColors.SECONDARY_5,
      main: secondaryColors.SECONDARY
    },
    success: {
      100: successColors.SUCCESS_1,
      200: successColors.SUCCESS_2,
      300: successColors.SUCCESS_3,
      400: successColors.SUCCESS_4,
      500: successColors.SUCCESS_5,
      main: successColors.SUCCESS
    },
    surface: {
      100: surfaceColors.SURFACE_1,
      200: surfaceColors.SURFACE_2,
      300: surfaceColors.SURFACE_3,
      400: surfaceColors.SURFACE_4,
      main: surfaceColors.SURFACE
    },
    warning: {
      100: warningColors.ERROR_1,
      200: warningColors.ERROR_2,
      300: warningColors.ERROR_3,
      400: warningColors.ERROR_4,
      500: warningColors.ERROR_5,
      main: warningColors.ERROR
    }
  },
  typography: {
    body_100: {
      fontFamily: themeFont.FONT_SECONDARY,
      fontSize: '12px',
      lineHeight: '20px'
    },
    body_200: {
      fontFamily: themeFont.FONT_SECONDARY,
      fontSize: '14px',
      lineHeight: '20px'
    },
    body_300: {
      fontFamily: themeFont.FONT_SECONDARY,
      fontSize: '16px',
      lineHeight: '20px'
    },
    body_400: {
      fontFamily: themeFont.FONT_SECONDARY,
      fontSize: '18px',
      lineHeight: '20px'
    },
    body_500: {
      fontFamily: themeFont.FONT_SECONDARY,
      fontSize: '20px',
      lineHeight: '20px'
    },
    button_100: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px'
    },
    button_200: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '20px'
    },
    fontFamily: themeFont.FONT_PRIMARY,
    fontSize: 16,
    heading_100: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '12px',
      lineHeight: '20px'
    },
    heading_200: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '14px',
      lineHeight: '20px'
    },
    heading_300: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px'
    },
    heading_400: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '20px'
    },
    heading_main: {
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '10px',
      lineHeight: '20px'
    },
    modalTitle: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px'
    },
    primary: {
      fontWeight: 500,
      lineHeight: '22px'
    },
    'primary-bold': {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '22px'
    },
    'primary-semi': {
      fontWeight: 600,
      lineHeight: '22px'
    },
    primaryBase: { font: themeFont.FONT_PRIMARY },
    secondary: {
      fontFamily: themeFont.FONT_SECONDARY,
      fontWeight: 500,
      lineHeight: '20px'
    },
    'secondary-bold': {
      fontFamily: themeFont.FONT_SECONDARY,
      fontWeight: 700,
      lineHeight: '20px'
    },
    'secondary-semi': {
      fontFamily: themeFont.FONT_SECONDARY,
      fontWeight: 600,
      lineHeight: '20px'
    },
    secondaryBase: { font: themeFont.FONT_PRIMARY },
    title: {
      color: themePalette.TITLE,
      fontFamily: themeFont.FONT_PRIMARY,
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '36px'
    }
  }
});

const ThemeConfig: React.FC<ThemeProp> = function ThemeConfig({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
