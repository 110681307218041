/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CardStimated, { ICardStimated } from './CardStimated';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import CardServiceFrequency from './CardServiceFrequency';

interface ICardHeader {
  componentType: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  propsTime?: ICardStimated;
  propsType?: string;
  projectType: string;
}

const CardHeader: React.FC<ICardHeader> = function CardHeader({
  componentType,
  title,
  isOpen,
  setIsOpen,
  propsType,
  propsTime,
  projectType
}) {
  const componentTypes: any = {
    estimated: (
      <CardStimated
        estimated={propsTime?.estimated || ''}
        projectType={projectType}
        total={propsTime?.total || ''}
      />
    ),
    service: ['Weekly', 'Biweekly', 'Monthly'].includes(propsType || '') ? (
      <CardServiceFrequency type={propsType || ''} />
    ) : null
  };

  return (
    <Box
      onClick={() => setIsOpen(!isOpen)}
      style={{
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px'
      }}
    >
      <Typography variant="primary-bold">{title}</Typography>
      <Box
        style={{
          alignContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {componentTypes[componentType]}
        <Box
          height={30}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
          width={30}
        >
          <Button
            size="small"
            style={{
              height: '30px',
              width: '30px'
            }}
          >
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CardHeader;
