import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps
} from '@mui/material';

import useSWR from 'swr';
import Api from '../../api';
import TableComponent from '../../components/shared/TableComponent';
import SummaryDrawer from '../../components/SummaryDrawer';
import useNavigationStore from '../../store/zustand/navigation';
import InviteModal from '../../components/modals/InviteModal';

const ServiceButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  marginBottom: '15px'
}));

const Users: React.FC = function Users() {
  const { t } = useTranslation();
  const createAction = Api.categoryApi.createCategory;
  const updateAction = Api.categoryApi.updateCategory;
  const deleteAction = Api.categoryApi.deleteCategory;
  const [openDraw, setOpenDraw] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fireUsersAPI, setFireUsersAPI] = useState<boolean>(true);
  const [valueDrawer, setValueDrawer] = useState<{}>('');
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const handleOpenDraw = () => setOpenDraw(true);
  const handleCloseDraw = () => {
    setValueDrawer({});
    setOpenDraw(false);
  };
  const [data, setData] = useState<Array<any>>([]);
  const navigation = useNavigationStore((state) => state);
  const pageNumber = useRef<number>(1);

  const params = {
    items: 20,
    page: pageNumber.current,
    url: 'api/categories'
  };

  const {
    data: categoriesResponse,
    error: categoryError,
    mutate
  } = useSWR(fireUsersAPI ? params : null, Api.categoryApi.getCategories);

  const loadMoreItems = () => {
    pageNumber.current += 1;
    setFireUsersAPI(true);
  };

  useEffect(() => {
    if (categoryError) {
      setData([]);
    }
  }, [categoryError]);

  useEffect(() => {
    if (categoriesResponse && categoriesResponse.data.length !== 0) {
      setData(categoriesResponse.data);
      setFireUsersAPI(false);
    }
  }, [categoriesResponse]);

  useEffect(() => {
    navigation.setCurrentStepScreen('Categories');
  }, []);

  const isItemLoaded = (index: any) => !!data[index];

  return (
    <Container sx={{ margin: '40px', width: 'auto' }}>
      {valueDrawer && (
        <SummaryDrawer
          arrayDisabled
          arrayType="category"
          createNestedAction={Api.categoryApi.createCategory}
          deleteAction={deleteAction}
          handleClose={handleCloseDraw}
          itemsArray={
            data as { description: string; id: string; name: string }[]
          }
          mutateAction={mutate}
          mutateNestedAction={mutate}
          open={openDraw}
          type="category"
          updateAction={updateAction}
          value={valueDrawer}
        />
      )}
      <ServiceButton onClick={handleOpen} variant="contained">
        <Typography color="mainText.100">{t('GENERAL.add')}</Typography>
      </ServiceButton>
      {data && data.length !== 0 && (
        <Box onClick={handleOpenDraw}>
          <TableComponent
            action={(value) => setValueDrawer(value)}
            headKeys={['name', 'description']}
            isItemLoaded={isItemLoaded}
            itemCount={
              categoriesResponse ? categoriesResponse.pagination.totalItems : 0
            }
            loadMoreItems={loadMoreItems}
            valuesArray={{ data } as unknown as { data: any[] }}
          />
        </Box>
      )}
      {openModal && (
        <InviteModal
          action={createAction}
          handleClose={handleClose}
          mutateAction={mutate}
          openValue={openModal}
          roles={[]}
          type="user"
        />
      )}
    </Container>
  );
};

export default Users;
