import React, { useEffect, useState } from 'react';
import { Box, Container, useTheme } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ReportIcon from '@mui/icons-material/Report';
import blueBerryLogoLeters from '../../../../../assets/images/bluberry.png';
import blueBerryLogoMini from '../../../../../assets/images/bluberry_logo_mini.png';

interface Props {}

const ProposalNotFound: React.FC<Props> = function ProposalNotFound() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            mt: 2,
            width: '100%'
          }}
        >
          <Box>
            <img
              alt="BlueBerry Logo"
              className="logo-image-mini"
              src={blueBerryLogoMini}
            />
            <img
              alt="BlueBerry leters"
              className="logo-image-letters"
              src={blueBerryLogoLeters}
            />
          </Box>
        </Box>
        <Box
          mt={6}
          padding={4}
          sx={{
            border: `4px solid ${theme.palette.grey[300]}`,
            maxWidth: '600px',
            width: '600px'
          }}
        >
          <Box
            mb={2}
            mt={2}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography variant="title">
              {t('LEAD.CREATE.PROPOSAL.proposalNotFoundTitle')}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ReportIcon sx={{ color: '#d3455b', fontSize: '200px' }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ProposalNotFound;
