import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  Modal,
  Backdrop
} from '@mui/material';

const ModalContainer = styled(Box)({
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 407
});

const ButtonContainerModal = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px'
});

const DrawerButton = styled(Button)({
  color: 'white',
  marginBottom: '20px',
  width: '100%'
});

interface Props {
  client: string;
  modalText: string;
  onOk: () => void;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OkModal: React.FC<Props> = function OkModal({
  setShowModal,
  modalText,
  client,
  onOk
}) {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <Container sx={{ margin: '40px', width: 'auto' }}>
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalContainer>
          <Typography color="primary" variant="heading_400">
            <Box mb={2} mt={2}>
              {modalText}
            </Box>
          </Typography>
          <Typography color="secondary" variant="primary-bold">
            <Box mb={2} mt={2}>
              {client}
            </Box>
          </Typography>
          <ButtonContainerModal>
            <Button
              onClick={() => {
                setShowModal(false);
                onOk();
              }}
              sx={{ width: '200px' }}
              variant="primary"
            >
              <Typography>{t('MODAL.okButton')}</Typography>
            </Button>
          </ButtonContainerModal>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default OkModal;
