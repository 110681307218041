import React, { useEffect, useState } from 'react';
import '../../style/App.scss';
import {
  Box,
  useTheme,
  Typography,
  PaletteColor,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import { getCustomColorBasedOnStatus } from '../../utils/general';

interface Props {
  onClose?: () => void;
  text: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

const ToastNotification: React.FC<Props> = function ToastNotification({
  type,
  text,
  onClose
}) {
  const theme = useTheme();
  const currentPalette: PaletteColor = getCustomColorBasedOnStatus(type, theme);
  const [open, setOpen] = useState<boolean>(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      size="small"
    >
      <i className="custom-icon icon-cancelCircle" />
    </IconButton>
  );

  useEffect(() => {}, []);

  return (
    <Box>
      <Snackbar
        action={action}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={5000}
        message={text}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          sx={{ border: 1, borderColor: currentPalette[400], width: '100%' }}
        >
          <Typography color={currentPalette[400]} variant="primary-bold">
            {text}
          </Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
};

ToastNotification.defaultProps = {
  onClose: undefined
};

export default ToastNotification;
