import {
  SearchApi,
  SearchApiSearchGetAllRequest
} from '../api-client/generated/api';

const apiCaller = new SearchApi();

const getSearch = async (q: SearchApiSearchGetAllRequest) => {
  const response = await apiCaller.searchGetAll(q);
  const { data } = response;
  return data;
};

export default {
  getSearch
};
