import { Box, SliderProps, TextField, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const GeneralInfoContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1)
}));

interface Props {
  clientView?: boolean;
  values: any;
}

const ProposalGeneralInformation: React.FC<Props> =
  function ProposalGeneralInformation({ values, clientView }) {
    const { t } = useTranslation();

    return (
      <Box mt={clientView ? 2 : 6}>
        <Box
          display="flex"
          flexDirection={isMobile || clientView ? 'column' : 'row'}
          gap={1}
          justifyContent="space-between"
        >
          <Box>
            <Typography
              sx={{ fontSize: clientView ? 20 : 26 }}
              variant="body_500"
            >
              {t('LEAD.CREATE.PROPOSAL.generalInfoTitle')}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: clientView ? 18 : 25 }}
              variant="body_500"
            >
              {t('LEAD.CREATE.PROPOSAL.generalInfoDuration')}
            </Typography>
            <Typography
              sx={{ fontSize: clientView ? 18 : 25 }}
              variant="primary"
            >
              {values?.startDate
                ? dayjs(values.startDate).format('MM/DD/YYYY')
                : '5/1/2023'}
            </Typography>{' '}
            -{' '}
            <Typography
              sx={{ fontSize: clientView ? 18 : 25 }}
              variant="primary"
            >
              {values?.endDate
                ? dayjs(values.endDate).format('MM/DD/YYYY')
                : '5/1/2023'}
            </Typography>
          </Box>
        </Box>
        <GeneralInfoContainer gap={clientView ? 1 : 2}>
          <Box
            display="flex"
            flexDirection={isMobile || clientView ? 'column' : 'row'}
            gap={1}
            justifyContent="space-between"
          >
            <Box display="flex">
              <Typography variant="primary-bold">
                {t('LEAD.CREATE.PROPOSAL.generalInfoClient')}
              </Typography>
              &nbsp;
              <Typography variant="primary">
                {values?.client?.name ?? ''}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="primary-bold">
                {t('LEAD.CREATE.PROPOSAL.generalInfoContact')}
              </Typography>
              &nbsp;
              <Typography variant="primary">
                {values?.contact?.name ?? ''}
              </Typography>
              &nbsp;
              <Typography variant="primary">
                ( {values?.contact?.phoneNumber ?? ''} )
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.generalInfoServiceAddress')}
            </Typography>
            &nbsp;
            <Typography variant="primary">
              {values?.address?.address ?? ''}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.generalInfoProjectName')}
            </Typography>
            &nbsp;
            <Typography variant="primary">{values?.name ?? ''}</Typography>
          </Box>
          <Box display="flex">
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.generalInfoDescription')}
            </Typography>
            &nbsp;
            <Typography variant="primary">
              {values?.description ?? ''}
            </Typography>
          </Box>
        </GeneralInfoContainer>
      </Box>
    );
  };

ProposalGeneralInformation.defaultProps = {
  clientView: false
};

export default ProposalGeneralInformation;
