import axios from 'axios';
import appConfig from '../../config';

//  Creating axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000',
  headers: {
    'X-App-Id': appConfig.appId
  }
});

axiosInstance.interceptors.request.use(
  (request) => {
    if (localStorage.getItem('accessToken')) {
      request.headers.Authorization = `Bearer ${localStorage
        .getItem('accessToken')
        .replaceAll('"', '')}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

//  Returning axios instance to use across the app
export default axiosInstance;
