import {
  ServiceEndpointsApi,
  CreateServiceDTO,
  ServiceEndpointsApiServiceGetAllPaginatedRequest,
  ServiceEndpointsApiServiceGetOneByIdRequest
} from '../api-client/generated/api';

const apiCaller = new ServiceEndpointsApi();

const getServices = async (
  params: ServiceEndpointsApiServiceGetAllPaginatedRequest
) => {
  const response = await apiCaller.serviceGetAllPaginated(params);
  const { data } = response;
  return data;
};

const getAllServices = async () => {
  const response = await apiCaller.serviceGetAll();
  const { data } = response;
  return data;
};

const getService = async (
  param: ServiceEndpointsApiServiceGetOneByIdRequest
) => {
  if (param && param.id) {
    const response = await apiCaller.serviceGetOneById(param);
    const { data } = response;
    return data;
  }
  return null;
};

const createService = async (params: CreateServiceDTO) => {
  const response = await apiCaller.serviceCreate({ createServiceDTO: params });
  const { data } = response;
  return data;
};

const updateService = async (params: any) => {
  if (!params.id) {
    return null;
  }
  // tasks could come with all the object, BE receives only the ids
  const tasks =
    params.tasks && params.tasks.length > 0 && params.tasks[0].id
      ? params.tasks.map((task: any) => {
          return task.id;
        })
      : params.tasks;

  const response = await apiCaller.serviceUpdate({
    id: params.id,
    updateServiceDTO: {
      description: params.description,
      name: params.name,
      setupTime: parseFloat(params.setupTime),
      tasks,
      teardownTime: parseFloat(params.teardownTime)
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteService = async (params: { id: string; url: string }) => {
  if (!params.id) {
    return null;
  }
  const response = await apiCaller.serviceDelete({ id: params.id });
  const { status, statusText } = response;
  return { status, statusText };
};

export default {
  createService,
  deleteService,
  getAllServices,
  getService,
  getServices,
  updateService
};
