import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  MenuItem,
  IconButton,
  Menu,
  styled,
  Typography,
  Box
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSWR from 'swr';
import Api from '../../api';
import avatar from '../../assets/images/avatars-25.png';
import useAuthStore from '../../store/zustand/auth';
import PhotoUploader from '../form/PhotoUploader';
import useUserRole from '../../hooks/useUserRoles';
import { ROLES } from '../../helpers/roles';
import { getLocalStorage } from '../../utils/authHelper';

const MenuButton = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.surface?.['300'],
    color: theme.palette.primary?.main
  },
  alignItems: 'center',
  borderRadius: '0.5em',
  color: theme.palette.secondary?.['400'],
  display: 'flex',
  fontSize: '15px',
  fontWeight: 'bold',
  height: '50px',
  justifyContent: 'center',
  margin: '0px 10px'
}));

const MenuComponent: React.FC = function MenuComponent() {
  const userState = useAuthStore((state) => state);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [profilePic, setprofilePic] = useState<string>(avatar);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [getPhoto, setGetPhoto] = useState<boolean>(false);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isClient = useUserRole([ROLES.CLIENT]);

  const { data: photoResponse, error: errorPhoto } = useSWR(
    getPhoto ? 'photo/profile' : null,
    Api.StorageApi.getProfilePhoto
  );

  useEffect(() => {
    if (!isClient) {
      setGetPhoto(true);
    }
  }, []);

  useEffect(() => {
    // TODO: ask for status response in BE request
    if (photoResponse) {
      const { data } = photoResponse;
      if (data) {
        if (data[1]) {
          const photo: any = data[1];
          setprofilePic(photo.fileUrl);
        }
      }
      setGetPhoto(false);
    }
  }, [photoResponse]);

  useEffect(() => {
    if (errorPhoto) {
      setGetPhoto(false);
    }
  }, [errorPhoto]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'fixed !important',
          right: '136px !important',
          textAlign: 'right',
          width: '136px'
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              color: 'mainText.main',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%'
            }}
          >
            <Typography variant="body_300">{userState.firstName}</Typography>
          </Box>
        )}
        {!isMobile && (
          <Box
            sx={{
              color: 'secondary.300',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%'
            }}
          >
            <Typography variant="body_200">
              {userState.roles.join(',  ')}
            </Typography>
          </Box>
        )}
      </Box>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        id="basic-button"
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent'
          },
          height: 64,
          position: isMobile ? 'static' : 'fixed',
          right: '30px',
          width: 88
        }}
      >
        <PhotoUploader edit={false} photoType="profile" size={50} />
        {!isMobile && <ExpandMoreIcon />}
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            '& .MuiAvatar-root': {
              height: 32,
              ml: -0.5,
              mr: 1,
              width: 32
            },
            '&:before': {
              bgcolor: 'background.paper',
              content: '""',
              display: 'block',
              height: 10,
              position: 'absolute',
              right: 14,
              top: 0,
              transform: 'translateY(-50%) rotate(45deg)',
              width: 10,
              zIndex: 0
            },
            borderRadius: '0.5em',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            overflow: 'visible'
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
        id="basic-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {!isClient && (
          <MenuButton
            onClick={() => {
              navigate('/app/profile/');
              handleClose();
            }}
          >
            <i
              className="custom-icon icon-profile"
              style={{ fontSize: 20, marginRight: 10 }}
            />
            View Profile
          </MenuButton>
        )}
        {isClient && (
          <MenuButton
            onClick={() => {
              const token = getLocalStorage('accessToken');
              userState.changeTenantClient();
              handleClose();
              navigate(`/app-link/${token}`, {
                replace: true
              });
            }}
          >
            Change Company
          </MenuButton>
        )}
        <MenuButton
          onClick={() => {
            userState.logout();
            setTimeout(() => {
              navigate('/login', {
                replace: true
              });
            }, 1000);
          }}
        >
          <i
            className="custom-icon icon-logout"
            style={{ fontSize: 20, marginRight: 10 }}
          />
          Log Out
        </MenuButton>
      </Menu>
    </>
  );
};

export default MenuComponent;
