/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import '../../style/App.scss';
import {
  styled,
  Autocomplete,
  Checkbox,
  Chip,
  Typography,
  TextField,
  Avatar
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box } from '@mui/system';
import useSWR from 'swr';
import Api from '../../api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AutoCompleteStyled = styled(Autocomplete)(() => ({
  '& .MuiFormLabel-root': {
    color: 'white !important',
    fontSize: '14px'
  },
  '& input': {
    bgcolor: 'white !important',
    fontSize: '14px',
    height: '23px',
    width: 200
  },
  '& li': {
    fontSize: '12px'
  },
  backgroundColor: 'white !important',
  borderRadius: '0.5em',
  display: 'inline-block'
}));

// eslint-disable-next-line consistent-return
const getColor = (status: string) => {
  switch (status) {
    case 'Available':
      return '#1ead9f';
    case 'Pending':
      return '#e7833a';
    case 'Unavailable':
      return '#d3445a';
    default:
      break;
  }
};

interface AutocompleteSupervisor {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  multiple?: boolean;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  type: string;
  // eslint-disable-next-line react/require-default-props
  values: any;
  // eslint-disable-next-line react/require-default-props
  width?: number;
}

const AutoCompleteSupervisor: React.FC<AutocompleteSupervisor> =
  function AutoCompleteSupervisor({
    type,
    values,
    setValues,
    disabled,
    width,
    multiple = true
  }) {
    const [currentSupervisor, setCurrentSupervisor] = useState<any>(
      values.supervisor || null
    );

    const { data: supervisorsList, error: mainError } = useSWR(
      { roles: ['Supervisor'], url: 'get/users/roles' },
      Api.UserResocesApi.getUsersByRole
    );

    const [filteredOptions, setFilteredOptions] = useState<any>([]);

    useEffect(() => {
      if (
        undefined !== supervisorsList &&
        supervisorsList?.length > 0 &&
        undefined !== supervisorsList[0]?.id
      ) {
        const auxV = supervisorsList.map((supervisorData: any) => {
          return {
            ...supervisorData,
            selected: supervisorData?.id === currentSupervisor?.id,
            status: supervisorData?.id === currentSupervisor?.id
          };
        });

        setFilteredOptions(auxV);
      }
    }, [supervisorsList, currentSupervisor]);
    //  ================================================================================= USEEFFECTS

    const handleSelectedOptionsChange = (event: any, options: any) => {
      if (options) {
        setCurrentSupervisor(options);
        setValues(options);
      } else {
        setCurrentSupervisor(null);
        setValues({ name: '' });
      }
    };

    const getOptionLabelFormatted = (option: any) => {
      let label = '';
      if(option.name && option.name.length > 0) {
        label = option.name;
      } else if (option.firstName && option.lastName) {
        label = `${option.firstName} ${option.lastName}`;
      }
      return label;
    }

    return (
      <AutoCompleteStyled
        disableCloseOnSelect
        disabled={disabled}
        getOptionLabel={(option: any) => getOptionLabelFormatted(option)}
        id="supervisor"
        multiple={multiple}
        onChange={handleSelectedOptionsChange}
        options={filteredOptions || []}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={params.InputProps}
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'hidden'
                },
                backgroundColor: 'white !important',
                borderStyle: 'hidden',
                fontSize: '15px'
              }}
              value={values.supervisor?.name || ''}
              variant="outlined"
            />
          );
        }}
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={option.selected}
              checkedIcon={checkedIcon}
              icon={icon}
              style={{
                marginRight: 8
              }}
            />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Box>
                <Box>
                  <Typography variant="body_300">
                    {`${option.firstName} ${option.lastName}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </li>
        )}
        size="small"
        sx={{ width: width || 400 }}
        value={currentSupervisor || null}
      />
    );
  };

export default AutoCompleteSupervisor;
