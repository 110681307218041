import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AUTHORIZED_VIEW_NAMES,
  DEFAULT_SCREENS
} from '../../helpers/routesConstants';
import ListingLeads from './ListingLeads';
import CreateLead from './CreateLead';
import EditLead from './EditLead';

const Leads: React.FC = function Leads() {
  return (
    <Routes>
      <Route
        element={<Navigate to={DEFAULT_SCREENS.LEADS} />}
        path={AUTHORIZED_VIEW_NAMES.INVALID}
      />
      <Route element={<ListingLeads />} path={AUTHORIZED_VIEW_NAMES.LIST} />
      <Route element={<CreateLead />} path={AUTHORIZED_VIEW_NAMES.NEW} />
      <Route element={<EditLead />} path={AUTHORIZED_VIEW_NAMES.EDIT_LEAD} />
    </Routes>
  );
};

export default Leads;
