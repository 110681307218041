/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import CardContainerServices from './CardContainerServices';
import { convertDecimalToHora } from '../../../../utils/general';
import Chat from '../../../../components/client/Chat';
import api from '../../../../api';

interface Props {
  dayChecked: string;
  detailProject: any;
  projectType: string;
  typeView: any;
  utcUpadtedAt: string;
}
const ServicesTimeContainer: React.FC<Props> = function ServicesTimeContainer({
  detailProject,
  projectType,
  typeView,
  dayChecked,
  utcUpadtedAt
}) {
  const location = useLocation();
  const currentUrl = location.pathname;
  const isClientPage = currentUrl.includes('client-page');

  const currentDate = new Date();
  const formattedDateToday = dayjs(currentDate).format('D/M/YYYY');

  const headerServiceTme = () => {
    return (
      <>
        <Typography variant="primary-bold">
          {projectType === 'Regular' ? 'Regular' : 'Ad-hoc'} Service times
        </Typography>
        <br />
        {projectType === 'Ad-Hoc' && (
          <>
            <Typography color="secondary.400" fontSize={12} variant="secondary">
              Travel time (round trip):
            </Typography>{' '}
            <Typography
              color="econdary.500"
              fontSize={13}
              variant="primary-bold"
            >
              {`${convertDecimalToHora(
                detailProject?.proposal?.lead?.serviceTimes[0].travelTime
              )}`}
            </Typography>
          </>
        )}
      </>
    );
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  return (
    <Box
      style={{ width: '100%' }}
      sx={{
        display: 'flex',
        flexGrow: 1,
        padding: '5px'
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={12} xl={12} xs={12}>
          {headerServiceTme()}
          <br />
          <br />
        </Grid>
        {detailProject?.proposal?.lead?.serviceTimes &&
          detailProject?.proposal?.lead?.serviceTimes.map(
            (index: any, key: any) => {
              return (
                <CardContainerServices
                  count={`${key + 1}`}
                  data={index}
                  dayChecked={
                    typeView === 'calendar'
                      ? dayjs(dayChecked).format('D/M/YYYY')
                      : formattedDateToday
                  }
                  detailProjectId={detailProject}
                  key={key}
                  projectType={projectType}
                  type="serviceTime"
                  typeView={typeView}
                  utcUpadtedAt={utcUpadtedAt}
                />
              );
            }
          )}
      </Grid>
    </Box>
  );
};
export default ServicesTimeContainer;
