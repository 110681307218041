import React, { useState } from 'react';
import { IconButton, Input, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
  customStyle: object;
  error?: boolean;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  submitEvent?: () => void;
  success?: boolean;
}

interface State {
  password: string;
  showPassword: boolean;
}

const PasswordInput: React.FC<Props> = function PasswordInput({
  customStyle,
  error = false,
  success = false,
  onChange,
  submitEvent
}) {
  const secondaryState = error || success;

  const getMainColor = () => {
    if (error || success) {
      return error ? 'alert.main' : 'success.main';
    }
    return 'mainText.main';
  };

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      if (prop === 'password') {
        onChange(event.target.value);
      }
    };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };
  return (
    <Input
      disableUnderline
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            edge="end"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {values.showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      error={secondaryState}
      id="outlined-adornment-password"
      onChange={handleChange('password')}
      onKeyDown={(e) => {
        if ((e.code === 'Enter' || e.keyCode === 13) && submitEvent) {
          submitEvent();
        }
      }}
      sx={{
        '&.Mui-error': {
          border: '1px solid',
          borderColor: error ? 'alert.main' : 'success.main'
        },
        ...customStyle,
        color: getMainColor()
      }}
      type={values.showPassword ? 'text' : 'password'}
      value={values.password}
    />
  );
};

PasswordInput.defaultProps = {
  error: false,
  submitEvent: () => {},
  success: false
};

export default PasswordInput;
