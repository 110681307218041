import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  AUTHORIZED_VIEW_NAMES,
  DEFAULT_SCREENS,
  GLOBAL
} from '../helpers/routesConstants';
import AuthProcess from './AuthProcess';
import AuthByRole from '../routes/AuthByRole';
import { ROLES } from '../helpers/roles';
import ChatsView from './chats';
import ProjectsView from './projects';
import CompanySettingsPage from './companySettings/CompanySettingsPage';
import Companies from './companies';
import CalendarView from './calendar/CalendarView';
import CalendarTechnicianView from './calendar/CalendarTechnicianView';
import Timesheet from './timesheet/Timesheet';
import TimesheetTechnician from './timesheet/TimesheetTechnician';
import Leads from './leads';
import ScreenLoader from '../components/shared/ScreenLoader';
import useAuthStore from '../store/zustand/auth';
import AssignmentsView from './assignments/AssignmentsView';
import CompanyIntegrationPage from './integrations/CompanyIntegrationPage';

const ServiceCatalog = lazy(() => import('./serviceCatalog'));
const Resources = lazy(() => import('./resources'));
const Clients = lazy(() => import('./clients'));
const DashboardView = lazy(() => import('./dashboard'));
const LandingView = lazy(() => import('./landing'));
const ProfileView = lazy(() => import('./profile'));

function AppContainer() {
  const userState = useAuthStore((state) => state);
  const userRoles = [...userState.roles];

  const dashboard = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <DashboardView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.DASHBOARD}
    />
  );

  const landing = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <LandingView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.LANDING}
    />
  );

  const profile = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <ProfileView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.PROFILE}
    />
  );

  const resources = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.ADMINISTRATOR,
            ROLES.OPERATIONS,
            ROLES.SUPER_USER
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Resources />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.RESOURCES}
      />
    </Route>
  );

  const serviceCatalog = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.OPERATIONS,
            ROLES.SUPER_USER,
            ROLES.ADMINISTRATOR
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <ServiceCatalog />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.SERVICE_CATALOG}
      />
    </Route>
  );

  const company = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.SUPER_USER]} />}>
      <Route element={<Companies />} path={AUTHORIZED_VIEW_NAMES.COMPANIES} />
    </Route>
  );

  const companySettings = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.ADMINISTRATOR,
            ROLES.OPERATIONS,
            ROLES.SUPER_USER
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <CompanySettingsPage />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.COMPANY_SETTINGS}
      />
    </Route>
  );

  const companySettingsIntegration = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.ADMINISTRATOR]} />}>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <CompanyIntegrationPage />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.COMPANY_SETTINGS_INTEGRATION}
      />
    </Route>
  );

  const clients = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.OPERATIONS]} />}>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Clients />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.CLIENTS}
      />
    </Route>
  );

  const leads = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.OPERATIONS]} />}>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Leads />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.LEADS}
      />
    </Route>
  );

  const chats = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <ChatsView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.CHATS}
    />
  );

  const calendar = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          {isMobile ? <CalendarTechnicianView /> : <CalendarView />}
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.CALENDAR}
    />
  );

  const projects = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <ProjectsView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.PROJECTS}
    />
  );

  const timesheet = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          {!userRoles.includes(ROLES.TECHNICIAN) ? (
            <Timesheet />
          ) : (
            <TimesheetTechnician />
          )}
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.TIMESHEET}
    />
  );

  const assignments = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <AssignmentsView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.ASSIGNMENTS}
    />
  );

  return (
    <Routes>
      <Route
        element={<Navigate to={DEFAULT_SCREENS.LANDING} />}
        path={AUTHORIZED_VIEW_NAMES.INVALID}
      />
      {dashboard()}
      {landing()}
      {profile()}
      {resources()}
      {company()}
      {companySettings()}
      {companySettingsIntegration()}
      {serviceCatalog()}
      {clients()}
      {leads()}
      {calendar()}
      {chats()}
      {projects()}
      {timesheet()}
      {assignments()}

      {/* --- AUTH PROCESS ---- */}
      {/* -- CHANGE PASSWORD -- */}
      <Route
        element={<AuthProcess startProcess="changePassword" />}
        path={GLOBAL.CHANGE_PASSWORD}
      />
      {/* -- RESET PASSWORD -- */}
      <Route
        element={<AuthProcess startProcess="resetPassword" />}
        path={GLOBAL.RESET_PASSWORD}
      >
        <Route
          element={<AuthProcess startProcess="resetPassword" />}
          path=":token"
        />
      </Route>
      {/* -- FORGOT PASSWORD -- */}
      <Route
        element={<AuthProcess startProcess="forgotPassword" />}
        path={GLOBAL.FORGOT_PASSWORD}
      >
        <Route
          element={<AuthProcess startProcess="forgotPassword" />}
          path=":token"
        />
      </Route>
      <Route
        element={<AuthProcess startProcess="impersonate" />}
        path={GLOBAL.IMPERSONATE}
      />
    </Routes>
  );
}

export default AppContainer;
