import { CreateTaskDTO, TaskApi } from '../api-client/generated/api';

const apiCaller = new TaskApi();

const createTravelTimeIteration = async (params: CreateTaskDTO) => {
  const response = await apiCaller.taskCreate({
    createTaskDTO: {
      ...params,
      imageRequired: params.imageRequired ? params.imageRequired : false
    }
  });
  const { data } = response;
  return data;
};

const updateTravelTimeIteration = async (params: any) => {
  if (!params.id) {
    return null;
  }
  const {
    id,
    name,
    description,
    skills,
    taskChecklist,
    materials,
    measure,
    measurePerHour,
    imageRequired,
    minimumResources
  } = params;
  const response = await apiCaller.taskUpdate({
    id,
    updateTaskDTO: {
      description,
      imageRequired,
      materials,
      measure,
      measurePerHour: parseFloat(measurePerHour),
      minimumResources: parseFloat(minimumResources),
      name,
      skills,
      taskChecklist
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

export default {
  createTravelTimeIteration,
  updateTravelTimeIteration
};
