import { FinancialApi } from '../api-client/generated/api';

const apiCaller = new FinancialApi();

const getAllIntegrations = async () => {
  const { data } = await apiCaller.financialGetFinancialIntegrations();
  return data;
};

const postConnectAccount = async (params: any) => {
  console.log(params);

  const { clientId, clientSecret } = params;
  const { data } = await apiCaller.financialConnectFinancialAccount({
    body: {
      clientId,
      clientSecret
    }
  });
  return data;
};

const postSynchAccount = async (params: any) => {
  console.log(params);

  const { clientId, clientSecret } = params;
  const { data } = await apiCaller.financialSynchFinancialAccount();
  return data;
};

const postSynchCustomer = async (params: any) => {
  console.log(params);

  const { clientId, customerId } = params;
  const { data } = await apiCaller.financialSynchCustomer({
    body: {
      customerId,
      clientId
    }
  });
  return data;
};

const deleteIntegrations = async () => {
  const { data } = await apiCaller.financialDisconnectFinancialAccount();
  return data;
};

const matchCustomer = async (params: any) => {
  const { data } = await apiCaller.financialMatchCustomer({
    body: {
      customerId: params.customerId,
      clientId: params.clientId
    }
  });
  return data;

}

const getCustomers = async (params: any) => {
  const { data } = await apiCaller.financialGetCustomers();
  return data;
}

const postUpdateWebhook = async (params: any) => {
  const { data } = await apiCaller.financialUpdateWebhookSecret({
    body: {
      webhookSecret: params.webhookSecret
    }
  });
  return data;
}

export default {
  getAllIntegrations,
  postConnectAccount,
  deleteIntegrations,
  postSynchAccount,
  postSynchCustomer,
  matchCustomer,
  getCustomers,
  postUpdateWebhook
};
