/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Tabs,
  Tab,
  Grid,
  useTheme,
  CardContent,
  Card as CardInfo,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import dayjs from 'dayjs';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Card from '../../leads/steps/components/CardComponent';
import Api from '../../../api';
import { InvoiceByIdResponseDTO } from '../../../api-client/generated/api';
import {
  capitalizeString,
  formatDate,
  getCurrency,
  statusStyle
} from '../../../utils/general';
import {
  accentColors,
  secondaryColors,
  warningColors
} from '../../../theme/themeConfig';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import TableComponentEditable from '../../../components/shared/TableComponentEditable';

interface Props {
  detailProject: any;
  isClient?: boolean;
}

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CustomTabs(props: any) {
  return (
    <Tabs
      style={{ marginleft: '-93px !important' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{
        '.MuiTabs-indicator': {
          display: 'none'
        }
      }}
    />
  );
}

const InvoicesList: React.FC<Props> = function InvoicesList({
  detailProject,
  isClient = false
}) {
  const [step, setStep] = useState<number>(0);
  const [stepInfo, setStepInfo] = useState<number | null>(null);
  const [cardData, setcardData] = useState<any>([]);
  const [status, setStatus] = useState<string | null>(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [infoInvoice, setInfoInvoice] = useState<InvoiceByIdResponseDTO | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sentFlag, setSentFlag] = useState<boolean>(false);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>('');
  const getInvoiceAction = isClient
    ? Api.ClientApi.getProjectInvoices
    : Api.InvoicesApi.getInvoicesList;
  const resendInvoiceByIdAction = Api.InvoicesApi.resendInvoiceById;
  const updateInvoiceByStatusAction = Api.InvoicesApi.updateInvoiceByStatus;
  const theme = useTheme();
  const dataToSend = isClient
    ? { id: detailProject?.id, url: 'client-view/get-invoices-by-project' }
    : [detailProject.id, status];

  const filterMapping: { [key: number]: string | null } = {
    0: '',
    1: 'PENDING',
    2: 'PAID'
  };

  useEffect(() => {
    setStatus(filterMapping[step]);
  }, [step]);

  const {
    data: getInvoiceData,
    error: getInvoiceError,
    mutate: cardMutate
  } = useSWR(
    detailProject?.id !== undefined && status !== undefined ? dataToSend : null,
    getInvoiceAction
  );

  useEffect(() => {
    if (getInvoiceData && !getInvoiceError) {
      setcardData(getInvoiceData);
    }
  }, [getInvoiceData]);

  const reloadInvoiceData = () => {
    cardMutate([detailProject.id, status], true);
  };

  const getInvoiceByIdAction = Api.InvoicesApi.getInvoicesById;
  const { data: responseData, error: responseError } = useSWR(
    selectedCardId || null,
    getInvoiceByIdAction
  );

  useEffect(() => {
    if (responseData && !responseError) {
      setInfoInvoice(responseData);
    }
  }, [responseData]);

  const { t } = useTranslation();

  const handleCardClick = (value: any) => {
    setSelectedCardId(value.id);
    setStepInfo(0);
  };

  const handleOpenModal = (status: any) => {
    if (status === 'PENDING') {
      setIsModalOpen(true);
      setTypeModal('status');
      setSelectedCardId(null);
    }
  };

  const { data: updateInvoiceStatusData, error: updateInvoiceStatusError } =
    useSWR(
      typeModal === 'status' && sentFlag ? [infoInvoice?.id, 'Paid'] : null,
      updateInvoiceByStatusAction
    );
  useEffect(() => {
    if (updateInvoiceStatusData && !updateInvoiceStatusError) {
      setSelectedCardId(null);
      reloadInvoiceData();
      setSentFlag(false);
    }
  }, [updateInvoiceStatusData]);

  useEffect(() => {
    if (sentFlag) {
      setIsModalOpen(false);
    }
  }, [sentFlag]);

  const { data: resendInvoiceData, error: resendInvoiceError } = useSWR(
    typeModal === 'resendInvoice' && sentFlag ? infoInvoice?.id : null,
    resendInvoiceByIdAction
  );

  useEffect(() => {
    if (resendInvoiceData && !resendInvoiceError) {
      setcardData(getInvoiceData);
      setSentFlag(false);
    }
  }, [resendInvoiceData]);

  const tabPanel1 = () => {
    return (
      <CustomTabs
        aria-label="basic tabs example"
        indicatorColor="transparent"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setStep(newValue);
          setInfoInvoice(null);
          setSelectedCardId(null);
        }}
        style={{
          backgroundColor: '#E3E9EE',
          borderBottom: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          borderTop: '1px solid #ddd',
          marginLeft: '-26px',
          marginTop: '-25px'
        }}
        value={step}
      >
        <Tab
          label={t('LISTING_PAGES.allInvoices')}
          style={{ fontSize: '13px' }}
        />
        <Tab
          label={t('LISTING_PAGES.pendingInvoices')}
          style={{ fontSize: '13px', paddingLeft: '0px' }}
        />
        <Tab
          label={t('LISTING_PAGES.paidInvoices')}
          style={{ fontSize: '13px', paddingLeft: '0px' }}
        />
      </CustomTabs>
    );
  };

  const tabPanel2 = () => {
    return (
      <CustomTabs
        aria-label="basic tabs example"
        indicatorColor="transparent"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setStepInfo(newValue);
        }}
        style={{
          backgroundColor: 'white',
          borderBottom: '1px solid #ddd',
          borderTop: '1px solid #ddd',
          marginLeft: '-16px',
          marginTop: '-25px'
        }}
        sx={{
          '.MuiTabs-root': {
            minHeight: 'unset'
          }
        }}
        value={stepInfo}
      >
        <Tab
          disabled={selectedCardId === null || selectedCardId === undefined}
          label={t('LISTING_PAGES.infoInvoices')}
          style={{ fontSize: '13px' }}
        />
        <Tab
          disabled={selectedCardId === null || selectedCardId === undefined}
          label={t('LISTING_PAGES.activityLog')}
          style={{ fontSize: '13px' }}
          sx={{
            borderLeft: '1px solid #ddd'
          }}
        />
      </CustomTabs>
    );
  };

  const headerInvoice = () => {
    return (
      <>
        <Grid container md={12} spacing={2} xl={12} xs={12}>
          <Grid item md={5} xl={5} xs={9}>
            {tabPanel1()}
          </Grid>
          <Grid item md={6} xl={6} xs={3}>
            {tabPanel2()}
          </Grid>
        </Grid>

        <Box />
        <br />
        <Grid container md={12} spacing={2} xl={12} xs={12}>
          <Grid item md={5} style={{ marginTop: '-6px' }} xl={5} xs={12}>
            {cardData.map((cardData: any, index: any) => (
              <TabPanel index={step} key={index} value={step}>
                <Card
                  cardData={cardData}
                  handleCardClick={handleCardClick}
                  handleOpenModal={handleOpenModal}
                  isActive={cardData.id === selectedCardId}
                />
                {isModalOpen && typeModal === 'status' && (
                  <ConfirmModal
                    modalText={t('MODAL.confirModalStatusInvoice')}
                    modalType="status"
                    setSentFlag={setSentFlag}
                    setShowModal={setIsModalOpen}
                    setShowOkModal={setShowOkModal}
                  />
                )}
              </TabPanel>
            ))}
          </Grid>
          <Grid item md={6} xl={7} xs={12}>
            {selectedCardId && (
              <>
                <TabPanel index={stepInfo || 0} value={0}>
                  {invoiceInformation()}
                </TabPanel>
                <TabPanel index={stepInfo || 0} value={1}>
                  {activityLogs()}
                </TabPanel>
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const cardContenido = (services: any[]) => {
    if (!services || services.length === 0) {
      return null;
    }

    return services.map((service) => (
      <CardContent key={service.id}>
        <Typography
          gutterBottom
          style={{
            color: secondaryColors.SECONDARY_5,
            fontSize: '16px'
          }}
          sx={{ fontSize: 14 }}
          variant="secondary"
        >
          {service.name}
        </Typography>
        {service.tasks.map((task: any) => (
          <Typography
            component="li"
            key={task.id}
            style={{
              color: secondaryColors.SECONDARY_5,
              fontSize: '13px'
            }}
            variant="secondary"
          >
            {task.name}
          </Typography>
        ))}
      </CardContent>
    ));
  };

  function ButtonsT() {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Typography
          style={{
            color: '#7D8090',
            fontSize: '12px'
            // marginRight: 'auto'
          }}
          variant="secondary"
        >
          This invoice has already been seen
        </Typography>
        <Button
          onClick={() => {
            setIsModalOpen(true);
            setTypeModal('resendInvoice');
          }}
          sx={{ gap: 2 }}
          variant="text"
        >
          {t('GENERAL.resendInvoice')}
          <i className="custom-icon icon-resendEmail" />
        </Button>
      </Box>
    );
  }

  const invoiceInformation = () => {
    return (
      <Container
        style={{
          marginLeft: '-41px',
          marginTop: '-51px',
          paddingInline: '0px'
        }}
      >
        {infoInvoice && (
          <Box
            style={{
              borderLeft: '1px solid #ddd',
              marginRight: '-48px'
            }}
          >
            <Box
              style={{
                borderBottom: '1px solid #ddd',
                marginRight: '-48px'
              }}
            >
              <Grid container spacing={1}>
                <Grid item md={6} xl={7} xs={12}>
                  <Typography
                    style={{
                      alignItems: 'center',
                      color: `${
                        infoInvoice.lastSeen
                          ? accentColors.ACCENT_3
                          : warningColors.ERROR
                      }`,
                      display: 'flex',
                      fontSize: '14px',
                      marginLeft: '10px',
                      paddingBottom: '5px'
                    }}
                    variant="primary-bold"
                  >
                    <VisibilityOutlinedIcon
                      style={{
                        color: `${
                          infoInvoice.lastSeen
                            ? accentColors.ACCENT_3
                            : warningColors.ERROR
                        }`,
                        fontSize: '20px',
                        marginRight: '8px'
                      }}
                    />

                    <Typography fontSize={15} variant="body1">
                      {infoInvoice.lastSeen
                        ? 'Seen by the client'
                        : 'Pending to be seen by the client'}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Typography
              style={{
                fontSize: '13px',
                paddingBottom: '5px',
                paddingLeft: '10px',
                paddingTop: '4px'
              }}
            >
              <Typography
                style={{
                  color: secondaryColors.SECONDARY,
                  fontSize: '13px'
                }}
                variant="primary-bold"
              >
                Invoice:
              </Typography>
              <Typography
                style={{
                  fontSize: '13px'
                }}
                variant="primary-bold"
              >
                {` #${infoInvoice?.invoiceNumber} `}
              </Typography>
              {infoInvoice?.status && (
                <span
                  style={{
                    display: 'inline-block',
                    fontSize: '13px',
                    marginLeft: '190px',
                    marginTop: '5px',
                    padding: '1px',
                    paddingInline: '14px',
                    textAlign: 'center',
                    // marginTop:'14px',
                    ...statusStyle(infoInvoice.status, 'invoice'),
                    borderRadius: '24px'
                  }}
                >
                  {` ${infoInvoice.status
                    ?.toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())} `}
                </span>
              )}
              <br />
              <Typography
                style={{
                  fontSize: '13px'
                }}
                variant="primary-bold"
              >
                {infoInvoice?.clientName}
              </Typography>

              <br />
              <Typography
                style={{
                  fontSize: '13px'
                }}
                variant="primary-bold"
              >
                {infoInvoice?.companyName}
              </Typography>
              <br />
              <Typography
                style={{
                  fontSize: '13px'
                }}
                variant="primary-bold"
              >
                {infoInvoice?.projectName}
              </Typography>
            </Typography>
            <Typography
              style={{
                borderBottom: '1px solid #ddd',
                borderTop: '1px solid #ddd',
                fontSize: '13px',
                paddingBottom: '5px',
                paddingLeft: '10px',
                paddingTop: '7px'
              }}
            >
              <Typography
                style={{ color: '#7D8090', fontSize: '13px' }}
                variant="primary-bold"
              >
                Date:{' '}
              </Typography>
              <strong>
                {dayjs(infoInvoice?.sendDate).format('MMM, DD YYYY')}
              </strong>
              <br />
              <Typography
                style={{ color: '#7D8090', fontSize: '13px' }}
                variant="primary-bold"
              >
                Due Date:{' '}
              </Typography>
              <strong>
                {dayjs(infoInvoice?.dueDate).format('MMM,DD YYYY')}
              </strong>
              <br />
              <Typography
                style={{ color: '#7D8090', fontSize: '13px' }}
                variant="primary-bold"
              >
                Range Date of Services:{' '}
              </Typography>
              <strong>
                {dayjs(infoInvoice?.rangeStartDate).format('MMM,DD YYYY')} -
                {dayjs(infoInvoice?.rangeEndDate).format('MMM,DD YYYY')}
              </strong>
              <br />
              <Typography
                style={{ color: '#7D8090', fontSize: '13px' }}
                variant="primary-bold"
              >
                Payment term:{' '}
              </Typography>
              <strong>
                {` Due in  ${dayjs(infoInvoice?.dueDate).diff(
                  dayjs(infoInvoice?.sendDate),
                  'day'
                )} Days `}
              </strong>
              <br />
            </Typography>

            <Typography
              style={{
                borderBottom: '1px solid rgba(247, 246, 249, 0.2)',
                borderTop: '1px solid rgba(247, 246, 249, 0.2)',
                fontSize: '14px',
                marginTop: '11px',
                paddingLeft: '10px'
              }}
            >
              {/* <br /> */}
              <Typography style={{ color: '#7D8090' }} variant="primary-bold">
                Services:{' '}
              </Typography>
              <strong
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  backgroundColor: `${secondaryColors.SECONDARY_5}`,
                  borderRadius: '12px',
                  color: 'white',
                  height: '20px',
                  justifyContent: 'center',
                  marginLeft: '10px',
                  paddingLeft: '13px',
                  paddingRight: '15px',
                  width: '30px'
                }}
              >
                {infoInvoice?.services.length}
              </strong>
              {/* <br /> */}
              <hr
                style={{
                  borderColor: 'rgba(247, 246, 249, 0.2)',
                  marginLeft: '-11px',
                  marginTop: '11px'
                }}
              />
              <br />
              <Box
                style={{
                  marginBottom: '-31px',
                  marginLeft: '-10px',
                  marginTop: '-11px'
                }}
                sx={{ minWidth: 260 }}
              >
                <CardInfo
                  style={{ marginLeft: '20px', marginRight: '37px' }}
                  variant="outlined"
                >
                  {infoInvoice?.services && cardContenido(infoInvoice.services)}
                </CardInfo>
              </Box>
              <br />
              <br />
              <hr
                style={{
                  borderColor: 'rgba(247, 246, 249, 0.2)',
                  marginLeft: '-11px'
                }}
              />
              <Typography
                style={{
                  fontSize: '13px',
                  paddingBottom: '11px',
                  paddingLeft: '7px',
                  paddingTop: '12px'
                }}
              >
                <Grid container spacing={2} xl={12}>
                  <Grid item md={6} xl={4} xs={6}>
                    <Typography
                      color={secondaryColors.SECONDARY_5}
                      style={{
                        fontSize: '17px'
                      }}
                      variant="primary-bold"
                    >
                      {t('DASHBOARD.subtotal')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    style={{ textAlign: 'right' }}
                    xl={7}
                    xs={6}
                  >
                    <Typography
                      style={{
                        color: secondaryColors.SECONDARY_5,
                        fontSize: '17px'
                      }}
                      variant="primary-bold"
                    >
                      {`${getCurrency(infoInvoice?.subTotal)}`}
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ paddingTop: '2px' }} xl={4} xs={6}>
                    <Typography
                      color={secondaryColors.SECONDARY_5}
                      style={{
                        fontSize: '13px'
                      }}
                      variant="primary-bold"
                    >
                      {t('DASHBOARD.taxes')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    style={{ paddingTop: '2px', textAlign: 'right' }}
                    xl={7}
                    xs={6}
                  >
                    <Typography
                      style={{
                        color: secondaryColors.SECONDARY_5,
                        fontSize: '13px'
                        // textAlign: 'right'
                      }}
                      variant="primary-bold"
                    >
                      {`${getCurrency(infoInvoice?.taxes)}`}
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ paddingTop: '2px' }} xl={6} xs={6}>
                    <Typography
                      color={secondaryColors.SECONDARY_5}
                      style={{
                        fontSize: '17px'
                      }}
                      variant="primary-bold"
                    >
                      {t('DASHBOARD.total')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    style={{ paddingTop: '2px', textAlign: 'right' }}
                    xl={5}
                    xs={6}
                  >
                    <Typography
                      style={{
                        color: secondaryColors.SECONDARY_5,
                        fontSize: '17px'
                      }}
                      variant="primary-bold"
                    >
                      {`${getCurrency(infoInvoice?.total)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <hr
                style={{
                  borderColor: 'rgba(247, 246, 249, 0.2)',
                  marginLeft: '-11px'
                }}
              />
              <br />

              {ButtonsT()}
              {isModalOpen && typeModal === 'resendInvoice' && (
                <ConfirmModal
                  modalText={t('MODAL.confirModalResendInvoice')}
                  modalType="resendInvoice"
                  setSentFlag={setSentFlag}
                  setShowModal={setIsModalOpen}
                  setShowOkModal={setShowOkModal}
                />
              )}
              <br />
              <br />
            </Typography>
          </Box>
        )}
      </Container>
    );
  };

  const activityLogs = () => {
    return (
      <Box>
        {infoInvoice && infoInvoice.activityLogs.length > 0 && (
          <Grid
            md={12}
            style={{
              borderLeft: '1px solid #ddd',
              // backgroundColor: 'green',
              marginLeft: '-41px',
              marginRight: '-27px',
              marginTop: '-52px',
              paddingTop: '10px'
            }}
            xl={12}
            xs={8}
          >
            {infoInvoice.activityLogs.map((log, index) => (
              <Box key={index} style={{ marginLeft: '20px' }}>
                <Box>
                  <Typography
                    style={{
                      color: secondaryColors.SECONDARY,
                      fontSize: '11px'
                    }}
                    variant="primary-bold"
                  >
                    {log.description}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{
                      color: secondaryColors.SECONDARY,
                      fontSize: '11px'
                    }}
                    variant="primary-bold"
                  >
                    {dayjs(log.timestamp).format('MM/DD, h:mm a')}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Grid>
        )}
      </Box>
    );
  };

  const listInvoicesClient = () => {
    return (
      <TableComponentEditable
        disableAdd
        action={() => {}}
        handleOpenDraw={() => {}}
        headKeys={['invoiceNumber', 'dueDate', 'dateRange', 'amount', 'status']}
        onChange={() => {}}
        valuesArray={{
          data:
            cardData?.length > 0
              ? cardData.map((invoice: any) => {
                  return {
                    amount: getCurrency(
                      (parseFloat(invoice?.amount) / 100).toFixed(2)
                    ),
                    dateRange: `${formatDate(
                      invoice?.rangeStartDate
                    )} - ${formatDate(invoice?.rangeEndDate)}`,
                    dueDate: invoice?.dueDate,
                    invoiceNumber: invoice?.invoiceNumber,
                    status: (
                      <Typography
                        style={{
                          color: 'secondary.main',
                          display: 'block',
                          fontSize: '13px',
                          marginLeft: '-10px'
                        }}
                        variant="primary-bold"
                      >
                        {capitalizeString(`${invoice.status}`)}
                      </Typography>
                    )
                  };
                })
              : []
        }}
      />
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xl={12} xs={12}>
        {isClient ? listInvoicesClient() : headerInvoice()}
      </Grid>
    </Grid>
  );
};

export default InvoicesList;
