import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatSumTime } from '../../../utils/calendarHelper';

export interface ICardStimated {
  estimated: string;
  projectType: string;
  total: string;
}
const CardStimated: React.FC<ICardStimated> = function CardStimated({
  estimated,
  projectType,
  total
}) {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      {projectType === 'Regular' && (
        <Typography fontSize={12} fontWeight={800} variant="primary-bold">
          Estimated time{' '}
          {estimated ? formatSumTime(parseFloat(estimated) * 60) : '00:00'}{' '}
        </Typography>
      )}

      <Typography
        fontSize={12}
        fontWeight={800}
        style={{
          marginLeft: '10px'
        }}
        variant="primary-bold"
      >
        Total time: {total ? formatSumTime(parseFloat(total)) : '00:00'}
      </Typography>
    </Box>
  );
};

export default CardStimated;
