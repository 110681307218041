/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useRef, useState } from 'react';

import { Container, Box, Button } from '@mui/material';

import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Api from '../../api';
import projects from '../../utils/dummy/projects';
import FilterSelect from '../../components/filters/FilterSelect';
import ListingPage from '../../components/list/ListingPage';
import AutoCompleteSearch from '../../components/input/AutoCompleteSearch';

const ListingProjects: React.FC = function ListingProjects() {
  const { t } = useTranslation();
  const [projectFilter, setProjectFilter] = useState('project');
  const [customerFilter, setCustomerFilter] = useState('client');
  const [textSearchFilter, setTextSearchFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('type');
  const [priorityFilter, setPriorityFilter] = useState('priority');
  const [servicesFilter, setServicesFilter] = useState('services');
  const [assignUrgencyFilter, setAssignUrgencyFilter] =
    useState<string>('assign urgency');
  const [fireProjectApi, setFireProjectApi] = useState<boolean>(false);
  const [lock, setLock] = useState<boolean>(false);
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [dataProject, setDataProject] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [askClients, setAskClients] = useState<boolean>(false);
  const [clients, setClients] = useState<Array<any>>([]);
  const [options, setOptions] = useState<Array<any>>([]);
  const { getClients } = Api.ClientApi;
  const pageNumber = useRef<number>(1);
  const navigate = useNavigate();

  const getAction = Api.ProjectApi.getProjectsPaginated;
  const params = {
    items: 20,
    page: pageNumber.current,
    url: 'api/projects'
  };

  const {
    data: mainResponse,
    error: mainError,
    mutate
  } = useSWR(
    fireProjectApi
      ? {
          ...params,
          assignUrgency:
            assignUrgencyFilter !== 'assign urgency' ? assignUrgencyFilter : '',
          client: customerFilter !== 'client' ? customerFilter : '',
          searchText: textSearchFilter,
          type: typeFilter !== 'type' ? typeFilter : ''
        }
      : null,
    getAction
  );

  const { data: clientsData, error: clientsError } = useSWR(
    askClients ? { url: 'get/allClients' } : null,
    getClients
  );

  const handleOpen = () => {
    navigate('/app/projects/delete');
  };

  const isItemLoaded = (index: any) => !!dataProject[index];

  const loadMoreItems = () => {
    if (dataProject && dataProject.length < (totalItems || 0) && !lock) {
      pageNumber.current += 1;
      setLock(true);
      setFireProjectApi(true);
    }
  };

  const modifiedData = (dataProject: any[]) => {
    return dataProject && dataProject.length > 0
      ? dataProject?.map((item: any) => {
          let assignmentStatus = 'Will Require assign';
          let icon = 'awaiting';
          let tooltip = 'Unassigned dates after ten days';
          let priorityIcon = 'lowPriority';

          if (
            item?.assignments?.requiredMore10Days > 0 &&
            item?.assignments?.requiredMore10Days >
              item?.assignments?.assignedMore10Days
          ) {
            assignmentStatus = 'Will Require assign';
            tooltip = 'Unassigned dates after ten days';
            priorityIcon = 'lowPriority';
          }

          if (
            item?.assignments?.requiredLess10Days > 0 &&
            item?.assignments?.requiredLess10Days >
              item?.assignments?.assignedLess10Days
          ) {
            assignmentStatus = 'Required assign';
            icon = 'warning';
            tooltip = 'Unassigned dates in the next ten days';
            priorityIcon = 'mediumPriority';
          }

          if (
            item?.assignments?.required3Days > 0 &&
            item?.assignments?.required3Days > item?.assignments?.assigned3Days
          ) {
            assignmentStatus = 'Urgent Required assign';
            priorityIcon = 'highPriority';
            icon = 'rejected';
            tooltip = 'Unassigned dates in the next three days';
          }

          if (
            (item?.assignments?.requiredMore10Days > 0 ||
              item?.assignments?.requiredLess10Days > 0 ||
              item?.assignments?.required3Days > 0) &&
            (item?.assignments?.required3Days === 0 ||
              item?.assignments?.required3Days ===
                item?.assignments?.assigned3Days) &&
            (item?.assignments?.requiredLess10Days === 0 ||
              item?.assignments?.requiredLess10Days ===
                item?.assignments?.assignedLess10Days) &&
            (item?.assignments?.requiredMore10Days === 0 ||
              item?.assignments?.requiredMore10Days ===
                item?.assignments?.assignedLess10Days)
          ) {
            assignmentStatus = 'All assigned';
            icon = 'approved';
            tooltip = 'All dates assigned';
          }

          const client =
            item?.clientCompanyName && item?.clientCompanyName !== ''
              ? item?.clientCompanyName
              : `${item?.clientFirstName} ${item?.clientLastName}`;
          return {
            ...item,
            assignmentStatus,
            client,
            icon,
            leadProjectEndDate: dayjs(item.leadProjectEndDate).format(
              'MM-DD-YYYY'
            ),
            leadProjectStartDate: dayjs(item.leadProjectStartDate).format(
              'MM-DD-YYYY'
            ),
            priorityIcon,
            priorityText: t(`STATUS.${priorityIcon}`),
            tooltip
          };
        })
      : [];
  };

  useEffect(() => {
    if (mainResponse && mainResponse.data && mainResponse.data.length > 0) {
      setEmptyState(mainResponse.data.length === 0);
      setTotalItems(mainResponse?.pagination?.totalItems || 0);
      setFireProjectApi(false);
      const dataPaginated = dataProject?.length > 0 ? [...dataProject] : [];
      mainResponse.data.forEach((item: any) => {
        dataPaginated.push(item);
      });
      setDataProject(dataPaginated);
      setLock(false);
    }
  }, [mainResponse]);

  useEffect(() => {
    if (dataProject && dataProject.length === 0 && !emptyState) {
      mutate();
    }
  }, [dataProject]);

  useEffect(() => {
    setAskClients(true);
    setFireProjectApi(true);
  }, []);

  useEffect(() => {
    if (mainError) {
      setDataProject([]);
    }
  }, [mainError]);

  useEffect(() => {
    if (clientsData && !clientsError) {
      setAskClients(false);
      const clients = clientsData.map((item: any) => {
        return {
          ...item,
          name: `${item?.businessName} - ${
            item?.firstName ? item?.firstName?.trim() : ''
          } ${item?.lastName ? item?.lastName?.trim() : ''}`
        };
      });

      if (clients.length > 0) {
        setClients(
          clients.sort((a: any, b: any) =>
            a?.businessName?.localeCompare(b?.businessName)
          )
        );
      }
    }
  }, [clientsData]);

  useEffect(() => {
    pageNumber.current = 1;
    setDataProject([]);
    setLock(true);
    setFireProjectApi(true);
  }, [textSearchFilter, customerFilter, typeFilter, assignUrgencyFilter]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        key="projectsListFitlers"
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            pl: 5
          }}
        >
          {/* 
            <Box width="120px">
            <FilterSelect
              label="Project"
              onChange={(event) =>
                setProjectFilter(event.target.value as string)
              }
              options={projects}
              value={projectFilter}
            />
          </Box> */}
          <Box width="120px">
            <FilterSelect
              label="Client"
              onChange={(event) =>
                setCustomerFilter(event.target.value as string)
              }
              options={clients}
              value={customerFilter}
            />
          </Box>
          <Box width="120px">
            <FilterSelect
              label="Type"
              onChange={(event) => setTypeFilter(event.target.value as string)}
              options={[
                { id: 'Ad-Hoc', name: 'Ad-Hoc' },
                { id: 'Regular', name: 'Regular' }
              ]}
              value={typeFilter}
            />
          </Box>
          <Box alignItems="center" display="flex">
            <FilterSelect
              label="Assign Urgency"
              onChange={(event) =>
                setAssignUrgencyFilter(event.target.value as string)
              }
              options={[
                { id: 'Less than 3 days', name: 'Less than 3 days' },
                { id: 'Between 3 and 10 days', name: 'Between 3 and 10 days' },
                { id: 'More than 10 days', name: 'More than 10 days' }
              ]}
              value={assignUrgencyFilter}
              width="200px"
            />
          </Box>

          <Box alignItems="center" display="flex">
            <AutoCompleteSearch
              options={[]}
              setOptions={setOptions}
              setSearch={setTextSearchFilter}
              setValueSearched={setTextSearchFilter}
              value={textSearchFilter}
            />
          </Box>
          <Button
            onClick={() => {
              setAssignUrgencyFilter('assign urgency');
              setCustomerFilter('client');
              setTypeFilter('type');
              setTextSearchFilter('');
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <ListingPage
          fullSize
          data={modifiedData(dataProject || [])}
          handleOpen={handleOpen}
          handleOpenDraw={() => {}}
          headKeys={[
            'leadName',
            'client',
            'leadProjectStartDate',
            'leadProjectEndDate',
            'status',
            'assignmentStatus'
          ]}
          isItemLoaded={isItemLoaded}
          itemCount={mainResponse?.pagination?.totalItems || 0}
          loadMoreItems={loadMoreItems}
          nameButton="deleteButton"
          orderByManual="createdAt"
          orderManual="desc"
          setValueDrawer={(value: any) => {
            navigate(`/app/projects/detail/${value.id}`, {
              state: { id: value.id }
            });
          }}
          title="projects"
        />
      </Box>
    </Box>
  );
};

export default ListingProjects;
