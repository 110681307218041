import { Dispatch, SetStateAction, createContext } from 'react';
import { ChannelEntity } from '@pubnub/react-chat-components';
import Pubnub from 'pubnub';
import { AxiosResponse } from 'axios';

export type ChatUserData = Partial<{
  pubnub: Pubnub;
  token: string | null;
  userId: string | null;
}>;

type StateSetter = {
  setCurrentChannel: Dispatch<SetStateAction<ChannelEntity | undefined>>;
};

type State = { currentChannel: ChannelEntity | null };

export type TChatContext = Partial<
  StateSetter &
    State &
    ChatUserData & {
      createChat: (userIds: string[]) => Promise<AxiosResponse<any, any>>;
    }
>;

const ChatContext = createContext<TChatContext>({});

export default ChatContext;
