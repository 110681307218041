/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/chevron-left.svg';
import { getStatusForRangeDate } from '../../../utils/general';

interface DatesProps {
  calendarState: string;
  dates: any;
  frequency: string;
  selected: number;
  setDay: React.Dispatch<React.SetStateAction<any>>;
  setDetailInfo: React.Dispatch<React.SetStateAction<{}>>;
  setSelectedDayIndex: React.Dispatch<React.SetStateAction<number>>;
}

const CarrouseDates: React.FC<DatesProps> = function CarrouseDates({
  selected,
  dates,
  setSelectedDayIndex,
  setDay,
  setDetailInfo,
  calendarState,
  frequency
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Encontrar el índice del primer elemento que es posterior a la fecha de hoy
    const today = new Date();
    const index = dates.findIndex((date: any) => {
      const [startDateStr] = date.range.split(' - ');
      const startDate = new Date(startDateStr);
      return startDate > today;
    });

    if (index !== -1) {
      let initialIndex = index - 2; // Establecer el índice al día anterior al primero después de hoy por defecto

      if (index === 0) {
        initialIndex = index; // Si la fecha de inicio es hoy, establecer el índice al día actual
      } else if (index === 1) {
        initialIndex = index - 1; // Si la fecha de inicio es mañana, establecer el índice al día actual
      }

      setCurrentIndex(initialIndex);
    } else {
      setCurrentIndex(dates.length - 6); // Mostrar los últimos 6 días si no hay días después de hoy
    }
  }, [dates]);

  const handleClick = (key: number) => {
    setSelectedDayIndex(key);
    setDay(null);
    setDetailInfo({});
  };

  const handlerCountRight = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < dates.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlerCountLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  function DateToSelect({ id, date, status, index, selected }: any) {
    const BGColor = selected === id ? '#005DA2' : `${status.bg}`;
    const BGColorText = selected === id ? '#FFFFFF' : `${status.txt}`;

    return (
      <Box
        onClick={() => handleClick(id)}
        style={{
          backgroundColor: BGColor,
          borderLeft: 'solid #CBCCD3 1px',
          cursor: 'pointer',
          display: 'flex',
          height: '35px',
          minWidth: '180px',
          padding: '5px',
          width: '100%'
        }}
      >
        <Typography
          style={{
            color: BGColorText,
            fontSize: '10px',
            fontWeight: 800,
            lineHeight: '25px',
            textAlign: 'center',
            width: '100%'
          }}
        >
          {date}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      style={{
        borderBottom: 'solid #CBCCD3 1px',
        borderTop: 'solid #CBCCD3 1px',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '-1px',
        width: '100%'
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          visibility: currentIndex === 0 ? 'hidden' : 'visible',
          width: '45px' // Establecer un ancho fijo para el Box
        }}
      >
        <Button
          onClick={handlerCountLeft}
          size="small"
          style={{ width: '90%' }}
        >
          <ChevronLeftIcon />
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'scroll',
          overflowX: 'hidden',
          overflowY: 'hidden',
          width: '90%'
        }}
      >
        {dates
          .slice(currentIndex, currentIndex + 6)
          .map((dateProps: any, index: number) => {
            const status = getStatusForRangeDate(dateProps.status);
            return (
              <DateToSelect
                date={dateProps.range}
                daysId={dateProps.days}
                id={dateProps.id}
                index={index}
                key={index}
                selected={selected}
                status={status}
              />
            );
          })}
      </Box>
      <Box
        style={{
          alignItems: 'center',
          borderLeft: 'solid #CBCCD3 1px',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          visibility: currentIndex === dates.length - 6 ? 'hidden' : 'visible',
          width: '43px' // Establecer un ancho fijo para el Box
        }}
      >
        <Button
          onClick={handlerCountRight}
          size="small"
          style={{ width: '100%' }}
        >
          <ChevronRightIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default CarrouseDates;
