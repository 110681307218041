import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AUTHORIZED_VIEW_NAMES,
  DEFAULT_SCREENS
} from '../../helpers/routesConstants';
import Invitations from './Invitations';
import Users from './Users';
import CompaniesList from './CompaniesList';

const Companies: React.FC = function Companies() {
  return (
    <Routes>
      <Route
        element={<Navigate to={DEFAULT_SCREENS.COMPANIES} />}
        path={AUTHORIZED_VIEW_NAMES.INVALID}
      />
      <Route
        element={<Invitations />}
        path={AUTHORIZED_VIEW_NAMES.INVITATIONS}
      />
      <Route element={<Users />} path={AUTHORIZED_VIEW_NAMES.USERS} />
      <Route
        element={<CompaniesList />}
        path={AUTHORIZED_VIEW_NAMES.COMPANIES_LIST}
      />
    </Routes>
  );
};

export default Companies;
