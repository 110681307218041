/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  number: String;
  text: String;
  position: number;
  isActive: number;
}
const TextNumber: React.FC<Props> = function TextNumber({
  number,
  text,
  position,
  isActive
}) {
  const colorText = position === isActive ? '#005DA2' : '#9799A6';
  const colorBG = position === isActive ? '#005DA2' : '#9799A6';
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: '-70px'
      }}
    >
      <Typography
        color={colorText}
        fontSize={14}
        style={{
          lineHeight: '20px'
        }}
        variant="secondary-bold"
      >
        {text}
      </Typography>{' '}
      <Box
        marginRight={10}
        style={{
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: colorBG,
          borderRadius: '12px',
          height: '20px',
          justifyContent: 'center',
          marginLeft: '10px',
          paddingLeft: '8px',
          paddingRight: '8px',
          width: '30px'
        }}
      >
        <Typography
          align="center"
          color="#FFFFFF"
          fontSize={12}
          style={{ lineHeight: '20px' }}
        >
          {number}
        </Typography>
      </Box>
    </Box>
  );
};

export default TextNumber;
