/* eslint-disable react/require-default-props */
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { getCurrency } from '../../utils/general';
import { alertColors, successColors } from '../../theme/themeConfig';
import Loader from '../shared/Loader';

interface Props {
  icon?: React.ReactNode;
  isLoading?: boolean;
  sameWidth?: boolean;
  title: string;
  type: string;
  value: string;
  variation?: string;
}

export const TileContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.box,
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'center',
  maxHeight: theme.spacing(12),
  maxWidth: theme.spacing(50),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingTop: theme.spacing(4)
}));

const Tile: React.FC<Props> = function Tile({
  title,
  value,
  icon,
  type,
  variation = '',
  isLoading = false,
  sameWidth = false
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const getValue = () => {
    switch (type) {
      case 'Currency':
        return getCurrency(value);
      case 'Counter':
        return `${value}`;
      case 'Percentage':
        return `${value} %`;
      default:
        return value;
    }
  };

  return (
    <TileContainer
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: '100px',
        padding: '20px',
        width: sameWidth ? '228px' : 'fit-content'
      }}
    >
      {icon && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.accent?.main,
            borderRadius: '10px',
            display: 'flex',
            height: '40px',
            justifyContent: 'center',
            width: '40px'
          }}
        >
          {icon}
        </Box>
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Typography color={theme.palette.mainText[300]} variant="heading_300">
          {title}
        </Typography>
        {!isLoading ? (
          <Typography
            color={theme.palette.secondary[500]}
            sx={{ fontSize: 32 }}
            variant="primary-bold"
          >
            {getValue()}
          </Typography>
        ) : (
          <Loader customColor="primary" />
        )}
      </Box>
      {variation === 'up' && (
        <TrendingUpIcon
          sx={{
            color: theme.palette.success.main,
            height: '3rem',
            width: 'auto'
          }}
        />
      )}
      {variation === 'down' && (
        <TrendingDownIcon
          sx={{
            color: theme.palette.alert.main,
            height: '3rem',
            width: 'auto'
          }}
        />
      )}
    </TileContainer>
  );
};

export default Tile;
