/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Modal,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomRadio from '../form/CustomRadio';
import {
  ButtonContainerModal,
  DrawerButton,
  ModalContainer
} from './GlobalConfirmModal';

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setShowManualPricingModal: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ManualorServiceCatalogModal: React.FC<Props> =
  function ManualorServiceCatalogModal({
    values,
    sendValues,
    setValues,
    saveStepper,
    setShowManualPricingModal
  }) {
    const { t } = useTranslation();
    const [auxManualPricing, setAuxManualPricing] = useState<boolean>(false);
    const theme = useTheme();

    function ManualPricingModal() {
      return (
        <ModalContainer>
          <Typography color="secondary.400" variant="modalTitle">
            <Box pl={10} pr={10}>
              <Box justifyContent="center" p={4} width="100%">
                <i
                  className="custom-icon icon-info"
                  style={{ color: theme.palette.surface?.main, fontSize: 34 }}
                />
              </Box>
              <Box>
                {t('LEAD.CREATE.GENERAL_INFORMATION.MODAL.title')}
                <Box
                  display="flex"
                  flexDirection="row"
                  height={100}
                  justifyContent="center"
                  mt={5}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    width={200}
                  >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event: any) => {
                          setAuxManualPricing(event.target.value === 'true');
                        }}
                      >
                        <FormControlLabel
                          checked={!auxManualPricing}
                          control={<CustomRadio />}
                          label={
                            <Typography variant="primary">
                              {t(
                                'LEAD.CREATE.GENERAL_INFORMATION.serviceCatalog'
                              )}
                            </Typography>
                          }
                          value={false}
                        />
                        <FormControlLabel
                          value
                          checked={auxManualPricing}
                          control={<CustomRadio />}
                          label={
                            <Typography variant="primary">
                              {t(
                                'LEAD.CREATE.GENERAL_INFORMATION.manualPricing'
                              )}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    alignItems="start"
                    border={1}
                    borderColor={theme.palette.grey[300]}
                    display="flex"
                    flexDirection="column"
                    height={100}
                    justifyContent="center"
                    p={2}
                    textAlign="left"
                    width={350}
                  >
                    <Typography variant="body_400">
                      <Typography variant="primary-bold">
                        {t(
                          'LEAD.CREATE.GENERAL_INFORMATION.MODAL.detailTitle',
                          [
                            `${
                              auxManualPricing
                                ? t(
                                    'LEAD.CREATE.GENERAL_INFORMATION.manualPricing'
                                  )
                                : t(
                                    'LEAD.CREATE.GENERAL_INFORMATION.serviceCatalog'
                                  )
                            }`
                          ]
                        )}
                      </Typography>
                    </Typography>
                    <Typography variant="primary">
                      {auxManualPricing
                        ? t(
                            'LEAD.CREATE.GENERAL_INFORMATION.MODAL.detailManualPricing'
                          )
                        : t(
                            'LEAD.CREATE.GENERAL_INFORMATION.MODAL.detailServiceCatalog'
                          )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Typography>
          <br />
          <br />
          <ButtonContainerModal>
            <>
              <DrawerButton
                onClick={() => {
                  // sendValues(values);
                  setShowManualPricingModal(false);
                }}
                sx={{ height: 46, width: 200 }}
                variant="cancel"
              >
                <Typography>{t('GENERAL.cancelButton')}</Typography>
              </DrawerButton>
              <DrawerButton
                onClick={() => {
                  setValues({ ...values, manualPricing: auxManualPricing });
                  sendValues({ ...values, manualPricing: auxManualPricing });
                  setShowManualPricingModal(false);
                  saveStepper({ next: true });
                }}
                sx={{ height: 46, width: 200 }}
                variant="contained"
              >
                <Typography>{t('GENERAL.confirmButton')}</Typography>
              </DrawerButton>
            </>
          </ButtonContainerModal>
        </ModalContainer>
      );
    }

    function OverrideManualPricing() {
      return (
        <ModalContainer>
          <Typography color="secondary.400" variant="modalTitle">
            <Box pl={10} pr={10}>
              <Box p={4}>
                <i
                  className="custom-icon icon-info"
                  style={{ color: theme.palette.surface?.main, fontSize: 34 }}
                />
              </Box>
              {`${t('LEAD.CREATE.GENERAL_INFORMATION.overrideManualPricing', [
                values.manualPricing
                  ? t('LEAD.CREATE.GENERAL_INFORMATION.manualPricing')
                  : t('LEAD.CREATE.GENERAL_INFORMATION.serviceCatalog')
              ])}`}
            </Box>
          </Typography>
          <br />
          <ButtonContainerModal>
            <>
              <DrawerButton
                onClick={() => {
                  setValues({
                    ...values,
                    manualPricing: !values.manualPricing
                  });
                  sendValues(values);
                  setShowManualPricingModal(false);
                  saveStepper({ next: true });
                }}
                sx={{ height: 46, width: 200 }}
                variant="outlined"
              >
                <Typography>
                  {values.manualPricing
                    ? t('LEAD.CREATE.GENERAL_INFORMATION.serviceCatalog')
                    : t('LEAD.CREATE.GENERAL_INFORMATION.manualPricing')}
                </Typography>
              </DrawerButton>
              <DrawerButton
                onClick={() => {
                  sendValues(values);
                  saveStepper({ next: true });
                  setShowManualPricingModal(false);
                }}
                sx={{ height: 46, width: 200 }}
                variant="contained"
              >
                <Typography>{t('GENERAL.confirmButton')}</Typography>
              </DrawerButton>
            </>
          </ButtonContainerModal>
        </ModalContainer>
      );
    }

    useEffect(() => {}, []);

    return (
      <Container sx={{ alignItems: 'center', margin: '40px', width: 'auto' }}>
        <Modal
          open
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {}}
        >
          {values.manualPricing === undefined
            ? ManualPricingModal()
            : OverrideManualPricing()}
        </Modal>
      </Container>
    );
  };

export default ManualorServiceCatalogModal;
