import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import { Box, Button, Container, Typography } from '@mui/material';

import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import Api from '../../api';
import InviteModal from '../../components/modals/InviteModal';
import DeleteModal from '../../components/modals/DeleteModal';
import ListingPage from '../../components/list/ListingPage';
import Loader from '../../components/shared/Loader';
import EmptyState from '../../components/list/EmptyState';
import Filter from '../../components/list/Filter';

const LeadStatus = ['Converted', 'Draft', 'Rejected', 'Working Lead'];

const ListingLeads: React.FC = function ListingLeads() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [resendItem, setResend] = useState<string | null>(null);
  const [deleteItem, setDelete] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fireLeadApi, setFireLeadApi] = useState<boolean>(false);
  const [lock, setLock] = useState<boolean>(false);
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const pageNumber = useRef<number>(1);
  const [selectInput, setSelectInput] = useState<string>('');
  const [filterdData, setFilterdData] = useState<any[]>([]);

  // ===================== Fetch
  const deleteAction = Api.CompanyInvitationApi.deleteCompanyInvitation;
  const resendAction = Api.CompanyInvitationApi.resendCompanyInvitation;
  // const getAction = Api.leadsApi.stepsApi.getAllDraft;
  const getAction = Api.leadsApi.leadApi.getLeadsPaginated;
  const params = {
    items: 20,
    page: pageNumber.current,
    url: 'api/leads'
  };

  const {
    data: mainResponse,
    error: mainError,
    mutate
  } = useSWR(fireLeadApi ? params : null, getAction);

  const { data: resendData, error: resendError } = useSWR(
    resendItem,
    resendAction
  );

  const handleOpen = () => {
    navigate('/app/leads/new');
  };

  const handleClose = () => setOpenModal(false);

  const isItemLoaded = (index: any) => !!data[index];

  const deleteButton = (item: any) => {
    return (
      <Button
        onClick={() => {
          setDelete(item.id);
          setShowDeleteModal(true);
        }}
        sx={{ width: '50px' }}
        variant="cancel"
      >
        <Typography variant="primary-bold">
          <i className="custom-icon icon-delete" />
        </Typography>
      </Button>
    );
  };

  const resendButton = (item: any) => {
    return (
      <Button
        onClick={() => {
          setResend(item.administratorEmail);
        }}
        sx={{ width: '100px' }}
        variant="primary"
      >
        <Typography variant="primary-bold">{`${t(
          'LISTING_PAGES.resend'
        )}`}</Typography>
      </Button>
    );
  };

  const loadMoreItems = () => {
    if (data && data.length < (totalItems || 0) && !lock) {
      pageNumber.current += 1;
      setLock(true);
      setFireLeadApi(true);
    }
  };

  useEffect(() => {
    if (selectInput && selectInput !== '') {
      const newData = data.filter((item: any) => item.status === selectInput);
      setFilterdData(newData);
    } else if (data && data.length > 0) {
      setFilterdData(data);
    } else {
      setFilterdData([]);
    }
  }, [selectInput]);

  /* useEffect(() => {
    if (mainError) {
      // setData([]);
    }
  }, [mainError]); */

  useEffect(() => {
    if (mainResponse && mainResponse.data && mainResponse.data.length > 0) {
      setEmptyState(mainResponse.data.length === 0);
      setTotalItems(mainResponse?.pagination?.totalItems || 0);
      setFireLeadApi(false);
      const newData = data?.length > 0 ? [...data] : [];
      mainResponse.data.forEach((item: any) => {
        const client =
          item?.clientCompanyName && item?.clientCompanyName !== ''
            ? item?.clientCompanyName
            : `${item?.clientFirstName} ${item?.clientLastName}`;
        newData.push({
          ...item,
          client,
          contact: `${item?.contactName}`,
          delete: deleteButton(item),
          icon: `${item?.proposalstatus}`,
          resend: resendButton(item),
          tooltip: `${t('TOOLTIP.PROPOSAL.proposal')} ${item?.proposalstatus}`
        });
      });
      setData(newData);

      if (selectInput && selectInput !== '') {
        const newDataFiltered = newData.filter(
          (item: any) => item.status === selectInput
        );
        setFilterdData(newDataFiltered);
      } else {
        setFilterdData(newData);
      }

      setLock(false);
    }
  }, [mainResponse]);

  useEffect(() => {
    if (resendData) {
      setResend(null);
      mutate();
    }
  }, [resendData]);

  useEffect(() => {
    if (resendError) {
      setResend(null);
    }
  }, [resendError]);

  useEffect(() => {
    if (data && data.length === 0 && !emptyState) {
      mutate();
    }
  }, [data]);

  useEffect(() => {
    setFireLeadApi(true);
  }, []);

  if (!data) {
    return <Loader customColor="primary" customSize={48} />;
  }

  const filters = () => {
    return (
      <Box alignItems="center" display="flex">
        <Filter
          arrayInput={LeadStatus?.map((status) => status) ?? []}
          selectInput={selectInput}
          setSelectInput={setSelectInput}
          title="Status"
        />
        <Button onClick={() => setSelectInput('')}>Clear</Button>
      </Box>
    );
  };

  return (
    <Box>
      {data && (
        <ListingPage
          fullSize
          data={filterdData.length > 0 ? filterdData : []}
          filters={filters}
          handleOpen={handleOpen}
          handleOpenDraw={() => {}}
          headKeys={[
            'name',
            'projectType',
            'client',
            'contact',
            'created',
            'statusIcon',
            'edit'
          ]}
          isItemLoaded={isItemLoaded}
          itemCount={mainResponse?.pagination?.totalItems || 0}
          loadMoreItems={loadMoreItems}
          nameButton="create"
          orderByManual="createdAt"
          orderManual="desc"
          setValueDrawer={(value: any) => {
            navigate(`/app/leads/edit-lead`, { state: { id: value.id } });
          }}
          title="leads"
        >
          <>
            {openModal && (
              <InviteModal
                action={Api.CompanyInvitationApi.sendInvitation}
                handleClose={handleClose}
                mutateAction={mutate}
                openValue={openModal}
                type="company"
              />
            )}
            {showDeleteModal && (
              <DeleteModal
                action={deleteAction}
                id={deleteItem}
                mutate={mutate}
                setShowModal={setShowDeleteModal}
              />
            )}
          </>
        </ListingPage>
      )}
      {mainResponse && mainResponse?.data.length === 0 && <EmptyState />}
    </Box>
  );
};

export default ListingLeads;
