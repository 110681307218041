import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import { Box, Typography } from '@mui/material';
import blueBerryLogoEmpty from '../../assets/images/empty-list.png';

const EmptyState = function EmptyState() {
  const { t } = useTranslation();
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      marginTop="8%"
      width="72vw"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginBottom="16px"
      >
        <img
          alt="BlueBerry Logo Empty list"
          className="logo-image-list"
          src={blueBerryLogoEmpty}
        />
      </Box>
      <Box>
        <Typography color="primary.main" variant="body_500">
          {t('LISTING_PAGES.emptyState')}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyState;
