/* eslint-disable react/jsx-props-no-spreading */
import { Box, SliderProps, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import RegularInput from '../input/RegularInput';
import Loader from '../shared/Loader';

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-12px'
}));

interface Props {
  setValue: (item: any) => void;
}

const AddMaterialModal: React.FC<Props> = function AddMaterialModal({
  setValue
}) {
  const { t } = useTranslation();
  const [getData, setData] = useState<boolean>(false);
  const [materials, setMaterials] = useState<Array<any>>();
  const [materialSelected, setMaterialSelected] = useState<any>();
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [askMaterial, setAskMaterial] = useState<boolean>(false);

  //  ================================================================================= SWR
  const { data, isValidating: dataLoading } = useSWR(
    getData ? { url: 'api/Materials' } : null,
    api.MaterialApi.getAllMaterials
  );

  const { data: materialData } = useSWR(
    askMaterial
      ? {
          id: materialSelected.id,
          url: 'api/getService'
        }
      : null,
    api.MaterialApi.getMaterial
  );

  //  ================================================================================= FUNCTIONS

  //  ================================================================================= USEEFFECTS

  useEffect(() => {
    if (data) {
      setData(false);
      setMaterials(data);
    }
  }, [data]);

  useEffect(() => {
    setData(true);
  }, []);

  useEffect(() => {
    if (materialData) {
      setValue({
        material: { name: materialData.name },
        ...materialData,
        cost: materialSelected.cost || 0.0
      });
    }
  }, [materialData]);

  useEffect(() => {
    if (materialSelected) {
      if (materialSelected.id) {
        setAskMaterial(true);
      } else {
        setValue({
          cost: materialSelected.cost || 0.0,
          material: { name: materialSelected.name }
        });
      }
    }
  }, [materialSelected]);

  //  ================================================================================= RENDER

  return (
    <Box
      alignItems="start"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      width={500}
    >
      <Box pb={2} pt={4}>
        <Typography variant="title">
          {t('LEAD.CREATE.COST_CALCULATOR.ADD_MATERIAL_MODAL.title')}
        </Typography>
      </Box>
      <SubTitle>
        {t('LEAD.CREATE.COST_CALCULATOR.ADD_MATERIAL_MODAL.description')}
      </SubTitle>
      {dataLoading && <Loader customColor="primary" />}
      {!dataLoading && materials && materials.length > 0 && (
        <Box width="100%">
          <AutoCompleteInput
            closeOnSelect
            onChange={(event: any, newValue: any | null) => {
              if (newValue.createValue) {
                setMaterialSelected({ name: newValue.createValue });
                setCreateMode(true);
              } else {
                setMaterialSelected(newValue);
                setValue(newValue);
                setCreateMode(false);
              }
            }}
            options={materials}
            renderInput={(params) => (
              <TextField
                placeholder={`${t(
                  'LEAD.CREATE.COST_CALCULATOR.ADD_MATERIAL_MODAL.autoCompletePlaceholder'
                )}`}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderStyle: 'hidden'
                  },
                  borderStyle: 'hidden',
                  fontSize: '15px'
                }}
                {...params}
              />
            )}
            value={materialSelected}
          />
        </Box>
      )}
      {materialSelected && createMode && (
        <Box alignItems="center" display="flex">
          <Box pb={1} pr={2}>
            <SubTitle>
              {`${t(
                'LEAD.CREATE.COST_CALCULATOR.ADD_MATERIAL_MODAL.cost'
              )} ($)`}
            </SubTitle>
          </Box>
          <Box>
            <RegularInput
              float
              customStyle={{ width: '100%' }}
              onChange={(cost) =>
                setMaterialSelected({ ...materialSelected, cost })
              }
              placeholder="0.00"
              type="number"
              value={materialSelected.cost || 0}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddMaterialModal;
