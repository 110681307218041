import React, { useEffect } from 'react';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps,
  Modal,
  Backdrop
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useNavigationStore from '../../store/zustand/navigation';

export const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 254,
  minWidth: 600,
  padding: '40px 0px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)'
}));

export const ModalContainerMobile = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 254,
  padding: '40px 0px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)'
}));

export const ButtonContainerModal = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '400px'
}));

export const DrawerButton = styled(Button)<SlideProps>(() => ({
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

interface Props {}

const GlobalConfirmationModal: React.FC<Props> =
  function GlobalConfirmationModal() {
    const {
      setConfirmModal,
      confirmModalPrimaryAction,
      confirmModalPrimaryTitle,
      confirmModalTitle,
      confirmModalSecondaryAction,
      confirmModalSecondaryTitle
    } = useNavigationStore((state) => state);

    const { t } = useTranslation();

    useEffect(() => {
      const fuctionValidation = () => {};
      if (
        fuctionValidation.toString() === confirmModalPrimaryAction.toString()
      ) {
        setConfirmModal();
      }
    }, []);

    return (
      <Container sx={{ alignItems: 'center', margin: '40px', width: 'auto' }}>
        <Modal
          open
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {
            confirmModalSecondaryAction();
            setConfirmModal();
          }}
        >
          <ModalContainer>
            <Typography color="secondary.400" variant="modalTitle">
              {/* <i
              className="custom-icon icon-info"
              style={{ fontSize: 40, marginBottom: 24 }}
            /> */}
              {confirmModalTitle}
              <br />
            </Typography>
            <br />
            <ButtonContainerModal>
              <>
                {confirmModalSecondaryTitle && (
                  <DrawerButton
                    onClick={() => {
                      confirmModalSecondaryAction();
                      setConfirmModal();
                    }}
                    sx={{ height: 46, width: 200 }}
                    variant={
                      confirmModalSecondaryTitle ===
                      `${t('GENERAL.cancelButton')}`
                        ? 'cancel'
                        : 'outlined'
                    }
                  >
                    <Typography>{confirmModalSecondaryTitle}</Typography>
                  </DrawerButton>
                )}
                <DrawerButton
                  onClick={() => {
                    confirmModalPrimaryAction();
                    setConfirmModal();
                  }}
                  sx={{ height: 46, width: 200 }}
                  variant="contained"
                >
                  <Typography>{confirmModalPrimaryTitle}</Typography>
                </DrawerButton>
              </>
            </ButtonContainerModal>
          </ModalContainer>
        </Modal>
      </Container>
    );
  };

export default GlobalConfirmationModal;
