import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { DEFAULT_SCREENS } from '../helpers/routesConstants';
import useAuthStore from '../store/zustand/auth';

interface Roles {
  allowedRoles: any[];
}

function AuthByRole({ allowedRoles }: Roles) {
  const userState = useAuthStore((state) => state);
  const userRoles = [...userState.roles];
  const location = useLocation();

  return allowedRoles.find((role) => userRoles.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate replace state={{ from: location }} to={DEFAULT_SCREENS.LANDING} />
  );
}

export default AuthByRole;
