/* eslint-disable no-loop-func */
/* eslint-disable import/prefer-default-export */

const getTotalSomething = (values: any, something: string) => {
  let totalSomething = 0.0;
  if (values?.serviceTimes?.length > 0) {
    values.serviceTimes.forEach((serviceTime: any) => {
      totalSomething += serviceTime[something] || 0;
    });
  }
  return totalSomething;
};

export const getTotalQuoteMarkup = (values: any) => {
  const counterServiceTimes = values?.serviceTimes?.length || 1;
  return getTotalSomething(values, 'quoteMarkup') / counterServiceTimes;
};

export const getTotalQuotePrice = (values: any) => {
  return getTotalSomething(values, 'quotePrice');
};

export const getQuotedTotal = (values: any) => {
  let totalAmount = 0.0;
  values?.serviceTimes?.forEach((service: any, index: number) => {
    const amount = service?.quotePrice
      ? service.quotePrice *
        parseInt(
          service?.availability?.generalInfo?.daysOfServiceCount || 1,
          10
        )
      : 0;
    totalAmount += amount;
  });
  return totalAmount;
};

export const counterOfferServiceTimeParser = (serviceTime: any) => {
  return {
    endDate: serviceTime?.endDate,
    frequency: serviceTime?.frequency,
    frequencyDays: serviceTime?.frequencyDays,
    frequencyWeek: serviceTime?.frequencyWeek,
    serviceTimeId: serviceTime?.id,
    startDate: serviceTime?.startDate
  };
};
