import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface DeleteButtonProp {
  deleteFn: any;
  disabled?: boolean;
  icon?: string;
  tooltip?: string;
}

const DeleteButton: React.FC<DeleteButtonProp> = function DeleteButton({
  deleteFn,
  icon,
  tooltip,
  disabled
}) {
  return (
    <Button
      disabled={disabled}
      onClick={() => deleteFn()}
      style={{ minWidth: '45px', padding: 0 }}
      variant="table"
    >
      <Typography variant="primary-bold">
        <Tooltip title={tooltip}>
          <i className={`custom-icon icon-${icon}`} />
        </Tooltip>
      </Typography>
    </Button>
  );
};

DeleteButton.defaultProps = {
  disabled: false,
  icon: 'delete',
  tooltip: 'delete'
};

export default DeleteButton;
