import { Options } from '@toast-ui/calendar';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface CalendarState {
  AssignmentsDates: string[];
  selectedDate: Date | null;
  selectedView: Required<Options>['defaultView'];
  setAssignmentsDates: (AssignmentsDates: string[]) => void;
  setSelectedDate: (selectedDate: Date) => void;
  setSelectedView: (selectedView: Required<Options>['defaultView']) => void;
}

const useCalendarStore = create<CalendarState>()(
  devtools(
    persist(
      (set) => ({
        AssignmentsDates: [],
        selectedDate: null,
        selectedView: 'month',
        setAssignmentsDates: (AssignmentsDates: string[]) =>
          set(() => ({ AssignmentsDates })),
        setSelectedDate: (selectedDate: Date) => set(() => ({ selectedDate })),
        setSelectedView: (selectedView: Required<Options>['defaultView']) =>
          set(() => ({ selectedView }))
      }),
      { name: 'calendar-storage' }
    )
  )
);

export default useCalendarStore;
