/* eslint-disable prettier/prettier */
import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

export interface ICardServiceFrequency {
  type: string;
}
const CardServiceFrequency: React.FC<ICardServiceFrequency> = function CardServiceFrequency({type}) {
  return (
    <Box
      style={{
        alignItems:'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Typography fontSize={12} fontWeight={800} variant="primary-bold">Service frequency:</Typography>
      <Box
       style={{
        alignItems:'center',
        backgroundColor:'#e0dede',
        borderRadius:'12px',
        flexDirection: 'row',
        height:'35px',
        justifyContent: 'space-between',
        marginLeft:'5px',
        paddingLeft:'16px',
        paddingRight:'16px',
        paddingTop:'2px'
      }}
      >
        <Typography
          fontSize={12}
          fontWeight={800}
          variant="primary-bold"
        >
          {type}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardServiceFrequency;
