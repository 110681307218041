import React from 'react';
import '../../../style/App.scss';
import {
  Container,
  Typography,
  Box,
  styled,
  Button,
  SliderProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../input/RegularInput';

const ButtonContainer = styled(Container)<SliderProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2)
}));

interface Props {
  confirmMFA: () => void;
  getError: () => false | JSX.Element;
  getMethodMasked: () => any;
  handleBack: () => void;
  methodSelected: number;
  resendCode: () => void;
  setCode: (value: React.SetStateAction<string>) => void;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationCode: React.FC<Props> = function ConfirmationCode({
  methodSelected,
  setCode,
  resendCode,
  getError,
  confirmMFA,
  setError,
  getMethodMasked,
  handleBack
}) {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        color="surface.main"
        sx={{ fontSize: 16, textAlign: 'center', width: '100%' }}
        variant="primary-bold"
      >
        {methodSelected === 2
          ? t('LOGIN.mfaConfirmationSubtitleEmail')
          : t('LOGIN.mfaConfirmationSubtitlePhone')}
      </Typography>
      <br />
      <Box className="Form" sx={{ textAlign: 'left', width: '100%' }}>
        <Typography
          color="surface.main"
          sx={{ fontSize: 18 }}
          variant="primary-bold"
        >
          {getMethodMasked()}
        </Typography>
        <br />
        <br />
        <Typography
          color="secondaryText.main"
          sx={{ fontSize: 18 }}
          variant="primary-bold"
        >
          {t('LOGIN.mfaVerificationCode')}
        </Typography>
        <RegularInput
          customStyle={{ width: '100%' }}
          inputInvalid={setError}
          onChange={(value) => setCode(value)}
          type="text"
        />
      </Box>
      {getError()}
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <Button
          onClick={() => resendCode()}
          sx={{ width: '100%' }}
          variant="outlined"
        >
          <Typography variant="primary-bold">
            {t('LOGIN.mfaResendCode')}
          </Typography>
        </Button>
        <Box sx={{ height: 16, width: 24 }} />
        <Button
          color="primary"
          onClick={() => confirmMFA()}
          sx={{ width: '100%' }}
          variant="contained"
        >
          <Typography variant="primary-bold">
            {t('GENERAL.verifyButton')}
          </Typography>
        </Button>
      </ButtonContainer>
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <Button
          color="primary"
          onClick={() => handleBack()}
          sx={{ width: '100%' }}
          variant="contained"
        >
          <Typography variant="primary-bold">
            {t('GENERAL.backButton')}
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default ConfirmationCode;
