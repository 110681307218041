import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg';

// Define styles for mobile and desktop devices
const MobileStyle = {
  margin: '20px',
  width: 'auto'
};

const DesktopStyle = {
  marginX: '30px',
  marginY: '20px',
  width: 'auto'
};

const TeamProject: React.FC = function TeamProject() {
  const location = useLocation();

  return (
    <Container sx={isMobile ? MobileStyle : DesktopStyle}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography
          color="primary.main"
          sx={{
            fontSize: isMobile ? 24 : 28,
            fontWeight: 900,
            paddingLeft: '1rem'
          }}
          variant="title"
        >
          {location?.state?.teamName}
        </Typography>
      </Box>

      <Box display="flex" sx={{ marginLeft: '1rem', marginTop: '2rem' }}>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          {Array.from(Array<any>(4)).map<any>(() => (
            <Box
              component="div"
              key={uniqueId()}
              sx={{
                backgroundColor: 'grey',
                borderRadius: '100%',
                marginRight: '10px',
                padding: '20px'
              }}
            />
          ))}
        </Box>

        <Button size="small" variant="outlined">
          Add / Remove Member
        </Button>
      </Box>

      {
        // Project Details
      }
      <Box
        display="flex"
        flexWrap="wrap"
        gap="1rem 1%"
        sx={{ marginLeft: '1rem', marginTop: '2rem' }}
      >
        <Card sx={{ minHeight: '18rem', width: '49.5%' }}>
          <CardContent>
            <Typography
              color="primary.main"
              sx={{ fontSize: isMobile ? 18 : 24, fontWeight: 700 }}
            >
              Project Details
            </Typography>

            <Box display="flex" marginTop="1rem">
              <Box
                component="div"
                sx={{
                  backgroundColor: 'grey',
                  borderRadius: '100%',
                  marginRight: '10px',
                  padding: '20px'
                }}
              />
              <Box
                display="flex"
                sx={{ flexFlow: 'column', marginLeft: '0.5rem' }}
              >
                <Typography
                  // color="primary.main"
                  sx={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }}
                >
                  Client Information
                </Typography>
                <Typography
                  // color="primary.main"
                  sx={{ fontSize: isMobile ? 12 : 14, fontWeight: 600 }}
                >
                  New client General Information
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {
          // Services and Tasks
        }
        <Card sx={{ minHeight: '18rem', width: '49.5%' }}>
          <CardContent>
            <Typography
              color="primary.main"
              sx={{ fontSize: isMobile ? 18 : 24, fontWeight: 700 }}
            >
              Services and Tasks
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              marginTop="0.5rem"
            >
              <Card style={{ backgroundColor: '#E7EDF9', marginTop: '0.5rem' }}>
                <CardContent>
                  <Typography
                    color="primary.main"
                    sx={{ fontSize: isMobile ? 12 : 14, fontWeight: 700 }}
                  >
                    Carpet Cleaning
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Prepare Carpet
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Clean Carpet
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Install Carpet
                        </Typography>
                      }
                    />
                  </FormGroup>
                </CardContent>
              </Card>
              <Card style={{ backgroundColor: '#E7EDF9', marginTop: '0.5rem' }}>
                <CardContent>
                  <Typography
                    color="primary.main"
                    sx={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }}
                  >
                    Covid
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Prepare Carpet
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Clean Carpet
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Install Carpet
                        </Typography>
                      }
                    />
                  </FormGroup>
                </CardContent>
              </Card>
              <Card style={{ backgroundColor: '#E7EDF9', marginTop: '0.5rem' }}>
                <CardContent>
                  <Typography
                    color="primary.main"
                    sx={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }}
                  >
                    Odor
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Prepare Carpet
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Clean Carpet
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <Typography color="secondary" sx={{ fontSize: '12px' }}>
                          Install Carpet
                        </Typography>
                      }
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            </Box>
          </CardContent>
        </Card>

        {
          // Chat
        }
        <Card sx={{ minHeight: '18rem', width: '49.5%' }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100%'
            }}
          >
            <Typography
              color="primary.main"
              sx={{ fontSize: isMobile ? 18 : 24, fontWeight: 700 }}
            >
              Chat
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E1EBF1',
                  borderRadius: '0.25rem',
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingBottom: '0.5rem',
                  paddingTop: '0.5rem'
                }}
              >
                <Typography
                  color="primary.main"
                  sx={{ fontSize: isMobile ? 10 : 12, fontWeight: 500 }}
                >
                  8:30 am
                </Typography>

                <Typography
                  color="secondary"
                  sx={{ fontSize: isMobile ? 12 : 14, fontWeight: 600 }}
                >
                  Good Morning
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#E1EBF1',
                  borderRadius: '0.25rem',
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingBottom: '0.5rem',
                  paddingTop: '0.5rem'
                }}
              >
                <Typography
                  color="primary.main"
                  sx={{ fontSize: isMobile ? 10 : 12, fontWeight: 500 }}
                >
                  8:30 am
                </Typography>

                <Typography
                  color="secondary"
                  sx={{ fontSize: isMobile ? 12 : 14, fontWeight: 600 }}
                >
                  Good Morning
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {
          // Documents
        }
        <Card sx={{ minHeight: '18rem', width: '49.5%' }}>
          <CardContent>
            <Typography
              color="primary.main"
              sx={{ fontSize: isMobile ? 18 : 24, fontWeight: 700 }}
            >
              Documents
            </Typography>

            <Box display="flex" gap="0.5rem" marginTop="0.5rem">
              <Card style={{ backgroundColor: '#E1EBF1', marginTop: '0.5rem' }}>
                <CardContent
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '100%'
                  }}
                >
                  <Typography
                    color="primary.main"
                    sx={{
                      fontSize: isMobile ? 12 : 14,
                      fontWeight: 700,
                      marginBottom: '2rem'
                    }}
                  >
                    General
                    <br />
                    Information
                  </Typography>

                  <DocumentIcon />
                </CardContent>
              </Card>

              <Card style={{ backgroundColor: '#E1EBF1', marginTop: '0.5rem' }}>
                <CardContent
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '100%'
                  }}
                >
                  <Typography
                    color="primary.main"
                    sx={{
                      fontSize: isMobile ? 12 : 14,
                      fontWeight: 700,
                      marginBottom: '2rem'
                    }}
                  >
                    Documentation
                  </Typography>

                  <DocumentIcon />
                </CardContent>
              </Card>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default TeamProject;
