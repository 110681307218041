import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, Toolbar, styled } from '@mui/material';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';
import {
  Accordion,
  TitleTab,
  AccordionDetails,
  AccordionSummary,
  ItemTab,
  ExpandableDrawer,
  IconBox
} from './parts';
import blueBerryLetters from '../../../assets/images/bluberry.png';
import blueBerryLogoMini from '../../../assets/images/bluberry_logo_mini.png';
import useOrderTabByRole from '../../../hooks/useOrderTabByRole';
import useAuthStore from '../../../store/zustand/auth';
import { ROLES } from '../../../helpers/roles';
import useUserRole from '../../../hooks/useUserRoles';
import { CALENDAR_TAB } from '../../../helpers/navItems';
import useCalendarStore from '../../../store/zustand/calendar';
import 'react-calendar/dist/Calendar.css';

interface Props {
  expandSidebar: (param: boolean) => void;
  expandSidebarFlag: boolean;
  // permanentSidebar: boolean;
  // setPermanentSidebar: (param: boolean) => void;
}

const LogoContainer = styled(Toolbar)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  maxHeight: '96px !important',
  minHeight: '96px !important',
  overflow: 'hidden'
}));

const SideBar: React.FC<Props> = function SideBar({
  expandSidebar,
  expandSidebarFlag
  // permanentSidebar
  // setPermanentSidebar
}) {
  const [expanded, setExpanded] = useState<string | false>('');

  const userState = useAuthStore((state) => state);
  const menuTabs = useMemo(() => useOrderTabByRole(userState), [userState]);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const hasRoleOfAdmin = useUserRole([ROLES.ADMINISTRATOR]);
  const isClient = useUserRole([ROLES.CLIENT]);
  const location = useLocation();
  const isCalendarPathActive = !!matchPath(location.pathname, '/app/calendar');

  const { selectedDate, setSelectedDate, setSelectedView } = useCalendarStore(
    (state) => state
  );

  useEffect(() => {
    if (isCalendarPathActive && !selectedDate) {
      setSelectedDate(new Date());
      setSelectedView('month');
    }
  }, [isCalendarPathActive]);

  return (
    <Container>
      <Box component="nav" sx={{ overflow: 'visible' }}>
        <ExpandableDrawer
          // onMouseEnter={() => expandSidebar(true)}
          // onMouseLeave={() =>
          //   permanentSidebar ? () => {} : expandSidebar(false)
          // }
          open={expandSidebarFlag}
          sx={{ overflow: 'visible' }}
          variant="permanent" // temporary
        >
          {/* <Box
            onClick={() => setPermanentSidebar(!permanentSidebar)}
            sx={{
              color: permanentSidebar ? 'primary.main' : 'secondary.300',
              cursor: 'pointer',
              position: 'absolute',
              right: '-12px'
            }}
          >
            <i className="custom-icon icon-pin" />
          </Box> */}
          {/* -------------- LOGO  --------------*/}
          <LogoContainer>
            <>
              <img
                alt="BlueBerry Logo"
                className="logo-image-mini"
                src={blueBerryLogoMini}
              />
              {expandSidebarFlag ? (
                <img
                  alt="BlueBerry leters"
                  className="logo-image-letters"
                  src={blueBerryLetters}
                />
              ) : null}
            </>
          </LogoContainer>

          {/* -------------- PARENT TABS  --------------*/}
          <Box
            className="scroll-bar-styled"
            onClick={() => expandSidebar(true)}
            sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
          >
            {menuTabs.map((parentTab) => (
              <Accordion
                expanded={expanded === parentTab.text}
                key={parentTab.text}
                onChange={handleChange(parentTab.text)}
                sx={{ margin: '0px' }}
              >
                <AccordionSummary
                  aria-controls={`aria ${parentTab.text}`}
                  id={parentTab.text}
                  sx={{
                    backgroundColor:
                      expanded === parentTab.text && expandSidebarFlag
                        ? 'primary.400'
                        : 'white'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width:
                        expanded === parentTab.text && expandSidebarFlag
                          ? '80%'
                          : '100%'
                    }}
                  >
                    <IconBox
                      expandSidebarFlag={expandSidebarFlag}
                      expanded={expanded.toString()}
                      tabLabel={parentTab.text}
                    >
                      <i className={`sidebar-icon  icon-${parentTab.icon}`} />
                    </IconBox>
                    {/* ----- IF DOESNT HAVE CHILDRENS THEN DIRECT NAVLINK  ------*/}
                    {parentTab.route ? (
                      <NavLink
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textDecoration: 'none'
                        }}
                        to={
                          isClient
                            ? `${parentTab.route}`
                            : `/app/${parentTab.route}`
                        }
                      >
                        <TitleTab
                          sx={{
                            color:
                              expanded === parentTab.text
                                ? 'white'
                                : 'secondary.400',
                            opacity: expandSidebarFlag ? 1 : 0
                          }}
                        >
                          {parentTab.text}
                        </TitleTab>
                      </NavLink>
                    ) : (
                      <TitleTab
                        sx={{
                          color:
                            expanded === parentTab.text
                              ? 'white'
                              : 'secondary.400',
                          opacity: expandSidebarFlag ? 1 : 0
                        }}
                      >
                        {parentTab.text}
                      </TitleTab>
                    )}
                  </Box>
                </AccordionSummary>
                {/* -------------- CHILDREN TABS  --------------*/}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    paddingY: '8px'
                  }}
                >
                  {parentTab.children
                    ? parentTab.children.map((tabItem) => (
                        <AccordionDetails
                          key={tabItem.text}
                          sx={{
                            display: expandSidebarFlag ? 'block' : 'none'
                          }}
                        >
                          <NavLink
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              textDecoration: 'none'
                            }}
                            to={tabItem.route}
                          >
                            {({ isActive }) => (
                              <ItemTab
                                sx={{
                                  color: isActive
                                    ? 'primary.400'
                                    : 'secondary.400'
                                }}
                              >
                                {tabItem.text}
                              </ItemTab>
                            )}
                          </NavLink>
                        </AccordionDetails>
                      ))
                    : null}
                </Box>
                {isCalendarPathActive &&
                  parentTab.text === CALENDAR_TAB.text &&
                  expandSidebarFlag && (
                    <Calendar
                      calendarType="gregory"
                      onChange={(date: any) => {
                        setSelectedDate(date);
                        setSelectedView('day');
                        return date;
                      }}
                      value={selectedDate ? new Date(selectedDate) : null}
                    />
                  )}
              </Accordion>
            ))}
          </Box>
          {hasRoleOfAdmin ? (
            <Accordion
              expanded={expanded === 'settings'}
              key="settings"
              onChange={handleChange('settings')}
              sx={{ height: '100px' }}
            >
              <AccordionSummary
                aria-controls="aria settings"
                id="aria settings-id"
                sx={{
                  backgroundColor:
                    expanded === 'settings' && expandSidebarFlag
                      ? 'primary.400'
                      : 'white',
                  marginY: '20px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width:
                      expanded === 'settings' && expandSidebarFlag
                        ? '80%'
                        : '100%'
                  }}
                >
                  <NavLink
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textDecoration: 'none'
                    }}
                    to="/app/company-settings"
                  >
                    <IconBox
                      expandSidebarFlag={expandSidebarFlag}
                      expanded={expanded.toString()}
                      tabLabel="settings"
                    >
                      <i className="sidebar-icon icon-settings" />
                    </IconBox>
                    <TitleTab
                      sx={{
                        color:
                          expanded === 'settings' ? 'white' : 'secondary.400',
                        opacity: expandSidebarFlag ? 1 : 0
                      }}
                    >
                      Settings
                    </TitleTab>
                  </NavLink>
                </Box>
              </AccordionSummary>
            </Accordion>
          ) : null}
        </ExpandableDrawer>
      </Box>
    </Container>
  );
};

export default SideBar;
