import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFrequencyWithDays } from '../../../../utils/general';

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceSummary: React.FC<Props> = function ServiceSummary({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const getIndividualTasks = (serviceTime: any, type: string) => {
    const { individualTasks } = serviceTime || {};

    if (
      type === 'services' &&
      individualTasks &&
      individualTasks.tasks &&
      individualTasks.tasks.length > 0
    ) {
      return `${individualTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }

    if (
      type !== 'services' &&
      individualTasks &&
      individualTasks?.tasks &&
      individualTasks?.tasks?.length > 0
    ) {
      return `${individualTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }

    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getServices = (serviceTime: any) => {
    if (
      serviceTime?.services &&
      serviceTime.id &&
      typeof serviceTime.id === 'number'
    ) {
      const { services: servicesByST } = serviceTime;
      if (servicesByST && servicesByST.length > 0) {
        return `${servicesByST
          .map((service: any) => {
            return service.name;
          })
          .toString()
          .replaceAll(',', ', ')}`;
      }
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };
  const auxKey = `schedule-serviceTime-${index}`;
  return (
    <Box
      key={`${auxKey}`}
      sx={{
        border: 1,
        borderColor: theme.palette.secondary[400],
        borderRadius: 1,
        mt: 5,
        p: 2
      }}
      width="100%"
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Box mb={2}>
            <Typography variant="body_500">
              {values &&
              values.projectType !== 'adhoc' &&
              values.projectType !== 'Ad-Hoc'
                ? `${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')} #${
                    index + 1
                  }`
                : `Ad-Hoc ${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')}`}
            </Typography>
          </Box>
          {values &&
          (values.projectType === 'adhoc' ||
            values.projectType === 'Ad-Hoc') ? null : (
            <Box display="flex">
              <Typography variant="primary-bold">
                {t('LEAD.CREATE.SERVICES.SUMMARY.frequency')}:
              </Typography>
              &nbsp;
              <Typography variant="primary">
                {' '}
                {values?.serviceTimes
                  ? getFrequencyWithDays(values?.serviceTimes[index], t)
                  : 'frequency'}
              </Typography>
            </Box>
          )}
          <Box display="flex">
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.SERVICES.SUMMARY.services')}:
            </Typography>
            &nbsp;
            <Typography variant="primary">
              {' '}
              {values?.serviceTimes
                ? getServices(values?.serviceTimes[index])
                : 'frequency'}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.SERVICES.SUMMARY.adhocTasks')}:
            </Typography>
            &nbsp;
            <Typography variant="primary">
              {' '}
              {values &&
              values.projectType !== 'adhoc' &&
              values.projectType !== 'Ad-Hoc'
                ? getIndividualTasks(values?.serviceTimes[index], 'services')
                : getIndividualTasks(values?.serviceTimes?.[index], 'Ad-Hoc')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceSummary;
