import { CreateTaskDTO, TaskApi } from '../api-client/generated/api';

const apiCaller = new TaskApi();

interface Pagination {
  items: number;
  page: number;
}

const getTasks = async (params: Pagination) => {
  const response = await apiCaller.taskGetAllPaginated(params);
  const { data } = response;
  return data;
};

const getAllTasks = async () => {
  const response = await apiCaller.taskGetAll();
  const { data } = response;
  return data;
};

const getTask = async (param: any) => {
  if (!param?.id) {
    return null;
  }

  const response = await apiCaller.taskGetOneById(param);
  const { data } = response;
  return data;
};

const createTask = async (params: CreateTaskDTO) => {
  const response = await apiCaller.taskCreate({
    createTaskDTO: {
      ...params,
      imageRequired: params.imageRequired ? params.imageRequired : false
    }
  });
  const { data } = response;
  return data;
};

const createTaskReturnList = async (params: CreateTaskDTO) => {
  const response = await apiCaller.taskCreate({
    createTaskDTO: {
      ...params,
      imageRequired: params.imageRequired ? params.imageRequired : false
    }
  });

  const response2 = await apiCaller.taskGetAllPaginated({ items: 20, page: 1 });

  const { data } = response;
  const { data: data2 } = response2;
  return { ...data, data2 };
};

const updateTask = async (params: any) => {
  if (!params.id) {
    return null;
  }
  const {
    id,
    name,
    description,
    skills,
    taskChecklist,
    materials,
    measure,
    measurePerHour,
    imageRequired,
    minimumResources
  } = params;
  const response = await apiCaller.taskUpdate({
    id,
    updateTaskDTO: {
      description,
      imageRequired,
      materials,
      measure,
      measurePerHour: parseFloat(measurePerHour),
      minimumResources: parseFloat(minimumResources),
      name,
      skills,
      taskChecklist
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteTask = async (params: { id: string; url: string }) => {
  if (!params.id) {
    return null;
  }
  const response = await apiCaller.taskDelete({ id: params.id });
  const { status, statusText } = response;
  return { status, statusText };
};

export default {
  createTask,
  createTaskReturnList,
  deleteTask,
  getAllTasks,
  getTask,
  getTasks,
  updateTask
};
