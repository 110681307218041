/* eslint-disable import/prefer-default-export */
export function setLocalStorage(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorage(key: string) {
  let toBack;
  if (key && localStorage.getItem(key)) {
    toBack = JSON.parse(localStorage.getItem(key) || '');
  }
  return toBack;
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export const rolesToEditUser = ['Administrator', 'Operations', 'SuperUser'];
