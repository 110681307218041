import {
  StorageApi,
  DeleteFileBodyDTO,
  StorageApiStorageGetAllEntityFilesByIdRequest,
  StorageApiStorageUploadDocumentsRequest,
  UpdateFileDTO,
  TechnicianTaskManagementApi,
  DeleteFilesBodyDTO
} from '../api-client/generated/api';

const apiCaller = new StorageApi();
const technicianTaskManagementApi = new TechnicianTaskManagementApi();

const deleteFile = async (params: DeleteFileBodyDTO) => {
  if (!params) {
    return null;
  }

  const { status, statusText } = await apiCaller.storageDeleteFile({
    deleteFileBodyDTO: params
  });

  return { status, statusText };
};

const deleteFiles = async (params: DeleteFilesBodyDTO) => {
  if (!params) {
    return null;
  }

  const { status, statusText } = await apiCaller.storageDeleteFiles({
    deleteFilesBodyDTO: params
  });

  return { status, statusText };
};

const uploadCompanyLogo = async (params: any) => {
  if (!params) {
    return null;
  }
  const { file } = params;
  const { status, statusText } = await apiCaller.storageUploadCompanyLogo({
    file
  });

  return { status, statusText };
};

const uploadProfilePhoto = async (params: any) => {
  if (!params) {
    return null;
  }
  const { file } = params;
  const { status, statusText } = await apiCaller.storageUploadUserPhoto({
    file
  });

  return { status, statusText };
};

const getProfilePhoto = async () => {
  try {
    const data: any = await apiCaller
      .storageGetProfilePhotos()
      .catch((error) => {
        return { data: null, error: error?.response };
      });
    return data;
  } catch (e) {
    return { data: null };
  }
};

const getProfilePhotoByID = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.storageGetProfilePhotosFromProfile({ id });
  return response;
};

const getCopmanyLogo = async () => {
  const data = await apiCaller.storageGetCompanyLogo();

  return data;
};

const getEntityFiles = async (
  params: StorageApiStorageGetAllEntityFilesByIdRequest
) => {
  if (!params) {
    return null;
  }

  const data = await apiCaller.storageGetAllEntityFilesById(params);

  return data;
};

const uploadCompanyBrochure = async (params: any) => {
  if (!params) {
    return null;
  }
  const { file } = params;
  const { status, statusText } = await apiCaller.storageUploadCompanyBrouchure({
    file
  });

  return { status, statusText };
};

const uploadCompanyTerms = async (params: any) => {
  if (!params) {
    return null;
  }
  const { files } = params;
  const { status, statusText } = await apiCaller.storageUploadCompanyTerms({
    files
  });

  return { status, statusText };
};

const uploadEntityFiles = async (params: any) => {
  if (!params || !params?.entityId) {
    return null;
  }

  if (params?.initialLeadFiles && params?.initialLeadFiles.length > 0) {
    await apiCaller.storageDeleteFiles({
      deleteFilesBodyDTO: { fileIds: params?.initialLeadFiles }
    });
  }

  const { files, relatedEntity, entityId } = params as any;
  const result = (await apiCaller.storageUploadDocuments({
    entityId,
    files,
    relatedEntity
  } as any)) as any;

  const { data, status, statusText } = result;
  return { data, status, statusText };
};

const updateEntityFiles = async (params: UpdateFileDTO) => {
  if (!params) {
    return null;
  }
  const { status, statusText } = await apiCaller.storageUpdateFile({
    updateFileDTO: params
  });

  return { status, statusText };
};

const uploadTaskFiles = async (params: any) => {
  if (!params) {
    return null;
  }

  const { files, entityId } = params as any;
  const response = await apiCaller.storageUploadTaskDocuments({
    files,
    taskId: entityId
  });

  return response;
};

const uploadTaskIterationFile = async (params: any) => {
  if (!params) {
    return null;
  }

  const { file, taskId } = params as any;
  const { status, statusText, data } =
    (await apiCaller.storageUploadTaskIterationFile({
      file: file[0],
      taskId
    } as any)) as any;

  if (params?.setTaskItearationFilesList && data && data[0]) {
    const response =
      await technicianTaskManagementApi.technicianTaskManagenmentGetTaskIterationById(
        { taskIterationId: taskId }
      );
    const { data: data2 } = response;
    const auxData2: any = typeof data2 === 'object' ? data2 : null;

    params?.setTaskItearationFilesList(auxData2?.fileBank);

    return null;
  }

  return { data, status, statusText };
};

export default {
  deleteFile,
  deleteFiles,
  getCopmanyLogo,
  getEntityFiles,
  getProfilePhoto,
  getProfilePhotoByID,
  updateEntityFiles,
  uploadCompanyBrochure,
  uploadCompanyLogo,
  uploadCompanyTerms,
  uploadEntityFiles,
  uploadProfilePhoto,
  uploadTaskFiles,
  uploadTaskIterationFile
};
