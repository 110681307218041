/* eslint-disable import/prefer-default-export */
function customSort(a: any, b: any) {
  const order = ['draft', 'converted', 'rejected', 'workingLead'];

  const indexA = order.indexOf(a.data);
  const indexB = order.indexOf(b.data);

  if (indexA === -1) return 1; // if a.data is not in order, move it to the end
  if (indexB === -1) return -1; // if b.data is not in order, move it to the beginning

  return indexA - indexB;
}

export function dashboardCounters(data: any, title: string) {
  const dataOrder = data;
  const counters = data.counters.sort(customSort);
  dataOrder.counters = counters;
  return { ...dataOrder, title };
}
