import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const TextAreaInput: React.FC<TextFieldProps> = function TextAreaInput({
  ...props
}) {
  return (
    <TextField
      // eslint-disable-next-line
      {...props}
      multiline
      InputProps={{
        disableUnderline: true,
        style: { height: '100%' }
      }}
      variant="standard"
    />
  );
};

export default TextAreaInput;
