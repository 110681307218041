import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Modal,
  Container,
  Snackbar
} from '@mui/material';
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import RegularInput from '../input/RegularInput';
import SortableTable from '../list/sortableTable';
import api from '../../api';
import {
  FormLabel,
  HeaderFormContainer,
  TitleButtonsBox,
  TitleFormBox,
  WhiteBoxContainer
} from '../form/parts';
import ButtonFormGroup from '../form/ButtonFormGroup';
import AvailableDaysForm from '../profile/AvailableDaysForm';
import { IOSSwitch } from './parts';
import ViewLoader from '../shared/ViewLoader';
import TimeComponent from '../input/TimeComponent';
import MinuteComponent from '../input/MinuteComponent';
import {
  CompanyRemindersDTO,
  CompanyTaxDTO
} from '../../api-client/generated/api';

interface RequestProps {
  requestedAvailableDays: any[];
  requestedTimeEnd: string;
  requestedTimeStart: string;
}

const companyStyle = {
  alignItems: 'flex-start !important',
  display: 'flex',
  flexDirection: 'row !important',
  flexWrap: 'wrap',
  gap: '24px',
  justifyContent: 'flex-start !important',
  width: '100% !important'
};

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 500
};

function CompanySettings() {
  const { t } = useTranslation();
  const taxHeader = [
    t('COMPANY_SETTINGS.name'),
    `${t('COMPANY_SETTINGS.tax')} (%)`,
    t('COMPANY_SETTINGS.type'),
    ''
  ];
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
  const [openSnackFile, setOpenSnackFile] = useState<boolean>(false);
  const [fireFileApi, setFireFileApi] = useState<boolean>(false);
  const [fireBrochureApi, setFireBrochureApi] = useState<boolean>(false);
  const [fireDeleteApi, setFireDeleteApi] = useState<boolean>(false);
  const [fireDeleteTaxApi, setFireDeleteTaxApi] = useState<boolean>(false);
  const [deleteID, setDeleteId] = useState<string>('');
  const [taxId, setTaxId] = useState<string>('');
  const [termFile, setTermFile] = useState<any>(null);
  const [brochureFile, setBrochureFile] = useState<any>(null);
  const [formTimeValue, setFormTimeValue] = useState<RequestProps>({
    requestedAvailableDays: [],
    requestedTimeEnd: '',
    requestedTimeStart: ''
  });
  const [formValues, setFormValues] = useState<{
    allowAudit?: boolean;
    brochure?: any;
    defaultAvailableDays?: any[];
    defaultResourceTimeEnd?: any;
    defaultResourceTimeStart?: any;
    enforceGeofencing?: boolean;
    extraHourlyRate?: number;
    fileBank?: any;
    files?: any;
    maxHoursWithoutSupervision?: number;
    minimumInvoice?: number;
    paymentTerms?: number;
    reminders?: CompanyRemindersDTO;
    taxes?: CompanyTaxDTO[];
    termRenewalIncrease?: number;
    varianceDown?: number;
    varianceUp?: number;
  }>({
    allowAudit: false,
    brochure: {},
    defaultAvailableDays: [],
    defaultResourceTimeEnd: '',
    defaultResourceTimeStart: '',
    enforceGeofencing: true,
    extraHourlyRate: 0,
    files: [],
    maxHoursWithoutSupervision: 0,
    minimumInvoice: 0,
    paymentTerms: 0,
    reminders: [] as CompanyRemindersDTO,
    taxes: [] as CompanyTaxDTO[],
    termRenewalIncrease: 0,
    varianceDown: 0,
    varianceUp: 0
  });
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [snackMsgFlag, setSnackMsgFlag] = useState<boolean>(false);
  const [actionCurrentCompany, setActionCurrentCompany] =
    useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([
    'Something go wrong'
  ]);
  const [taxesList, setTaxesList] = useState([]);
  const [disableHours, setDisableHours] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [taxesErrorMessage, setTaxesErrorMessage] = useState('');
  const [variantErrorMessage, setVariantErrorMessage] = useState('');

  const { data: currentCompany } = useSWR(
    actionCurrentCompany ? 'api/currentCompany/settings' : null,
    api.CompanyApi.getCurrentCompany
  );

  const { data: updatedCompany, error: updateError } = useSWR(
    actionUpdate ? ['update/currentCompany', formValues] : null,
    api.CompanyApi.updateCurrentCompany
  );

  const { data: deleteTax, error: deleteTaxError } = useSWR(
    fireDeleteTaxApi ? taxId : null,
    api.CompanyApi.deleteTax
  );

  const { data: fileResponse, error: errorFile } = useSWR(
    fireFileApi ? { files: [termFile.target.files[0]] } : null,
    api.StorageApi.uploadCompanyTerms
  );

  const { data: brochureResponse, error: errorBrochure } = useSWR(
    fireBrochureApi ? { file: brochureFile.target.files[0] } : null,
    api.StorageApi.uploadCompanyBrochure
  );

  const { data: deleteResponse, error: errorDelete } = useSWR(
    fireDeleteApi
      ? { fileId: deleteID, metaDescription: 'termsAndConditions' }
      : null,
    api.StorageApi.deleteFile
  );

  const clearClient = () => mutate('api/currentCompany/settings', null, true);

  // --------------- FUNCTIONS ---------------
  const onChange = (name: string, value: any) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const resetCompanyValues = () => {
    if (currentCompany) {
      // eslint-disable-next-line no-console
      console.log('renovados', currentCompany);
      const termsConditionArray = currentCompany.fileBank
        ? currentCompany.fileBank
            ?.filter(
              (file: any) => file.metaDescription === 'termsAndConditions'
            )
            .map((values: any) => {
              return {
                description: values.description,
                fileId: values.id,
                fileName: values.fileOriginalName,
                name: values.fileOriginalName
              };
            })
        : [];

      const taxesArray = currentCompany.taxes
        ? currentCompany.taxes.map((tax: any) => {
            return {
              id: tax.id,
              name: tax.name,
              tax: Number(tax.tax),
              type: tax.type
            };
          })
        : [];

      const brochure = currentCompany.fileBank
        ? currentCompany.fileBank.filter(
            (file: any) => file.metaDescription === 'companyBrouchure'
          )
        : [''];

      setFormValues({
        ...formValues,
        brochure: brochure[0],
        defaultAvailableDays: currentCompany?.defaultAvailableDays,
        defaultResourceTimeEnd: currentCompany?.defaultResourceTimeEnd,
        defaultResourceTimeStart: currentCompany?.defaultResourceTimeStart,
        enforceGeofencing: currentCompany.enforceGeofencing,
        extraHourlyRate: currentCompany?.extraHourlyRate,
        files: termsConditionArray,
        maxHoursWithoutSupervision: currentCompany.maxHoursWithoutSupervision,
        minimumInvoice: currentCompany.minimumInvoice,
        paymentTerms: currentCompany?.paymentTerms,
        reminders: {
          invoices: currentCompany.companyReminders?.invoices,
          proposals: currentCompany.companyReminders?.proposals,
          upcomingProjects: currentCompany.companyReminders?.upcomingProjects
        },
        taxes: taxesArray,
        termRenewalIncrease: currentCompany?.termRenewalIncrease,
        varianceDown: currentCompany?.varianceDown,
        varianceUp: currentCompany?.varianceUp
      });
    }
  };

  const handleCancel = () => {
    resetCompanyValues();
    setErrorFields([]);
    setTaxesList([]);
    setDisableHours(false);
    setIsEditActive(false);
  };
  const handleSubmit = () => {
    const temporalErrorArray: string[] = [];
    const {
      minimumInvoice,
      maxHoursWithoutSupervision,
      reminders,
      extraHourlyRate,
      termRenewalIncrease,
      varianceUp,
      varianceDown,
      paymentTerms,
      taxes: newTaxes
    } = formValues;

    if (minimumInvoice && minimumInvoice < 0) {
      temporalErrorArray.push('minimumInvoice');
    }

    if (paymentTerms && paymentTerms < 0) {
      temporalErrorArray.push('paymentTerms');
    }

    if (extraHourlyRate && extraHourlyRate < 0) {
      temporalErrorArray.push('extraHourlyRate');
    }

    if (termRenewalIncrease && termRenewalIncrease < 0) {
      temporalErrorArray.push('termRenewalIncrease');
    }

    if (maxHoursWithoutSupervision && maxHoursWithoutSupervision < 0) {
      temporalErrorArray.push('maxHoursWithoutSupervision');
    }

    if (reminders) {
      const { upcomingProjects, proposals, invoices } = reminders;

      if (upcomingProjects !== undefined && upcomingProjects <= 0) {
        temporalErrorArray.push('upcomingProjects');
      }

      if (proposals !== undefined && proposals <= 0) {
        temporalErrorArray.push('proposals');
      }

      if (invoices !== undefined && invoices <= 0) {
        temporalErrorArray.push('invoices');
      }
    }

    // validation if integer number
    const isInteger = (value: number) => {
      return Number.isInteger(value);
    };

    if (varianceUp !== undefined) {
      if (varianceUp < 0 || !Number.isInteger(varianceUp)) {
        temporalErrorArray.push('varianceUp');
        setVariantErrorMessage('Field should be a non-negative integer');
      }
    }

    if (varianceDown !== undefined) {
      if (varianceDown < 0 || !Number.isInteger(varianceDown)) {
        temporalErrorArray.push('varianceDown');
        setVariantErrorMessage('Field should be a non-negative integer');
      }
    }

    if (newTaxes?.length) {
      const newTaxesName = newTaxes.map((tax) => tax.name);
      const namesExists = newTaxesName.filter((name) => !name);
      // validate if names exist
      if (namesExists.length) {
        temporalErrorArray.push('taxes');
        setTaxesErrorMessage('Names fields should not be empty');
      }

      const newTaxesTax = newTaxes.map((tax) => tax.tax);
      const taxExists = newTaxesTax.filter((tax) => tax === undefined);
      // validate if taxes exists
      if (taxExists.length) {
        temporalErrorArray.push('taxes');
        setTaxesErrorMessage('Tax fields should not be empty');
      }

      // validate tax range
      newTaxesTax.forEach((tax) => {
        if (tax <= 0.01 && tax > 100) {
          temporalErrorArray.push('taxes');
          setTaxesErrorMessage('Tax field must be from 0.01 to 100');
        }
      });

      const newTaxesType = newTaxes.map((tax) => tax.type);
      const typeExists = newTaxesType?.filter((type) => !type);

      if (typeExists.length) {
        temporalErrorArray.push('taxes');
        setTaxesErrorMessage('Type fields should not be empty');
      }
    }

    setErrorFields(temporalErrorArray);

    if (temporalErrorArray.length === 0) {
      // eslint-disable-next-line no-console
      console.log('entro submit');
      delete formValues.brochure;
      const termsConditionArray = formValues.files.map((values: any) => {
        return {
          description: values.description,
          fileId: values.fileId,
          name: values.name
        };
      });
      setFormValues({
        ...formValues,
        defaultAvailableDays: formTimeValue.requestedAvailableDays,
        defaultResourceTimeEnd: formTimeValue.requestedTimeEnd,
        defaultResourceTimeStart: formTimeValue.requestedTimeStart,
        files: termsConditionArray
      });
      setErrorFields([]);
      setActionUpdate(true);
    } else {
      setError(true);
    }
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          {errorMessage?.map((error, index) => (
            <span key={`error-${index + 1}`}>
              &bull; &nbsp;
              {error}
              <br />
            </span>
          ))}
        </Typography>
      </Container>
    );

  // --------------- USEEFFECTS ---------------
  useEffect(() => {
    if (currentCompany) {
      resetCompanyValues();
      setActionCurrentCompany(false);
    }
  }, [currentCompany]);

  useEffect(() => {
    if (
      updateError ||
      errorFile ||
      errorBrochure ||
      errorDelete ||
      deleteTaxError
    ) {
      const error = [
        updateError,
        errorFile,
        errorBrochure,
        errorDelete,
        deleteTaxError
      ].find((param) => param !== undefined);
      const response = error?.response?.data?.message;
      setErrorMessage(typeof response === 'string' ? [response] : response);
      setError(true);
      setActionUpdate(false);
      setOpenSuccessful(true);
    }
  }, [updateError, errorFile, errorBrochure, errorDelete, deleteTaxError]);

  useEffect(() => {
    if (termFile?.target) {
      setFireFileApi(true);
    }
  }, [termFile]);

  useEffect(() => {
    if (deleteID) {
      setFireDeleteApi(true);
    }
  }, [deleteID]);

  useEffect(() => {
    if (taxId) {
      setFireDeleteTaxApi(true);
    }
  }, [taxId]);

  useEffect(() => {
    if (brochureFile?.target) {
      setFireBrochureApi(true);
    }
  }, [brochureFile]);

  useEffect(() => {
    if (updatedCompany) {
      setActionUpdate(false);
      setError(false);
      setOpenSuccessful(true);
      setIsEditActive(false);
    }
  }, [updatedCompany]);

  useEffect(() => {
    if (fileResponse) {
      setError(false);
      setSnackMsgFlag(true);
      setFireFileApi(false);
      setOpenSnackFile(true);
      clearClient();
      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('se puso en true');
        clearClient();
        setActionCurrentCompany(true);
      }, 1000);
    }
  }, [fileResponse]);

  useEffect(() => {
    if (brochureResponse) {
      setError(false);
      setSnackMsgFlag(true);
      setFireBrochureApi(false);
      setOpenSnackFile(true);
      clearClient();
    }
  }, [brochureResponse]);

  useEffect(() => {
    if (deleteResponse) {
      setError(false);
      setSnackMsgFlag(false);
      setFireDeleteApi(false);
      setOpenSnackFile(true);
      clearClient();
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (deleteTax) {
      setError(false);
      setSnackMsgFlag(false);
      setFireDeleteTaxApi(false);
      setOpenSnackFile(true);
      clearClient();
    }
  }, [deleteTax]);

  if (JSON.stringify(formValues) === '{}') {
    return <ViewLoader />;
  }

  return (
    <Container>
      <Box sx={{ wdith: '100%' }}>
        <HeaderFormContainer isMobile={isMobile}>
          <TitleFormBox isMobile={isMobile}>
            <Typography sx={{ marginBottom: '4px' }} variant="title">
              {t('COMPANY_SETTINGS.companySettings')}
            </Typography>
            <Typography color="secondary.400" variant="body_300">
              {t('COMPANY_SETTINGS.pageSubtitle')}
            </Typography>
          </TitleFormBox>
          <TitleButtonsBox isEditActive={isEditActive} isMobile={isMobile}>
            <ButtonFormGroup
              actionButtonMsg={t('PROFILE.saveInformation')}
              cancelAction={() => handleCancel()}
              enableEditAction={() => setIsEditActive(true)}
              enableEditButtonMsg={t('PROFILE.editInformation')}
              isMobile={isMobile}
              mainAction={() => {
                handleSubmit();
              }}
              updateFlag={isEditActive}
            />
          </TitleButtonsBox>
        </HeaderFormContainer>
      </Box>

      {/* -------------- Company FORM -------------- */}
      <Container sx={companyStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box>
            {/* -------------- INFORMATION -------------- */}
            <WhiteBoxContainer isMobile={isMobile}>
              <Typography color="mainText.main" variant="secondary">
                {t('COMPANY_SETTINGS.informationTitle')}
              </Typography>

              <Box
                alignItems="self-end"
                display="flex"
                gap="16px"
                marginTop="16px"
              >
                <Box width="95%">
                  <FormLabel
                    error={errorFields.includes('minimumInvoice')}
                    label={t('COMPANY_SETTINGS.minimumInvoice')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('minimumInvoice')}
                    onChange={(event) => {
                      onChange('minimumInvoice', Number(event));
                    }}
                    type="number"
                    value={formValues.minimumInvoice?.toString()}
                  />
                </Box>

                <Box width="100%">
                  <FormLabel
                    error={errorFields.includes('maxHoursWithoutSupervision')}
                    label={t('COMPANY_SETTINGS.maxHours')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive || disableHours}
                    error={errorFields.includes('maxHoursWithoutSupervision')}
                    onChange={(event) => {
                      onChange('maxHoursWithoutSupervision', Number(event));
                    }}
                    type="number"
                    value={formValues.maxHoursWithoutSupervision?.toString()}
                  />
                </Box>
                <Box sx={{ marginBottom: '10px', width: '100%' }}>
                  <Checkbox
                    checked={disableHours}
                    disabled={!isEditActive}
                    onChange={(event) => {
                      if (event.target.checked) {
                        onChange('maxHoursWithoutSupervision', 0);
                      } else {
                        onChange(
                          'maxHoursWithoutSupervision',
                          formValues.maxHoursWithoutSupervision
                        );
                      }

                      setDisableHours(event.target.checked);
                    }}
                    sx={{ marginLeft: '-10px' }}
                  />
                  <Typography color="mainText.500" variant="primary-bold">
                    {t('COMPANY_SETTINGS.disableHours')}
                  </Typography>
                </Box>
              </Box>
            </WhiteBoxContainer>
          </Box>

          {/* -------------- REMINDERS -------------- */}
          <WhiteBoxContainer isMobile={isMobile}>
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.remindersTitle')}
            </Typography>

            <Box alignItems="center" display="flex" gap="16px" marginTop="16px">
              <Box width="95%">
                <FormLabel
                  error={errorFields.includes('upcomingProjects')}
                  label={t('COMPANY_SETTINGS.upcomingProjects')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  error={errorFields.includes('upcomingProjects')}
                  onChange={(event) => {
                    onChange('reminders', {
                      ...formValues.reminders,
                      upcomingProjects: Number(event)
                    });
                  }}
                  type="number"
                  value={formValues.reminders?.upcomingProjects?.toString()}
                />
              </Box>

              <Box width="100%">
                <FormLabel
                  error={errorFields.includes('invoices')}
                  label={t('COMPANY_SETTINGS.invoices')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  error={errorFields.includes('invoices')}
                  onChange={(event) => {
                    onChange('reminders', {
                      ...formValues.reminders,
                      invoices: Number(event)
                    });
                  }}
                  type="number"
                  value={formValues.reminders?.invoices?.toString()}
                />
              </Box>

              <Box width="100%">
                <FormLabel
                  error={errorFields.includes('proposals')}
                  label={t('COMPANY_SETTINGS.proposals')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  error={errorFields.includes('proposals')}
                  onChange={(event) => {
                    onChange('reminders', {
                      ...formValues.reminders,
                      proposals: Number(event)
                    });
                  }}
                  type="number"
                  value={formValues.reminders?.proposals?.toString()}
                />
              </Box>
            </Box>
          </WhiteBoxContainer>
        </Box>

        {/* -------------- TAXES -------------- */}
        <WhiteBoxContainer isMobile={isMobile}>
          <Typography color="mainText.main" variant="secondary">
            {t('COMPANY_SETTINGS.taxesTitle')}
          </Typography>
          <Box marginTop="16px">
            <Box display="flex">
              {taxHeader.map((item: any, index: number) => (
                <Box
                  sx={{
                    marginLeft: '10px',
                    width: index + 1 === taxHeader.length ? '20%' : '100%'
                  }}
                >
                  <Typography color="mainText.500" variant="primary-bold">
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>

            <SortableTable
              disableSort
              addManagement={isEditActive}
              bodyKeys={['name', 'tax', 'type']}
              disabledTable={!isEditActive}
              handleDelete={setTaxId}
              onChange={(newTaxesList) => {
                const formatTaxes = newTaxesList.map((item: any) => ({
                  ...item,
                  tax: Number(item.tax)
                }));
                setTaxesList(formatTaxes);
                setFormValues({
                  ...formValues,
                  taxes: formatTaxes
                });
              }}
              type="tax-input"
              values={formValues.taxes ?? taxesList}
            />
          </Box>
        </WhiteBoxContainer>

        {/* -------------- VARIANTS -------------- */}
        <WhiteBoxContainer isMobile={isMobile}>
          <Typography color="mainText.main" variant="secondary">
            {t('COMPANY_SETTINGS.variantsTitle')}
          </Typography>

          <Box alignItems="center" display="flex" gap="16px" marginTop="16px">
            <Box width="95%">
              <FormLabel
                error={errorFields.includes('varianceUp')}
                label={t('COMPANY_SETTINGS.varianceUp')}
              />
              <MinuteComponent
                disabled={!isEditActive}
                setTime={(v) => {
                  onChange('varianceUp', v);
                  setVariantErrorMessage('');
                }}
                time={formValues.varianceUp || 0.0}
              />
              {errorFields.includes('varianceUp') && (
                <Typography
                  color="alert.main"
                  sx={{ fontSize: '11px' }}
                  variant="primary-bold"
                >
                  {variantErrorMessage}
                </Typography>
              )}
            </Box>

            <Box width="100%">
              <FormLabel
                error={errorFields.includes('varianceDown')}
                label={t('COMPANY_SETTINGS.varianceDown')}
              />
              <MinuteComponent
                disabled={!isEditActive}
                setTime={(v) => {
                  onChange('varianceDown', v);
                  setVariantErrorMessage('');
                }}
                time={formValues.varianceDown || 0.0}
              />
              {errorFields.includes('varianceDown') && (
                <Typography
                  color="alert.main"
                  sx={{ fontSize: '11px' }}
                  variant="primary-bold"
                >
                  {variantErrorMessage}
                </Typography>
              )}
            </Box>
          </Box>
        </WhiteBoxContainer>

        {/* -------------- AVAILBLE DAYS -------------- */}

        <Box sx={{ marginTop: '-24px' }}>
          <AvailableDaysForm
            diabledButton
            enable={isEditActive}
            externalFormValue={formTimeValue}
            isMobile={isMobile}
            profile={formValues}
            setFormValueExternal={setFormTimeValue}
            type="company"
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {/* -------------- OTHER -------------- */}
          <WhiteBoxContainer isMobile={isMobile}>
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.other')}
            </Typography>

            <Box alignItems="center" display="flex" gap="16px" marginTop="16px">
              <Box width="95%">
                <FormLabel
                  error={errorFields.includes('paymentTerms')}
                  label={t('COMPANY_SETTINGS.paymentTermsLabel')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  error={errorFields.includes('paymentTerms')}
                  onChange={(paymentTerms) => {
                    onChange('paymentTerms', Number(paymentTerms));
                  }}
                  type="number"
                  value={formValues.paymentTerms?.toString()}
                />
              </Box>

              <Box width="100%">
                <FormLabel
                  error={errorFields.includes('termRenewalIncrease')}
                  label={t('COMPANY_SETTINGS.termRenewallLabel')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  error={errorFields.includes('termRenewalIncrease')}
                  onChange={(termRenewalIncrease) => {
                    onChange(
                      'termRenewalIncrease',
                      Number(termRenewalIncrease)
                    );
                  }}
                  type="number"
                  value={formValues.termRenewalIncrease?.toString()}
                />
              </Box>

              <Box width="100%">
                <FormLabel
                  error={errorFields.includes('extraHourlyRate')}
                  label={t('COMPANY_SETTINGS.extraHourlyRateLabel')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  error={errorFields.includes('extraHourlyRate')}
                  onChange={(extraHourlyRate) => {
                    onChange('extraHourlyRate', Number(extraHourlyRate));
                  }}
                  type="number"
                  value={formValues.extraHourlyRate?.toString()}
                />
              </Box>
            </Box>
            <Box>
              <FormControlLabel
                control={<IOSSwitch defaultChecked sx={{ m: 1 }} />}
                disabled={!isEditActive}
                label="Allow Audit"
                onChange={() => {
                  onChange('allowAudit', !formValues.allowAudit);
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={formValues.enforceGeofencing}
                    sx={{ m: 1 }}
                  />
                }
                disabled={!isEditActive}
                label={t('COMPANY_SETTINGS.enforceGeofencing')}
                onChange={() => {
                  onChange('enforceGeofencing', !formValues.enforceGeofencing);
                }}
              />
            </Box>
          </WhiteBoxContainer>

          {/* -------------- TERM & CONDITIONS -------------- */}
          <WhiteBoxContainer isMobile={isMobile}>
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.termConditionLabel')}
            </Typography>

            <Box display="flex" marginTop="10px">
              {[
                t('COMPANY_SETTINGS.name'),
                t('COMPANY_SETTINGS.description'),
                t('COMPANY_SETTINGS.filesTableLabel')
              ].map((item) => (
                <Box
                  sx={{
                    marginLeft: item !== 'Name' ? '152px' : '0px',
                    width: '100%'
                  }}
                >
                  <Typography color="mainText.500" variant="primary-bold">
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <SortableTable
                disableSort
                addManagement={isEditActive}
                bodyKeys={['name', 'description', 'files']}
                disabledTable={!isEditActive}
                handleDelete={setDeleteId}
                handleFileChange={setTermFile}
                onChange={(newTermList) => {
                  setFormValues({
                    ...formValues,
                    files: newTermList
                  });
                }}
                type="generic-input"
                values={formValues.files ?? []}
              />
            </Box>
            <Box
              sx={{
                alignItems: 'left',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '16px'
              }}
            >
              <Box
                sx={{
                  alignItems: 'left',
                  display: 'flex'
                }}
              >
                <Typography color="mainText.500" variant="primary-bold">
                  {t('COMPANY_SETTINGS.brochure')}
                </Typography>
              </Box>

              <Box marginTop="4px" maxHeight="100px">
                {formValues?.brochure && formValues?.brochure.fileName ? (
                  <Box>
                    <Typography variant="body_200">
                      <b>Actual brochure:</b> {formValues?.brochure.fileName}
                    </Typography>
                  </Box>
                ) : null}
                <input
                  accept="application/vnd.ms-powerpoint, application/pdf"
                  className="custom-file-input-table"
                  disabled={!isEditActive}
                  id="brochure"
                  onChange={setBrochureFile}
                  type="file"
                />
              </Box>
            </Box>
          </WhiteBoxContainer>

          {/* -------------- SUCCES MODAL-------------- */}
          <Modal
            aria-describedby="modal-modal-description-profile"
            aria-labelledby="modal-modal-title-profile"
            onClose={() => setOpenSuccessful(false)}
            open={openSuccessful}
          >
            <Box sx={style}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  {error ? (
                    <>
                      <Typography
                        color="alert.main"
                        sx={{ fontSize: 22 }}
                        variant="primary-bold"
                      >
                        Error!
                      </Typography>
                      {getError()}
                    </>
                  ) : (
                    <Typography
                      color="success.main"
                      sx={{ fontSize: 22 }}
                      variant="primary-bold"
                    >
                      {t('PROFILE.successfulMsg')}
                    </Typography>
                  )}
                </Box>
                <br />
                <Button
                  onClick={() => {
                    setOpenSuccessful(false);
                  }}
                  sx={{ width: isMobile ? '100%' : '50%' }}
                  variant="contained"
                >
                  <Typography variant="primary-bold">
                    {t('GENERAL.done')}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Modal>
          <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoHideDuration={3000}
            key="snack-key"
            message={snackMsgFlag ? 'File updated!' : 'Deleted!'}
            onClose={() => setOpenSnackFile(false)}
            open={openSnackFile}
          />
        </Box>
      </Container>
    </Container>
  );
}

export default CompanySettings;
