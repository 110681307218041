import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import en from '../languages/en';

// TODO: maybe we only need to load the system language
const resources = {
  en: {
    translation: en
  }
};

i18n
  .use(ICU) // interpolation
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    lng: 'en',
    resources
  });
