import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface ResendEmailButtonProp {
  disabled?: boolean;
  handleOpenDraw: any;
  tooltip?: string;
}

const ResendEmailButton: React.FC<ResendEmailButtonProp> =
  function ResendEmailButton({ handleOpenDraw, tooltip, disabled }) {
    return (
      <Tooltip arrow title={tooltip}>
        <Button
          disabled={disabled}
          onClick={handleOpenDraw}
          style={{ minWidth: '45px' }}
          variant="table"
        >
          <Typography color="secondary.main" variant="primary-bold">
            <i className="custom-icon icon-resendEmail" />
          </Typography>
        </Button>
      </Tooltip>
    );
  };

ResendEmailButton.defaultProps = {
  disabled: false,
  tooltip: ''
};

export default ResendEmailButton;
