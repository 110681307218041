import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropzoneLibrary from 'react-dropzone-uploader';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import profile from '../../api/profile';
import ImageCropper from './CropImage';

interface Props {
  disabledDrop?: boolean;
  filesList?: Array<File>;
  isMobile: boolean;
  multiple?: boolean;
  parent: string;
  setFiles: React.Dispatch<React.SetStateAction<any>>;
  type: string;
}

const Dropzone: React.FC<Props> = function Dropzone({
  disabledDrop,
  filesList,
  type,
  multiple,
  isMobile,
  parent,
  setFiles
}) {
  const { t } = useTranslation();
  const [content, setContent] = useState<Array<File>>([]);
  const [intialContent, setIntialContent] = useState<Array<File>>([]);
  const [letContent, setLetContent] = useState<boolean>(false);
  const dropzoneRef = useRef<any>(null);
  useEffect(() => {
    if (filesList && filesList.length > 0 && !letContent) {
      setLetContent(true);
      const auxInitialContent = filesList.map((contentItem: any) => {
        let auxType = contentItem?.type || contentItem?.mimeType || '';

        if (contentItem?.type.includes('image') && parent === 'lead') {
          auxType = 'application/pdf';
        }

        return new File(
          [contentItem],
          contentItem.fileOriginalName || contentItem.name,
          {
            type: auxType
          }
        );
      });

      setIntialContent(auxInitialContent);
    } else {
      setIntialContent([]);
    }

    setContent(filesList || []);
  }, [filesList]);

  const inputState = () => {
    switch (type) {
      case 'image':
        if (parent === 'company' || parent === 'profile') {
          const inputName =
            parent === 'profile'
              ? `${t('PROFILE.picture')}`
              : `${t('COMPANY_SETTINGS.logo')}`;

          return (
            <Container
              key="dropzone-type-image-container"
              sx={{
                color: 'primary.300',
                display: 'grid',
                gridTemplateColumns: '35% 70%',
                marginLeft: '50px'
              }}
            >
              <Box
                key="dropzone-container-icon-box"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}
              >
                {isMobile ? null : <Typography>{inputName}</Typography>}
                <i
                  className="custom-icon icon-photo-upload"
                  style={{
                    fontSize: 60,
                    fontWeight: 'normal',
                    marginBottom: 1
                  }}
                />
              </Box>
              <Box
                key="dropzone-container-inputName-box"
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography
                  sx={{
                    justifyContent: 'center',
                    ml: 4,
                    mr: 4,
                    textAlign: 'center'
                  }}
                  variant={isMobile ? 'body_100' : 'primary'}
                >
                  {`${t('GENERAL.dragNDrop', [inputName])}`}
                </Typography>
              </Box>
            </Container>
          );
        }
        if (parent === 'taskDetails') {
          const inputName = `${t('COMPANY_SETTINGS.logo')}`;

          return (
            <Container
              key="dropzone-type-image-taskDetails-container"
              sx={{
                color: 'primary.300',
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <AddPhotoAlternateOutlinedIcon
                  style={{
                    color: '#7D8090',
                    fontSize: 20,
                    fontWeight: 'normal',
                    marginBottom: 1
                  }}
                />
                <Typography
                  color="#7D8090"
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="700"
                  sx={{
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                  variant={isMobile ? 'body_100' : 'primary'}
                >
                  Add image
                </Typography>
              </Box>
            </Container>
          );
        }
        return null;
      case 'file':
        return (
          <Container
            sx={{
              color: 'primary.300',
              display: 'grid',
              gridTemplateColumns: '100%'
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                sx={{
                  justifyContent: 'center',
                  ml: 4,
                  mr: 4,
                  textAlign: 'center'
                }}
                variant={isMobile ? 'body_100' : 'primary'}
              >
                {`${t('GENERAL.dragNDrop', [type])}`}
              </Typography>
            </Box>
          </Container>
        );
      default:
        return null;
    }
  };

  const canRemove: boolean = true;

  return (
    <>
      {type === 'image' &&
      (parent === 'profile' || parent === 'company') &&
      content.length > 0 ? (
        false
      ) : (
        <DropzoneLibrary
          // PreviewComponent={(props: any) => {
          //   return null;
          // }}
          accept={parent === 'taskDetails' ? 'image/*' : '*'}
          canRemove={canRemove}
          disabled={disabledDrop}
          initialFiles={intialContent}
          inputContent={true ? inputState() : undefined}
          maxFiles={multiple ? undefined : 1}
          multiple={multiple}
          onChangeStatus={({ meta, file }: any, status: any) => {
            const idMeta =
              content.filter(
                (fileItem) =>
                  fileItem.name === meta.name && fileItem.size === meta.size
              ).length > 0;

            if (file && file.type !== '') {
              if (type === 'image' && status === 'done') {
                if (parent === 'taskDetails') {
                  setFiles([...content, file]);
                } else {
                  setContent([meta.previewUrl]);
                }
              } else if (status === 'done' && !idMeta) {
                // setContent([...content, file]);
                setFiles([...content, file]);
              } else if (status === 'removed') {
                const auxContent = content.filter((f) => f.name !== file.name);
                setFiles([...auxContent]);
              }
            }
          }}
          onSubmit={
            !multiple
              ? undefined
              : () => {
                  console.log('onSubmit');
                }
          }
          ref={dropzoneRef}
          styles={{
            dropzone: {
              // Exception in color cause its not a material component.
              backgroundColor: '#EBF2F6',
              border:
                parent === 'taskDetails'
                  ? '1px dashed #7D8090'
                  : '1px dashed #005DA2',
              height: parent === 'taskDetails' ? 'auto' : '100%',
              minHeight: 'auto',
              overflow: 'hidden',
              padding: parent === 'taskDetails' ? '1.5rem' : '50px'
            },
            preview: {
              paddingBottom: '0px',
              paddingLeft: '40px',
              paddingRight: '3%',
              paddingTop: '0px'
            },
            submitButton: {
              display: 'none'
            },
            submitButtonContainer: {
              display: 'none'
            }
          }}
          submitButtonDisabled={!multiple}
        />
      )}
      {type === 'image' &&
        (parent === 'profile' || parent === 'company') &&
        content.length > 0 && (
          <ImageCropper
            hideCropper={() => {}}
            image={content[0]}
            onCropCancel={() => {
              setContent([]);
            }}
            onCropDone={(newFile: any) => {
              // eslint-disable-next-line no-console
              console.log('update profile', newFile);
              profile.setProfilePhoto(newFile).then((data) => {
                setFiles(data);
                setContent([]);
              });
            }}
          />
        )}
    </>
  );
};

Dropzone.defaultProps = {
  disabledDrop: false,
  filesList: [],
  multiple: false
};

export default Dropzone;
