import { ProposalApi } from '../api-client/generated/api';
import { counterOfferServiceTimeParser } from '../utils/proposalHelper';

const proposalApi = new ProposalApi();

interface Pagination {
  items: number;
  page: number;
}

const mapDataForProposal = (
  requestParameters: any,
  proposalFormValue: any,
  totalcostProject: any
) => {
  const serviceTimePricing = (requestParameters.serviceTimes || []).map(
    (serviceTime: any) => {
      return {
        baseCost: parseFloat(serviceTime?.baseCost.toFixed(2)),
        id: serviceTime.id,
        margin: serviceTime?.quoteMargin,
        markup: serviceTime?.quoteMarkup,
        pricePerVisit: parseFloat(serviceTime.quotePrice.toFixed(2))
      };
    }
  );

  const paymentDaysTerm = proposalFormValue?.paymentTerms
    ? Number(proposalFormValue.paymentTerms.replace('net ', ''))
    : 0;
  const reminder = proposalFormValue?.reminder
    ? Number(proposalFormValue.reminder)
    : 0;

  const validInvoiceFrequencies = ['Weekly', 'Biweekly', 'Monthly'];
  if (proposalFormValue?.frequency) {
    // eslint-disable-next-line no-param-reassign
    proposalFormValue.frequency =
      proposalFormValue.frequency.charAt(0).toUpperCase() +
      proposalFormValue.frequency.slice(1).toLowerCase();
  }
  const invoiceFrequency = validInvoiceFrequencies.includes(
    proposalFormValue?.frequency
  )
    ? proposalFormValue?.frequency
    : null;
  // eslint-disable-next-line no-restricted-globals
  const validReminder = !isNaN(reminder) && reminder >= 1;

  const validInvoiceFrequency =
    validInvoiceFrequencies.includes(invoiceFrequency);

  const {
    allowCounterOffers,
    proposalValue,
    proposalDiscountPercentage,
    proposalPenalizationPercentage,
    varianceDiscountPercentage,
    variancePenalizationPercentage
  } = proposalFormValue;

  const proposalObject: any = {
    allowCounterOffers,
    clientId: requestParameters?.client?.id || '',
    depositAmount: proposalFormValue?.depositAmount || 0,
    leadId: requestParameters?.id || '',
    marginInProposal: proposalFormValue.marginInProposal,
    message: proposalFormValue?.personalizedMessage || '',
    proposalDiscountPercentage: proposalDiscountPercentage || 0,
    proposalPenalizationPercentage: proposalPenalizationPercentage || 0,
    proposalValue,
    reminder: validReminder ? reminder : 1,
    securityDeposit: Boolean(proposalFormValue?.depositAmount),
    serviceTimePricing,
    varianceDiscountPercentage: varianceDiscountPercentage || 0,
    variancePenalizationPercentage: variancePenalizationPercentage || 0
  };

  if (invoiceFrequency) {
    proposalObject.invoiceFrequency = invoiceFrequency;
  }
  if (proposalFormValue?.frequencyDay) {
    proposalObject.sendOn = proposalFormValue?.frequencyDay;
  }
  if (paymentDaysTerm) {
    proposalObject.paymentDaysTerm = paymentDaysTerm;
  }
  return proposalObject;
};

const createProposal = async (
  requestParameters: any,
  proposalFormValue: any,
  totalcostProject: any
) => {
  const createProposalData = mapDataForProposal(
    requestParameters,
    proposalFormValue,
    totalcostProject
  );

  const { approvalRequired } = proposalFormValue;
  const response = await proposalApi.proposalCreateProposal({
    proposalCreateProposalRequest: { ...createProposalData, approvalRequired }
  });
  const { data } = response;
  return Array.isArray(data) ? data : [];
};

const proposalUpdate = async (
  requestParameters: any,
  proposalFormValue: any,
  totalcostProject: any,
  id: string
) => {
  const updateProposalData: any = mapDataForProposal(
    requestParameters,
    proposalFormValue,
    totalcostProject
  );
  const {
    depositAmount,
    serviceTimePricing,
    paymentDaysTerm,
    securityDeposit,
    reminder,
    invoiceFrequency,
    message,
    sendOn,
    allowCounterOffers,
    proposalValue,
    proposalDiscountPercentage,
    proposalPenalizationPercentage,
    varianceDiscountPercentage,
    variancePenalizationPercentage
  } = updateProposalData;
  const response = await proposalApi.proposalUpdate({
    id,
    updateProposalBodyDTO: {
      allowCounterOffers,
      depositAmount,
      invoiceFrequency,
      message,
      paymentDaysTerm,
      proposalDiscountPercentage,
      proposalPenalizationPercentage,
      proposalValue,
      reminder,
      securityDeposit,
      sendOn,
      serviceTimePricing,
      varianceDiscountPercentage,
      variancePenalizationPercentage
    }
  });
  return response;
};

const getProposalCompanyById = async (id: string) => {
  const response = await proposalApi.proposalGetProposalById({ id });
  const { data } = response;

  return data;
};

const getProposalByLead = async (id: string) => {
  const response = await proposalApi.proposalGetProposalByLeadId({ id });
  const { data } = response;

  return data;
};

const getProposalsByLead = async (params: any) => {
  const { id } = params;
  const response = await proposalApi.proposalGetProposalsByLeadId({ id });
  const { data } = response;

  return data;
};

/*
const getProposalsPaginated = async (params: Pagination) => {
  const response = await proposalApi.proposalListProposal(params);
  const { data } = response;
  return Array.isArray(data.data) ? data.data : [];
};
*/

const acceptProposal = async (params: any) => {
  const acceptProposalData = {
    decisionMessageDTO: {},
    status: 'approved',
    token: params.token
  };
  const response = await proposalApi.proposalSetDecisionStatus(
    acceptProposalData
  );
  const { status, statusText } = response;
  return { status, statusText };
};

const rejectProposal = async (params: any) => {
  const rejectProposalData = {
    decisionMessageDTO: {
      message: params.message,
      requestUpdatedProposal: params.requestUpdatedProposal
    },
    status: 'rejected',
    token: params.token
  };
  const response = await proposalApi.proposalSetDecisionStatus(
    rejectProposalData
  );
  const { status, statusText } = response;
  return { status, statusText };
};

const getProposalByToken = async (token: string) => {
  const response = await proposalApi
    .proposalGetProposalByToken({ token })
    .then((res: any) => res)
    .catch((err: any) => err.response);
  return response;
};

const recallProposal = async (id: any) => {
  // const { proposalId: id } = params;
  const response = await proposalApi.proposalRecallProposal({
    id
  });
  return response.data as any;
};

const resendProposal = async (id: any) => {
  // const { proposalId: id } = params;
  const response = await proposalApi.proposalResendProposal({
    id
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const getChatMessages = async (id: string) => {
  const response = await proposalApi.proposalGetProposalChatById({ id });
  return response.data;
};

const postOperatorMessage = async (params: any) => {
  const { id, message } = params;
  const response = await proposalApi.proposalCreateMessageInProposal({
    createMessageInProposalDTO: { message },
    id
  });
  return response.data;
};

const postClientMessage = async (params: any) => {
  const { id: token, message } = params;
  const response = await proposalApi.proposalCreateClientMessageInProposal({
    createMessageInProposalDTO: { message },
    token
  });
  return response.data;
};

const updateWatchers = async (params: any) => {
  const { watchers: w, id } = params;
  const watchers = Array.isArray(w) ? w : [w];
  const response: any = await proposalApi.proposalAddNewWatcher({
    id,
    watchersIdsArrayDTO: {
      watchers: watchers.map((item: any) => item.id || item.userId)
    }
  });
  return { ...response.data, status: response.status };
};

const counterOfferRecalculate = async (params: any) => {
  const { deleted, notModified, services, token } = params;
  const counterOffer = services?.map((service: any) =>
    counterOfferServiceTimeParser(service)
  );
  const response: any = await proposalApi.proposalCreateProposalCounterOffer({
    proposalCounterOfferDTO: {
      action: 'recalculate',
      counterOffer,
      deleted,
      notModified
    },
    token
  });
  return response.data;
};

const counterOfferSubmit: any = async (params: any) => {
  const { deleted, notModified, services, token } = params;
  const counterOffer = services?.map((service: any) =>
    counterOfferServiceTimeParser(service)
  );
  const response = await proposalApi.proposalCreateProposalCounterOffer({
    proposalCounterOfferDTO: {
      action: 'submit',
      counterOffer,
      deleted,
      notModified
    },
    token
  });
  return response.data;
};

const counterOfferDecision = async (params: any) => {
  const { id, message, status } = params;
  const response: any = await proposalApi.proposalSetCounterOfferDecisionStatus(
    {
      decisionMessageDTO: { message },
      id,
      status
    }
  );

  return response.data;
};

const getCounterOffers = async (params: any) => {
  const { id } = params;
  const response = await proposalApi.proposalGetProposalCounterOffers({ id });
  return response.data;
};

export default {
  acceptProposal,
  counterOfferDecision,
  counterOfferRecalculate,
  counterOfferSubmit,
  createProposal,
  getChatMessages,
  getCounterOffers,
  getProposalByLead,
  getProposalByToken,
  getProposalCompanyById,
  getProposalsByLead,
  postClientMessage,
  postOperatorMessage,
  proposalUpdate,
  recallProposal,
  rejectProposal,
  resendProposal,
  updateWatchers
};
