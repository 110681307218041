/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  styled,
  SliderProps,
  useTheme
} from '@mui/material';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Api from '../../../api';
import PageNotFound from '../../../components/shared/PageNotFound';
import ExpiredProposalLink from './components/propoosal/ExpiredProposalLink';
import AcceptedProposal from './components/propoosal/AcceptedProposal';
import useAuthStore from '../../../store/zustand/auth';
import ProposalTitle from '../../../components/proposal/Title';
import ProposalGeneralInformation from '../../../components/proposal/GeneralInformation';
import SummaryService from './components/SummaryService';
import Loader from '../../../components/shared/Loader';
import TableComponent from '../../../components/shared/TableComponent';
import { getCurrency } from '../../../utils/general';
import Chat from './components/Chat';
import RejectProposalModal from '../../../components/modals/RejectProposalModal';

const ButtonsContainer = styled(Box)<SliderProps>(() => ({
  display: 'flex',
  float: 'right',
  justifyContent: 'space-between'
}));

const ButtonsContainerCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(3),
  width: '100%'
}));

interface ProposalViewProps {
  admin?: boolean;
  closeModal?: () => void;
  proposalInfo?: any;
}

const ProposalView: React.FC<ProposalViewProps> = function ProposalView({
  proposalInfo,
  admin,
  closeModal
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isAuthenticated } = useAuthStore();
  const [sentFlag, setSentFlag] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [expiredProposal, setExpiredProposal] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [rejected, setRejected] = useState<boolean>(false);
  const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [acceptProposal, setAcceptProposal] = useState<boolean>(false);
  const [acceptedProposal, setAcceptedProposal] = useState<boolean>(false);
  const [proposalId, setProposalId] = useState<string>('');
  const [values, setValues] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const tokenParam = window.location.pathname.split('/')[2];

  const { data: proposalData } = useSWR(
    sentFlag ? tokenParam : null,
    Api.ProposalApi.getProposalByToken
  ) as any;
  const acceptProposalAction = Api.ProposalApi.acceptProposal;
  const { data: acceptProposalData, error: acceptProposalDataError } = useSWR(
    acceptProposal ? { token: tokenParam } : null,
    acceptProposalAction
  );

  const getButtons = () => {
    if (isAuthenticated) {
      return (
        <ButtonsContainerCentered>
          <Button
            onClick={() => {
              if (closeModal) {
                closeModal();
              }
            }}
            sx={{ width: 200 }}
            variant="cancel"
          >
            {t('GENERAL.closeButton')}
          </Button>
        </ButtonsContainerCentered>
      );
    }
    return (
      <ButtonsContainer mt={6} width="100%">
        {accepted && !rejected && (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
            mb={4}
            mt={-3}
            width="100%"
          >
            <Typography sx={{ fontSize: 18, marginRight: 4 }} variant="primary">
              {t('LEAD.CREATE.PROPOSAL.buttonContinueTermsConditions')}
            </Typography>
            <Button
              onClick={() => setOpenSuccessful(true)}
              sx={{ m: 2, width: '24%' }}
              variant="primary"
            >
              {t('LEAD.CREATE.PROPOSAL.buttonNext')}
            </Button>
          </Box>
        )}

        {rejected && !accepted && (
          <Box
            alignItems="left"
            display="flex"
            justifyContent="flex-start"
            mb={4}
            mt={-3}
            width="100%"
          >
            <Typography sx={{ fontSize: 18, marginRight: 4 }} variant="primary">
              {t('LEAD.CREATE.PROPOSAL.rejectedProposalText')}
            </Typography>
          </Box>
        )}

        {!accepted && !rejected && (
          <Box
            display="flex"
            mb={4}
            mt={-3}
            sx={{
              alignItems: 'center',
              justifyContent: 'center'
            }}
            width="100%"
          >
            <Button
              fullWidth
              onClick={() => setShowRejectModal(true)}
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
                m: 1,
                width: 200
              }}
              variant="cancel"
            >
              {t('LEAD.CREATE.PROPOSAL.buttonClientDecline')}
            </Button>
            <Button
              fullWidth
              onClick={() => setAcceptProposal(true)}
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
                m: 1,
                width: 200
              }}
              variant="primary"
            >
              {t('LEAD.CREATE.PROPOSAL.buttonClientAccept')}
            </Button>
          </Box>
        )}
      </ButtonsContainer>
    );
  };

  const getServiceTimeSummary = () => {
    const { lead } = values;
    return (
      <Box>
        {lead && lead?.serviceTimes && lead?.serviceTimes.length > 0 && (
          <Box width="100%">
            {lead?.serviceTimes?.map((serviceTime: any, index: number) => {
              if (typeof serviceTime.id === 'number') {
                return (
                  <SummaryService
                    clientView
                    validQuotePrice
                    index={index}
                    key={`service-time${index}`}
                    serviceTime={{ ...serviceTime }}
                    setNewMargin={() => {}}
                    setNewMarginCost={() => {}}
                    setTotalcostProject={() => {}}
                    setTotalcostProjectCost={() => {}}
                    setTotalcostvisit={() => {}}
                    setValidQuotePrice={() => {}}
                    setValues={() => {}}
                    totalcostProject={0}
                    totalcostProjectCost={serviceTime?.sellPrice}
                    totalvisit={
                      serviceTime.availability?.generalInfo
                        ?.daysOfServiceCount || 0
                    }
                    type="proposal"
                    values={lead}
                  />
                );
              }

              return null;
            })}
          </Box>
        )}
      </Box>
    );
  };

  const pricingSummary = () => {
    const { lead } = values;
    let proposalTotal = 0;
    const data = lead?.serviceTimes?.map((service: any, index: number) => {
      const amount = service?.sellPrice || 0;

      proposalTotal += amount;
      return {
        amount: getCurrency(amount),
        serviceTitle: `${t(
          'LEAD.CREATE.PROPOSAL.tablePricingBillingService'
        )} #${index + 1}: (${service?.frequency} with ${
          service?.availability?.generalInfo?.daysOfServiceCount
        } visits)`
      };
    });
    const data2 = [
      {
        amount: getCurrency(values?.depositAmount || 0),
        detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSecurityDeposit')
      },
      {
        amount: getCurrency(proposalTotal),
        detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSecurityDepositAlternative')
      },
      // {
      //   amount: `$ ${TotalMisc.current || 0} `,
      //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceTotalMiscelaneoues')
      // },
      // {
      //   amount: `$ ${TotalCosttaxt.current || 0} `,
      //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSalesTaxable')
      // },
      // {
      //   amount: maskData(TotalCosttaxt.current || 0),
      //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceCollectedTax')
      // },
      {
        amount: getCurrency(proposalTotal),
        detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceTotal')
      }
    ];

    const isItemLoaded = (index: any) => {
      if (undefined !== data && undefined !== data[index]) {
        return data[index];
      }
      return false;
    };

    return (
      <Box>
        <Box mt={6}>
          <Typography variant="body_500">
            {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable1')}
          </Typography>
        </Box>
        <Box mt={2}>
          <TableComponent
            fullSize
            action={() => {}}
            headKeys={['serviceTitle', 'amount']}
            isItemLoaded={isItemLoaded}
            itemCount={1}
            loadMoreItems={() => {}}
            valuesArray={{ data } as unknown as { data: any[] }}
          />
        </Box>
        <Box mt={6}>
          <Typography variant="body_500">
            {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable2')}
          </Typography>
        </Box>
        <Box mt={2}>
          <TableComponent
            fullSize
            action={() => {}}
            headKeys={['detail', 'amount']}
            isItemLoaded={isItemLoaded}
            itemCount={1}
            loadMoreItems={() => {}}
            valuesArray={{ data: data2 }}
          />
        </Box>
      </Box>
    );
  };

  const parseProposalInfomation = (proposalData: any) => {
    const { data } = proposalData;
    setSentFlag(false);
    setProposalId(data?.id);
    const { status } = data;

    if (!admin && (status === 'approved' || status === 'rejected')) {
      setNotFound(true);
      setLoading(false);
      return;
    }

    const newServTimes =
      data?.lead?.serviceTimes?.length > 0
        ? data?.lead?.serviceTimes?.map((serviceTime: any) => {
            const newServicesResponse =
              Api.LeadServiceTimesApi.newServicesResponseFunc(
                serviceTime.services
              );
            let sellPrice = 0;
            data?.proposalServiceTime.forEach((item: any) => {
              if (serviceTime.id === item.leadServiceTime?.id) {
                sellPrice = item.pricePerVisit;
              }
            });
            return {
              ...serviceTime,
              sellPrice,
              services: newServicesResponse.newServs,
              totalTime: newServicesResponse.totalTime
            };
          })
        : [];
    const auxNewValues = {
      ...data,
      lead: {
        ...data.lead,
        client: {
          ...data.lead.client,
          name: `${data?.lead?.client?.firstName} ${data?.lead?.client?.lastName} from ${proposalData?.data?.lead?.client?.businessName}`
        },
        endDate: data?.lead?.projectEndDate,
        profit: data?.lead?.profitPercentage,
        serviceTimes: newServTimes,
        startDate: data?.lead?.projectStartDate
      }
    };
    setValues(auxNewValues);
  };

  // ======================================================================== LIFECYCLE

  useEffect(() => {
    if (acceptProposalData && !acceptProposalDataError) {
      setAcceptProposal(false);
      setAcceptedProposal(true);
    }
  }, [acceptProposalData]);

  useEffect(() => {
    if (proposalData?.data) {
      if (proposalData?.data?.error) {
        setSentFlag(false);
        setNotFound(true);
      } else {
        parseProposalInfomation(proposalData);
      }
      setLoading(false);
    }
  }, [proposalData]);

  useEffect(() => {
    if (values?.lead) {
      setLoading(false);
    }
  }, [values]);

  useEffect(() => {
    if (proposalInfo) {
      parseProposalInfomation({ data: proposalInfo });
    }
  }, [proposalInfo]);

  useEffect(() => {
    if (!proposalData && !admin) {
      setSentFlag(true);
    }
  }, []);

  // ======================================================================== RENDER

  if (loading) {
    return <Loader customColor="primary" customSize={48} />;
  }

  return (
    <Container
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {!admin && (
        <Container
          className="TopFrame"
          sx={{
            backgroundColor: 'primary.main',
            height: 334,
            position: 'absolute',
            top: 0,
            zIndex: -1
          }}
        />
      )}
      <Box
        p={isMobile ? 1 : 6}
        sx={{
          backgroundColor: 'white',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 2,
          height: '80vh',
          marginTop: admin ? 0 : 10,
          maxWidth: 1200,
          overflow: 'scroll',
          overflowX: 'hidden',
          width: '100%'
        }}
      >
        {notFound && <PageNotFound />}
        {expiredProposal && proposalId && (
          <ExpiredProposalLink proposalId={proposalId} />
        )}
        {showRejectModal && (
          <RejectProposalModal
            proposalData={proposalData}
            setRejected={setRejected}
            setRejectedProposal={setAcceptedProposal}
            setShowRejectModal={setShowRejectModal}
            values={values}
            view="client"
          />
        )}
        {acceptedProposal && proposalId && (
          <AcceptedProposal proposalId={proposalId} />
        )}
        {!notFound &&
          !expiredProposal &&
          !acceptedProposal &&
          values &&
          values?.id && (
            <Box>
              <ProposalTitle clientView previewFlag values={values?.lead} />
              {values && <ProposalGeneralInformation values={values?.lead} />}
              <Box
                sx={{
                  borderBottom: `2px solid ${theme.palette.mainText[400]}`,
                  marginBottom: theme.spacing(4),
                  marginTop: theme.spacing(1),
                  width: '100%'
                }}
              />
              {values && getServiceTimeSummary()}
              {values && pricingSummary()}
              {values?.proposalMessages && !admin && (
                <Box
                  border={`1px solid ${theme.palette.secondary[100]}`}
                  borderRadius={4}
                  display="flex"
                  maxHeight={500}
                  mt={4}
                  pt={4}
                  style={{ overflow: 'hidden' }}
                >
                  <Chat
                    clientView={!admin}
                    id={tokenParam || values.id}
                    messages={values.proposalMessages}
                    updateMessages={(proposalMessages: any) => {
                      setValues({ ...values, proposalMessages });
                    }}
                  />
                </Box>
              )}
              <Box>{getButtons()}</Box>
            </Box>
          )}
      </Box>
    </Container>
  );
};

ProposalView.defaultProps = {
  admin: false,
  closeModal: () => {},
  proposalInfo: null
};

export default ProposalView;
