import { ConfigurationApi } from '../api-client/generated/api';

const invitationApi = new ConfigurationApi();

const getPasswordRules = async () => {
  const response =
    await invitationApi.configurationGetPasswordComplexityRules();
  const { data } = response;
  return data;
};

export default {
  getPasswordRules
};
