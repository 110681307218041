/* eslint-disable no-useless-escape */
interface ProfileField {
  float?: boolean;
  label: string;
  name: string;
  pattern: RegExp;
  type: 'text' | 'password' | 'email' | 'number' | 'phone';
  validationLabel: string;
}

export const ACCOUNT_INFO_FIELDS: ProfileField[] = [
  {
    label: 'First Name',
    name: 'firstName',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'text',
    validationLabel: 'This Field is required & must be only characters.'
  },
  {
    label: 'Last Name',
    name: 'lastName',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'text',
    validationLabel: 'This Field is required & must be only characters.'
  },
  {
    label: 'Email address',
    name: 'email',
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    type: 'email',
    validationLabel: 'This Field is required & must be valid email.'
  },
  {
    label: 'Phone number',
    name: 'phoneNumber',
    pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    type: 'text',
    validationLabel: 'This Field is required & must be valid phone number.'
  }
];

export const COMPANY_INFO_FIELDS: ProfileField[] = [
  {
    label: 'Company Name',
    name: 'companyName',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'text',
    validationLabel: 'This Field is required & must be only characters.'
  },
  {
    label: 'Roles',
    name: 'roles',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'text',
    validationLabel: 'This Field is required & must be only characters.'
  },
  {
    label: 'Job Title',
    name: 'jobTitle',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'email',
    validationLabel: 'This Field is required & must be valid email.'
  },
  {
    label: 'Timezone',
    name: 'timeZone',
    pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    type: 'text',
    validationLabel: 'This Field is required & must be valid phone number.'
  }
];

export default {
  ACCOUNT_INFO_FIELDS
};

export const COMPANY_USER_FIELDS: ProfileField[] = [
  {
    label: 'Company Name',
    name: 'companyName',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'text',
    validationLabel: 'This Field is required & must be only characters.'
  },
  {
    label: 'Job Title',
    name: 'jobTitle',
    pattern: /^[A-Za-z.\s_-]+$/,
    type: 'text',
    validationLabel: 'This Field is required & must be valid email.'
  },
  {
    float: true,
    label: 'Hourly Rate',
    name: 'rate',
    pattern: /^[1-9]\d*$/,
    type: 'number',
    validationLabel: 'This Field is required & must be valid email.'
  },
  {
    label: 'Timezone',
    name: 'timeZone',
    pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    type: 'text',
    validationLabel: 'This Field is required & must be valid phone number.'
  }
];
