import React from 'react';
import '../../style/App.scss';
import { CircularProgress, Container } from '@mui/material';

const ViewLoader: React.FC = function ViewLoader() {
  return (
    <Container
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '80vh',
        justifyContent: 'center',
        width: '940px'
      }}
    >
      <CircularProgress size={64} />
    </Container>
  );
};

export default ViewLoader;
