import React from 'react';
import useSWR from 'swr';
import { Box, Button, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../../../api';

interface Props {
  isMobile: boolean;
  login: () => void;
  setUser: (data: any) => void;
}

const Success: React.FC<Props> = function Success({
  isMobile,
  login,
  setUser
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: profile, error } = useSWR(
    'api/profile',
    api.authApi.getProfile
  );

  const setProfile = () => {
    if (!error) {
      if (profile) {
        const { roles, email, mfa, block, active, firstName, id, phoneNumber } =
          profile;
        const rolesName = roles.map((ele: any) => ele.name);
        setUser({
          active,
          block,
          email,
          firstName,
          id,
          mfa,
          phoneNumber,
          roles: rolesName
        });
      }
    }
  };

  return (
    <Container sx={{ display: 'flex' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          color="success.main"
          sx={{ fontSize: 24 }}
          variant="primary-bold"
        >
          {t('LOGIN.successTitle')}
        </Typography>
        <br />
        <br />
        <Typography color="surface.main" variant="primary-bold">
          {t('LOGIN.successSubtitle')}
        </Typography>
      </Box>
      <br />
      <Button
        onClick={() => {
          setProfile();
          login();
          navigate('/app');
        }}
        sx={{ width: isMobile ? '100%' : '50%' }}
        variant="contained"
      >
        <Typography variant="primary-bold">{t('GENERAL.done')}</Typography>
      </Button>
    </Container>
  );
};

export default Success;
