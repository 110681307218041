import React, { useEffect, useState } from 'react';
import { Button, Typography, styled, SlideProps, Box } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import isEmail from 'validator/lib/isEmail';
import useAuthStore from '../../../store/zustand/auth';
import PasswordInput from '../../input/PasswordInput';
import Api from '../../../api';
import RegularInput from '../../input/RegularInput';
import { validRegexStringRules } from '../../../utils/general';
import Loader from '../Loader';

interface Props {
  action: () => void;
  isMobile: boolean;
  isProfileForm: boolean;
  // eslint-disable-next-line react/require-default-props
  profileAction?: () => void;
}

const ButtonContainer = styled(Container)<SlideProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2)
}));

const PasswordUpdateForm: React.FC<Props> = function PasswordUpdateForm({
  action,
  isProfileForm,
  profileAction,
  isMobile
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [pass2, setPass2] = useState<string>('');
  const [fireApiPassword, setFireApiPassword] = useState<boolean>(false);
  const [rules, setRules] = useState<Array<any>>([]);
  const { email: emailValueStore } = useAuthStore((state) => state);

  const { data: dataPassword, error: errorPassword } = useSWR(
    fireApiPassword ? { email, newPassword: pass, oldPassword } : null,
    Api.authApi.changePassword
  );

  const {
    data: rulesResponse,
    isValidating: isValidatingPassword,
    error: rulesError
  } = useSWR(
    !rules.length ? 'api/getPasswordRules' : null,
    Api.ConfigApi.getPasswordRules
  );

  useEffect(() => {
    if (isProfileForm) {
      setEmail(emailValueStore as unknown as string);
    }
  }, []);

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const changePasswordButton = () => {
    if (pass) {
      const validInput = validRegexStringRules(rules, pass);
      if (validInput.error) {
        setError(validInput.error);
        setErrorMessage(validInput.errorMessage);
        return;
      }
      setError(false);
    }
    if (pass2 !== pass) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationError')}`);
      return;
    }
    if (
      email === '' ||
      oldPassword === '' ||
      pass === '' ||
      pass2 === '' ||
      pass2 !== pass
    ) {
      setError(true);
      setErrorMessage(`${t('LOGIN.inputRequired')}`);
      return;
    }
    if (!isEmail(email)) {
      setError(true);
      setErrorMessage(`${t('LOGIN.emailInvalid')}`);
      return;
    }
    setError(false);
    setFireApiPassword(true);
  };

  useEffect(() => {
    setError(false);
  }, []);

  useEffect(() => {
    if (errorPassword || rulesError) {
      setError(true);
      setFireApiPassword(false);
      if (errorPassword) {
        const { response } = errorPassword;
        setErrorMessage(response.data.message);
      }
    }
  }, [errorPassword, rulesError]);

  useEffect(() => {
    if (dataPassword && dataPassword.status === 202) {
      action();
    }
  }, [dataPassword]);

  useEffect(() => {
    if (rulesResponse) {
      setRules(rulesResponse.data);
    }
  }, [rulesResponse]);

  return (
    <>
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('GENERAL.email')}*
      </Typography>
      <RegularInput
        customStyle={{ width: '100%' }}
        disabled={isProfileForm}
        error={error}
        onChange={(value) => setEmail(value)}
        type="email"
        value={isProfileForm ? emailValueStore : email}
      />
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('LOGIN.resetPasswordOld')}*
      </Typography>
      <PasswordInput
        customStyle={{ width: '100%' }}
        error={error}
        onChange={(value) => setOldPassword(value)}
      />
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('LOGIN.resetPasswordNew')}*
      </Typography>
      <PasswordInput
        customStyle={{ width: '100%' }}
        error={error}
        key="input-pass1"
        onChange={(value) => setPass(value)}
      />
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('LOGIN.resetPasswordConfirm')}*
      </Typography>
      <PasswordInput
        customStyle={{ width: '100%' }}
        error={error}
        key="input-pass2"
        onChange={(value) => setPass2(value)}
        submitEvent={changePasswordButton}
      />
      {getError()}
      {isProfileForm ? (
        <Box sx={{ display: 'flex', marginTop: '20px' }}>
          <Button
            onClick={() => (profileAction ? profileAction() : {})}
            sx={{ width: '50%' }}
            variant="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              changePasswordButton();
            }}
            sx={{
              lineHeight: 'normal',
              marginLeft: '10px',
              width: '50%'
            }}
            variant="primary"
          >
            Update Password
          </Button>
        </Box>
      ) : (
        <ButtonContainer
          sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
        >
          <Button
            color="primary"
            onClick={() => changePasswordButton()}
            sx={{ width: '50%' }}
            variant="contained"
          >
            {isValidatingPassword ? (
              <Loader />
            ) : (
              <Typography variant="primary-bold">
                {t('LOGIN.resetPasswordButton')}
              </Typography>
            )}
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

export default PasswordUpdateForm;
