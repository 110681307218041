import { Backdrop, Container, Fade, Modal } from '@mui/material';
import React, { useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ModalContainer } from './modals/GlobalConfirmModal';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  url: string;
}

const ImageViewer: React.FC<Props> = function ImageViewer(props) {
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const { isOpen, setIsOpen, url } = props;

  return (
    <Container sx={{ alignItems: 'center', width: 'auto' }}>
      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        open={auxManualClose}
        sx={{ maxHeight: '100vh' }}
      >
        <Fade in={auxManualClose}>
          <ModalContainer
            sx={{
              flexDirection: 'row',
              height: 'auto',
              maxHeight: 'inherit',
              maxWidth: '100vh',
              minWidth: 'auto',
              padding: '10px !important',
              width: '90vw'
            }}
          >
            <img
              alt=""
              loading="eager"
              src={url}
              style={{
                // height: 'auto',
                maxHeight: '90vh',
                maxWidth: '90vw',
                objectFit: 'contain',
                width: '-webkit-fill-available'
              }}
            />
            <CancelRoundedIcon
              key="task-details-img-view-cancel-icon"
              onClick={() => {
                setIsOpen(false);
              }}
              sx={{
                backgroundColor: 'rgb(255 255 255 / 67%)',
                borderRadius: '50%',
                cursor: 'pointer',
                fill: 'black',
                fontSize: '2.5rem',
                position: 'absolute',
                right: 0,
                top: 0
              }}
            />
          </ModalContainer>
        </Fade>
      </Modal>
    </Container>
  );
};

export default ImageViewer;
