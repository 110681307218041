import React from 'react';
import { Container } from '@mui/system';
import { CircularProgress } from '@mui/material';

const ScreenLoader = function ScreenLoader() {
  return (
    <Container
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw'
      }}
    >
      <CircularProgress size={64} />
    </Container>
  );
};

export default ScreenLoader;
