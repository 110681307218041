// Define styles for mobile and desktop devices
export const MobileStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
  minHeight: '95%',
  width: 'auto'
};

export const DesktopStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginX: '30px',
  marginY: '20px',
  minHeight: '95%',
  width: 'auto'
};
