/* eslint-disable import/prefer-default-export */
export function taskValidation(
  isUnit: any,
  value: any,
  getMeasureName: any,
  t: any
) {
  if (value?.skills.length <= 0) {
    return t('ERROR.skillsRequired');
  }

  if (!value?.measurePerHour || value.measurePerHour <= 0) {
    return t('ERROR.numberInt', [
      `${
        isUnit()
          ? `${t('MODAL.timePerMeasure', [
              `${t(`MODAL.${getMeasureName()}`)}`
            ])}`
          : `${t('MODAL.measurePerHour')}`
      }`
    ]);
  }

  if (!value?.minimumResources || value.minimumResources <= 0) {
    return t('ERROR.minimumResources');
  }

  if (!value.measurePerHour || value.measurePerHour <= 0) {
    return t('ERROR.measurePerHour', [
      `${
        isUnit()
          ? `${t('MODAL.timePerMeasure', [
              `${t(`MODAL.${getMeasureName()}`)}`
            ])}`
          : `${t('MODAL.measurePerHour')}`
      }`
    ]);
  }

  if (!value.measure) {
    return t('ERROR.measurePerHour', ['Measure']);
  }

  if (value.materials && value.materials.length > 0) {
    const materials = value.materials as any[];
    let costEmpty = false;
    materials.forEach((material) => {
      if (material.cost < 0) {
        costEmpty = true;
      }
    });

    if (costEmpty) {
      return t('ERROR.materialCost');
    }
  }

  return '';
}
