import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { weekDays } from '../../helpers/weekDays';
import { DayCheckBox, DayCheckBoxSelected } from '../profile/parts';

interface Props {
  disabled?: boolean;
  setValues: (days: Array<string>) => void;
  type: string;
  values: any;
}

const DaysOfTheWeek: React.FC<Props> = function DaysOfTheWeek({
  type,
  values,
  setValues,
  disabled
}) {
  const label = { inputProps: { 'aria-label': 'Checkbox date' } };
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    if (values.includes(value)) {
      setValues(values.filter((item: string) => item !== value));
      return;
    }
    setValues([...values, value]);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {true &&
        weekDays.map((ele: any, index: number) => (
          <Checkbox
            checked={values.includes(ele.name)}
            disabled={disabled}
            // eslint-disable-next-line react/no-array-index-key
            key={`${ele.name} - ${index}`}
            sx={{
              height: '64px',
              p: type === 'square' ? '1px' : 9,
              width: type === 'square' ? '90px' : '64px'
            }}
            value={ele.name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...label}
            checkedIcon={
              <DayCheckBoxSelected
                disabled={disabled}
                isMobile={false}
                sx={
                  type === 'square'
                    ? {
                        borderRadius: type === 'square' ? '5%' : '50%',
                        width: '100%'
                      }
                    : {}
                }
              >
                {t(
                  `DAYS.${ele.name.toLowerCase()}${
                    type === 'square' ? 'Short' : 'L'
                  }`
                )}
              </DayCheckBoxSelected>
            }
            icon={
              <DayCheckBox
                disabled={disabled}
                isMobile={false}
                sx={
                  type === 'square'
                    ? {
                        borderRadius: type === 'square' ? '5%' : '50%',
                        m: 0,
                        p: 0,
                        width: '100%'
                      }
                    : {}
                }
              >
                {t(
                  `DAYS.${ele.name.toLowerCase()}${
                    type === 'square' ? 'Short' : 'L'
                  }`
                )}
              </DayCheckBox>
            }
            onChange={(value) => {
              handleChange(value.currentTarget.defaultValue);
            }}
          />
        ))}
    </Box>
  );
};

DaysOfTheWeek.defaultProps = {
  disabled: false
};

export default DaysOfTheWeek;
