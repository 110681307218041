import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface EditButtonProp {
  handleOpenDraw: any;
  tooltip?: String | undefined | null;
}

const EditButton: React.FC<EditButtonProp> = function EditButton({
  handleOpenDraw,
  tooltip
}) {
  return (
    <Button
      onClick={handleOpenDraw}
      style={{ minWidth: '45px' }}
      variant="table"
    >
      <Typography color="secondary.main" variant="primary-bold">
        <Tooltip arrow title={tooltip}>
          <i className="custom-icon icon-edit" />
        </Tooltip>
      </Typography>
    </Button>
  );
};

EditButton.defaultProps = {
  tooltip: ''
};

export default EditButton;
