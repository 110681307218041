import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  actionButtonMsg: any;
  cancelAction: () => void;
  enableEditAction: () => void;
  enableEditButtonMsg: any;
  isMobile: boolean;
  mainAction: () => void;
  updateFlag: boolean;
}

const ButtonFormGroup: React.FC<Props> = function ButtonFormGroup({
  actionButtonMsg,
  cancelAction,
  enableEditAction,
  enableEditButtonMsg,
  isMobile,
  mainAction,
  updateFlag
}) {
  const { t } = useTranslation();

  return updateFlag ? (
    <>
      <Button onClick={cancelAction} sx={{ width: '50%' }} variant="cancel">
        {t('GENERAL.cancelButton')}
      </Button>
      <Button
        onClick={mainAction}
        sx={{
          lineHeight: 'normal',
          marginLeft: '10px',
          width: '50%'
        }}
        variant="primary"
      >
        {actionButtonMsg}
      </Button>
    </>
  ) : (
    <Button
      onClick={enableEditAction}
      sx={{ lineHeight: 'normal', width: isMobile ? '100%' : '50%' }}
      variant="primary"
    >
      {enableEditButtonMsg}
    </Button>
  );
};

export default ButtonFormGroup;
