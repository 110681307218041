/* eslint-disable react/require-default-props */
import React, { useEffect, useState, FC } from 'react';
import '../../style/App.scss';
import { Box, TextField, Autocomplete } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useSWR from 'swr';
import useDebouncedSearch from '../../hooks/useDebounceSearch';
import api from '../../api';

interface AutoComplete {
  disabled?: boolean;
  error?: boolean;
  keyName?: string;
  onChange: any;
  placeHolder?: string;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  value?: any;
}

const AutoCompletePlacesMemo: FC<AutoComplete> =
  function AutoCompletePlacesMemo({
    onChange,
    keyName,
    error,
    placeHolder,
    disabled = false,
    setSelected,
    value
  }) {
    const [valueDirection, setValueDirection] = useState<any>({});
    const [requestLocations, setRequestLocations] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([]);
    const [search, setSearch] = useState<string>('');
    // const lastSearch = useRef(valueDirection?.place_name);
    const debouncedSearchQuery = useDebouncedSearch(search, 1000);
    const { data: direction, error: updateError } = useSWR(
      requestLocations ? debouncedSearchQuery : null,
      api.MapsApi.searchDirection
    );

    useEffect(() => {
      if (search) {
        if (search !== value) {
          setRequestLocations(true);
        }
      }
    }, [debouncedSearchQuery]);

    useEffect(() => {
      if (direction && direction.features) {
        // eslint-disable-next-line no-console
        setOptions([...direction.features]);
        setRequestLocations(false);
        return;
      }
      if (updateError) {
        setRequestLocations(false);
      }
    }, [direction, updateError]);

    return (
      <Autocomplete
        autoComplete
        disablePortal
        filterSelectedOptions
        freeSolo
        fullWidth
        includeInputInList
        disabled={disabled}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option?.place_name! ?? option}
        id="combo-box-demo"
        noOptionsText="No locations"
        onChange={(event: any, newValue: any | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValueDirection(newValue);
          if (newValue && newValue?.place_name) setSelected(true);
          else setSelected(false);
          if (keyName) {
            onChange(
              {
                coords: newValue?.center,
                keyName,
                value: newValue?.place_name
              } || ''
            );
          } else {
            onChange(
              { coords: newValue?.center, value: newValue?.place_name } || ''
            );
          }
        }}
        onInputChange={(event: any, newValue: any | null) => {
          setSearch(newValue);
        }}
        options={options}
        renderInput={(params) => {
          return (
            <TextField
              error={error}
              name={keyName}
              placeholder={placeHolder || ''}
              sx={{
                '& input': {
                  bgcolor: 'mainText.light',
                  color: 'mainText.main',
                  fontSize: '16px',
                  height: 10,
                  paddingBottom: '50px',
                  width: 200
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'hidden'
                },
                borderStyle: 'hidden'
              }}
              // eslint-disable-next-line
                {...params}
            />
          );
        }}
        renderOption={(props: any, options) => {
          return (
            <Box
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              sx={{
                marginY: '10px'
              }}
            >
              <LocationOnIcon
                sx={{ color: 'text.secondary', marginX: '5px' }}
              />
              {options?.place_name! || ''}
            </Box>
          );
        }}
        sx={{ border: error ? '1px solid #CE5A55' : '', borderRadius: '8px' }}
        value={valueDirection?.place_name ? valueDirection.place_name : value}
      />
    );
  };

const AutoCompletePlaces = React.memo(AutoCompletePlacesMemo);
export default AutoCompletePlaces;
