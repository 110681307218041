/* eslint-disable no-useless-escape */
interface CompanyInformationFields {
  label: string;
  name: string;
  pattern: RegExp;
  type: 'text' | 'password' | 'email' | 'number' | 'textarea';
  validationLabel: string;
}
interface Props {
  companyDescriptionLabel: string;
  companyNameLabel: string;
  companyNameValidation: string;
}

export const COMPANY_INFORMATION_FIELDS = ({
  companyNameLabel,
  companyDescriptionLabel,
  companyNameValidation
}: Props) => {
  const companyFields: CompanyInformationFields[] = [
    {
      label: companyNameLabel,
      name: 'companyName',
      pattern: /^[A-Za-z.\s_-]+$/,
      type: 'text',
      validationLabel: companyNameValidation
    },
    {
      label: companyDescriptionLabel,
      name: 'companyDescription',
      pattern: /[^\n]+/,
      type: 'textarea',
      validationLabel: ''
    }
  ];

  return companyFields;
};

export default {
  COMPANY_INFORMATION_FIELDS
};
