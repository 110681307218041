import useAuthStore from '../store/zustand/auth';

const useUserRole = (roles: string[]): boolean => {
  const userState = useAuthStore((state) => state);
  const userRoles = [...userState.roles];

  const isRoleValid = roles.some((role: string) => userRoles.includes(role));

  return isRoleValid;
};

export default useUserRole;
