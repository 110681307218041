import {
  CompanyApi,
  UpdateCurrentCompanyDTO
} from '../api-client/generated/api';

const apiCaller = new CompanyApi();

const getCurrentCompany = async () => {
  const { data } = await apiCaller.companyGetCurrentCompany();
  return data;
};

const updateCurrentCompany = async (
  url: string,
  params: UpdateCurrentCompanyDTO
) => {
  if (!params) {
    return null;
  }
  // eslint-disable-next-line no-console
  // console.log('Lo que llega: ', params);
  const { status, statusText } = await apiCaller.companyUpdateCurrentCompany({
    updateCurrentCompanyDTO: params
  });

  return { status, statusText };
};

const deleteTax = async (id: string) => {
  const { data } = await apiCaller.companyDeleteTaxById({ id });
  return data;
};

const getCompanies = async () => {
  const response = await apiCaller.companyGetAll();
  return response.data;
};

const companyArchiveCompany = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.companyArchiveCompany({ id });
  return { ...response.data, status: response.status };
};

const companyUnarchiveCompany = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.companyUnarchiveCompany({ id });
  return { ...response.data, status: response.status };
};

export default {
  companyArchiveCompany,
  companyUnarchiveCompany,
  deleteTax,
  getCompanies,
  getCurrentCompany,
  updateCurrentCompany
};
