import {
  ImpersonateApi,
  ImpersonateApiImpersonateGetEmployesToImpersonateRequest,
  ImpersonateApiImpersonateGetTokenForImpersonateRequest
} from '../api-client/generated/api';

const apiCaller = new ImpersonateApi();

const getUsersByCompany = async (id: string) => {
  if (!id) {
    return null;
  }
  const requestParams: ImpersonateApiImpersonateGetEmployesToImpersonateRequest =
    {
      id
    };

  const { data } = await apiCaller.impersonateGetEmployesToImpersonate(
    requestParams
  );
  return data as unknown as any[];
};

const getTokenImpersonate = async (id: string) => {
  if (!id) {
    return null;
  }
  const requestParams: ImpersonateApiImpersonateGetTokenForImpersonateRequest =
    {
      id
    };

  const { data } = await apiCaller.impersonateGetTokenForImpersonate(
    requestParams
  );

  return data as unknown as any;
};

export default {
  getTokenImpersonate,
  getUsersByCompany
};
