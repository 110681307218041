import { Box, Card, Grid } from '@mui/material';
import {
  Chat,
  MessageInput,
  MessageList,
  Themes,
  useChannels,
  useUsers
} from '@pubnub/react-chat-components';
import { useContext, useState } from 'react';
import ChatContext from '../ChatContext';

export const ChatCard: React.FC = function ChatCard() {
  const { currentChannel } = useContext(ChatContext);
  const [channels] = useChannels();
  const [users] = useUsers();
  const [theme] = useState<Themes>('light');

  const channelId: any = currentChannel?.id || currentChannel;

  /**
   * Handling publish errors
   */
  const handleError = (e: any) => {
    if (
      (e.status?.operation === 'PNPublishOperation' &&
        e.status?.statusCode === 403) ||
      e.message.startsWith('Publish failed')
    ) {
      alert(
        'Your message was blocked. Perhaps you tried to use offensive language or send an image that contains nudity?'
      );
    }

    console.warn(e);
  };
  console.log(currentChannel);
  return (
    <Grid container item xs={8}>
      <Card
        sx={{
          background: 'white',
          flexGrow: 1,
          overflowX: 'hidden',
          overflowY: 'scroll'
        }}
      >
        <Chat
          {...{
            channels: channels.map(({ id }) => id) ?? [],
            currentChannel: channelId || 'Default',
            onError: handleError,
            theme,
            users
          }}
        >
          {/* Chat is an obligatory state provider. It allows you to configure some common component
        options, like the current channel and the general theme for the app. */}
          <Box display="flex" flexDirection="column" height="100%">
            <Box height="100%" overflow="hidden">
              <MessageList fetchMessages={25} />
            </Box>
            <Box>
              <MessageInput typingIndicator fileUpload="all" />
            </Box>
          </Box>
        </Chat>
      </Card>
    </Grid>
  );
};

export default ChatCard;
