/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Modal,
  styled,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCallback, useEffect, useState } from 'react';
import {
  ButtonContainerModal,
  DrawerButton,
  ModalContainer
} from '../../components/modals/GlobalConfirmModal';
import { resourseColors } from '../../utils/general';

export const AutoCompleteStyled = styled(Autocomplete)(() => ({
  '& .MuiFormLabel-root': {
    color: 'white !important',
    fontSize: '14px'
  },
  '& input': {
    bgcolor: 'white !important',
    fontSize: '14px',
    height: '40px',
    width: 'auto'
  },
  '& li': {
    fontSize: '12px'
  },
  backgroundColor: 'white !important',
  borderRadius: '0.5em',
  display: 'flex'
}));

const resourcesData = [
  {
    active: true,
    archived: false,
    availability: true,
    availabilityData: null,
    availableDays: null,
    availableHoursPerDay: null,
    availableHoursPerWeek: null,
    availableTimeEnd: '17:00',
    availableTimeStart: '08:00',
    block: false,
    blockMessage: null,
    canSwap: true,
    createdAt: '2023-03-31T21:37:16.387Z',
    email: 'rafael+6001@helloiconic.com',
    firstName: 'Opt Rafael',
    ftsFullNameEn: "'flore':3 'opt':1 'rafael':2",
    id: '40170803-1253-45b1-8dfc-df48764101b0',
    isOwner: false,
    jobTitle: 'Operator',
    lastName: 'Flores',
    metadata: null,
    password: '$2b$10$GK1fhAZL001guGkSpuox3uyDwRDeydxDolPNjIzhNRZIxwJqNof6S',
    passwordLastUpdated: '2024-05-05T14:29:07.940Z',
    phoneNumber: '+18472146876',
    rate: '10.00',
    skills: [
      {
        createdAt: '2023-04-25T16:16:03.680Z',
        description: null,
        ftsNameEn: "'desinfect':1",
        id: 'dedb4cd3-44dd-4249-abcc-17a4b8f49703',
        name: 'Desinfection',
        updatedAt: '2023-04-25T16:16:03.680Z'
      }
    ],
    timeZone: null,
    unblockUntil: null,
    updatedAt: '2024-05-05T19:29:07.947Z',
    userRoles: [
      {
        createdAt: '2023-03-31T21:37:16.397Z',
        roleId: 'd5a4ae07-2bab-4aa0-9dea-489c9aa72031',
        userId: '40170803-1253-45b1-8dfc-df48764101b0'
      }
    ]
  },
  {
    active: false,
    archived: false,
    availability: true,
    availabilityData: null,
    availableDays: [
      '["monday"',
      '"tuesday"',
      '"wednesday"',
      '"thursday"',
      '"friday"]'
    ],
    availableHoursPerDay: null,
    availableHoursPerWeek: null,
    availableTimeEnd: '17:00',
    availableTimeStart: '08:00',
    block: false,
    blockMessage: null,
    canSwap: true,
    createdAt: '2023-09-05T23:00:08.139Z',
    email: 'paulatatiana223@feerock.com',
    firstName: 'Paula',
    ftsFullNameEn: "'paula':1 'tatiana':2",
    id: '160e6597-e942-49bc-b36a-7853c1ce8093',
    isOwner: false,
    jobTitle: 'Cleaner',
    lastName: 'Tatiana',
    metadata: null,
    password: '$2b$10$socXq0Ou6Yu0zhlLmsCDd.zfi.aGUyg5zWdcDmN7OKsehNloUjzxy',
    passwordLastUpdated: '2023-09-05T23:00:08.363Z',
    phoneNumber: null,
    rate: '20.00',
    skills: [
      {
        createdAt: '2023-04-25T16:16:03.680Z',
        description: null,
        ftsNameEn: "'desinfect':1",
        id: 'dedb4cd3-44dd-4249-abcc-17a4b8f49703',
        name: 'Desinfection',
        updatedAt: '2023-04-25T16:16:03.680Z'
      }
    ],
    timeZone: null,
    unblockUntil: null,
    updatedAt: '2024-03-06T00:00:00.132Z',
    userRoles: [
      {
        createdAt: '2023-09-05T23:00:08.151Z',
        roleId: 'a588bf56-e236-4e3d-9647-68e7ae2852d3',
        userId: '160e6597-e942-49bc-b36a-7853c1ce8093'
      }
    ]
  },
  {
    active: false,
    archived: false,
    availability: true,
    availabilityData: null,
    availableDays: null,
    availableHoursPerDay: null,
    availableHoursPerWeek: null,
    availableTimeEnd: '17:00',
    availableTimeStart: '08:00',
    block: false,
    blockMessage: null,
    canSwap: true,
    createdAt: '2023-09-13T08:03:07.878Z',
    email: 'ayomikun.osaze@feerock.com',
    firstName: 'ayomikun',
    ftsFullNameEn: "'ayomikun':1 'osaz':2",
    id: 'fa1d260f-417c-43d9-86cd-67856fa2bc3c',
    isOwner: false,
    jobTitle: 'C',
    lastName: 'Osaze',
    metadata: null,
    password: '$2b$10$12VTbLnzn4SPuDLS3USTPOLS0Z3RoopvlXIJ/GFEYhwt3guGURR92',
    passwordLastUpdated: '2023-09-13T03:03:08.321Z',
    phoneNumber: null,
    rate: '12.00',
    skills: [
      {
        createdAt: '2023-04-25T16:16:03.680Z',
        description: null,
        ftsNameEn: "'desinfect':1",
        id: 'dedb4cd3-44dd-4249-abcc-17a4b8f49703',
        name: 'Desinfection',
        updatedAt: '2023-04-25T16:16:03.680Z'
      }
    ],
    timeZone: null,
    unblockUntil: null,
    updatedAt: '2024-03-12T00:00:00.113Z',
    userRoles: [
      {
        createdAt: '2023-09-13T08:03:08.383Z',
        roleId: 'a588bf56-e236-4e3d-9647-68e7ae2852d3',
        userId: 'fa1d260f-417c-43d9-86cd-67856fa2bc3c'
      }
    ]
  },
  {
    active: false,
    archived: false,
    availability: true,
    availabilityData: null,
    availableDays: null,
    availableHoursPerDay: null,
    availableHoursPerWeek: null,
    availableTimeEnd: '17:00',
    availableTimeStart: '08:00',
    block: false,
    blockMessage: null,
    canSwap: true,
    createdAt: '2023-09-13T09:38:05.932Z',
    email: 'jiles.taisei@feerock.com',
    firstName: 'Jiles',
    ftsFullNameEn: "'jile':1 'taisei':2",
    id: 'cddbdb62-a55c-44fa-bb99-b32ad709e96f',
    isOwner: false,
    jobTitle: 'F',
    lastName: 'Taisei',
    metadata: null,
    password: '$2b$10$RhpULVR8k.JcVnzRHF/qX.L79OrGVUUZVCA25M6gWy8Bgzq90Tk5u',
    passwordLastUpdated: '2023-09-13T04:38:06.369Z',
    phoneNumber: '+12131345748',
    rate: '10.00',
    skills: [
      {
        createdAt: '2023-04-25T16:16:03.680Z',
        description: null,
        ftsNameEn: "'desinfect':1",
        id: 'dedb4cd3-44dd-4249-abcc-17a4b8f49703',
        name: 'Desinfection',
        updatedAt: '2023-04-25T16:16:03.680Z'
      }
    ],
    timeZone: null,
    unblockUntil: null,
    updatedAt: '2024-03-12T00:00:00.113Z',
    userRoles: [
      {
        createdAt: '2023-09-13T09:38:06.410Z',
        roleId: 'a588bf56-e236-4e3d-9647-68e7ae2852d3',
        userId: 'cddbdb62-a55c-44fa-bb99-b32ad709e96f'
      }
    ]
  },
  {
    active: true,
    archived: false,
    availability: true,
    availabilityData: {
      availableDays: [
        'Thursday',
        ' Monday',
        ' Tuesday',
        ' Wednesday',
        ' Friday'
      ],
      availableHoursPerDay: 8,
      availableHoursPerWeek: 40,
      dates: [],
      scheduledHoursPerWeek: 0,
      tasks: []
    },
    availableDays: ['Thursday', ' Monday', ' Tuesday', ' Wednesday', ' Friday'],
    availableHoursPerDay: 8,
    availableHoursPerWeek: 40,
    availableTimeEnd: '17:00',
    availableTimeStart: '08:00',
    block: false,
    blockMessage: null,
    canSwap: true,
    createdAt: '2023-11-27T09:36:09.720Z',
    email: 'lochlan.roane@forkshape.com',
    firstName: 'lochlan',
    ftsFullNameEn: "'lochlan':1 'roan':2",
    id: 'b35cf5a5-fdf6-4791-92ab-391b42b0f69d',
    isOwner: false,
    jobTitle: 'Technician 2',
    lastName: 'roane',
    metadata: null,
    password: '$2b$10$GK1fhAZL001guGkSpuox3uyDwRDeydxDolPNjIzhNRZIxwJqNof6S',
    passwordLastUpdated: '2024-04-13T04:36:09.889Z',
    phoneNumber: '+12794992386',
    rate: '30.00',
    skills: [
      {
        createdAt: '2023-04-25T16:16:03.680Z',
        description: null,
        ftsNameEn: "'desinfect':1",
        id: 'dedb4cd3-44dd-4249-abcc-17a4b8f49703',
        name: 'Desinfection',
        updatedAt: '2023-04-25T16:16:03.680Z'
      }
    ],
    timeZone: null,
    unblockUntil: null,
    updatedAt: '2024-04-14T09:36:09.895Z',
    userRoles: [
      {
        createdAt: '2023-11-27T09:36:09.750Z',
        roleId: 'a588bf56-e236-4e3d-9647-68e7ae2852d3',
        userId: 'b35cf5a5-fdf6-4791-92ab-391b42b0f69d'
      }
    ]
  },
  {
    active: true,
    archived: false,
    availability: true,
    availabilityData: {
      availableDays: [
        'Thursday',
        ' Monday',
        ' Tuesday',
        ' Wednesday',
        ' Friday'
      ],
      availableHoursPerDay: 9,
      availableHoursPerWeek: 45,
      dates: [],
      scheduledHoursPerWeek: 0,
      tasks: []
    },
    availableDays: ['Thursday', ' Monday', ' Tuesday', ' Wednesday', ' Friday'],
    availableHoursPerDay: 9,
    availableHoursPerWeek: 45,
    availableTimeEnd: '17:00',
    availableTimeStart: '08:00',
    block: false,
    blockMessage: null,
    canSwap: true,
    createdAt: '2023-12-07T05:49:44.043Z',
    email: 'unknown.micheal@forkshape.com',
    firstName: 'unknown',
    ftsFullNameEn: "'micheal':2 'unknown':1",
    id: '9c34ac72-3e2f-42db-b5dc-8a82f6ff8b8d',
    isOwner: false,
    jobTitle: 'Techinician',
    lastName: 'micheal',
    metadata: null,
    password: '$2b$10$GoHP9.DGbycKSso/wL6U6OrSvyGwpS1i3XCqOSxi2AVMzvjKNQMuy',
    passwordLastUpdated: '2023-12-07T00:49:45.418Z',
    phoneNumber: '+18454511144',
    rate: '20.00',
    skills: [
      {
        createdAt: '2023-04-25T16:16:03.680Z',
        description: null,
        ftsNameEn: "'desinfect':1",
        id: 'dedb4cd3-44dd-4249-abcc-17a4b8f49703',
        name: 'Desinfection',
        updatedAt: '2023-04-25T16:16:03.680Z'
      }
    ],
    timeZone: null,
    unblockUntil: null,
    updatedAt: '2023-12-07T05:49:45.425Z',
    userRoles: [
      {
        createdAt: '2023-12-07T05:49:44.074Z',
        roleId: 'a588bf56-e236-4e3d-9647-68e7ae2852d3',
        userId: '9c34ac72-3e2f-42db-b5dc-8a82f6ff8b8d'
      }
    ]
  }
];

const resources = resourcesData.map((user: any) => ({
  hrAvailable:
    (user?.availabilityData?.availableHoursPerWeek || 40) -
    (user?.availabilityData?.scheduledHoursPerWeek || 0),
  id: user.id,
  name: `${user.firstName} ${user.lastName}`,
  profilePic: user.profilePic ? user.profilePic : null,
  rate: user.rate || 'not rate',
  status: user.availability
}));

interface Props {
  setOpenDetail: React.Dispatch<React.SetStateAction<boolean>>;
  taskData: any;
}

const AssignmentsTask: React.FC<Props> = function AssignmentsTask({
  setOpenDetail,
  taskData
}: Props) {
  const [task, setTask] = useState<any>(taskData);
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);

  const [unassignedDates, setUnassignedDates] = useState<boolean>(false);
  const [useRange, setUseRange] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [optionsList, setOptionsList] = useState<any[]>(resources);
  const [dashboardRanges, setDashboardRanges] = useState({
    fromDate: null,
    fromDateFormated: '',
    toDate: null,
    toDateFormated: ''
  });
  const [filteredOptions, setFilteredOptions] = useState<any>([]);

  const handleSelectedOptionsChange = (event: any, options: any) => {
    if (options) {
      if (!options.selected) {
        const newSelectedOptions = [...selectedOptions, options];
        setSelectedOptions(newSelectedOptions);
      } else {
        const newSelectedOptions = selectedOptions.filter(
          (option: any) => option.id !== options.id
        );
        setSelectedOptions(newSelectedOptions);
      }
    }
  };

  useEffect(() => {
    if (optionsList.length > 0 && undefined !== optionsList[0]?.id) {
      const auxV = optionsList.map((value: any) => {
        const auxSt = !!selectedOptions.find(
          (selectedOption: any) => selectedOption.id === value.id
        );
        return {
          ...value,
          selected: auxSt,
          status: auxSt
        };
      });
      setFilteredOptions(auxV);
    }
  }, [optionsList, selectedOptions]);

  const handleDateChange = (newDate: any, opt: number) => {
    if (opt === 1) {
      setDashboardRanges({
        ...dashboardRanges,
        fromDate:
          newDate !== null && `${newDate?.$y}` !== 'NaN' ? newDate : null,
        fromDateFormated:
          newDate !== null && `${newDate?.$y}` !== 'NaN'
            ? `${newDate.format('YYYY-MM-DD')}T00:00:00`
            : ''
      });
    }

    if (opt === 2) {
      setDashboardRanges({
        ...dashboardRanges,
        toDate: newDate !== null && `${newDate?.$y}` !== 'NaN' ? newDate : null,
        toDateFormated:
          newDate !== null && `${newDate?.$y}` !== 'NaN'
            ? `${newDate.format('YYYY-MM-DD')}T23:59:59`
            : ''
      });
    }
  };

  const handleClose = useCallback(() => {
    setOpenDetail(false);
    setAuxManualClose(false);
  }, [setOpenDetail]);

  useEffect(() => {
    setTask(taskData);
  }, [taskData]);

  return (
    <Container
      sx={{
        alignItems: 'normal',
        margin: '2rem'
      }}
    >
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={handleClose}
        open={auxManualClose}
        sx={{
          alignItems: 'center',
          padding: '2rem'
        }}
      >
        <ModalContainer sx={{ padding: '2rem' }}>
          <Box
            key="detail-popup-title"
            sx={{ display: 'flex', textAlign: 'right', width: '100%' }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 'bolder',
                variant: 'title'
              }}
            >
              {taskData?.title || 'Task 1'}
            </Typography>
          </Box>
          <Box
            key="detail-popup-task-data"
            sx={{ display: 'block', textAlign: 'left', width: '100%' }}
          >
            <Typography variant="body_300">
              Hours per day: 2 Req. resources: 1 - Project hour rate: $ 16.00
            </Typography>
          </Box>
          <Box
            key="detail-popup-task-data-all-assigned"
            sx={{ display: 'block', textAlign: 'left', width: '100%' }}
          >
            <Typography color="red" variant="body_300">
              55% of all dates assigned
            </Typography>
          </Box>
          <Box
            key="detail-popup-task-data-next-30days-assigned"
            sx={{ display: 'block', textAlign: 'left', width: '100%' }}
          >
            <Typography color="orange" variant="body_300">
              85% of the next 30 days dates assigned
            </Typography>
          </Box>
          <Box
            key="detail-popup-body"
            sx={{ display: 'flex', textAlign: 'left', width: '100%' }}
          >
            <AutoCompleteStyled
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.name || ''}
              onChange={handleSelectedOptionsChange}
              options={filteredOptions || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment:
                      selectedOptions && selectedOptions.length > 0
                        ? selectedOptions.map((option: any, key: any) => {
                            const initials = option?.name
                              .split(' ')
                              .slice(0, 2)
                              .map((word: string) => word[0]?.toUpperCase())
                              .join('');
                            const auxKey = `schedule-serviceTime-${option.id}`;

                            return (
                              <Box
                                key={auxKey}
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  gap: '1rem',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}
                                title={option.name || ''}
                              >
                                <Box
                                  key={`avatar-box-${auxKey}`}
                                  sx={{
                                    alignItems: 'center',
                                    display: 'inline-flex'
                                  }}
                                >
                                  <Avatar
                                    alt={option?.name}
                                    src={option?.profilePic}
                                    sx={{
                                      backgroundColor: resourseColors[key],
                                      mr: '1rem'
                                    }}
                                  >
                                    {option.profilePic ? null : initials}
                                  </Avatar>
                                  <Typography variant="body_300">
                                    {option.name}
                                  </Typography>
                                </Box>
                                <Box key={`resource-rate-box-${auxKey}`}>
                                  <Typography
                                    color={
                                      Number(option.rate) > 16 ? 'red' : 'green'
                                    }
                                    variant="body_300"
                                  >
                                    Rate: $ {option.rate}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })
                        : null
                  }}
                  className="auto-complete-styled-xxx"
                  sx={{
                    '.MuiAutocomplete-clearIndicator': {
                      display: 'none'
                    },
                    '.MuiInputBase-adornedStart': {
                      display: 'flow !important'
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderStyle: 'hidden'
                    },
                    backgroundColor: 'white !important',
                    borderStyle: 'hidden',
                    display: 'flex',
                    fontSize: '15px'
                  }}
                  value=""
                  variant="outlined"
                />
              )}
              renderOption={(props, option: any) => {
                const auxKey = `options-resources-serviceTime-${option.id}`;
                return (
                  <li {...props}>
                    <Checkbox
                      checked={option.selected}
                      style={{
                        marginRight: 8
                      }}
                    />
                    <Box
                      key={auxKey}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '1rem',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                      title={option.name || ''}
                    >
                      <Box
                        key={`avatar-box-${auxKey}`}
                        sx={{
                          alignItems: 'center',
                          display: 'inline-flex'
                        }}
                      >
                        <Typography variant="body_300">
                          {option.name}
                        </Typography>
                      </Box>
                      <Box key={`resource-rate-box-${auxKey}`}>
                        <Typography
                          color={Number(option.rate) > 16 ? 'red' : 'green'}
                          variant="body_300"
                        >
                          Rate: $ {option.rate}
                        </Typography>
                      </Box>
                    </Box>
                  </li>
                );
              }}
              size="small"
              sx={{ width: '100%' }}
              value={selectedOptions}
            />
          </Box>
          <Box
            key="detail-popup-options"
            sx={{ display: 'flex', textAlign: 'right', width: '100%' }}
          >
            <FormControl>
              <FormControlLabel
                control={<Checkbox />}
                defaultChecked={unassignedDates}
                key="unassigned-dates-checkbox"
                label={
                  <Typography variant="primary">
                    Only unassigned dates
                  </Typography>
                }
                onChange={() => setUnassignedDates(!unassignedDates)}
              />
              <FormControlLabel
                control={<Checkbox />}
                defaultChecked={useRange}
                key="date-range-checkbox"
                label={
                  <Typography variant="primary">Select data range</Typography>
                }
                onChange={() => setUseRange(!useRange)}
              />
            </FormControl>
          </Box>
          {useRange && (
            <Box sx={{ display: 'block', width: '100%' }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key="timeSheet-LocalizationProvider-range1-fromDate"
                sx={{
                  maxWidth: '200px !important'
                }}
              >
                <DatePicker
                  label="Start Date"
                  onChange={(fromDate: any) => {
                    handleDateChange(fromDate, 1);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: 'transparent !important',
                        marginBottom: '0.75rem !important'
                      }}
                    />
                  )}
                  value={dashboardRanges.fromDate}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key="timeSheet-LocalizationProvider-range1-toDate"
                sx={{
                  '.MuiInputBase-formControl': {
                    maxWidth: '200px !important'
                  },
                  marginBottom: '0.75rem',
                  maxWidth: '200px !important'
                }}
              >
                <DatePicker
                  label="End Date"
                  minDate={dayjs(dashboardRanges.fromDate) || undefined}
                  onChange={(toDate) => {
                    handleDateChange(toDate, 2);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ backgroundColor: 'transparent !important' }}
                    />
                  )}
                  value={dashboardRanges?.toDate}
                />
              </LocalizationProvider>
            </Box>
          )}
          <Box>
            <DrawerButton
              onClick={handleClose}
              sx={{ height: 46, width: 200 }}
              variant="cancel"
            >
              <Typography>Cancel</Typography>
            </DrawerButton>
            <DrawerButton
              onClick={handleClose}
              sx={{ height: 46, width: 200 }}
              variant="contained"
            >
              <Typography>Confirm</Typography>
            </DrawerButton>
          </Box>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default AssignmentsTask;
