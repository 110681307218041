import React from 'react';
import '../../style/App.scss';
import { CircularProgress, Box, useTheme } from '@mui/material';

interface LoaderProps {
  customColor?: string;
  customSize?: number;
  marginTop?: string;
}

const Loader: React.FC<LoaderProps> = function Loader({
  customColor,
  customSize,
  marginTop
}) {
  const theme = useTheme();

  const getCustomColor = () => {
    if (customColor) {
      switch (customColor) {
        case 'primary':
          return theme.palette.primary.main;
        case 'delete':
        case 'error':
          return theme.palette.error.main;
        case 'warning':
          return theme.palette.warning.main;
        case 'white':
          return theme.palette.mainText[100];
        default:
          return theme.palette.surface[100];
      }
    }
    return theme.palette.surface[100];
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginTop: marginTop || 'inherit',
        width: '100%'
      }}
    >
      <CircularProgress
        size={customSize || 24}
        sx={{
          color: getCustomColor()
        }}
      />
    </Box>
  );
};

Loader.defaultProps = {
  customColor: undefined,
  customSize: undefined,
  marginTop: undefined
};

export default Loader;
