import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Container,
  Modal,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomRadio from '../form/CustomRadio';
import {
  ButtonContainerModal,
  DrawerButton,
  ModalContainer
} from './GlobalConfirmModal';

interface Props {
  setShowResendProposalConfirtmationModal: React.Dispatch<
    React.SetStateAction<any>
  >;
}

const ResendProposalConfirmationModal: React.FC<Props> =
  function ResendProposalConfirmationModal({
    setShowResendProposalConfirtmationModal
  }) {
    const { t } = useTranslation();
    const [auxManualPricing, setAuxManualPricing] = useState<boolean>(false);
    const theme = useTheme();

    return (
      <Container
        sx={{
          alignItems: 'center',
          margin: '40px',
          padding: '40px',
          width: 'auto'
        }}
      >
        <Modal
          open
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {}}
        >
          <ModalContainer sx={{ padding: '40px' }}>
            <Typography sx={{ marginBottom: '1rem' }} variant="title">
              {t('LEAD.CREATE.PROPOSAL.resendProposalLinkModalTitle')}
            </Typography>
            <Typography
              align="center"
              color="secondary.400"
              sx={{ marginBottom: '2rem', maxWidth: '32rem' }}
              variant="title"
            >
              {t('LEAD.CREATE.PROPOSAL.resendProposalLinkModalText')}
            </Typography>
            <br />
            <ButtonContainerModal>
              <DrawerButton
                onClick={() => {
                  setShowResendProposalConfirtmationModal(false);
                }}
                sx={{ height: 46, maxWidth: '120px' }}
                variant="contained"
              >
                <Typography>OK</Typography>
              </DrawerButton>
            </ButtonContainerModal>
          </ModalContainer>
        </Modal>
      </Container>
    );
  };

export default ResendProposalConfirmationModal;
