import {
  Box,
  Button,
  Container,
  Card,
  Divider,
  Typography,
  CardContent
} from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '@pubnub/react-chat-components';
import AutoCompleteSearch from '../../components/input/AutoCompleteSearch';

import { ReactComponent as CreateChatIcon } from '../../assets/icons/create-chat-button.svg';
import ChatContext from './ChatContext';

// Define styles for mobile and desktop devices
const MobileStyle = {
  margin: '20px',
  width: 'auto'
};

const DesktopStyle = {
  marginX: '30px',
  marginY: '20px',
  width: 'auto'
};

type ProjectRedirectState = {
  id: string;
  projectName: string;
  teamName: string;
};

const ListingChats: React.FC = function ListingChats() {
  const { token, userId } = useContext(ChatContext);

  // Translation hook for internationalization
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClick = useCallback(
    ({ id, teamName, projectName }: ProjectRedirectState) =>
      () =>
        navigate(`/app/chats/projects/${id}`, {
          state: { id, projectName, teamName, token }
        }),
    []
  );

  // Memoized translation key for the title
  const [CHATS_LIST_TITLE, RECENTLY_VISITED] = useMemo(
    () => ['CHATS.LIST.title', 'CHATS.LIST.recentlyVisited'],
    []
  );

  return (
    <Container sx={isMobile ? MobileStyle : DesktopStyle}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography
          color="primary.main"
          sx={{
            fontSize: isMobile ? 24 : 28,
            fontWeight: 900,
            paddingLeft: '1rem'
          }}
          variant="title"
        >
          {t(CHATS_LIST_TITLE)}
        </Typography>

        <Box alignItems="center" display="flex" sx={{ color: 'text.primary' }}>
          <AutoCompleteSearch
            options={[]}
            setOptions={() => {}}
            setSearch={() => {}}
            setValueSearched={() => {}}
            value=""
          />

          <Button
            color="primary"
            onClick={() =>
              navigate('/app/chats/conversation', { state: { token, userId } })
            }
            sx={{ marginLeft: '2rem', padding: 0 }}
            variant="contained"
          >
            <CreateChatIcon fill="white" />
          </Button>
        </Box>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        sx={{ marginTop: '2rem' }}
      >
        <Box
          color="secondary.main"
          component="span"
          sx={{
            fontSize: '1.5rem',
            fontWeight: 700
          }}
        >
          {t(RECENTLY_VISITED)}
        </Box>
        <Divider sx={{ minWidth: '80%' }} />
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {Array.from(Array<any>(12)).map<any>(() => {
          const key = uniqueId();
          const teamName = 'Allen Roy Team';
          const projectName = 'project name';

          return (
            <Card
              key={key}
              onClick={handleClick({ id: key, projectName, teamName })}
              sx={{
                cursor: 'pointer',
                marginBottom: '2rem',
                marginTop: '2rem',
                minWidth: 275
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  color="primary.main"
                  sx={{ fontSize: 18, fontWeight: 700 }}
                >
                  {teamName}
                </Typography>

                <Typography
                  gutterBottom
                  color="primary.main"
                  sx={{ fontSize: 14, fontWeight: 700 }}
                >
                  {projectName}
                </Typography>
              </CardContent>

              <CardContent>
                <Box display="flex" sx={{ marginTop: '0.5rem' }}>
                  {Array.from(Array<any>(4)).map<any>(() => (
                    <Box
                      component="div"
                      key={uniqueId()}
                      sx={{
                        backgroundColor: 'grey',
                        borderRadius: '100%',
                        marginRight: '10px',
                        padding: '15px'
                      }}
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Container>
  );
};

export default ListingChats;
