import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Palette } from '@mui/material';
import {
  CalendarEventsApi,
  CalendarEventsApiCalendarEventsFindByMonthRequest,
  CreateNoteDTO,
  NoteApi,
  CalendarEventIterationApi,
  TaskIterationChecklistDTO,
  TaskIterationStatusStatusEnum,
  TechnicianTaskManagementApi,
  TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest
} from '../api-client/generated/api';
import { ProjectStatus, formatDate } from '../utils/general';
import {
  getTodayEvents,
  getFormattedDatesList,
  getUpcomingEvents,
  getCompletedEvents,
  getTaskInProgress,
  formatTimesAndCalculateDifference,
  getYesterdayEvents,
  formatDateSimple
} from '../utils/calendarHelper';
import {
  alertColors,
  mainColors,
  successColors,
  surfaceColors
} from '../theme/themeConfig';

const calendarApi = new CalendarEventsApi();
const calendarEventIterationApi = new CalendarEventIterationApi();
const technicianTaskManagementApi = new TechnicianTaskManagementApi();
const noteApi = new NoteApi();

dayjs.extend(utc);
dayjs.extend(timezone);

const calendarGetCalendarEventsMobile = async () => {
  const response = await calendarApi.calendarEventsFindByTechnicianDashboard();
  const { data } = response;
  const upcomingEvents: any[] = [];
  const completedEvents: any[] = [];

  const prevTaskInProgres = getTaskInProgress(data);

  const auxEventsToday = getTodayEvents(data);
  const todayProjects = getFormattedDatesList(auxEventsToday, 2);

  const auxEventsYesterday = getYesterdayEvents(data);
  const yesterdayProjects = getFormattedDatesList(auxEventsYesterday, 2);

  const upcomingEventsList = getUpcomingEvents(data);
  const upcomingEventsListDates: any[] = [];
  upcomingEventsList.forEach((event) => {
    const currentEventDate = upcomingEventsListDates.find(
      (upcomingEvent) => upcomingEvent.date === event.start
    );

    if (!currentEventDate) {
      upcomingEventsListDates.push({
        date: event.start,
        events: [event]
      });
    } else {
      currentEventDate.events.push(event);
    }
  });

  upcomingEventsListDates.forEach((eventsDateList) => {
    const formatedDatesList = getFormattedDatesList(eventsDateList.events);
    upcomingEvents.push({
      date: formatDateSimple(new Date(eventsDateList.date)),
      events: formatedDatesList
    });
  });

  const completedEventsList = getCompletedEvents(data);

  const completeEventsListDates: any[] = [];

  completedEventsList.forEach((event) => {
    const completedEventDate = completeEventsListDates.find(
      (completedEvent) => completedEvent.date === event.start
    );

    if (!completedEventDate) {
      completeEventsListDates.push({
        date: event.start,
        events: [event]
      });
    } else {
      completedEventDate.events.push(event);
    }
  });

  completeEventsListDates.forEach((eventsDateList) => {
    const formatedDatesList = getFormattedDatesList(eventsDateList.events, 1);
    completedEvents.push({
      date: formatDateSimple(new Date(eventsDateList.date)),
      events: formatedDatesList
    });
  });

  return {
    completedEvents,
    prevTaskInProgres,
    todayProjects,
    upcomingEvents,
    yesterdayProjects
  };
};

const calendarGetCalendarEventsByTechnician = async () => {
  const response = await calendarApi.calendarEventsFindByTechnician();
  const { data } = response;
  const calandarEvents: any[] = [];
  data.forEach((event: any) => {
    if (
      !calandarEvents.find(
        (calandarEvent) =>
          calandarEvent.date === event.start &&
          calandarEvent.leadServiceId === event.leadService.id &&
          calandarEvent.leadServiceTimeId === event.leadServiceTime.id
      )
    ) {
      const tasksList: any[] = [];
      event?.leadService?.tasks.forEach((task: any) => {
        tasksList.push({
          attendees: [
            `${event?.client?.firstName} ${event?.client?.lastName} - ${event?.client?.businessName}`
          ],
          endTime: event.leadServiceTime?.endTime,
          frequency: event.leadServiceTime.frequency,
          frequencyDays: event.leadServiceTime.frequencyDays.map((day: any) => {
            return day;
          }),
          leadServiceTaskId: task.id,
          location: event?.location,
          phone: event?.client?.phone,
          projectId: event.project.id,
          time: event.leadServiceTime.startTime,
          title: task.name
        });
      });
      calandarEvents.push({
        date: event.start,
        events: tasksList,
        id: event.id,
        leadServiceId: event.leadService.id,
        leadServiceTimeId: event.leadServiceTime.id,
        projectId: event.project.id,
        startTime: event.leadServiceTime.startTime
      });
    }
  });

  return calandarEvents;
};

const calendarGetCalendarEventsByProject = async (id: string) => {
  const response = await calendarApi.calendarEventsFindOne({ id });
  const { data } = response;

  return data;
};

const calendarGetProjectsCalendarEvents = async (params: any) => {
  const requestParams = {
    day: params?.day
  } as CalendarEventsApiCalendarEventsFindByMonthRequest;

  const response = await calendarApi.calendarEventsFindByMonth(requestParams);

  const { data } = response;
  const projects: any[] = [];
  const events: any[] = [];
  const eventsProjects: any[] = [];
  const { palette } = params as { palette: Palette };

  const auxStartDate = new Date(`${params?.day} 00:00:00`).toISOString();

  data.forEach((calendarData: any) => {
    let backgroundColor = '';
    let color = '';

    const auxtimezoneIANA =
      calendarData?.project?.proposal?.lead?.address?.timezoneIANA || null;

    if (new Date(calendarData?.start) > new Date(auxStartDate)) {
      if (!projects.find((project) => project.id === calendarData.project.id)) {
        projects.push({
          backgroundColor,
          borderColor: backgroundColor,
          borderRadius: '4px',
          color,
          id: calendarData?.project?.id,
          name: calendarData?.title
        });
      } else {
        const auxProject = projects.find(
          (project) => project.id === calendarData.project.id
        );
        backgroundColor = auxProject?.backgroundColor;
        color = auxProject?.color;
      }

      const auxMembers: any[] = [];
      const auxServices: any[] = [];

      calendarData?.leadService?.tasks.forEach((task: any) => {
        let auxStatus: string = TaskIterationStatusStatusEnum.Created;

        if (task?.taskIteration && task.taskIteration.length > 0) {
          const filterTaskIteration = task.taskIteration.filter(
            (iteration: any) =>
              calendarData?.taskIterations.filter(
                (calendartTaskIte: any) =>
                  calendartTaskIte?.id === iteration?.id
              ) && iteration.status === TaskIterationStatusStatusEnum.Completed
          );

          if (filterTaskIteration.length >= task.minimumResources) {
            auxStatus = TaskIterationStatusStatusEnum.Completed;
          } else if (filterTaskIteration.length > 0) {
            auxStatus = TaskIterationStatusStatusEnum.InProgress;
          }
        }

        auxServices.push({
          id: task.id,
          name: task.name,
          status: auxStatus
        });

        task?.leadTaskTechnicians?.forEach((technicianData: any) => {
          const technician = technicianData?.technician;

          if (!auxMembers.find((member) => member.id === technician.id)) {
            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);
            auxMembers.push({
              backgroundColor: `rgb(${red}, ${green}, ${blue})`,
              borderRadius: '4px',
              color,
              firstName: technician?.firstName,
              id: technician?.id,
              image: {
                url: technician?.profilePic
              },
              lasName: technician?.lastName,
              name: `${technician?.firstName} ${technician?.lastName}`,
              profilePic: technician?.profilePic
            });
          }
        });
      });

      let auxToLocalStart = dayjs(calendarData?.start).format(
        'YYYY-MM-DDTHH:mm:ss'
      );

      let auxToLocalEnd = dayjs(calendarData?.end).format(
        'YYYY-MM-DDTHH:mm:ss'
      );

      if (auxtimezoneIANA) {
        auxToLocalStart = dayjs(calendarData?.start)
          .tz(auxtimezoneIANA)
          .format('YYYY-MM-DDTHH:mm:ss');

        auxToLocalEnd = dayjs(calendarData?.end)
          .tz(auxtimezoneIANA)
          .format('YYYY-MM-DDTHH:mm:ss');
      }

      if (
        !eventsProjects.find(
          (eventProject) =>
            eventProject.projectId === calendarData.project.id &&
            eventProject.startDate === calendarData?.start
        )
      ) {
        const auxServicesTimes: any[] = [];
        let cont = 0;

        calendarData?.project?.proposal?.lead?.serviceTimes?.forEach(
          (serviceTime: any) => {
            cont += 1;

            const auxServices = serviceTime?.services.map((service: any) => {
              return {
                id: service.id,
                name: service.name
              };
            });

            auxServicesTimes.push({
              endTime: serviceTime.endTime,
              id: serviceTime.id,
              name: `Service Time #${cont}`,
              services: auxServices,
              startTime: serviceTime.startTime
            });
          }
        );

        switch (calendarData?.state) {
          case 'Completed':
            backgroundColor = palette.success?.[100] || '';
            color = palette.success?.[400] || '';
            break;
          case 'Started':
            backgroundColor = palette.warning?.[100] || '';
            color = palette.warning?.[400] || '';
            break;
          default:
            backgroundColor = palette.informative?.[100] || '';
            color = palette.informative?.[400] || '';
            break;
        }

        if (calendarData?.project?.status === ProjectStatus.CANCELLED) {
          backgroundColor = palette.alert?.[100] || '';
          color = palette.alert?.[400] || '';
        }

        if (calendarData?.project?.status === ProjectStatus.ON_HOLD) {
          backgroundColor = palette.grey?.[100] || '';
          color = palette.grey?.[400] || '';
        }

        eventsProjects.push({
          attendees: [
            `${calendarData?.client?.firstName} ${calendarData?.client?.lastName} - ${calendarData?.client?.businessName}`
          ],
          backgroundColor,
          body: `${calendarData?.title}`,
          borderRadius: '4px',
          calendarId: calendarData?.project?.id,
          category: 'allday',
          color,
          end: auxToLocalEnd,
          id: calendarData?.id,
          location: calendarData?.location,
          projectId: calendarData.project.id,
          raw: {
            members: auxMembers,
            projectId: calendarData.project.id,
            services: auxServicesTimes
          },
          start: auxToLocalStart,
          startDate: calendarData?.start,
          title: `${calendarData?.title}`
        });
      }

      if (
        !events.find(
          (event) =>
            event.serviceId === calendarData?.leadService?.id &&
            event.serviceTimeId === calendarData?.leadServiceTime?.id &&
            event.startDate === calendarData?.start
        )
      ) {
        switch (calendarData?.state) {
          case 'Completed':
            backgroundColor = palette.success?.[100] || '';
            color = palette.success?.[400] || '';
            break;
          case 'Started':
            backgroundColor = palette.warning?.[100] || '';
            color = palette.warning?.[400] || '';
            break;
          case 'Created':
          default:
            backgroundColor = palette.informative?.[100] || '';
            color = palette.informative?.[400] || '';
            break;
        }

        if (calendarData?.project?.status === ProjectStatus.CANCELLED) {
          backgroundColor = palette.alert?.[100] || '';
          color = palette.alert?.[400] || '';
        }

        if (calendarData?.project?.status === ProjectStatus.ON_HOLD) {
          backgroundColor = palette.grey?.[100] || '';
          color = palette.grey?.[400] || '';
        }

        const calendarDataStartLenght: number = calendarData?.start.length;

        events.push({
          attendees: [
            `${calendarData?.client?.firstName} ${calendarData?.client?.lastName} - ${calendarData?.client?.businessName}`
          ],
          backgroundColor,
          body: calendarData?.leadService?.name,
          borderRadius: '4px',
          calendarId: calendarData?.project?.id,
          category: 'time',
          color,
          end: calendarData?.end.substring(0, calendarDataStartLenght - 5),
          id: calendarData?.id,
          location: calendarData?.location,
          projectId: calendarData.project.id,
          raw: {
            members: auxMembers,
            projectId: calendarData.project.id,
            services: [
              {
                id: calendarData?.leadService?.id,
                name: calendarData?.leadService?.name
              }
            ]
          },
          serviceId: calendarData?.leadService?.id,
          serviceTimeId: calendarData?.leadServiceTime?.id,
          start: calendarData?.start.substring(0, calendarDataStartLenght - 5),
          startDate: calendarData?.start,
          state: calendarData?.state,
          title: `${calendarData?.title} ${calendarData?.leadService?.name} - ${calendarData?.client?.firstName} ${calendarData?.client?.lastName}`
        });
      }
    }
  });

  return {
    ...data,
    events,
    eventsProjects,
    projects
  };
};

const setEventOnRoad = async (id: string) => {
  const response = await calendarApi.calendarEventsSetTravelTimeOnRoad({ id });
  const { data } = response;

  return data;
};

const setEventArrived = async (params: any): Promise<any> => {
  const { id, calendarInProgressMutate, setGetCalendarInProgressEvents } =
    params;

  const response = await calendarApi.calendarEventsSetTravelTimeArrived({ id });
  const { data } = response;
  calendarInProgressMutate(undefined, true);
  setGetCalendarInProgressEvents(true);
  return data;
};

const setCompleteCalendarIteration = async (params: any): Promise<any> => {
  const { id, calendarInProgressMutate, setGetCalendarInProgressEvents } =
    params;

  const response =
    await calendarEventIterationApi.calendarEventIterationSetCompleteCalendarIteration(
      { id }
    );
  const { data } = response;
  calendarInProgressMutate(undefined, true);
  setGetCalendarInProgressEvents(true);
  return data;
};

const technicianTaskManagenmentUpdate = async (params: any): Promise<any> => {
  const auxParams = {
    taskIterationId: params.taskIterationId,
    taskIterationStatus: {
      geofencingApproved: params.geofencingApproved,
      lat: params.lat,
      long: params.long,
      status: params.status as TaskIterationStatusStatusEnum
    }
  } as TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest;

  const response =
    await technicianTaskManagementApi.technicianTaskManagenmentUpdate(
      auxParams
    );

  const { data } = response;

  return data;
};

const technicianTaskManagenmentGetTaskIterationById = async (
  params: any
): Promise<any> => {
  const response =
    await technicianTaskManagementApi.technicianTaskManagenmentGetTaskIterationById(
      { taskIterationId: params?.id }
    );

  const { data } = response;

  const auxData: any = typeof data === 'object' ? data : null;
  const auxFiles: any[] = [];

  if (params?.setTaskItearationFilesList && auxData && auxData?.fileBank) {
    auxData.fileBank.forEach((file: any) => {
      if (
        !auxFiles.find(
          (auxFile) => auxFile.fileOriginalName === file.fileOriginalName
        )
      ) {
        auxFiles.push({
          ...file,
          name: file.fileOriginalName,
          [file.photoSize]: file.fileUrl
        });
      } else {
        const fileIndex = auxFiles.findIndex(
          (auxFile) => auxFile.fileOriginalName === file.fileOriginalName
        );
        auxFiles[fileIndex] = {
          ...auxFiles[fileIndex],
          [file.photoSize]: file.fileUrl
        };
      }
    });

    params?.setTaskItearationFilesList(auxFiles);

    if (params.setCurrentTaskDetails) {
      const auxCurrentParentFiles: any =
        auxData?.leadServiceTask?.parentTask &&
        auxData?.leadServiceTask?.parentTask?.fileBank
          ? auxData?.leadServiceTask?.parentTask?.fileBank
          : null;

      params.setCurrentTaskDetails({
        ...params?.currentTaskDetails,
        parentTask: {
          ...params?.currentTaskDetails?.parentTask,
          fileBank: auxCurrentParentFiles
        },
        taskIteration: [
          {
            ...params?.currentTaskDetails.taskIteration[0],
            fileBank: auxFiles
          }
        ]
      });
    }
  }

  return true;
};

const calendarEventsfindDotDaysByTechnician = async (): Promise<any> => {
  const response = await calendarApi.calendarEventsFindDotDaysByTechnician();
  const { data } = response;
  return data;
};

const calendarGetCalendarEventsByTechnicianByDate = async (day: string) => {
  const response = await calendarApi.calendarEventsFindByTechnicianAndDate({
    day
  });

  const { data } = response;
  const calandarEvents: any[] = [];

  data.forEach((event: any) => {
    if (
      !calandarEvents.find(
        (calandarEvent) =>
          calandarEvent.date === event.start &&
          calandarEvent.leadServiceId === event.leadService.id &&
          calandarEvent.leadServiceTimeId === event.leadServiceTime.id
      )
    ) {
      const tasksList: any[] = [];
      event?.leadService?.tasks.forEach((task: any) => {
        let auxStatus = TaskIterationStatusStatusEnum.Created;
        const auxStatusColors = {
          backgroundColor: `${surfaceColors.SURFACE_5}`,
          color: `${mainColors.MAIN_6}`
        };

        if (task.taskIteration && task.taskIteration.length > 0) {
          auxStatus = task.taskIteration[0].status;

          if (
            task.taskIteration[0].status ===
            TaskIterationStatusStatusEnum.Completed
          ) {
            auxStatusColors.backgroundColor = `${successColors.SUCCESS_6}`;
            auxStatusColors.color = `${successColors.SUCCESS_7}`;
          }

          if (
            task.taskIteration[0].status ===
            TaskIterationStatusStatusEnum.InProgress
          ) {
            auxStatusColors.backgroundColor = `${alertColors.ALERT_6}`;
            auxStatusColors.color = `${alertColors.ALERT_7}`;
          }
        }

        const newTecnicians = task?.leadTaskTechnicians.map(
          (technicianData: any) => {
            const red = Math.floor(Math.random() * 256);
            const technician = technicianData?.technician;
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);

            return {
              backgroundColor: `rgb(${red}, ${green}, ${blue})`,
              firstName: technician?.firstName || technician?.name,
              id: technician?.id,
              image: {
                url: ''
              },
              lasName: technician?.lastName,
              name:
                technician?.name ||
                `${technician?.firstName} ${technician?.lastName}`,
              profilePic: null,
              rate: technician?.rate
            };
          }
        );

        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        const supervisors = event?.leadServiceTime?.assignSupervisor
          ? [
              {
                backgroundColor: `rgb(${red}, ${green}, ${blue})`,
                firstName: event?.leadServiceTime?.supervisor?.firstName,
                id: event?.leadServiceTime?.supervisor?.id,
                image: {
                  url: ''
                },
                lasName: event?.leadServiceTime?.supervisor?.lastName,
                name: `${event?.leadServiceTime?.supervisor?.firstName} ${event?.leadServiceTime?.supervisor?.lastName}`,
                profilePic: null
              }
            ]
          : [];
        const taskWorkedTimes = formatTimesAndCalculateDifference(
          task?.taskIteration[0]?.startTime,
          task?.taskIteration[0]?.endTime || new Date().toISOString()
        );

        const taskDetailFormated = {
          date: formatDate(event.start),
          editable: false,
          id: task.id,
          taskDetails: {
            ...task,
            address: event?.location,
            configTravelTime: event?.leadServiceTime?.travelTime || 0,
            endTime: event.leadServiceTime.endTime,
            startTime: event.leadServiceTime.startTime,
            status: auxStatus,
            supervisors,
            taskWorkedTime: taskWorkedTimes.differenceInMinutes,
            technicians: newTecnicians,
            enforceGeofencing: event?.project.enforceGeofencing,
            travelTime: event.leadServiceTime.travelTime || 0
          }
        };

        tasksList.push({
          attendees: [
            `${event?.client?.firstName} ${event?.client?.lastName} - ${event?.client?.businessName}`
          ],
          endTime: event.leadServiceTime?.endTime,
          frequency: event.leadServiceTime.frequency,
          frequencyDays: event.leadServiceTime.frequencyDays.map((day: any) => {
            return day;
          }),
          id: event?.leadServiceTime?.id,
          leadServiceTaskId: task.id,
          location: event?.location,
          phone: event?.client?.phone,
          projectId: event.project.id,          
          status: auxStatus,
          statusColors: auxStatusColors,
          taskDetailFormated,
          time: event.leadServiceTime.startTime,
          title: task.name
        });
      });
      calandarEvents.push({
        date: event.start,
        events: tasksList,
        id: event.id,
        leadServiceId: event.leadService.id,
        leadServiceTimeId: event.leadServiceTime.id,
        projectId: event.project.id,
        startTime: event.leadServiceTime.startTime
      });
    }
  });

  return calandarEvents;
};

const calendarEventsUpdateTaskIterationChecklistStatus = async (
  params: any
) => {
  const requestParams = {
    taskIterationCheckListId: params.checklistId,
    taskIterationChecklistDTO: {
      complete: params.status
    } as TaskIterationChecklistDTO
  };
  const response =
    await technicianTaskManagementApi.technicianTaskManagenmentUpdateTaskIterationChecklistStatus(
      requestParams
    );
  const { data } = response;

  return response.status === 200 ? true : data;
};

const noteCreate = async (params: CreateNoteDTO) => {
  const response = await noteApi.noteCreate({
    createNoteDTO: params
  });
  const { data } = response;

  return data;
};

const reassignTeam = async (params: any) => {
  const { newTechnician, previousTechnician, id } = params;
  const response: any =
    await technicianTaskManagementApi.technicianTaskManagenmentSwapTaskTechnician(
      {
        swapTaskTechnicianDTO: { newTechnician, previousTechnician },
        taskId: id
      }
    );
  return { ...response.data, status: response.status };
};

const calendarGetInProgressEvents = async () => {
  const response =
    await calendarApi.calendarEventsFindInProgressByTechnicianDashboard();
  const { data } = response;
  const inProgressProjects = getFormattedDatesList(data, 2);
  return inProgressProjects;
};

export default {
  calendarEventsUpdateTaskIterationChecklistStatus,
  calendarEventsfindDotDaysByTechnician,
  calendarGetCalendarEventsByProject,
  calendarGetCalendarEventsByTechnician,
  calendarGetCalendarEventsByTechnicianByDate,
  calendarGetCalendarEventsMobile,
  calendarGetInProgressEvents,
  calendarGetProjectsCalendarEvents,
  noteCreate,
  reassignTeam,
  setCompleteCalendarIteration,
  setEventArrived,
  setEventOnRoad,
  technicianTaskManagenmentGetTaskIterationById,
  technicianTaskManagenmentUpdate
};
