import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  AUTHORIZED_VIEW_NAMES,
  CLIENT_SCREENS,
  CLIENT_TABS,
  DEFAULT_SCREENS
} from '../helpers/routesConstants';
import ScreenLoader from '../components/shared/ScreenLoader';
import ProposalsClient from './clientPage/ProposalsClient';
import ProjectsClient from './clientPage/ProjectsClient';
import InvoicesClient from './clientPage/InvoicesClient';
import EditClient from './clients/EditClient';
import DetailProject from './projects/DetailProject';

function ClientContainerAuthenticated() {
  return (
    <Routes>
      <Route
        element={<Navigate to={DEFAULT_SCREENS.LANDING} />}
        path={AUTHORIZED_VIEW_NAMES.INVALID}
      />
      <Route
        element={<Navigate to={DEFAULT_SCREENS.LANDING} />}
        path={AUTHORIZED_VIEW_NAMES.LANDING}
      />
      <Route
        element={<EditClient />}
        path={`${AUTHORIZED_VIEW_NAMES.INFORMATION}`}
      />
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <ProposalsClient />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.PROPOSALS}
      />
      <Route path={AUTHORIZED_VIEW_NAMES.PROJECTS}>
        <Route
          element={
            <Suspense fallback={<ScreenLoader />}>
              <ProjectsClient />
            </Suspense>
          }
          path={AUTHORIZED_VIEW_NAMES.PROJECTS_LIST}
        />
        <Route
          element={<DetailProject isClient />}
          path={AUTHORIZED_VIEW_NAMES.PROJECTS_DETAIL}
        />
      </Route>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <InvoicesClient />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.INVOICES}
      />
    </Routes>
  );
}

export default ClientContainerAuthenticated;
