import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps,
  Modal,
  Backdrop,
  useTheme,
  MenuItem
} from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import useSWR from 'swr';
import { capitalizeString } from '../../utils/general';
import SelectInput from '../form/SelectInput';
import api from '../../api';
import Loader from '../shared/Loader';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)'
}));

interface Props {
  data: any;
  mutate: () => void;
  setShowModal: (v: boolean) => void;
}

const ReassignTechnicianModal: React.FC<Props> =
  function ReassignTechnicianModal({ setShowModal, data, mutate }) {
    const { t } = useTranslation();
    const [technicians, setTechnicians] = useState<any>();
    const [previous, setPrevious] = useState<any>();
    const [future, setFuture] = useState<any>();
    const [reassignApi, setReassignApi] = useState<boolean>(false);

    const { data: techniciansData, error: techniciansError } = useSWR(
      technicians
        ? null
        : {
            skillIds: data?.skillsInformation.map((skill: any) => skill.id),
            url: 'project/reassign-team'
          },
      api.UserResocesApi.getSkillResource
    );

    const { data: reassignData, error: reassignError } = useSWR(
      reassignApi
        ? {
            id: data?.id,
            newTechnician: future.id,
            previousTechnician: previous.id,
            url: 'project/task/reassign-resource'
          }
        : null,
      api.CalendarApi.reassignTeam
    );

    useEffect(() => {}, []);

    useEffect(() => {
      if (reassignData) {
        setReassignApi(false);
        setShowModal(false);
        mutate();
      }
    }, [reassignData]);

    useEffect(() => {
      if (techniciansData) {
        setTechnicians(techniciansData);
      }
    }, [techniciansData]);

    useEffect(() => {
      if (reassignError) {
        setReassignApi(false);
      }
    }, [reassignError]);

    return (
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalContainer sx={{ gap: 2, paddingLeft: 2, paddingRight: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'center'
            }}
          >
            <Typography color="primary" variant="heading_400">
              {t('MODAL.reassignTechnicianTitle')}
            </Typography>
            <Typography color="secondary" variant="body_100">
              {t('MODAL.reassignTechnicianDescription')}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <SelectInput
              onChange={(event) => {
                setPrevious(event.target.value);
              }}
              sx={{ width: 200 }}
              value={previous}
            >
              {data?.technicians?.map((technician: any) => (
                <MenuItem key={technician.id} value={technician}>
                  {technician?.name}
                </MenuItem>
              ))}
            </SelectInput>
            <Typography sx={{ fontSize: 20 }} variant="body_500">
              {`>`}
            </Typography>
            {technicians && (
              <SelectInput
                disabled={!previous}
                onChange={(event) => {
                  setFuture(event.target.value);
                }}
                sx={{ width: 200 }}
                value={future}
              >
                {technicians?.map((technician: any) => (
                  <MenuItem key={technician.id} value={technician}>
                    {technician?.name}
                  </MenuItem>
                ))}
              </SelectInput>
            )}
            {!technicians && <Loader customColor="primary" customSize={16} />}
          </Box>
          <br />
          <Box
            sx={{
              bottom: 0,
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between'
            }}
          >
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              sx={{ width: 200 }}
              variant="cancel"
            >
              <Typography variant="primary-bold">
                {t('MODAL.cancel')}
              </Typography>
            </Button>
            <Button
              disabled={!previous || !future}
              onClick={() => {
                setReassignApi(true);
              }}
              sx={{
                height: 46,
                width: 200
              }}
              variant="primary"
            >
              <Typography variant="primary-bold">
                {t('GENERAL.confirmButton')}
              </Typography>
            </Button>
          </Box>
        </ModalContainer>
      </Modal>
    );
  };

export default ReassignTechnicianModal;
