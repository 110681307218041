/* eslint-disable no-unused-vars */
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface NavigationState {
  confirmModalPrimaryAction: () => void;
  confirmModalPrimaryTitle: string | undefined;
  confirmModalSecondaryAction: () => void;
  confirmModalSecondaryTitle: string | undefined;
  confirmModalStatus: boolean;
  confirmModalTitle: JSX.Element | string;
  currentScreen: string;
  currentStepScreen: string;
  setConfirmModal: (
    primaryAction?: () => void,
    primaryText?: string | undefined,
    secondaryAction?: () => void,
    secondaryText?: string | undefined,
    title?: JSX.Element | string
  ) => void;
  setCurrentScreen: (currentScreen: string) => void;
  setCurrentStepScreen: (currentStepScreen: string) => void;
  setSidebarOpen: (sidebarOpen: boolean) => void;
  sidebarOpen: boolean;
}

const useNavigationStore = create<NavigationState>()(
  devtools(
    persist(
      (set) => ({
        confirmModalPrimaryAction: () => {},
        confirmModalPrimaryTitle: '',
        confirmModalSecondaryAction: () => {},
        confirmModalSecondaryTitle: '',
        confirmModalStatus: false,
        confirmModalTitle: '',
        currentScreen: 'dashboard',
        currentStepScreen: '',
        setConfirmModal: (
          primaryAction?: () => void,
          primaryText?: string,
          secondaryAction?: () => void,
          secondaryText?: string,
          title?: JSX.Element | string
        ) =>
          set(() => ({
            confirmModalPrimaryAction: primaryAction,
            confirmModalPrimaryTitle: primaryText,
            confirmModalSecondaryAction: secondaryAction,
            confirmModalSecondaryTitle: secondaryText,
            confirmModalStatus: !!primaryAction,
            confirmModalTitle: title
          })),
        setCurrentScreen: (currentScreen: string) =>
          set(() => ({ currentScreen })),
        setCurrentStepScreen: (currentStepScreen: string) =>
          set(() => ({ currentStepScreen })),
        setSidebarOpen: (sidebarOpen: boolean) => set(() => ({ sidebarOpen })),
        sidebarOpen: true
      }),
      {
        name: 'navigation-storage'
      }
    )
  )
);

export default useNavigationStore;
