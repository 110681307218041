import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { getFrequencyWithDays } from '../../../../utils/general';
import TimeComponent from '../../../../components/input/TimeComponent';

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceTimeDuration: React.FC<Props> = function ServiceTimeDuration({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [scheduleTimeError, setScheduleTimeError] = useState<boolean>(false);
  const [totalServiceTime, setTotalServiceTime] = useState<any>(0);
  const adHoc = values?.serviceTimes?.[index]?.adhocTasks;

  const services =
    index >= 0 && values?.serviceTimes?.[index]
      ? values?.serviceTimes?.[index]?.services
      : values?.serviceTimes?.[0]?.services;

  const [timeStart, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );
  const [timeEnd, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(9).minute(0)
  );

  const finalHour = () => {
    const hours = Math.floor(totalServiceTime);
    const minutes = Math.round((totalServiceTime - hours) * 60);
    const finalTime = timeStart
      ? timeStart.add(hours, 'hour').add(minutes, 'minute')
      : null;
    const newDate = finalTime ? finalTime.format('h:mm A') : '';
    return newDate;
  };

  useEffect(() => {
    const hours = Math.floor(totalServiceTime);
    const minutes = Math.round((totalServiceTime - hours) * 60);
    const finalTime = timeStart
      ? timeStart.add(hours, 'hour').add(minutes, 'minute')
      : null;
    if (finalTime && timeEnd) {
      const finalArriveTime = timeEnd.hour() + timeEnd.minute() / 100;
      const finalCalculatedTime = finalTime.hour() + finalTime.minute() / 100;
      if (finalCalculatedTime > finalArriveTime) {
        setScheduleTimeError(true);
      }
    }
    setTimeStart(
      dayjs()
        .hour(parseFloat(services?.[index]?.startTime?.split(':')[0]))
        .minute(parseFloat(services?.[index]?.startTime?.split(':')[1]))
        .add(values.address.timezone, 'minute')
    );
    setTimeEnd(
      dayjs()
        .hour(parseFloat(services?.[index]?.endTime?.split(':')[0]))
        .minute(parseFloat(services?.[index]?.endTime?.split(':')[1]))
        .add(values.address.timezone, 'minute')
    );

    const totalValue = services
      ? services
          .flatMap((array: any) => array.tasks)
          .reduce(
            (accumulator: any, item: any) =>
              accumulator + (item?.totalTime || 0),
            0
          )
      : 0;

    const adHocValueTime = adHoc?.totalTime ? adHoc.totalTime : 0;
    const totalTimeValue = totalValue + adHocValueTime;

    setTotalServiceTime(totalTimeValue);
  }, [values]);

  useEffect(() => {
    if (typeof values.serviceTimes[index].startTime === 'string') {
      const startTime = dayjs()
        .hour(parseFloat(values.serviceTimes[index]?.startTime?.split(':')[0]))
        .minute(
          parseFloat(values.serviceTimes[index]?.startTime?.split(':')[1])
        )
        .add(values.address.timezone, 'minute');
      const endTime = dayjs()
        .hour(parseFloat(values.serviceTimes[index]?.endTime?.split(':')[0]))
        .minute(parseFloat(values.serviceTimes[index]?.endTime?.split(':')[1]))
        .add(values.address.timezone, 'minute');
      setTimeStart(startTime);
      setTimeEnd(endTime);
    }
  }, []);

  return (
    <Box
      sx={{
        border: scheduleTimeError ? 2 : 1,
        borderColor: scheduleTimeError
          ? theme.palette.error.main
          : theme.palette.secondary[400],
        borderRadius: 1,
        mt: 5,
        p: 2
      }}
      width="100%"
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Box mb={2}>
            <Typography variant="body_500">
              {t('LEAD.CREATE.SERVICES.SCHEDULE.serviceTimeTitle')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography variant="primary">
                {t('LEAD.CREATE.SERVICES.SCHEDULE.totalDurationMsg1')}
              </Typography>
            </Box>
            <Box display="flex" marginTop="-4px">
              <Typography variant="primary-bold">
                <TimeComponent
                  readOnly
                  setTime={() => {}}
                  time={totalServiceTime || 0.0}
                />
              </Typography>
              &nbsp;
              {timeStart &&
                timeStart.isValid() &&
                timeEnd &&
                timeEnd.isValid() && (
                  <div>
                    <Typography variant="primary">
                      {values.projectType === 'Regular'
                        ? 'Starting'
                        : 'Starting between'}
                    </Typography>
                    &nbsp;
                    <Typography variant="primary">
                      {timeStart.format('h:mm A')}{' '}
                      {t('LEAD.CREATE.SERVICES.SCHEDULE.arriveMsg2')}
                      {values.projectType === 'Regular' ? 'Finishing ' : ''}
                      {false && finalHour()}
                      {/* final hour is to show the total time, but the estimated arrival is the service time */}
                      {timeEnd.format('h:mm A')}
                    </Typography>
                  </div>
                )}
            </Box>
          </Box>
          {values &&
          values.projectType === 'adhoc' &&
          values.projectType === 'Ad-Hoc' ? null : (
            <Box display="flex" marginTop="12px">
              <Typography variant="primary">
                {' '}
                {values?.serviceTimes
                  ? getFrequencyWithDays(values?.serviceTimes[index], t)
                  : 'frequency'}
              </Typography>
            </Box>
          )}
          {scheduleTimeError ? (
            <Box display="flex" marginTop="12px">
              <Typography color={theme.palette.error.main} variant="body_300">
                * {t('LEAD.CREATE.SERVICES.SCHEDULE.moreHoursMsg')}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceTimeDuration;
