/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardHeader from './CardHeader';
import ImageCarousel from './components/ImageCarousel';
import ImageViewer from '../../../components/ImageViewer';
import { downloadAll } from '../../../utils/general';

export interface IServiceByService {
  calendarEvenState?: string;
  detailInfo: any;
  detailProjectId: any;
  mutateAction: () => void;
  onlyTask: boolean;
  projectType: string;
  service: any;
  servicesInfo: any;
  setOnlyTask: React.Dispatch<React.SetStateAction<any>>;
  type: string;
  typeView: string;
}

const ServiceByServiceClientPage: React.FC<IServiceByService> =
  function ServiceByServiceClientPage({
    service,
    servicesInfo,
    type,
    typeView,
    detailProjectId,
    onlyTask,
    setOnlyTask,
    detailInfo,
    projectType,
    mutateAction,
    calendarEvenState
  }) {
    const [isOpen, setIsOpen] = useState(type === 'serviceTime' ? true : false);
    const [step, setStep] = useState<number>(0);
    const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
    const [imgData, setImgData] = useState('');
    const { t } = useTranslation();

    const auxTime = {
      estimated: service?.totalTimeInHours,
      projectType,
      total: service?.workedTime
    };

    function getAllFileBanks(data: any): any {
      if (!data) return null;
      let fileBanks: any = [];

      data.forEach((item: any) => {
        item.taskIteration.forEach((iteration: any) => {
          if (iteration.fileBank)
            fileBanks = fileBanks.concat(iteration.fileBank);
        });
      });

      return fileBanks;
    }

    const images = getAllFileBanks(service.tasks);

    return (
      <Box
        style={{
          borderTop: 'solid #CBCCD3 1px'
        }}
      >
        <CardHeader
          componentType="estimated"
          isOpen={isOpen}
          projectType={projectType}
          propsTime={auxTime}
          setIsOpen={setIsOpen}
          title={onlyTask ? service?.name : service?.name}
        />
        {isOpen && (
          <Box>
            <Box
              style={{
                backgroundColor: '#F7F6F9',
                borderTop: 'solid #CBCCD3 1px',
                display: 'flex',
                flexDirection: 'row',
                padding: '10px'
              }}
            >
              <Box display="flex" flexDirection="column" gap={0}>
                {images?.length !== 0 && (
                  <>
                    <Button
                      onClick={() => {
                        downloadAll(images);
                      }}
                      variant="text"
                    >
                      {t('GENERAL.downloadAllButton')}
                    </Button>

                    <ImageCarousel
                      images={images}
                      openViewer={setIsImageViewerOpen}
                      setImageUrlViewer={setImgData}
                    />
                  </>
                )}
                {images?.length === 0 && (
                  <Typography>{t('GENERAL.noImages')}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <Box>
          {isImageViewerOpen && imgData !== '' && (
            <ImageViewer
              isOpen={isImageViewerOpen}
              setIsOpen={setIsImageViewerOpen}
              url={imgData}
            />
          )}
        </Box>
      </Box>
    );
  };
ServiceByServiceClientPage.defaultProps = {
  calendarEvenState: undefined
};
export default ServiceByServiceClientPage;
