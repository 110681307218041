import moment from 'moment';

export interface projects {
  assignmentsPercent: number;
  id: string;
  proposal: {
    id: string;
    lead: {
      address: {
        address: string;
        id: number;
        name: string;
      };
      client: {
        businessName: string;
        firstName: string;
        id: string;
        lastName: string;
        phone: string;
      };
      contact: {
        id: number;
        name: string;
        phoneNumber: string;
      };
      id: string;
      name: string;
      projectEndDate: Date;
      projectStartDate: Date;
      projectType: string;
      status: string;
    };
    status: string;
  };
  status: string;
}

const generateData = (length: number): projects[] => {
  const listadoProjects: projects[] = [];
  for (let i = 0; i < length; i += 1) {
    const data = {
      assignmentsPercent: 100 - i * 5,
      id: `project${i + 1}`,
      proposal: {
        id: `proposal${i + 1}`, // Generar un ID único para cada propuesta
        lead: {
          address: {
            address: `Address ${i + 1}`,
            id: i + 1000, // Generar un ID numérico único
            name: `Address Name ${i + 1}`
          },
          client: {
            businessName: `Business ${i + 1}`,
            firstName: `Client First Name ${i + 1}`,
            id: `client${i + 1}`, // Generar un ID único para cada cliente
            lastName: `Client Last Name ${i + 1}`,
            phone: `123-456-${i + 1000}` // Generar un número de teléfono único
          },
          contact: {
            id: i + 2000, // Generar un ID numérico único para el contacto
            name: `Contact Name ${i + 1}`,
            phoneNumber: `987-654-${i + 2000}` // Generar un número de teléfono único
          },
          id: `lead${i + 1}`, // Generar un ID único para cada líder
          name: `Proyect Name ${i + 1}`,
          projectEndDate: new Date('2024-09-05'), // Usar la fecha actual como fecha de finalización del proyecto
          projectStartDate: new Date('2024-01-05'), // Usar la fecha actual como fecha de inicio del proyecto
          projectType: `Regular`,
          status: `Converted`
        },
        status: `Proposal Status` // Generar un estado único para la propuesta
      },
      status: `Project Status`,
      title: `Proyect Name ${i + 1}` // Generar un estado único para el proyecto
    } as projects;
    listadoProjects.push(data);
  }
  return listadoProjects;
};

const assignmentsGetProjects = async (): Promise<any> => {
  try {
    const response = await new Response();
    return response.json();
  } catch (error) {
    console.error('assignmentsGetProjects', error);
    return [];
  }
};

export default {
  assignmentsGetProjects
};
