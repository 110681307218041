import React, { useEffect, useState } from 'react';
import { Box, Container, useTheme } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HandshakeIcon from '@mui/icons-material/Handshake';
import blueBerryLogoLeters from '../../../../../assets/images/bluberry.png';
import blueBerryLogoMini from '../../../../../assets/images/bluberry_logo_mini.png';

interface Props {
  wantNewProposal: boolean;
}

const RejectedProposal: React.FC<Props> = function RejectedProposal({
  wantNewProposal = false
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            mt: 2,
            width: '100%'
          }}
        >
          <Box>
            <img
              alt="BlueBerry Logo"
              className="logo-image-mini"
              src={blueBerryLogoMini}
            />
            <img
              alt="BlueBerry leters"
              className="logo-image-letters"
              src={blueBerryLogoLeters}
            />
          </Box>
        </Box>
        <Box
          mt={6}
          padding={4}
          sx={{
            border: `4px solid ${theme.palette.grey[300]}`,
            maxWidth: '600px'
          }}
        >
          <Box
            mb={2}
            mt={2}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography variant="title">
              {wantNewProposal
                ? `${t('LEAD.CREATE.PROPOSAL.rejectedWantNewProposalTitle')}`
                : `${t('LEAD.CREATE.PROPOSAL.rejectedProposalTitle')}`}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {wantNewProposal ? (
              <i
                className="custom-icon icon-clock"
                style={{
                  color: theme.palette.grey[400],
                  fontSize: 180
                }}
              />
            ) : (
              <i
                className="custom-icon icon-delete-file"
                style={{
                  color: '#d3455b',
                  fontSize: 180
                }}
              />
            )}
          </Box>
          <Box
            borderColor={theme.palette.grey[300]}
            mt={2}
            sx={{
              alignItems: 'center',
              display: 'block',
              justifyContent: 'center',
              justifyItems: 'center',
              padding: '0 4rem',
              textAlign: 'center'
            }}
          >
            <Typography variant="body_400">
              {wantNewProposal
                ? `${t('LEAD.CREATE.PROPOSAL.rejectedWantNewProposalText')}`
                : `${t('LEAD.CREATE.PROPOSAL.rejectedProposalText')}`}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default RejectedProposal;
