import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps
} from '@mui/material';
import TableComponent from '../shared/TableComponent';
import { ListMainContainer } from '../shared/container/containerParts';
import ViewLoader from '../shared/ViewLoader';
import EmptyState from './EmptyState';

const ServiceButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  marginBottom: '15px'
}));

const ViewBox = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  position: 'absolute',
  top: theme.spacing(12.5)
}));

interface Props {
  buttonComplement?: JSX.Element;
  children?: JSX.Element;
  data: any;
  // eslint-disable-next-line react/require-default-props
  filters?: any;
  fullSize?: boolean;
  handleOpen: () => void;
  handleOpenDraw: () => void;
  headKeys: Array<string>;
  headerOnTop?: boolean;
  hideTableHeader?: boolean;
  isItemLoaded: (index: any) => boolean;
  itemCount: number;
  loadMoreItems: any;
  nameButton: string;
  // eslint-disable-next-line react/require-default-props
  orderByManual?: string;
  // eslint-disable-next-line react/require-default-props
  orderManual?: string;
  redirectRow?: boolean;
  setValueDrawer: React.Dispatch<React.SetStateAction<any>>;
  title?: string;
}

const ListingPage: React.FC<Props> = function ListingPage({
  handleOpen,
  data,
  handleOpenDraw,
  setValueDrawer,
  title,
  children,
  redirectRow,
  headKeys,
  filters,
  isItemLoaded,
  itemCount,
  loadMoreItems,
  orderByManual,
  nameButton,
  orderManual,
  headerOnTop,
  hideTableHeader,
  fullSize,
  buttonComplement
}) {
  const { t } = useTranslation();
  const widthSize = fullSize ? '100%' : '88%';

  const getHeader = () => {
    return (
      <Box
        justifyContent="space-between"
        sx={{
          display: 'flex',
          mt: 2,
          width: headKeys?.length < 4 ? '940px' : widthSize
        }}
      >
        {title && (
          <Box sx={{ mt: '8px', width: '50%' }}>
            <Typography variant="title">{`${t(`MENU.${title}`)}`}</Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          {buttonComplement}
          {t(`GENERAL.${nameButton}`) !== 'Delete' && (
            <ServiceButton
              color={
                t(`GENERAL.${nameButton}`) === 'Delete' ? 'error' : 'primary'
              }
              onClick={handleOpen}
              sx={{ width: '160px' }}
              variant="contained"
            >
              <Typography color="mainText.100" variant="button_200">
                {t(`GENERAL.${nameButton}`)}
              </Typography>
            </ServiceButton>
          )}
          {/* Viewing is necessary? */}
        </Box>
        {false && data && data.length !== 0 ? (
          <ViewBox>
            <Typography
              color="secondary.400"
              fontWeight="bold"
              variant="heading_300"
            >
              Viewing
            </Typography>
            <Typography
              fontWeight="bold"
              variant="heading_300"
            >{` ${data.length}`}</Typography>
          </ViewBox>
        ) : null}
      </Box>
    );
  };

  return (
    <ListMainContainer>
      <>
        {children}
        {headerOnTop && getHeader()}
        {filters ? (
          <Box
            sx={{
              display: 'flex',
              marginTop: '-16px',
              width: headKeys?.length < 4 ? '940px' : widthSize
            }}
          >
            {filters()}
          </Box>
        ) : null}
        {data && data.length !== 0 ? (
          <Box width="100%">
            <TableComponent
              action={setValueDrawer}
              fullSize={fullSize}
              handleOpenDraw={handleOpenDraw}
              headKeys={headKeys}
              hideTableHeader={hideTableHeader}
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              orderByManual={orderByManual}
              orderManual={orderManual}
              redirectRow={redirectRow}
              valuesArray={{ data } as unknown as { data: any[] }}
            />
          </Box>
        ) : null}
        {!data ? <ViewLoader /> : null}
        {data && data.length === 0 && <EmptyState />}
      </>
    </ListMainContainer>
  );
};

ListingPage.defaultProps = {
  buttonComplement: undefined,
  children: <span />,
  fullSize: false,
  headerOnTop: true,
  hideTableHeader: undefined,
  redirectRow: true,
  title: undefined
};

export default ListingPage;
