import React from 'react';
import { Box, SliderProps, styled, Typography } from '@mui/material';

interface BoxProps extends SliderProps {
  isMobile: boolean;
  isPadding?: string;
}

type ButtonBoxProps = {
  isEditActive: boolean;
  isMobile: boolean;
};
interface HeaderContainerProps {
  sticky: boolean;
}

export const GridContainer = styled(Box)<BoxProps>(({ isMobile }) => ({
  display: 'grid',
  gridTemplateColumns: isMobile ? '100%' : '50% 50%',
  rowGap: '4px',
  width: '100%'
}));

export const FlexContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  p: theme.spacing(1),
  rowGap: theme.spacing(1)
}));

export const WhiteBoxContainer = styled(Box)<BoxProps>(
  ({ isMobile, isPadding = '16px 62px' }) => ({
    backgroundColor: 'white',
    padding: isMobile ? '24px' : isPadding,
    width: isMobile ? '80%' : '920px'
  })
);

export const WhiteBoxContainerCard = styled(Box)<BoxProps>(
  ({ isMobile, isPadding = '0px 28px' }) => ({
    backgroundColor: '#FCFCFC',
    margin: 'auto',
    padding: isMobile ? '5px' : isPadding,
    width: isMobile ? '100%' : 'auto'
  })
);

export const HeaderContainer = styled(Box)<HeaderContainerProps>(
  ({ sticky }) => ({
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: `0px 5px 10px -5px ${sticky ? '#CBCCD3' : 'white'}`,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-16px',
    position: 'sticky',
    top: 0,
    transition: 'border 0.3s, box-shadow 0.3s',
    zIndex: 1000
  })
);

export const GridBoxInput = styled(Box)<BoxProps>(({ isMobile }) => ({
  justifySelf: 'center',
  marginTop: '8px',
  paddingX: '0px',
  width: isMobile ? '100%' : '400px'
}));

export const FlexBoxAutoComplete = styled(Box)(() => ({
  marginTop: '-8px'
}));

export const FlexBoxTable = styled(Box)(() => ({
  marginTop: '-8px'
}));

export const HeaderFormContainer = styled(Box)<BoxProps>(({ isMobile }) => ({
  display: isMobile ? 'block' : 'grid',
  flexDirection: 'row',
  gridTemplateColumns: '50% 50%',
  marginBottom: isMobile ? '24px' : '0px',
  width: isMobile ? '100%' : '940px'
}));

export const TitleFormBox = styled(Box)<BoxProps>(({ isMobile }) => ({
  alignItems: 'left',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '8px',
  overflowWrap: 'break-word',
  paddingLeft: isMobile ? '0px' : '40px',
  width: '100%'
}));

export const TitleButtonsBox = styled(Box)<ButtonBoxProps>(
  ({ isMobile, isEditActive }) => ({
    alignSelf: isMobile ? 'center' : 'flex-end',
    display: 'flex',
    justifyContent: isEditActive ? '' : 'flex-end',
    marginBottom: isMobile ? '0px' : '16px',
    marginTop: '16px',
    paddingLeft: isMobile ? '0px' : '8px',
    paddingRight: isMobile ? '0px' : '40px',
    width: '100%'
  })
);

interface formLabelProps {
  error?: boolean;
  label: string;
}

export const FormLabel: React.FC<formLabelProps> = function FormLabel({
  error,
  label
}) {
  return (
    <Box sx={{ display: 'flex', marginBottom: '-12px' }}>
      <Typography color="mainText.500" fontSize="16px" variant="primary-bold">
        {label}
      </Typography>
      <Typography
        color="alert.main"
        sx={{
          display: error ? 'inline' : 'none',
          fontSize: '18px',
          marginLeft: '10px'
        }}
        variant="body_100"
      >
        *
      </Typography>
    </Box>
  );
};

FormLabel.defaultProps = {
  error: false
};
