import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Pubnub from 'pubnub';
import { ChannelEntity } from '@pubnub/react-chat-components';
import { ChatUserData, TChatContext } from './ChatContext';

type ChatHandlerHook = () => TChatContext;

type BearerContents = { companyId: string; userId: string };

const useChatHandler: ChatHandlerHook = function useChatHandler() {
  const [currentChannel, setCurrentChannel] = useState<ChannelEntity>();

  // Construct authorization header using access token from local storage
  const bearerToken = useMemo(
    () => `Bearer ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`, // Remove any potential quotes from access token
    []
  );

  // State to store the chat token
  const [chatData, setChatUserData] = useState<ChatUserData>({
    token: null,
    userId: null
  });

  const generatePubnubInstance = useCallback((userId: string) => {
    const pubnub = new Pubnub({
      publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY as string,
      userId
    });

    pubnub.setToken(chatData.token as string);

    return pubnub;
  }, []);

  const createChat = useCallback(
    (userIds: string[]) =>
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/chats`,
        { userIds: userIds.map((id) => id.split('.')[1]) },
        { headers: { Authorization: bearerToken } }
      ),
    []
  );

  useEffect(() => {
    // Fetch chat token if not already present in state
    if (!chatData.token || !chatData.userId) {
      // API endpoint for fetching chat token
      const API_URL = `${process.env.REACT_APP_BASE_URL}/chats/token`;
      const { companyId, userId }: BearerContents = jwtDecode(bearerToken);

      // Make GET request to fetch chat token
      // TODO: Change this implementation to useSWR with api generate
      axios
        .get(API_URL, { headers: { Authorization: bearerToken } })
        .then(({ data: chatToken }) => {
          // Store received token in session storage and state
          setChatUserData(() => ({
            pubnub: generatePubnubInstance(`${companyId}.${userId}`),
            token: chatToken,
            userId: `${companyId}.${userId}`
          }));
          console.log(chatData);
          sessionStorage.setItem('chatToken', chatToken);
          sessionStorage.setItem('chatUserId', `${companyId}.${userId}`);
        })
        .catch(console.error); // TODO: Implement proper error handling
    }
  }, []); // Empty dependency array to run effect only once

  return {
    ...chatData,
    createChat,
    currentChannel,
    setCurrentChannel
  }; // Return the current chat token
};

export default useChatHandler;
