import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/system';
import { Button, Typography } from '@mui/material';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderRounded';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import ResendProposalConfirmationModal from '../../../../../components/modals/ResendProposalConfirmation';
import Api from '../../../../../api';

interface Props {
  proposalId: string;
}

const ExpiredProposalLink: React.FC<Props> = function Welcome({ proposalId }) {
  const [sentFlag, setSentFlag] = useState<boolean>(false);
  const sendProposalAction = Api.ProposalApi.resendProposal;
  const { data: sendProposalData } = useSWR(
    sentFlag ? { proposalId } : null,
    sendProposalAction
  );
  const [
    showResendProposalConfirtmationModal,
    setShowResendProposalConfirtmationModal
  ] = useState<boolean>(false);
  useEffect(() => {
    /* console.log(
      'end ExpiredProposalLink useEffect sendProposalData',
      sendProposalData
    ); */
  }, [sendProposalData]);
  const { t } = useTranslation();

  return (
    <Box>
      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <QueryBuilderOutlinedIcon
          sx={{ color: '#7D8090', fontSize: '120px', marginBottom: '3rem' }}
        />
        <Typography sx={{ marginBottom: '1rem' }} variant="title">
          <b>{t('LEAD.CREATE.PROPOSAL.proposalExpiredLinkTitle')}</b>
        </Typography>
        <Typography
          align="center"
          color="secondary.400"
          sx={{ marginBottom: '4rem', maxWidth: '32rem' }}
          variant="title"
        >
          {t('LEAD.CREATE.PROPOSAL.proposalExpiredLinkFirstText')}
        </Typography>
        <Button
          fullWidth
          onClick={() => {
            setSentFlag(true);
            setShowResendProposalConfirtmationModal(true);
          }}
          sx={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '1.5rem',
            gap: 2,
            m: 1,
            marginBottom: '2rem',
            maxWidth: '18rem'
          }}
          variant="primary"
        >
          {t('LEAD.CREATE.PROPOSAL.requestNewLink')}
        </Button>
        <Typography
          align="center"
          color="secondary.400"
          sx={{ fontSize: '1.55rem', marginBottom: '4rem', maxWidth: '32rem' }}
          variant="primary-bold"
        >
          {t('LEAD.CREATE.PROPOSAL.proposalExpiredLinkLastText')}
        </Typography>
      </Container>
      {showResendProposalConfirtmationModal && (
        <ResendProposalConfirmationModal
          setShowResendProposalConfirtmationModal={
            setShowResendProposalConfirtmationModal
          }
        />
      )}
    </Box>
  );
};

export default ExpiredProposalLink;
