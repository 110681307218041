import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  useTheme
} from '@mui/material';

interface SidebarProps {
  data: any[];
  setUserIndex: React.Dispatch<React.SetStateAction<any>>;
  userIndex: number;
}

const SidebarReview: React.FC<SidebarProps> = function SidebarReview({
  data,
  setUserIndex,
  userIndex
}) {
  const theme = useTheme();
  return (
    <List
      sx={{
        backgroundColor: 'surface.200'
      }}
    >
      {data.map((user, index) => (
        <ListItem
          key={user.id}
          onClick={() => setUserIndex(index)}
          sx={{
            backgroundColor:
              index === userIndex
                ? theme.palette.secondary[100]
                : 'transparent',
            borderLeft: index === userIndex ? 4 : 0,
            borderLeftColor: theme.palette.primary.main,
            cursor: 'pointer'
          }}
        >
          <ListItemText
            primary={`(${user?.dataList?.length}) ${user.technician}`}
            primaryTypographyProps={{
              noWrap: true,
              position: index === userIndex ? 'sticky' : 'relative'
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarReview;
