import testApi from './template_example';
import authApi from './authentication';
import serviceApi from './service';
import taskApi from './tasks';
import categoryApi from './category';
import skillApi from './skill';
import ConfigApi from './configuration';
import UserInvitationApi from './resources/invitations';
import CompanyInvitationApi from './companies/invitations';
import MaterialApi from './materials';
import CompanyApi from './company';
import MeasureApi from './measure';
import ClientApi from './client';
import ClientPageApi from './clientPage';
import MapsApi from './mapApi';
import leadsApi from './lead';
import StorageApi from './storage';
import SearchApi from './search';
import UserResocesApi from './resources/user';
import UserRequestsApi from './resources/requests';
// import AvailabilityApi from './resources/availability';
import LeadServiceTimesApi from './lead/leadServicesTime';
import ProposalApi from './proposal';
import CalendarApi from './calendar';
import ProjectApi from './projects';
import DashboardApi from './dashboard';
import TravelTimeIterationApi from './travelTimeIteration';
import InvoicesApi from './invoices';
import TimesheetApi from './timesheet';
import AssignmentsApi from './assignments';
import IntegrationsApi from './integration';
import ImpersonateApi from './impersonate';

export default {
  AssignmentsApi,
  CalendarApi,
  // AvailabilityApi,
  ClientApi,
  ClientPageApi,
  CompanyApi,
  CompanyInvitationApi,
  ConfigApi,
  DashboardApi,
  ImpersonateApi,
  InvoicesApi,
  LeadServiceTimesApi,
  MapsApi,
  MaterialApi,
  MeasureApi,
  ProjectApi,
  ProposalApi,
  SearchApi,
  StorageApi,
  TimesheetApi,
  TravelTimeIterationApi,
  IntegrationsApi,
  UserInvitationApi,
  UserRequestsApi,
  UserResocesApi,
  authApi,
  categoryApi,
  leadsApi,
  serviceApi,
  skillApi,
  taskApi,
  testApi
};
