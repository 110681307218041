/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Collapse
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../../../utils/general';
import { decimalsToHours } from '../../../utils/calendarHelper';

interface DataList {
  actualEnd: string;
  actualStart: string;
  actualTotal: string;
  day: string;
  id: number;
  laborHourCost: string;
  laborTotalCost: string;
  scheduledEnd: string;
  scheduledStart: string;
  scheduledTotal: string;
  status: string;
  taskName: string;
  technician: string;
  travelTime: string;
  type: string;
  typeText: string;
  variance: string;
  varianceActual: number;
}

interface Props {
  byDate: boolean;
  data: {
    dataList: DataList[];
    email: string;
    id: string;
    technician: string;
    technicianPaymentTotal: number;
    totalHoursScheduled: number;
    totalHoursWorked: string;
  }[];
  isTechnician?: boolean;
}

const TimesheetReportTable: React.FC<Props> = function TimesheetReportTable({
  data,
  byDate,
  isTechnician = false
}) {
  const { t } = useTranslation();
  const [openRow, setOpenRow] = useState<number | null>(null);

  const taskByDate =
    data
      ?.flatMap((technicianTasks) =>
        technicianTasks?.dataList?.map((task) => ({
          ...task,
          ...technicianTasks
        }))
      )
      .sort((a, b) => {
        return new Date(a.day).getTime() - new Date(b.day).getTime();
      }) || [];

  const toggleRow = (index: number) => {
    if (openRow === index) {
      setOpenRow(null);
      return;
    }
    setOpenRow(index);
  };

  return (
    <div>
      {data.length > 0 && (
        <TableContainer
          component={Paper}
          style={{ marginTop: '20px', overflowX: 'scroll' }}
        >
          {byDate && (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {!isTechnician && (
                    <TableCell align="center">
                      {`${t('TIMESHEET.technician')}`}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {`${t('TIMESHEET.item')}`}
                  </TableCell>
                  <TableCell align="center">
                    {`${t('TIMESHEET.date')}`}
                  </TableCell>
                  <TableCell align="center">
                    {`${t('TIMESHEET.type')}`}
                  </TableCell>
                  <TableCell align="center">
                    {`${t('TIMESHEET.totalTime')}`}
                    <br />
                    {`${t('TIMESHEET.hhmm')}`}
                  </TableCell>
                  <TableCell align="center">
                    {`${t('TIMESHEET.totalCost')}`}
                  </TableCell>
                </TableRow>
              </TableHead>
              {taskByDate.map((item) => (
                <TableBody>
                  <TableRow>
                    {!isTechnician && (
                      <TableCell align="center">{item?.technician}</TableCell>
                    )}
                    <TableCell align="center">{item?.taskName}</TableCell>
                    <TableCell align="center">{item?.day}</TableCell>
                    <TableCell align="center">{item.typeText}</TableCell>
                    <TableCell align="center">
                      {decimalsToHours(item?.actualTotal)}
                    </TableCell>
                    <TableCell align="center">
                      {getCurrency(item?.laborTotalCost)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          )}
          {!byDate &&
            data.map((item, index) => (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <Typography my="20px" paddingLeft="18px" variant="h6">
                      {`Technician: ${item?.technician}`}
                    </Typography>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {`${t('TIMESHEET.item')}`}
                    </TableCell>
                    <TableCell align="center">
                      {`${t('TIMESHEET.date')}`}
                    </TableCell>
                    <TableCell align="center">
                      {`${t('TIMESHEET.type')}`}
                    </TableCell>
                    <TableCell align="center">
                      {`${t('TIMESHEET.totalTime')}`}
                      <br />
                      {`${t('TIMESHEET.hhmm')}`}
                    </TableCell>
                    <TableCell align="center">
                      {`${t('TIMESHEET.totalCost')}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.dataList.map((val) => (
                    <TableRow>
                      <TableCell align="center">{val?.taskName}</TableCell>
                      <TableCell align="center">{val?.day}</TableCell>
                      <TableCell align="center">{val.typeText}</TableCell>
                      <TableCell align="center">
                        {decimalsToHours(val?.actualTotal)}
                      </TableCell>
                      <TableCell align="center">
                        {getCurrency(val?.laborTotalCost)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="center">
                      <Typography fontSize={16} variant="h6">
                        {`${t('TIMESHEET.totalHours')}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontSize={16} variant="h6">
                        {`${t('TIMESHEET.totalCost')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="center">
                      {decimalsToHours(item?.totalHoursWorked || 0)}
                    </TableCell>
                    <TableCell align="center">
                      {getCurrency(
                        item?.technicianPaymentTotal?.toFixed(2) || 0
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
        </TableContainer>
      )}
    </div>
  );
};

export default TimesheetReportTable;
