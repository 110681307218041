import React from 'react';
import { Box } from '@mui/material';

type Props = {
  children: JSX.Element;
};

const Scroll: React.FC<Props> = function Scroll({ children }) {
  return (
    <Box className="scroll-shadows scrollbar" sx={{ height: '100%' }}>
      <Box>{children}</Box>
    </Box>
  );
};

export default Scroll;
