import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  styled,
  SlideProps,
  Typography,
  Box,
  Button,
  useTheme,
  Chip,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import {
  capitalizeString,
  getComparator,
  getCurrency,
  regularInputType,
  stableSort,
  statusStyle
} from '../../utils/general';
import { Order } from '../../types';
import ViewLoader from './ViewLoader';
import TimeComponent from '../input/TimeComponent';
import RegularInput from '../input/RegularInput';
import AutoCompleteResource from '../input/AutoCompleteResource';
import TableComponent from './TableComponent';
import useNavigationStore from '../../store/zustand/navigation';
import Api from '../../api';
import {
  calculateMaterialCostFromTask,
  getTaskCosts
} from '../../utils/calculatorHelper';

const ServiceButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  marginBottom: '15px'
}));

const HeadColumn = styled(TableCell)<SlideProps>(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold'
}));

const RowElement = styled(TableCell)<SlideProps>(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.secondary[400],
  fontSize: '14px',
  fontWeight: '400',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

const RowContainer = styled(TableRow)<SlideProps>(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.mainText?.[100]
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#FCFCFC'
  },
  alignItems: 'center',
  borderTop: '1px solid #DDDDDD',
  cursor: 'pointer',
  display: 'flex',
  height: '56px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

const noHeaderColumns = [
  'delete',
  'resend',
  'edit',
  'calculator',
  'resent',
  'recall',
  'showDetail',
  'seen',
  'mailIcon',
  'phoneIcon',
  'impersonate',
  'review'
];

const inTableButtons = [
  'delete',
  'resend',
  'edit',
  'calculator',
  'resent',
  'recall',
  'showDetail',
  'seen',
  'admins',
  'resourcesQuantity',
  'statusChip',
  'mailIcon',
  'phoneIcon',
  'impersonate'
];

const shortInTableTextButtons = [
  'recall',
  'status',
  'edit',
  'resent',
  'showDetail',
  'statusChip',
  'review'
];

const getTableWith = (columns: number, index: number) => {
  if (columns <= 4 && index === 0) {
    return '60%';
  }
  if (columns <= 4) {
    return '40%';
  }
  return '100%';
};

const getTableWithButton = (buttonString: string, columns: number) => {
  if (shortInTableTextButtons.includes(buttonString)) {
    return '30%';
  }
  if (buttonString === 'admins' || buttonString === 'resourcesQuantity') {
    return '20%';
  }
  if (buttonString === 'delete' && columns >= 5) {
    return '25%';
  }
  return '10%';
};

interface EnhancedTableProps {
  headValues: Array<string>;
  // eslint-disable-next-line no-unused-vars
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  t: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headValues, t } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        '.MuiTableHead-root': { boxShadow: 0 },
        backgroundColor: '#F5F5F5'
      }}
    >
      <TableRow
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '56px'
        }}
      >
        {headValues.map((headCell: string, index: number) => (
          <HeadColumn
            key={`${headCell}-${index + 1}`}
            sortDirection={orderBy === headCell ? order : false}
            sx={{ justifyContent: 'left' }}
            width={
              inTableButtons.includes(headCell)
                ? getTableWithButton(headCell, headValues.length)
                : getTableWith(headValues.length, index)
            }
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
              sx={{
                '.MuiSvgIcon-root': { display: 'none' },
                color: 'mainText.500',
                fontSize: '16px'
              }}
            >
              {!noHeaderColumns.includes(headCell) && (
                <>{capitalizeString(`${t(`LISTING_PAGES.${headCell}`)}`)}</>
              )}
            </TableSortLabel>
          </HeadColumn>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface rowprops {
  action: any;
  alignCenterFields: Array<string>;
  alignRightFields: Array<string>;
  editableFields?: Array<string>;
  handleOpenDraw?: any;
  headKeys: Array<string>;
  index: number;
  // eslint-disable-next-line react/require-default-props
  indexST?: number;
  manualPricing?: boolean;
  onChange: (value: any, key: string, index: number) => void;
  order: Order;
  orderBy: string;
  rows: any;
  style: any;
  successRows: Array<number>;
  warningRows: Array<number>;
}

const RowElementComponent: React.FC<rowprops> = function RowElementComponent({
  index,
  indexST,
  handleOpenDraw,
  headKeys,
  action,
  style,
  rows,
  manualPricing,
  order,
  orderBy,
  onChange,
  editableFields,
  alignRightFields,
  alignCenterFields,
  warningRows,
  successRows
}) {
  const row: any = stableSort(rows, getComparator(order, orderBy));
  const initItem = row[index];
  const [item, setItem] = useState<any>(initItem);
  // const item = row[index];
  const indexRow = index;
  const { setConfirmModal } = useNavigationStore((state) => state);
  const { t } = useTranslation();
  const theme = useTheme();
  const [skillIds, setSkillIds] = useState<any>([]);
  const [getAllSkills, setGetAllSkills] = useState<boolean>(false);
  const [getResource, setGetResource] = useState<boolean>(false);
  const [fakeResources, setFakeResources] = useState<any>([]);
  const [mediaRateBySkills, setMediaRateBySkills] = useState<number>(0);

  const {
    data: skillResponse,
    error: skillError,
    mutate: mutateSkill
  } = useSWR(
    getAllSkills ? 'api/getSkills' : null,
    getAllSkills ? Api.skillApi.getAllSkills : null
  );

  useEffect(() => {
    let auxSkillIds: any[] = [];
    if (manualPricing) {
      setSkillIds([]);
      setGetAllSkills(true);
    } else {
      rows.forEach((task: any) => {
        if (task?.skills && Array.isArray(task.skills) && task?.skills[0]) {
          const taskSkillIds = task.skills.map((skill: any) => {
            return skill?.id || skill;
          });
          auxSkillIds =
            auxSkillIds?.length > 0
              ? [...auxSkillIds, ...taskSkillIds]
              : taskSkillIds || [];
        }
      });

      setSkillIds(auxSkillIds);
      setGetResource(true);
    }
  }, [rows]);

  useEffect(() => {
    if (skillResponse && !skillError) {
      setGetAllSkills(false);
      const auxSkillIds = skillResponse
        .filter((auxSkill) => auxSkill.name !== 'Supervisor')
        .map((skill) => {
          return skill.id;
        });
      setSkillIds(auxSkillIds);
      setGetResource(true);
    }
  }, [skillResponse]);

  useEffect(() => {
    if (skillIds && skillIds.length > 0) {
      setGetResource(true);
    }
  }, [skillIds]);

  const { data: resources } = useSWR(
    getResource && skillIds && skillIds.length > 0 ? { skillIds } : null,
    Api.UserResocesApi.getSkillResource
  );

  useEffect(() => {
    if (resources && resources.length > 0) {
      setGetResource(false);

      if (initItem?.mediaRate && Number(`${initItem?.mediaRate}`) > 0) {
        setMediaRateBySkills(Number(`${initItem?.mediaRate}`));
      } else {
        setMediaRateBySkills(Api.UserResocesApi.getMediaRate(resources));
      }
    }
  }, [resources]);

  useEffect(() => {
    if (mediaRateBySkills) {
      setItem({ ...item, mediaRate: mediaRateBySkills });
      // item.mediaRate = mediaRateBySkills;
    }
  }, [mediaRateBySkills]);

  useEffect(() => {
    if (initItem) {
      setItem(initItem);
    }
  }, [initItem]);

  const getFakeResources = () => {
    // const Api.UserResocesApi.createFakeResources(mediaRateBySkills, fakeResources.length)
    // onChange(value, 'resources', index)
  };

  const getElement = (
    row: any,
    key: string,
    index: number,
    onChange: (value: any, key: string, index: number) => void
  ) => {
    // const { t } = useTranslation();
    if (false) {
      return (
        <TimeComponent
          setTime={(v) => {
            onChange(v, 'time', index);
            if (false) {
              onChange(v, 'time', index);
            }
          }}
          time={row.time}
        />
      );
    }
    switch (key) {
      case 'time': {
        return (
          <TimeComponent
            setTime={(v) => {
              onChange(v, 'time', index);
            }}
            time={row[key]?.name ? row[key].name : row[key]}
          />
        );
      }
      case 'quantity': {
        const measure = row.measure?.name ? row.measure.name : row.measure;
        return (
          <Box alignItems="center" display="flex" justifyContent="start">
            <RegularInput
              customStyle={{ marginRight: 2, width: 80 }}
              onChange={(value) => onChange(value, key, index)}
              type="number"
              value={row.quantity}
            />
            <Typography>{measure}</Typography>
          </Box>
        );
      }
      case 'review': {
        return (
          <Box>
            <Button onClick={() => onChange(row, key, index)} variant="text">
              <i className="custom-icon icon-eye" />
            </Button>
          </Box>
        );
      }
      case 'totalTime': {
        const quantity = row.quantity || 0;
        const measurePerTime = parseFloat(row.measurePerHour);
        const measure = row?.measure?.name ? row?.measure?.name : row?.measure;
        const time =
          measure !== 'Unit'
            ? quantity / measurePerTime
            : quantity * measurePerTime;
        return (
          <Typography>
            <TimeComponent readOnly setTime={() => {}} time={time} />
          </Typography>
        );
      }
      case 'assigned': {
        return (
          /* <AutoCompleteResource
            disabled={row?.readOnly}
            indexST={indexST}
            minimumResources={row?.minimumResources || 0}
            optionsList={resources || []}
            setValues={(value) => {
              onChange(value, 'resources', index);
            }}
            type="resources"
            values={
              undefined !== row?.resources && row?.resources?.length > 0
                ? row
                : resources || []
            } 
            width={300}
          /> */
          <RegularInput
            customStyle={{ fontSize: '15px', width: '6rem' }}
            onChange={(event: any) => {
              onChange(
                Api.UserResocesApi.createFakeResources(
                  mediaRateBySkills.toString(),
                  event
                ),
                'resources',
                index
              );
            }}
            type="number"
            value={row?.resources?.length || '0'}
          />
        );
      }
      case 'task': {
        return <Typography>{row?.name ? row.name : 'Task name'}</Typography>;
      }
      case 'minRequired': {
        return (
          <Typography>
            {row?.minimumResources ? row.minimumResources.toString() : ''}
          </Typography>
        );
      }
      // Agregar los campos task === name, minRequired === minimumResources, assigned === resources
      case 'summaryCost':
        return editableFields?.includes(key) || index === 0 ? (
          <RegularInput
            align="right"
            customStyle={{
              backgroundColor: 'transparent !important',
              border: 1,
              borderColor: theme.palette.grey[500],
              width: '100%'
            }}
            float={regularInputType[key] === 'number'}
            onChange={(value) => {
              onChange(value, key, index);
            }}
            type={regularInputType[key]}
            value={row[key]?.name ? row[key].name : row[key]}
          />
        ) : (
          <Typography>{row[key]?.name ? row[key].name : row[key]}</Typography>
        );
      case 'subTotal':
        return editableFields?.includes(key) ? (
          <Box>
            $
            <RegularInput
              float
              customStyle={{
                backgroundColor: 'transparent !important',
                width: '100%'
              }}
              onChange={(value) => {
                onChange(value, key, index);
              }}
              type={regularInputType[key]}
              value={row[key]?.name ? row[key].name : row[key]}
            />
          </Box>
        ) : (
          <Typography>${row[key]?.name ? row[key].name : row[key]}</Typography>
        );
      case 'detail':
      case 'misc':
      case 'total':
      case 'name':
      case 'taxableItems':
      case 'salesTax':
        return editableFields?.includes(key) ? (
          <RegularInput
            customStyle={{
              backgroundColor: 'transparent !important',
              width: '100%'
            }}
            onChange={(value) => {
              onChange(value, key, index);
            }}
            type={regularInputType[key]}
            value={row[key]?.name ? row[key].name : row[key]}
          />
        ) : (
          <Typography>{row[key]?.name ? row[key].name : row[key]}</Typography>
        );

      case 'calculator': {
        if (row.taskCosts?.data && row.taskCosts.data.length > 0) {
          return (
            <Button
              onClick={() => {
                setConfirmModal(
                  () => {
                    console.log('');
                  },
                  `${t('GENERAL.backButton')}`,
                  () => {},
                  undefined,
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    pt={5}
                    width={1300}
                  >
                    <Box mb={4}>
                      <Typography variant="title">
                        {t('LEAD.CREATE.COST_CALCULATOR.taskModalTitle', [
                          row.name
                        ])}
                      </Typography>
                    </Box>
                    <TableComponent
                      action={() => {}}
                      headKeys={[
                        'resource',
                        'rate',
                        'hours',
                        'laborCost',
                        'payrollTax',
                        'other',
                        'total'
                      ]}
                      isItemLoaded={(index: any) => {
                        return !!index;
                      }}
                      itemCount={1}
                      loadMoreItems={() => {}}
                      valuesArray={row.taskCosts}
                    />
                  </Box>
                );
              }}
            >
              <i className="custom-icon icon-calculator" />
            </Button>
          );
        }
        if (row?.length > 0) {
          const headKeys =
            row?.task?.measure?.name && row.task.measure.name === 'Unit'
              ? ['item', 'priceUnit', 'quantityUnits', 'totalCostMaterial']
              : [
                  'item',
                  'priceSquareFeet',
                  'quantitySquareFeet',
                  'totalCostMaterial'
                ];
          return (
            <Button
              onClick={() => {
                setConfirmModal(
                  () => {
                    console.log('levanto modal 1');
                  },
                  `${t('GENERAL.backButton')}`,
                  () => {},
                  undefined,
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    pt={5}
                    width={1000}
                  >
                    <Box mb={4}>
                      <Typography variant="title">
                        {t('LEAD.CREATE.COST_CALCULATOR.materialModalTitle', [
                          row.task.name
                        ])}
                      </Typography>
                    </Box>
                    <TableComponent
                      action={() => {}}
                      headKeys={headKeys}
                      isItemLoaded={(index: any) => {
                        return !!index;
                      }}
                      itemCount={1}
                      loadMoreItems={() => {}}
                      valuesArray={{ data: [row] }}
                    />
                  </Box>
                );
              }}
            >
              <i className="custom-icon icon-calculator" />
            </Button>
          );
        }
        return false;
      }
      case 'cost':
        return `${getCurrency(row[key])}`;
      case 'materialsFromTasks':
      case 'materialsAndSupplies':
        return row.name;
      case 'costMaterial':
        return (
          <Box>
            {`$ `}
            <RegularInput
              float
              customStyle={{ width: 100 }}
              onChange={(value) => onChange(value, key, index)}
              placeholder="0"
              type="number"
              value={row.cost || 0}
            />
          </Box>
        );
      case 'totalCostMaterial':
        return `${getCurrency(calculateMaterialCostFromTask(row))}`;
      case 'totalCost': {
        const data = getTaskCosts(row, t);
        return (
          <Box>
            {`${getCurrency(
              parseFloat(data.data[data.data.length - 1].total)
            )}`}
          </Box>
        );
      }
      case 'statusChip': {
        if (!row.status) {
          return '';
        }
        const toReturn = row.status;
        return (
          <Stack>
            <Chip
              label={
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  <i className={`custom-icon icon-${toReturn}`} />
                  <Typography variant="button_100">{toReturn}</Typography>
                </Box>
              }
              style={statusStyle(toReturn)}
            />
          </Stack>
        );
      }
      case 'resourcesQuantity': {
        return (
          <Box alignItems="center" display="flex" justifyContent="center">
            {row[key]}
          </Box>
        );
      }
      default:
        return row[key]?.name ? row[key].name : row[key];
    }
  };

  const getAlign = (key: string) => {
    if (alignRightFields.includes(key)) {
      return 'right';
    }
    if (alignCenterFields.includes(key)) {
      return 'center';
    }
    return 'left';
  };

  const getRowColor = () => {
    if (successRows.includes(index)) {
      return `${theme.palette.success[100]} !important`;
    }
    if (warningRows.includes(index)) {
      return `${theme.palette.warning[100]} !important`;
    }
    return undefined;
  };

  return row[index] ? (
    <RowContainer
      hover
      key={`${item.id}`}
      onClick={() => action(item)}
      style={style}
      sx={{
        backgroundColor: getRowColor(),
        height: headKeys.includes('assigned') ? 'auto !important' : 'normal',
        textOverflow: inTableButtons.includes(item) ? 'unset' : 'ellipsis'
      }}
    >
      <>
        {headKeys.map((headKey: string, index: number) => (
          <RowElement
            align={getAlign(headKey)}
            key={`${index + 1}-${headKey}${item[headKey]}-${item.id}`}
            onClick={
              !inTableButtons.includes(headKey) ? handleOpenDraw : () => {}
            }
            sx={{
              textOverflow: inTableButtons.includes(headKey)
                ? 'unset'
                : 'ellipsis'
            }}
            width={
              inTableButtons.includes(headKey)
                ? getTableWithButton(headKey, headKeys.length)
                : getTableWith(headKeys.length, index)
            }
          >
            <Typography variant="body_200">
              {getElement(item, headKey, indexRow, onChange)}
            </Typography>
          </RowElement>
        ))}
        <br />
      </>
    </RowContainer>
  ) : null;
};

RowElementComponent.defaultProps = {
  editableFields: [],
  handleOpenDraw: undefined,
  manualPricing: false
};

type props = {
  action: React.Dispatch<React.SetStateAction<string>>;
  addType?: string;
  alignCenterFields?: Array<string>;
  alignRightFields?: Array<string>;
  disableAdd?: boolean;
  editableFields?: Array<string>;
  // eslint-disable-next-line react/require-default-props
  handleOpenDraw?: any;
  headKeys: Array<string>;
  hideTableHeader?: boolean;
  indexST?: number;
  manualPricing?: boolean;
  onChange: (value: any, key: string, index: number) => void;
  successRows?: Array<number>;
  valuesArray: { data: any[] };
  warningRows?: Array<number>;
};

const TableComponentEditable: React.FC<props> =
  function TableComponentEditable({
    valuesArray,
    action,
    headKeys,
    handleOpenDraw = () => {},
    hideTableHeader,
    editableFields,
    indexST,
    onChange,
    manualPricing,
    disableAdd,
    addType,
    alignRightFields,
    alignCenterFields,
    warningRows,
    successRows
  }) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('');
    const rows = valuesArray.data;

    const { t } = useTranslation();

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: string
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    return valuesArray !== undefined ? (
      <>
        <TableContainer
          className="scrollbar"
          sx={{
            border: '1px solid #DDDDDD'
          }}
        >
          <Table>
            {!hideTableHeader && (
              <EnhancedTableHead
                headValues={headKeys}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                t={t}
              />
            )}
            <TableBody sx={{ backgroundColor: 'white' }}>
              {rows &&
                rows.length > 0 &&
                rows.map((row: any, index: number) => {
                  return (
                    <RowElementComponent
                      action={action}
                      alignCenterFields={alignCenterFields || []}
                      alignRightFields={alignRightFields || []}
                      editableFields={editableFields}
                      handleOpenDraw={() => {}}
                      headKeys={headKeys}
                      index={index}
                      indexST={indexST}
                      manualPricing={manualPricing}
                      onChange={onChange}
                      order={order}
                      orderBy={orderBy}
                      rows={rows}
                      style={{}}
                      successRows={successRows || []}
                      warningRows={warningRows || []}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!disableAdd && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pt: 2,
              width: '100%'
            }}
          >
            <ServiceButton
              onClick={() => {
                handleOpenDraw();
              }}
              sx={{ width: '160px' }}
              variant="contained"
            >
              <Typography color="mainText.100" variant="button_200">
                {t(`GENERAL.${addType}`)}
              </Typography>
            </ServiceButton>
          </Box>
        )}
      </>
    ) : (
      <ViewLoader />
    );
  };

TableComponentEditable.defaultProps = {
  addType: 'create',
  alignCenterFields: [],
  alignRightFields: [],
  disableAdd: false,
  editableFields: [],
  handleOpenDraw: undefined,
  hideTableHeader: undefined,
  indexST: undefined,
  manualPricing: false,
  successRows: [],
  warningRows: []
};

export default TableComponentEditable;
