import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import React, { useState } from 'react';
import useSWR from 'swr';
import CompanyInformation from '../../components/companySettings/CompanyInformation';
import CompanySettings from '../../components/companySettings/CompanySettings';
import CompanyFinancialIntegration from '../../components/companySettings/CompanyFinancialIntegration';
import api from '../../api';
import ViewLoader from '../../components/shared/ViewLoader';
import { FormMainContainer } from '../../components/shared/container/containerParts';
import QuickbooksIntegrationPage from './quickbooks/QuickbooksIntegrationPage';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      // eslint-disable-next-line
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '24px 24px 24px 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CompanyIntegrationPage() {
  const { state: userInfo } = useLocation();
  console.log(userInfo);
  
  // const [value, setValue] = useState(0);

  // const { data: currentCompany } = useSWR(
  //   'api/currentCompany',
  //   api.CompanyApi.getCurrentCompany
  // );

  // if (!currentCompany) {
  //   return <ViewLoader />;
  // }

  return (
    <Box>
       <QuickbooksIntegrationPage />
     </Box>
  );
}

TabPanel.defaultProps = {
  children: null
};

export default CompanyIntegrationPage;
