import { MeasureApi } from '../api-client/generated/api';

const apiCaller = new MeasureApi();

const getMeasure = async () => {
  const { data } = await apiCaller.measureFindAll();
  return data as unknown as Array<any>;
};

export default {
  getMeasure
};
