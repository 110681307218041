import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { width } from '@mui/system';
import useSWR from 'swr';
import welcomeImage from '../../assets/images/onboarding/welcome.png';
import personalInfoImage from '../../assets/images/onboarding/personalInfo.png';
import companyInfoImage from '../../assets/images/onboarding/companyInfo.png';
import serviceInfoImage from '../../assets/images/onboarding/serviceInfo.png';
import billingInfoImage from '../../assets/images/onboarding/billingInfo.png';
import contactInfoImage from '../../assets/images/onboarding/contactInfo.png';
import summaryImage from '../../assets/images/onboarding/summary.png';
import errorImage from '../../assets/images/onboarding/error.png';
import finishImage from '../../assets/images/onboarding/finish.png';
import {
  FormLabel,
  GridBoxInput,
  GridContainer
} from '../../components/form/parts';
import RegularInput from '../../components/input/RegularInput';
import AutoCompletePlaces from '../../components/input/AutoCompletePlaces';
import { getLocationTimeZone } from '../../utils/general';
import SortableTable from '../../components/list/sortableTable';
import api from '../../api';
import { setLocalStorage } from '../../utils/authHelper';
import useAuthStore from '../../store/zustand/auth';

const steps = [
  { image: welcomeImage, sizeHeader: 350, title: 'Welcome' },
  { image: personalInfoImage, sizeHeader: 250, title: 'Personal Info' },
  { image: companyInfoImage, sizeHeader: 250, title: 'Company Info' },
  { image: billingInfoImage, sizeHeader: 250, title: 'Billing Info' },
  { image: serviceInfoImage, sizeHeader: 250, title: 'Services Addresses' },
  { image: contactInfoImage, sizeHeader: 250, title: 'Contact' },
  { image: summaryImage, sizeHeader: 50, title: 'Summary' },
  { image: finishImage, sizeHeader: 350, title: 'Congratulations' }
];

const OnboardingClient: FC = function OnboardingClient() {
  const { t } = useTranslation();
  const { state: params } = useLocation();
  const navigate = useNavigate();
  const userState = useAuthStore((state) => state);
  const [fireOnboarding, setFireOnboarding] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false); // no estoy segura de que hace esto, revisar
  const [addressDeleteId, setAddressDeleteId] = useState<string>('');
  const [contactDeleteId, setContactDeleteId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [getProfile, setGetProfile] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<any>({
    addresses: [],
    billingAddress: '',
    billingEmail: [],
    businessName: '',
    contacts: [],
    email: '',
    firstName: '',
    jobTitle: '',
    lastName: '',
    notes: '',
    phone: '',
    timeZone: '',
    workOrderEmails: []
  });

  const { data, error } = useSWR(
    fireOnboarding ? { ...formValue } : null,
    api.ClientPageApi.onboardingClient
  );

  const { data: profile, mutate } = useSWR(
    getProfile ? '/clients-portal/information' : null,
    api.ClientPageApi.getClientInformation
  );

  useEffect(() => {
    if (params) {
      setFormValue({
        addresses: params.addresses,
        billingAddress: params.billingAddress,
        billingEmail: params.billingEmail,
        businessName: params.businessName,
        contacts: params.contacts,
        email: params.email,
        firstName: params.firstName,
        jobTitle: params.jobTitle,
        lastName: params.lastName,
        notes: params.notes,
        phone: params.phone,
        timeZone: params.timeZone,
        workOrderEmails: params.workOrderEmails
      });
    }
  }, []);

  useEffect(() => {
    if (data) {
      setFireOnboarding(false);
      setIsLoading(false);
      setIsError(false);
      setActiveStep(7);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setFireOnboarding(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(error.response.data.errors.map((data: string) => data));
      setActiveStep(7);
    }
  }, [error]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSave = () => {
    setFireOnboarding(true);
    setIsLoading(true);
  };

  const handleFinish = () => {
    setGetProfile(true);
  };

  useEffect(() => {
    if (profile) {
      setGetProfile(false);
      if (profile.onboardingComplete) {
        const { email, firstName, lastName, phone, id } = profile;
        userState.setUser({
          active: true,
          block: false,
          email,
          firstName,
          id,
          lastName,
          mfa: false,
          phoneNumber: phone,
          roles: ['Client']
        });
        userState.login();
        navigate('/client-page/landing');
      } else {
        navigate('/client-page/onboarding', { state: profile });
      }
    }
  }, [profile]);

  const onChange = (name: string, value: string | any[]) => {
    setFormValue({
      ...formValue,
      [name]: value
    });
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '96%',
          justifyContent: 'space-between',
          width: '80%'
        }}
      >
        <CardContent>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label.title}>
                <StepLabel />
              </Step>
            ))}
          </Stepper>
          {!isLoading ? (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
              sx={{ mb: 2, mt: 2 }}
              width="100%"
            >
              <Typography
                gutterBottom
                align="center"
                marginTop={4}
                variant="h5"
              >
                {isError && activeStep === 7
                  ? 'Error'
                  : steps[activeStep].title}
              </Typography>
              <img
                alt="Welcome Onboarding"
                src={
                  isError && activeStep === 7
                    ? errorImage
                    : steps[activeStep].image
                }
                style={{
                  height: steps[activeStep].sizeHeader,
                  width: steps[activeStep].sizeHeader
                }}
              />
              {/* welcome */}
              {activeStep === 0 && (
                <Typography gutterBottom align="center" variant="h5">
                  {`${t('CLIENT_PORTAL.ONBOARDING.welcome')}`}
                </Typography>
              )}
              {/* personal info */}
              {activeStep === 1 && (
                <GridContainer
                  isMobile={isMobile}
                  sx={{
                    gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                    width: '100%'
                  }}
                >
                  <GridBoxInput
                    isMobile={isMobile}
                    key="firstName"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="First Name *" />
                    <RegularInput
                      customStyle={{
                        width: '100%'
                      }}
                      error={errorFields.includes('firstName')}
                      name="firstName"
                      onChange={(inputValue) =>
                        onChange('firstName', inputValue)
                      }
                      type="text"
                      value={formValue.firstName}
                    />
                  </GridBoxInput>
                  <GridBoxInput
                    isMobile={isMobile}
                    key="lastName"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="Last Name *" />
                    <RegularInput
                      customStyle={{
                        width: '100%'
                      }}
                      error={errorFields.includes('lastName')}
                      name="lastName"
                      onChange={(inputValue) =>
                        onChange('lastName', inputValue)
                      }
                      type="text"
                      value={formValue.lastName}
                    />
                  </GridBoxInput>
                </GridContainer>
              )}
              {/* company info */}
              {activeStep === 2 && (
                <GridContainer
                  isMobile={isMobile}
                  sx={{
                    gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                    width: '100%'
                  }}
                >
                  <GridBoxInput
                    isMobile={isMobile}
                    key="email"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="Email address *" />
                    <RegularInput
                      customStyle={{
                        width: '100%'
                      }}
                      error={errorFields.includes('email')}
                      name="email"
                      onChange={(inputValue) => onChange('email', inputValue)}
                      type="text"
                      value={formValue.email}
                    />
                  </GridBoxInput>
                  <GridBoxInput
                    isMobile={isMobile}
                    key="phone"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="Phone number *" />
                    <RegularInput
                      customStyle={{
                        width: '100%'
                      }}
                      error={errorFields.includes('phone')}
                      name="phone"
                      onChange={(inputValue) => onChange('phone', inputValue)}
                      type="phone"
                      value={formValue.phone}
                    />
                  </GridBoxInput>
                  <GridBoxInput
                    isMobile={isMobile}
                    key="businessName"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="Company Name *" />
                    <RegularInput
                      customStyle={{
                        width: '100%'
                      }}
                      error={errorFields.includes('businessName')}
                      name="businessName"
                      onChange={(inputValue) =>
                        onChange('businessName', inputValue)
                      }
                      type="text"
                      value={formValue.businessName}
                    />
                  </GridBoxInput>
                  <GridBoxInput
                    isMobile={isMobile}
                    key="jobTitle"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="Job Title *" />
                    <RegularInput
                      customStyle={{
                        width: '100%'
                      }}
                      error={errorFields.includes('jobTitle')}
                      name="jobTitle"
                      onChange={(inputValue) =>
                        onChange('jobTitle', inputValue)
                      }
                      type="text"
                      value={formValue.jobTitle}
                    />
                  </GridBoxInput>
                </GridContainer>
              )}
              {/* billing info */}
              {activeStep === 3 && (
                <GridContainer
                  isMobile={isMobile}
                  sx={{
                    gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                    maxHeight: '180px',
                    overflowY: 'scroll',
                    width: '100%'
                  }}
                >
                  <GridBoxInput
                    isMobile={isMobile}
                    key="address"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label="Billing Address *" />
                    <AutoCompletePlaces
                      error={errorFields.includes('billingAddress')}
                      keyName="billingAddress"
                      onChange={(values: any) => {
                        onChange(values.keyName, values.value);
                      }}
                      setSelected={setSelectedAddress}
                      value={formValue.billingAddress}
                    />
                  </GridBoxInput>
                  <GridBoxInput
                    isMobile={isMobile}
                    key="address"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel label={`${t('CLIENT.billingEmail')}*`} />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        marginTop: '16px',
                        width: '100%'
                      }}
                    >
                      <SortableTable
                        addManagement
                        disableSort
                        bodyKeys={['email']}
                        name="billingEmail"
                        onChange={(newEmail: any) => {
                          setFormValue({
                            ...formValue,
                            billingEmail: [...newEmail]
                          });
                        }}
                        type="input"
                        values={formValue.billingEmail}
                      />
                    </Box>
                  </GridBoxInput>
                </GridContainer>
              )}
              {/* services info */}
              {activeStep === 4 && (
                <GridContainer
                  isMobile={isMobile}
                  sx={{
                    gridTemplateColumns: isMobile ? '100%' : '1fr',
                    maxHeight: '180px',
                    overflowY: 'scroll',
                    width: '100%'
                  }}
                >
                  <GridBoxInput
                    isMobile={isMobile}
                    key="address"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel
                      error={errorFields.includes('placeValue')}
                      label={t('LEAD.CREATE.GENERAL_INFORMATION.address')}
                    />
                    <Box sx={{ display: 'flex', marginTop: '4px' }}>
                      <SortableTable
                        addManagement
                        disableSort
                        isAddress
                        bodyKeys={['name', 'phone', 'address']}
                        handleDelete={setAddressDeleteId}
                        name="addresses"
                        onChange={(newAddresses: any) => {
                          setFormValue({
                            ...formValue,
                            addresses: newAddresses
                          });
                        }}
                        selectedAddress={selectedAddress}
                        type="addresses"
                        values={formValue.addresses}
                      />
                    </Box>
                  </GridBoxInput>
                </GridContainer>
              )}
              {/* contact info */}
              {activeStep === 5 && (
                <GridContainer
                  isMobile={isMobile}
                  sx={{
                    gridTemplateColumns: isMobile ? '100%' : '1fr',
                    maxHeight: '180px',
                    overflowY: 'scroll',
                    width: '100%'
                  }}
                >
                  <GridBoxInput
                    isMobile={isMobile}
                    key="address"
                    sx={{ width: isMobile ? '100%' : '80%' }}
                  >
                    <FormLabel
                      error={errorFields.includes('contacts')}
                      label={t('CLIENT.contactsLabel')}
                    />
                    <Box mt={2}>
                      <SortableTable
                        addManagement
                        disableSort
                        bodyKeys={['name', 'phoneNumber']}
                        handleDelete={setContactDeleteId}
                        name="contacts"
                        onChange={(newContactList: any) => {
                          setFormValue({
                            ...formValue,
                            contacts: newContactList
                          });
                        }}
                        type="generic-input"
                        values={formValue.contacts}
                      />
                    </Box>
                  </GridBoxInput>
                </GridContainer>
              )}
              {/* Summary */}
              {activeStep === 6 && (
                <Box
                  sx={{
                    maxHeight: '400px',
                    overflowY: 'scroll'
                  }}
                >
                  <GridContainer
                    isMobile={isMobile}
                    sx={{
                      gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                      width: '100%'
                    }}
                  >
                    <GridBoxInput
                      isMobile={isMobile}
                      key="firstName"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="First Name *" />
                      <RegularInput
                        disabled
                        customStyle={{
                          width: '100%'
                        }}
                        error={errorFields.includes('firstName')}
                        name="firstName"
                        onChange={(inputValue) =>
                          onChange('firstName', inputValue)
                        }
                        type="text"
                        value={formValue.firstName}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="lastName"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="Last Name *" />
                      <RegularInput
                        disabled
                        customStyle={{
                          width: '100%'
                        }}
                        error={errorFields.includes('lastName')}
                        name="lastName"
                        onChange={(inputValue) =>
                          onChange('lastName', inputValue)
                        }
                        type="text"
                        value={formValue.lastName}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="email"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="Email address *" />
                      <RegularInput
                        disabled
                        customStyle={{
                          width: '100%'
                        }}
                        error={errorFields.includes('email')}
                        name="email"
                        onChange={(inputValue) => onChange('email', inputValue)}
                        type="text"
                        value={formValue.email}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="phone"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="Phone number *" />
                      <RegularInput
                        disabled
                        customStyle={{
                          width: '100%'
                        }}
                        error={errorFields.includes('phone')}
                        name="phone"
                        onChange={(inputValue) => onChange('phone', inputValue)}
                        type="text"
                        value={formValue.phone}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="businessName"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="Company Name *" />
                      <RegularInput
                        disabled
                        customStyle={{
                          width: '100%'
                        }}
                        error={errorFields.includes('businessName')}
                        name="businessName"
                        onChange={(inputValue) =>
                          onChange('businessName', inputValue)
                        }
                        type="text"
                        value={formValue.businessName}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="jobTitle"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="Job Title *" />
                      <RegularInput
                        disabled
                        customStyle={{
                          width: '100%'
                        }}
                        error={errorFields.includes('jobTitle')}
                        name="jobTitle"
                        onChange={(inputValue) =>
                          onChange('jobTitle', inputValue)
                        }
                        type="text"
                        value={formValue.jobTitle}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="address"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label="Billing Address *" />
                      <AutoCompletePlaces
                        disabled
                        error={errorFields.includes('billingAddress')}
                        keyName="billingAddress"
                        onChange={(values: any) => {
                          onChange(values.keyName, values.value);
                        }}
                        setSelected={setSelectedAddress}
                        value={formValue.billingAddress}
                      />
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="address"
                      sx={{ width: isMobile ? '100%' : '80%' }}
                    >
                      <FormLabel label={`${t('CLIENT.billingEmail')}*`} />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'left',
                          marginTop: '16px',
                          width: '100%'
                        }}
                      >
                        <SortableTable
                          disableSort
                          disabledTable
                          addManagement={false}
                          bodyKeys={['email']}
                          name="billingEmail"
                          onChange={(newEmail: any) => {
                            setFormValue({
                              ...formValue,
                              billingEmail: [...newEmail]
                            });
                          }}
                          type="input"
                          values={formValue.billingEmail}
                        />
                      </Box>
                    </GridBoxInput>
                  </GridContainer>
                  <GridContainer
                    isMobile={isMobile}
                    sx={{
                      gridTemplateColumns: isMobile ? '100%' : '1fr',
                      width: '100%'
                    }}
                  >
                    <GridBoxInput
                      isMobile={isMobile}
                      key="address"
                      sx={{ width: isMobile ? '100%' : '90%' }}
                    >
                      <FormLabel
                        error={errorFields.includes('placeValue')}
                        label={t('LEAD.CREATE.GENERAL_INFORMATION.address')}
                      />
                      <Box sx={{ display: 'flex', marginTop: '4px' }}>
                        <SortableTable
                          disableSort
                          disabledTable
                          isAddress
                          addManagement={false}
                          bodyKeys={['name', 'phone', 'address']}
                          handleDelete={setAddressDeleteId}
                          name="addresses"
                          onChange={(newAddresses: any) => {
                            setFormValue({
                              ...formValue,
                              addresses: newAddresses
                            });
                          }}
                          selectedAddress={selectedAddress}
                          type="addresses"
                          values={formValue.addresses}
                        />
                      </Box>
                    </GridBoxInput>
                    <GridBoxInput
                      isMobile={isMobile}
                      key="address"
                      sx={{ width: isMobile ? '100%' : '90%' }}
                    >
                      <FormLabel
                        error={errorFields.includes('contacts')}
                        label={t('CLIENT.contactsLabel')}
                      />
                      <Box mt={2}>
                        <SortableTable
                          disableSort
                          disabledTable
                          addManagement={false}
                          bodyKeys={['name', 'phoneNumber']}
                          handleDelete={setContactDeleteId}
                          name="contacts"
                          onChange={(newContactList: any) => {
                            setFormValue({
                              ...formValue,
                              contacts: newContactList
                            });
                          }}
                          type="generic-input"
                          values={formValue.contacts}
                        />
                      </Box>
                    </GridBoxInput>
                    <Button
                      color="primary"
                      onClick={handleSave}
                      sx={{
                        justifySelf: 'center',
                        margin: '20px 0px',
                        width: isMobile ? '100%' : '90%'
                      }}
                      variant="contained"
                    >
                      {`${t('GENERAL.saveButton')}`}
                    </Button>
                  </GridContainer>
                </Box>
              )}
              {/* End */}
              {activeStep === 7 && !isError && (
                <Typography
                  gutterBottom
                  align="center"
                  color="primary"
                  variant="heading_400"
                >
                  {t('ONBOARDING.success', [
                    `${formValue?.firstName} ${formValue?.lastName}`
                  ])}
                </Typography>
              )}
              {activeStep === 7 && isError && (
                <Typography gutterBottom align="center">
                  {errorMessage}
                </Typography>
              )}
            </Box>
          ) : (
            <Box
              alignItems="center"
              display="flex"
              height="100%"
              justifyContent="center"
              sx={{ mb: 10, mt: 20 }}
              width="100%"
            >
              <CircularProgress size={40} />
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {activeStep !== 6 && (
            <Button
              onClick={
                activeStep === steps.length - 1 ? handleFinish : handleNext
              }
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default OnboardingClient;
