import React from 'react';
import '../../../style/App.scss';
import {
  Container,
  Typography,
  Box,
  styled,
  Button,
  SliderProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface Option {
  selected: boolean;
}

const MfaOptionSelected = styled(Button)<Option>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.input?.main : '',
  color: selected ? theme.palette.primary[300] : theme.palette.secondary[400],
  display: 'flex',
  flexDirection: 'column',
  height: isMobile ? '120px !important' : '190px !important',
  width: '100%'
}));

const ButtonContainer = styled(Container)<SliderProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2)
}));

interface Props {
  alterAction: () => void;
  confirmCode: () => void;
  getError: () => false | JSX.Element;
  methodSelected: number;
  mfa: any;
  setMethodSelected: (value: React.SetStateAction<number>) => void;
  updateFlag: boolean;
}

const SelectMethod: React.FC<Props> = function SelectMethod({
  methodSelected,
  setMethodSelected,
  getError,
  confirmCode,
  alterAction,
  updateFlag,
  mfa
}) {
  const { t } = useTranslation();

  return (
    <Container sx={{ display: 'flex !important' }}>
      <Typography
        color="secondaryText.main"
        sx={{ fontSize: 16, textAlign: 'center', width: '100%' }}
        variant="primary-bold"
      >
        {mfa?.enabled
          ? 'You can Update your actual MFA Method or Change Method'
          : t('LOGIN.mfaRegistrationSubtitle')}
      </Typography>
      <br />
      {getError()}
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <MfaOptionSelected
          onClick={() => setMethodSelected(1)}
          selected={methodSelected === 1}
        >
          <i
            className="custom-icon icon-phone"
            style={{ fontSize: 50, marginBottom: 28 }}
          />
          {mfa?.enabled ? (
            <Typography variant="primary-bold">
              {mfa?.method === 'Email' ? 'Change to ' : 'Update '}
              {t('GENERAL.sms')}
            </Typography>
          ) : (
            <Typography variant="primary-bold">{t('GENERAL.sms')}</Typography>
          )}
        </MfaOptionSelected>
        <Box sx={{ height: 16, width: 24 }} />
        <MfaOptionSelected
          onClick={() => setMethodSelected(2)}
          selected={methodSelected === 2}
        >
          <i
            className="custom-icon icon-email"
            style={{ fontSize: 50, marginBottom: 28 }}
          />
          {mfa?.enabled ? (
            <Typography variant="primary-bold">
              {mfa?.method === 'Phone' ? 'Change to ' : 'Update '}
              {t('GENERAL.email')}
            </Typography>
          ) : (
            <Typography variant="primary-bold">{t('GENERAL.email')}</Typography>
          )}
        </MfaOptionSelected>
      </ButtonContainer>
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <Button
          onClick={() => alterAction()}
          sx={{ width: '100%' }}
          variant="outlined"
        >
          <Typography variant="primary-bold">
            {updateFlag ? 'Cancel' : 'Skip'}
          </Typography>
        </Button>
        <Box sx={{ height: 16, width: 24 }} />
        <Button
          color="primary"
          onClick={() => confirmCode()}
          sx={{ width: '100%' }}
          variant="contained"
        >
          <Typography variant="primary-bold">
            {t('GENERAL.nextButton')}
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default SelectMethod;
