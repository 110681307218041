import { SkillApi, SkillResponseDTO } from '../api-client/generated/api';

const apiCaller = new SkillApi();

interface Pagination {
  items: number;
  page: number;
}

const getSkills = async (params: Pagination) => {
  const response = await apiCaller.skillGetAllPaginated(params);
  const { data } = response;
  return data;
};

const getAllSkills = async () => {
  const response = await apiCaller.skillGetAll();
  const { data } = response;
  return data as SkillResponseDTO[];
};

const getSkill = async (param: any) => {
  if (!param?.id) {
    return null;
  }

  const response = await apiCaller.skillGetOneById(param);
  const { data } = response;
  return data;
};

const getSkillByName = async (param: any) => {
  const response = await apiCaller.skillGetOneByName(param);
  const { data } = response;
  return data;
};

const createSkill = async (params: any) => {
  const { category: categoryId, description, name } = params;
  const response = await apiCaller.skillCreate({
    createSkillDTO: { categoryId, description, name }
  });
  const { data } = response;
  return { data };
};

const updateSkill = async (param: any) => {
  if (!param.id) {
    return null;
  }
  const response = await apiCaller.skillUpdate({
    id: param.id,
    updateSkillDTO: {
      categoryId: param.category,
      description: param.description,
      name: param.name
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteSkill = async (params: { id: string; url: string }) => {
  if (!params.id) {
    return null;
  }
  const response = await apiCaller.skillDelete({ id: params.id });
  const { status, statusText } = response;
  return { status, statusText };
};

export default {
  createSkill,
  deleteSkill,
  getAllSkills,
  getSkill,
  getSkillByName,
  getSkills,
  updateSkill
};
