export const getUserLocation:any = async () =>  {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude;
                    resolve({ lat, long });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
};

export function deg2rad(deg:any) {
    return deg * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm(lat1:any, lon1:any, lat2:any, lon2:any) {
    const R = 6371; // Radius in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

export function checkGeofence(lat:any, long:any, geofenceCenter: { lat: number; long: number; }) {
    const geofenceRadius = 2000; // radius in meters

    // Calculate the distance between the user's location and the geofence center
    const distance = getDistanceFromLatLonInKm(lat, long, geofenceCenter.lat, geofenceCenter.long) * 1000; // convert to meters

    return distance <= geofenceRadius;
}



