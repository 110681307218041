/* eslint-disable no-param-reassign */
import {
  LeadServiceTimesApi,
  LeadServiceTimesApiLeadServiceTimeCreateRequest,
  LeadServiceTimesApiLeadServiceTimeDeleteRequest,
  LeadServiceTimesApiLeadServiceTimeUpdateRequest
} from '../../api-client/generated/api';

const newServicesResponseFunc = (data: any[]) => {
  let sumTotalServiceTimeHours = 0;
  const newServs = data?.map((service: any) => {
    let sumTotalServiceHours = 0;
    const newTasks = service?.tasks?.map((task: any) => {
      const quantity = task.quantity || 0;
      const measurePerTime = parseFloat(task.measurePerHour || 0);
      const totalTime =
        task?.measure === 'Unit'
          ? quantity * measurePerTime
          : quantity / measurePerTime;
      // const totalTime = auxTotalTime;
      const minRequired = Number(task?.minimumResources) ?? 1;
      const cantTechnicians =
        task?.technicians?.length > 0
          ? task?.technicians?.length
          : task?.resources?.length || 1;
      const totalTimeNumber =
        Number((totalTime * minRequired) / cantTechnicians) || 0;
      sumTotalServiceHours += totalTimeNumber;
      return {
        ...task,
        resources: task?.resources || task?.technicians || [],
        taskHoursPerDay: totalTimeNumber || 0,
        technicians: task?.resources || task?.technicians || [],
        time: totalTimeNumber || 0,
        totalTime: totalTimeNumber || 0
      };
    });
    sumTotalServiceTimeHours += sumTotalServiceHours;
    return {
      ...service,
      tasks: newTasks,
      time: sumTotalServiceHours,
      totalTime: sumTotalServiceHours
    };
  });
  return { newServs, totalTime: sumTotalServiceTimeHours };
};

const createLeadServicesTime = async (id: string, params: any) => {
  const apiCaller = new LeadServiceTimesApi();
  const requestParameters: LeadServiceTimesApiLeadServiceTimeCreateRequest = {
    id
  } as any;

  if (!params || !params?.services) {
    return null;
  }

  const newServices: any[] = [];
  params.services.map((service: any) => {
    if (service?.id) {
      delete service.id;
      delete service?.ftsNameEn;
      delete service?.createdAt;
      delete service?.updatedAt;
      delete service?.fileBank;
      delete service?.totalTime;
      newServices.push({
        ...service,
        tasks: service.tasks.map((task: any) => {
          const taskId = task?.id;
          delete task.id;
          const auxMaterials = task?.taskMaterials;
          delete task.createdAt;
          delete task.updatedAt;
          delete task.taskMaterials;
          let taskSkills: string[] = [];
          const associatedTechnicians = task?.resources || [];

          if (task?.skills?.length && task?.skills.length > 0) {
            if (task?.skills[0]?.id) {
              taskSkills = task.skills.map((skill: any) => skill.id);
            } else {
              taskSkills = task.skills;
            }
          }

          const quantity = task?.quantity || 0;
          const measurePerTime = parseFloat(task?.measurePerHour || 0);
          const totalTime =
            task?.measure === 'Unit'
              ? quantity * measurePerTime
              : quantity / measurePerTime;
          // const totalTime = auxTotalTime;
          const totalTimeNumber = Number(totalTime) || 0;

          return {
            ...task,
            associatedTechnicians,
            checklist: task?.taskChecklist || task?.checklist || [],
            materials: task?.materials || auxMaterials || [],
            measure: task?.measure?.name || task?.measure || null,
            quantity: task?.quantity || totalTimeNumber,
            skills: taskSkills,
            taskHoursPerDay: task?.taskHoursPerDay || totalTimeNumber,
            taskId,
            totalTime: task?.taskHoursPerDay || totalTimeNumber
          };
        })
      });
    }
    return null;
  });

  const newIndividualTasks =
    params?.individualTasks?.tasks && params?.individualTasks?.tasks.length > 0
      ? params.individualTasks.tasks.map((task: any) => {
          let taskSkills: string[] = [];
          if (task?.skills?.length && task?.skills.length > 0) {
            if (task?.skills[0]?.id) {
              taskSkills = task.skills.map((skill: any) => skill.id);
            } else {
              taskSkills = task.skills;
            }
          }
          delete task.createdAt;
          delete task.updatedAt;
          delete task.id;
          return {
            ...task,
            measure: task?.measure?.name || task?.measure || null,
            skills: taskSkills
          };
        })
      : [];
  // const response = await apiCaller.leadServiceTimeCreate(requestParameters, {
  //   ...params,
  //   endTime: params.endTime || '18:00',
  //   individualTasks: newIndividualTasks,
  //   services: newServices,
  //   startTime: params.startTime || '08:00'
  // });
  const response = await apiCaller.leadServiceTimeCreate({
    ...requestParameters,
    createLeadServiceTimeDto: {
      ...params,
      endTime: params.endTime || '18:00',
      individualTasks: newIndividualTasks,
      services: newServices,
      startTime: params.startTime || '08:00'
    }
  });

  const { data } = response as any;
  const newServicesResponse = newServicesResponseFunc(data.services);

  return {
    ...data,
    services: newServicesResponse.newServs,
    totalTime: newServicesResponse.totalTime
  };
};

const updateLeadServicesTime = async (
  id: string,
  serviceTimeId: number,
  params: any
) => {
  if (!params || !params?.services) {
    return null;
  }

  delete params.id;
  const apiCaller = new LeadServiceTimesApi();
  const newServices: any[] = [];
  params.services.map((service: any) => {
    if (undefined !== service?.name) {
      delete service.id;
      newServices.push({
        ...service,
        tasks:
          service?.tasks &&
          service?.tasks.length > 0 &&
          service.tasks.map((task: any) => {
            delete task.id;
            delete task.taskMaterials;
            const technicians = task?.resources || [];
            let taskSkills: string[] = [];

            if (task?.skills?.length && task?.skills.length > 0) {
              if (task?.skills[0]?.id) {
                taskSkills = task.skills.map((skill: any) => skill.id);
              } else {
                taskSkills = task.skills;
              }
            }
            const quantity = task?.quantity || 0;
            const measurePerTime = task?.measurePerHour || 0;
            const totalTime =
              task?.measure === 'Unit'
                ? quantity * measurePerTime
                : quantity / measurePerTime;
            // const totalTime = auxTotalTime;
            const totalTimeNumber = Number(totalTime) || 0;
            return {
              ...task,
              checklist: task?.checklist || [],
              materials: task?.materials || [],
              measure: task?.measure?.name || task?.measure || '',
              measurePerHour: measurePerTime,
              quantity: task?.quantity || totalTime,
              skills: taskSkills,
              taskHoursPerDay: totalTime,
              technicians,
              time: totalTime,
              totalTime
            };
          })
      });
    }

    return null;
  });

  const newIndividualTasks =
    params?.individualTasks?.tasks && params?.individualTasks?.tasks.length > 0
      ? params.individualTasks.tasks.map((task: any) => {
          let taskSkills: string[] = [];
          if (task?.skills?.length && task?.skills.length > 0) {
            if (task?.skills[0]?.id) {
              taskSkills = task.skills.map((skill: any) => skill.id);
            } else {
              taskSkills = task.skills;
            }
          }
          delete task.updatedAt;
          delete task.id;
          return {
            ...task,
            measure: task?.measure?.name || task?.measure || null,
            skills: taskSkills
          };
        })
      : [];

  delete params.serviceTimeCost;
  delete params.totalTime;
  delete params.company;

  let formattedDate = params?.startDate;
  if (params?.startDate?.$d) {
    const auxSD = new Date(params?.startDate.$d);

    const year = auxSD.getFullYear();
    const month = auxSD.getMonth() + 1;
    const day = auxSD.getDate();
    formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
  }

  const requestParameters: LeadServiceTimesApiLeadServiceTimeUpdateRequest = {
    id,
    serviceTimeId,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    body: {
      ...params,
      individualTasks: newIndividualTasks,
      services: newServices,
      startDate: formattedDate,
      supervisor: params?.supervisor?.id ? params?.supervisor : null
    }
  } as any;

  const response = await apiCaller.leadServiceTimeUpdate(requestParameters);

  const { data } = response as any;

  const newServicesResponse = newServicesResponseFunc(data.services);

  return {
    ...data,
    services: newServicesResponse.newServs,
    totalTime: newServicesResponse.totalTime
  };
};

const updateMultipleLeadServicesTime = async (params: any) => {
  const response = params.serviceTimes.map(async (serviceTime: any) => {
    if (serviceTime?.id && typeof serviceTime?.id === 'number') {
      const { id, ...rest } = serviceTime;
      const updatedServiceTime = await updateLeadServicesTime(
        params.idLead,
        id,
        rest
      );
      return updatedServiceTime;
    }
    return null;
  });

  return response;
};

const deleteLeadServicesTime = async (
  params: LeadServiceTimesApiLeadServiceTimeDeleteRequest
) => {
  if (!params) {
    return null;
  }
  const apiCaller = new LeadServiceTimesApi();
  const response = await apiCaller.leadServiceTimeDelete(params);
  const { data } = response;
  return data;
};

export default {
  createLeadServicesTime,
  deleteLeadServicesTime,
  newServicesResponseFunc,
  updateLeadServicesTime,
  updateMultipleLeadServicesTime
};
