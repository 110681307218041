import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {}

const EditContent: React.FC<Props> = function EditContent() {
  const { t } = useTranslation();
  return (
    <Box display="flex" gap={2} justifyContent="center" pr={1}>
      <i className="custom-icon icon-edit" />
      {t('PROFILE.editInformation')}
    </Box>
  );
};

export default EditContent;
