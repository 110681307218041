import { DashboardApi } from '../api-client/generated/api';
import { dashboardCounters } from '../utils/dashboardHelper';

const apiCaller = new DashboardApi();

const getDashboardProject = async () => {
  const { data } = await apiCaller.dashboardGetProjectDashboardInformation();
  return dashboardCounters(data, 'Project') as any;
};
const getDashboardLead = async () => {
  const { data } = await apiCaller.dashboardGetLeadDashboardInformation();
  return dashboardCounters(data, 'Lead') as any;
};
const getDashboardProposal = async () => {
  return {};
};
const getDashboardInvoices = async () => {
  const { data } = await apiCaller.dashboardGetInvoicesDashboardInformation();
  return dashboardCounters(data, 'Invoices') as any;
};
const getDashboardResources = async () => {
  const { data } = await apiCaller.dashboardGetResourcesDashboardInformation();
  return dashboardCounters(data, 'Resources') as any;
};

const getDashboardTime = async () => {
  const { data } = await apiCaller.dashboardGetTimeDashboardInformation();
  return dashboardCounters(data, 'Time') as any;
};

const manualUpdateCompanyDashboard = async () => {
  await apiCaller.dashboardUpdateCompanyDashboardValues();
  return true;
};

export default {
  getDashboardInvoices,
  getDashboardLead,
  getDashboardProject,
  getDashboardProposal,
  getDashboardResources,
  getDashboardTime,
  manualUpdateCompanyDashboard
};
