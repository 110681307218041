/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CardContainerServices from './serviceTimes/components/CardContainerServices';

interface Props {
  detailProject: any;
  projectType: any;
}

const ProjectsTimeList: React.FC<Props> = function ProjectsTimeList({
  detailProject,
  projectType
}) {
  const [projectDetails, setProjectDetails] = useState<any>(null);

  useEffect(() => {
    if (
      detailProject &&
      (!projectDetails || projectDetails.id !== detailProject.id)
    ) {
      setProjectDetails(detailProject);
    }
  }, [detailProject]);

  const headerServiceTme = () => {
    return (
      <Typography variant="primary-bold">
        {' '}
        {projectType === 'Regular'
          ? `${'Service Setup'} - ${'Regular Time'}`
          : `${'Service Setup'} ${'Ad-Hoc time'}`}
      </Typography>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  return (
    <Box
      style={{ width: '100%' }}
      sx={{
        display: 'flex',
        flexGrow: 1,
        padding: '5px'
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={12} xl={12} xs={12}>
          {headerServiceTme()}
          <br />
          <br />
        </Grid>
        {projectDetails &&
          projectDetails?.proposal?.lead?.serviceTimes &&
          projectDetails?.proposal?.lead?.serviceTimes.map(
            (index: any, key: any) => {
              return (
                <CardContainerServices
                  count={`${key + 1}`}
                  data={index}
                  dayChecked=""
                  detailProjectId={projectDetails.id}
                  key={key}
                  projectType={projectType}
                  type="serviceSetup"
                  typeView=""
                  utcUpadtedAt=""
                />
              );
            }
          )}
      </Grid>
    </Box>
  );
};

export default ProjectsTimeList;
