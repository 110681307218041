import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  isCompany: boolean;
  isMobile: boolean;
  setShowWelcomeImg: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | undefined;
}

const Welcome: React.FC<Props> = function Welcome({
  token,
  isMobile,
  setShowWelcomeImg,
  isCompany = false
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowWelcomeImg(true);
    return () => {
      setShowWelcomeImg(false);
    };
  }, []);

  const navigateNext = isCompany
    ? `/company/invitation/accept-invitation/${token}/2`
    : `/invitation/accept-invitation/${token}/2`;

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        onClick={() => navigate(navigateNext)}
        sx={{ width: isMobile ? '100%' : '50%' }}
        variant="contained"
      >
        <Typography variant="primary-bold">
          {t('GENERAL.nextButton')}
        </Typography>
      </Button>
    </Container>
  );
};

export default Welcome;
