import { Container, styled, SlideProps } from '@mui/material';

interface ContainerProps {
  isMobile: boolean;
}

const MobileStyle = {
  margin: '16px',
  width: 'auto'
};

const DesktopStyle = {
  margin: '16px 104px 16px 40px',
  width: '100%'
};

export const ListMainContainer = styled(Container)<SlideProps>(() => ({
  alignItems: 'start !important',
  display: 'flex',
  flexDirection: 'column',
  margin: '16px 40px 16px 40px',
  width: 'auto'
}));

export const FormMainContainer = styled(Container)<ContainerProps>(
  ({ isMobile }) => (isMobile ? MobileStyle : DesktopStyle)
);
