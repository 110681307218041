import { UserManagementApi } from '../../api-client/generated/api';

const apiCaller = new UserManagementApi();

const createFakeResources = (mediaRate?: string, cantResources: number = 1) => {
  const resources = Array.from({ length: cantResources }, (_, index) => {
    return {
      hrAvailable: 40,
      id: index,
      name: `Resource ${index + 1}`,
      profilePic: null,
      rate: mediaRate,
      status: 'AVAILABLE'
    };
  });

  return resources;
};

const getMediaRate = (resources: any[]) => {
  if (resources.length === 0) {
    throw new Error('The array of resources is empty.');
  }

  const rates = resources
    .map((resource) => resource.rateNumber)
    .sort((a, b) => a - b);
  const mid = Math.floor(rates.length / 2);

  if (rates.length % 2 === 0) {
    return ((rates[mid - 1] + rates[mid]) / 2).toFixed(2);
  }

  return rates[mid].toFixed(2);
};

const getSkillResource = async (params: any) => {
  const response = await apiCaller.usersUserRources({
    skillResourceDTO: {
      skills: params.skillIds
    }
  });

  if (Array.isArray(response.data)) {
    const { data } = response;
    const mappedData = data.map((user: any) => ({
      hrAvailable:
        (user?.availabilityData?.availableHoursPerWeek || 40) -
        (user?.availabilityData?.scheduledHoursPerWeek || 0),
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      profilePic: user.profilePic ? user.profilePic : null,
      rate: user.rate || 'not rate',
      rateNumber: Number(user.rate || 0),
      status: user.availability
    }));
    const mediaRate = getMediaRate(mappedData);
    // console.log('getSkillResource mediaRate', mediaRate);
    return mappedData.map((resource) => {
      return { ...resource, mediaRate };
    });
  }
  // console.error('Data is not an array:', response.data);
  return [];
};

const getSupervisor = async (params: any) => {
  const response = await apiCaller.usersUserRources({
    skillResourceDTO: {
      skills: [params]
    }
  });

  if (Array.isArray(response.data)) {
    const { data } = response;
    const mappedData = data.map((user: any) => ({
      hrAvailable:
        (user?.availabilityData?.availableHoursPerWeek || 40) -
        (user?.availabilityData?.scheduledHoursPerWeek || 0),
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      profilePic: user.profilePic ? user.profilePic : null,
      rate: user.rate || 'not rate',
      status: user.availability
    }));
    return mappedData;
  }
  // eslint-disable-next-line no-console
  console.error('Data is not an array:', response.data);
  return [];
};

const getUsersByRole = async (params: any) => {
  const { roles } = params;
  const response = await apiCaller.usersFindUsersByRole({ rolesArray: roles });
  return response.data;
};

export default {
  createFakeResources,
  getMediaRate,
  getSkillResource,
  getSupervisor,
  getUsersByRole
};
