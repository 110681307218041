import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegularInput from './RegularInput';

interface MinuteInput {
  minutes: string;
}

interface MinuteProps {
  disabled?: boolean;
  readOnly?: boolean;
  setTime: (time: number) => void;
  time: number;
}

const MinuteComponent: React.FC<MinuteProps> = function MinuteComponent({
  time,
  setTime,
  disabled,
  readOnly
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <Box>
        {!readOnly && (
          <RegularInput
            customStyle={{
              backgroundColor: 'transparent !important',
              border: 1,
              borderColor: theme.palette.secondary[100],
              mr: 2,
              width: 90
            }}
            disabled={disabled}
            onChange={(value: string) => {
              setTime(Number(value));
            }}
            type="number"
            value={time.toString()}
          />
        )}
        <Typography variant="primary">
          {readOnly && <span>{time}</span>}
          <Typography variant="body_100">{t('GENERAL.minutes')}</Typography>
        </Typography>
      </Box>
    </Box>
  );
};

MinuteComponent.defaultProps = {
  disabled: undefined,
  readOnly: undefined
};

export default MinuteComponent;
