import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Container,
  Snackbar,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button
} from '@mui/material';
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { HeaderFormContainer, TitleFormBox } from '../form/parts';
import { accentColors } from '../../theme/themeConfig';

function IntegrationCard() {
  const [actionIntegrations, setActionIntegrations] = useState<boolean>(true);
  const navigate = useNavigate();

  const { data: integrations } = useSWR(
    actionIntegrations ? 'api/integration' : null,
    api.IntegrationsApi.getAllIntegrations
  );

  console.log(integrations);

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '10px',
        // gridTemplateColumns: `repeat(auto-fill, minmax(350px}, 22fr))`,
        gridTemplateColumns: `repeat(auto-fill, minmax(350px, 2fr))`,
        padding: '20px'
      }}
    >
      {(integrations || []).map((integration: any) => (
        <Card
          onClick={() => {
            navigate('/app/company-settings-integration', {
              state: { ...integration, lastPage: '/app/company-settings' }
            });
          }}
          sx={{
            display: 'flex',
            width: '350px',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            transition: '0.3s',
            boxShadow: 1,
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 6
            }
          }}
        >
          <CardMedia
            alt="Live from space album cover"
            component="img"
            image={integration.logo}
            sx={{ width: 65, height: 65 }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="heading_400">
                {integration.name}
              </Typography>
              <Typography color={accentColors.ACCENT} variant="body_300">
              {integration.status}
              </Typography>
              <Typography
                color="text.secondary"
                component="div"
                variant="body_100"
              >
                {integration.description}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      ))}

      {/* <Card
        onClick={() => {
          navigate('/app/company-settings-integration');
        }}
        sx={{
          display: 'flex',
          width: '350px',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          transition: '0.3s',
          boxShadow: 1,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: 6
          }
        }}
      >
        <CardMedia
          alt="Live from space album cover"
          component="img"
          image="https://cdn.worldvectorlogo.com/logos/quickbooks-2.svg"
          sx={{ width: 65, height: 65 }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="heading_400">
              QuickBooks
            </Typography>
            <Typography color={accentColors.ACCENT} variant="body_300">
              Connected
            </Typography>
            <Typography
              color="text.secondary"
              component="div"
              variant="body_100"
            >
              Connect your QuickBooks account to sync your financial data.
            </Typography>
          </CardContent>
        </Box>
      </Card>
      <Card
        onClick={() => {
          navigate('/app/company-settings-integration');
        }}
        sx={{
          display: 'flex',
          width: '350px',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          transition: '0.3s',
          boxShadow: 1,
          '&:hover': {
            cursor: 'pointer',
            boxShadow: 6
          }
        }}
      >
        <CardMedia
          alt="Live from space album cover"
          component="img"
          image="https://cdn.worldvectorlogo.com/logos/quickbooks-2.svg"
          sx={{ width: 65, height: 65 }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="heading_400">
              QuickBooks
            </Typography>
            <Typography
              color="text.secondary"
              component="div"
              variant="body_100"
            >
              Connect your QuickBooks account to sync your financial data.
            </Typography>
          </CardContent>
        </Box>
      </Card>
      <Card
        onClick={() => {
          navigate('/app/company-settings-integration');
        }}
        sx={{
          display: 'flex',
          width: '350px',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          transition: '0.3s',
          boxShadow: 1,
          '&:hover': {
            cursor: 'pointer',
            boxShadow: 6
          }
        }}
      >
        <CardMedia
          alt="Live from space album cover"
          component="img"
          image="https://cdn.worldvectorlogo.com/logos/quickbooks-2.svg"
          sx={{ width: 65, height: 65 }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="heading_400">
              QuickBooks
            </Typography>
            <Typography
              color="text.secondary"
              component="div"
              variant="body_100"
            >
              Connect your QuickBooks account to sync your financial data.
            </Typography>
          </CardContent>
        </Box>
      </Card> */}
    </Box>
  );
}
function CompanyFinancialIntegration() {
  const { t } = useTranslation();

  return (
    <Container>
      <Box sx={{ wdith: '100%' }}>
        <HeaderFormContainer isMobile={isMobile}>
          <TitleFormBox isMobile={isMobile}>
            <Typography sx={{ marginBottom: '4px' }} variant="title">
              {t('COMPANY_SETTINGS.companyFinancialIntegration')}
            </Typography>
          </TitleFormBox>
        </HeaderFormContainer>
      </Box>

      {/* -------------- Company FORM -------------- */}
      <Box>
        <IntegrationCard />
      </Box>
    </Container>
  );
}

export default CompanyFinancialIntegration;
