/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  styled,
  SliderProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../../../components/input/RegularInput';
import { getColor, getCurrency } from '../../../../utils/general';
import {
  totalValueServiceTime,
  totalValueServiceTimeCost,
  getTotalPerVisit,
  getMarkup,
  parserLeadCalculator,
  getMargin
} from '../../../../utils/calculatorHelper';

const MainContainer = styled(Box)<SliderProps>(({ theme }) => ({
  width: '100%'
}));

const QuotedContainer = styled(Box)<SliderProps>(({ theme }) => ({
  backgroundColor: '#d1efec',
  border: `1px solid ${theme.palette.secondary[400]}`,
  borderRadius: theme.spacing(1),
  display: 'flex',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  width: '100%'
}));

const BoxRow = styled(Box)<SliderProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 4
}));

const QuotedSummary = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '40%'
}));

const ButtonsContainer = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

const CostDetailsContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.informative[100],
  border: `2px solid ${theme.palette.informative[400]}`,
  borderRadius: theme.spacing(1),
  bottom: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '80px !important',
  padding: theme.spacing(2),
  right: 0,
  width: '336px'
}));

function maskData(value: number) {
  // eslint-disable-next-line no-restricted-globals
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }
  return 'Value no valid';
}
interface Props {
  clientView?: boolean;
  deleteServiceTime?: (index: number) => void;
  index: number;
  saveStepper?: ({ next }: any) => void;
  serviceTime: any;
  setCurrentServiceTime?: (value: React.SetStateAction<number>) => void;
  setNewMargin: (value: React.SetStateAction<number>) => void;
  setNewMarginCost: (value: React.SetStateAction<number>) => void;
  setServiceIndex?: (value: React.SetStateAction<number>) => void;
  setTotalcostProject: (value: React.SetStateAction<number>) => void;
  setTotalcostProjectCost: (value: React.SetStateAction<number>) => void;
  setTotalcostvisit: (value: React.SetStateAction<number>) => void;
  setValidQuotePrice: (value: React.SetStateAction<boolean>) => void;
  setValues?: (value: any) => void;
  totalcostProject: number;
  totalcostProjectCost: number;
  totalvisit: number;
  type: 'proposal' | 'schedule' | 'services';
  validQuotePrice: boolean;
  values: any;
}

const SummaryService: React.FC<Props> = function SummaryService({
  values,
  setValues,
  serviceTime,
  setNewMargin,
  setNewMarginCost,
  setTotalcostProject,
  setTotalcostProjectCost,
  totalcostProjectCost,
  totalcostProject,
  setTotalcostvisit,
  validQuotePrice,
  setValidQuotePrice,
  index,
  deleteServiceTime,
  setServiceIndex,
  setCurrentServiceTime,
  saveStepper,
  type,
  clientView,
  totalvisit
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const travelTime: any =
    serviceTime.travelTime !== undefined ? serviceTime.travelTime : 0.0;

  const buyPrice = clientView
    ? 0
    : getTotalPerVisit(
        parserLeadCalculator(
          {
            ...serviceTime,
            company: serviceTime.company ? serviceTime.company : values.company
          },
          t
        ),
        travelTime
      );
  const sellPrice = values?.profit
    ? (parseFloat(values.profit || 0) / 100) * buyPrice + buyPrice
    : buyPrice;
  const markup = getMarkup(buyPrice, sellPrice);

  const [quotePrice, setQuotePrice] = useState<number>(
    serviceTime.sellPrice || sellPrice
  );

  const quotedMarkup = getMarkup(buyPrice, quotePrice);

  const getServiceTimeCost = () => {
    setTotalcostProject(
      totalValueServiceTime(
        serviceTime?.availability?.generalInfo?.daysOfServiceCount,
        serviceTime,
        travelTime,
        validQuotePrice,
        quotePrice
      )
    );

    setTotalcostProjectCost(
      totalValueServiceTimeCost(
        serviceTime?.availability?.generalInfo?.daysOfServiceCount,
        serviceTime,
        travelTime
      )
    );
    setTotalcostvisit(
      getTotalPerVisit(
        serviceTime,
        travelTime,
        1 + Number(values?.profit || 0) / 100
      )
    );
    return `${getCurrency(
      validQuotePrice
        ? quotePrice
        : maskData(
            getTotalPerVisit(
              serviceTime,
              travelTime,
              1 + Number(values?.profit || 0) / 100
            )
          )
    )} = ${getCurrency(
      totalValueServiceTime(
        serviceTime?.availability?.generalInfo?.daysOfServiceCount,
        serviceTime,
        travelTime,
        validQuotePrice,
        quotePrice,
        1 + Number(values?.profit || 0) / 100
      )
    )}`;
  };

  const getFrequency = (serviceTime: any) => {
    if (serviceTime.frequency) {
      const formattedStartDate = serviceTime.startDate
        ? new Date(serviceTime.startDate).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            timeZone: 'UTC',
            year: 'numeric'
          })
        : '';
      const formattedEndDate = serviceTime.endDate
        ? new Date(serviceTime.endDate).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            timeZone: 'UTC',
            year: 'numeric'
          })
        : '';

      return `${
        formattedStartDate === formattedEndDate ? '' : serviceTime.frequency
      } ${
        serviceTime.frequencyDays
          ? `on ${serviceTime.frequencyDays.toString().replaceAll(',', ', ')}`
          : ''
      } ${formattedStartDate ? `from ${formattedStartDate}` : ''} ${
        formattedEndDate ? `to ${formattedEndDate}` : ''
      }`;
    }

    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getServicesDescription = (serviceTime: any) => {
    const { services } = serviceTime;
    if (services && serviceTime.services) {
      return serviceTime.services
        .map((service: any) => {
          return `${service.name}(${
            service?.description ? service?.description : ''
          })`;
        })
        .toString()
        .replaceAll(',', ', ');
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };
  const getServices = (serviceTime: any) => {
    const { services } = serviceTime;
    if (services && serviceTime.services) {
      return `${serviceTime.services
        .map((service: any) => {
          return service.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getAdhockTasks = (serviceTime: any, type: string) => {
    const { adhocTasks } = serviceTime || {};
    if (type === 'services' && adhocTasks && adhocTasks.tasks) {
      return `${adhocTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    if (type !== 'services' && serviceTime) {
      return `${serviceTime.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const updateQuoteValues = (quotePrice: number, markup?: number) => {
    if (values?.serviceTimes && setValues) {
      const serviceTimesCopy = values.serviceTimes;
      serviceTimesCopy[index] = {
        ...serviceTimesCopy[index],
        baseCost: buyPrice,
        quoteMargin: getMargin(buyPrice, quotePrice).percent,
        quoteMarkup: markup || getMarkup(buyPrice, quotePrice).percent,
        quotePrice
      };
      setValues({
        ...values,
        serviceTimes: serviceTimesCopy
      });
    }
  };

  const getIndividualTasks = (serviceTime: any, type: string) => {
    const { individualTasks } = serviceTime || {};
    if (type === 'services' && individualTasks && individualTasks.tasks) {
      return `${individualTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    if (type !== 'services' && serviceTime) {
      return `${serviceTime.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };
  useEffect(() => {
    const margin = Math.floor(Math.random() * 30) + 20;
    const newServiceTime = Array.isArray(values?.serviceTimes)
      ? values?.serviceTimes.map((service: any) => {
          const randomFloat = Math.random();
          const result = randomFloat * (700 - 300) + 300;
          const visit = Math.floor(Math.random() * 6) + 1;
          const valueMargin = result * (margin / 100);
          const totalMarginInclude = result + valueMargin;
          return {
            ...service,
            proposalCost: totalMarginInclude,
            totalCost: result,
            visits: visit
          };
        })
      : [];

    if (setValues) {
      setValues({
        ...values,
        actualMargin: margin,
        margin,
        serviceTimes: newServiceTime
      });
    }
    updateQuoteValues(sellPrice);
  }, []);

  // eslint-disable-next-line react/no-unstable-nested-components
  function QuotedPrice() {
    return (
      <Box width="100%">
        {setValues ? (
          <Box>
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.serviceSummaryCostperVisit')}
            </Typography>
            &nbsp;
            <Typography variant="primary">{getCurrency(buyPrice)} +</Typography>
            &nbsp;
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.serviceSummaryMarkup')}
            </Typography>
            &nbsp;
            <Typography variant="primary">
              {getCurrency(markup.amount)} =
            </Typography>
            &nbsp;
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.serviceSummarySuggestedPrice')}
            </Typography>
            &nbsp;
            <Typography variant="primary">{getCurrency(sellPrice)}</Typography>
            &nbsp;
            <QuotedContainer>
              <Box alignItems="center" display="flex" width="60%">
                <Box>
                  <Typography variant="primary-bold">
                    {t('LEAD.CREATE.PROPOSAL.serviceSummaryQuotedperVisit')}
                  </Typography>
                </Box>
                &nbsp;
                <Box width="120px">
                  <RegularInput
                    customStyle={{
                      backgroundColor: 'white !important',
                      fontSize: '15px',
                      width: '100%'
                    }}
                    onChange={(value: any) => {
                      setQuotePrice(value);
                      setValidQuotePrice(value !== 0);
                      updateQuoteValues(value);
                    }}
                    type="number"
                    value={quotePrice.toFixed(2)}
                  />
                </Box>
              </Box>
              <QuotedSummary>
                <BoxRow>
                  <Typography variant="primary-bold">
                    {t('LEAD.CREATE.PROPOSAL.serviceSummaryActualMargin')}
                  </Typography>
                  <Typography
                    color={getColor(markup.amount, quotedMarkup.amount)}
                    variant="primary-bold"
                  >
                    {getCurrency(quotedMarkup.amount)}
                  </Typography>
                </BoxRow>
                <BoxRow>
                  <Typography variant="primary-bold">
                    {t('LEAD.CREATE.PROPOSAL.serviceSummaryActualpersent')}
                  </Typography>
                  <Typography
                    color={getColor(markup.amount, quotedMarkup.amount)}
                    variant="primary-bold"
                  >
                    {`${quotedMarkup.percent}%`}
                  </Typography>
                </BoxRow>
                <BoxRow>
                  <Typography variant="primary-bold">
                    Total in {totalvisit} visits x {getCurrency(quotePrice)} ={' '}
                  </Typography>
                  <Typography
                    color={getColor(markup.amount, quotedMarkup.amount)}
                    variant="primary-bold"
                  >
                    {getCurrency(totalvisit * quotePrice)}
                  </Typography>
                </BoxRow>
              </QuotedSummary>
            </QuotedContainer>
          </Box>
        ) : null}
      </Box>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ScheduleButtons() {
    return (
      <ButtonsContainer>
        {setCurrentServiceTime &&
        deleteServiceTime &&
        setServiceIndex &&
        saveStepper ? (
          <>
            <Box display="flex" gap={1} justifyContent="space-between" p={1}>
              <Button
                disabled={values?.readOnly}
                onClick={() => setCurrentServiceTime(index)}
                sx={{ width: 100 }}
                variant="primary"
              >
                {t('GENERAL.editButton')}
              </Button>
              <Button
                disabled={values?.readOnly}
                onClick={() => {
                  deleteServiceTime(index);
                }}
                sx={{ width: 100 }}
                variant="cancel"
              >
                {t('GENERAL.deleteButton')}
              </Button>
            </Box>
            <Box
              alignItems="center"
              borderRadius={2}
              display="flex"
              height={40}
              justifyContent="center"
              onClick={() => {
                setServiceIndex(index);
                saveStepper({ next: true });
              }}
              sx={{
                backgroundColor: serviceTime?.scheduled
                  ? theme.palette.success.main
                  : theme.palette.warning?.main,
                cursor: 'pointer'
              }}
              width={210}
            >
              <Typography color="mainText.100" variant="primary">
                {serviceTime?.scheduled
                  ? t('LEAD.CREATE.SERVICES.SUMMARY.scheduled')
                  : t('LEAD.CREATE.SERVICES.SUMMARY.pending')}
              </Typography>
            </Box>
          </>
        ) : null}
      </ButtonsContainer>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function PreviewCostDetails() {
    return (
      <CostDetailsContainer
        sx={{
          position: isMobile || clientView ? 'relative' : 'absolute',
          width: 'fit-content'
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 20 }} variant="primary-bold">
            Price per visit: &nbsp;
            {getCurrency(
              validQuotePrice
                ? quotePrice
                : maskData(
                    getTotalPerVisit(
                      serviceTime,
                      travelTime,
                      1 + Number(values?.profit || 0) / 100
                    )
                  )
            )}
          </Typography>
        </Box>
      </CostDetailsContainer>
    );
  }

  return (
    <Box mb={4}>
      <Box width="100%">
        <MainContainer mt={type === 'proposal' ? 1 : 5}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box position="relative" width="100%">
              <Box>
                <Typography variant="body_500">
                  {values &&
                  values.projectType !== 'adhoc' &&
                  values.projectType !== 'Ad-Hoc'
                    ? `${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')} #${
                        index + 1
                      }`
                    : `Ad-Hoc ${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')}`}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={type === 'proposal' && clientView ? 2 : 0}
                my={2}
              >
                <Box display="flex">
                  <Typography variant="primary-bold">
                    {t('LEAD.CREATE.SERVICES.SUMMARY.frequency')}:
                  </Typography>
                  &nbsp;
                  <Typography variant="primary">
                    {getFrequency(serviceTime)}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="primary-bold">
                    {t('LEAD.CREATE.SERVICES.SUMMARY.services')}:
                  </Typography>
                  &nbsp;
                  <Typography variant="primary">
                    {type === 'proposal'
                      ? getServicesDescription(serviceTime)
                      : getServices(serviceTime)}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="primary-bold">
                    {t('LEAD.CREATE.SERVICES.SUMMARY.adhocTasks')}:
                  </Typography>
                  &nbsp;
                  <Typography variant="primary">
                    {' '}
                    {values &&
                    values.projectType !== 'adhoc' &&
                    values.projectType !== 'Ad-Hoc'
                      ? getIndividualTasks(
                          values?.serviceTimes[index],
                          'services'
                        )
                      : getIndividualTasks(
                          values?.serviceTimes?.[index].services[0],
                          'Ad-Hoc'
                        )}
                  </Typography>
                </Box>
              </Box>
              {type === 'schedule' ? ScheduleButtons() : null}
              {type === 'proposal' && !clientView && (
                <Box display="flex" my={2} width="100%">
                  {QuotedPrice()}
                </Box>
              )}
              {type === 'proposal' && clientView && (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  {PreviewCostDetails()}
                </Box>
              )}
            </Box>
          </Box>
        </MainContainer>
      </Box>
    </Box>
  );
};

SummaryService.defaultProps = {};

export default SummaryService;
