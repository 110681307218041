/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {
  Box,
  Button,
  Container,
  SlideProps,
  Table,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  styled,
  TextField,
  SliderProps,
  MenuItem
} from '@mui/material';
import RegularInput from '../input/RegularInput';
import AutoCompleteInput from '../input/AutoCompleteInput';
import {
  capitalizeString,
  getLocationTimeZone,
  taxTypes
} from '../../utils/general';
import AutoCompletePlaces from '../input/AutoCompletePlaces';
import SelectInput from '../form/SelectInput';

const RowElement = styled(Container)<SlideProps>(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.secondary[400],
  fontSize: '14px',
  fontWeight: '400',
  width: '100%'
}));

const RowContainer = styled(Box)<SlideProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}));

const ContentContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  width: '100%'
}));

const InputsContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  margin: '0 !important',
  width: '100%'
}));

const CustomButton = styled(Button)<SlideProps>(({ theme }) => ({
  height: `${theme.spacing(5)} !important`,
  marginLeft: theme.spacing(1),
  minHeight: `${theme.spacing(5)} !important`,
  minWidth: `${theme.spacing(5)} !important`,
  width: `${theme.spacing(5)} !important`
}));

const FileBoxContainer = styled(Box)<SlideProps>(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  display: 'flex',
  height: theme.spacing(5),
  justifyContent: 'center',
  width: '230px'
}));

const GenericInputContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: theme.spacing(6),
  width: '100%'
}));

interface Props {
  addManagement: boolean;
  billingAddress?: string | boolean;
  bodyKeys?: string[];
  copybillingAddress?: boolean;
  createNestedElement?: (newValue: any) => void;
  disableSort?: boolean;
  disabledTable?: boolean;
  handleDelete?: (newValue: any) => void;
  handleFileChange?: (newValue: any) => void;
  isAddress?: boolean;
  name?: string;
  nestedOptions?: any[];
  nestedValues?: { name: string };
  onChange: React.Dispatch<React.SetStateAction<any>>;
  selectedAddress?: boolean;
  setCopybillingAddress?: React.Dispatch<React.SetStateAction<any>>;
  timeZoneBillingAddres?: number | undefined;
  type:
    | 'input'
    | 'material-input'
    | 'generic-input'
    | 'addresses'
    | 'tax-input';
  values: Array<any>;
}

const SortableTable: React.FC<Props> = function SortableTable({
  type,
  bodyKeys,
  addManagement,
  copybillingAddress,
  timeZoneBillingAddres,
  setCopybillingAddress,
  selectedAddress,
  billingAddress,
  values,
  onChange,
  createNestedElement,
  handleFileChange,
  handleDelete,
  name,
  nestedOptions,
  disableSort,
  disabledTable,
  isAddress
}) {
  const [items, setItems] = useState<Array<any>>(values || []);
  const [selected, setSelected] = useState<boolean>(false);
  const DragHandle = SortableHandle(() => (
    <Tooltip title="Sort item">
      <Typography
        sx={{
          cursor: 'move',
          display: 'flex',
          fontSize: 16,
          width: 26
        }}
        variant="primary-bold"
      >
        <i className="custom-icon icon-move" style={{ fontSize: 24 }} />
      </Typography>
    </Tooltip>
  ));
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (isAdd: boolean, index: number, value: string | {}) => {
    switch (type) {
      case 'input':
        {
          const newItems = items;
          if (isAdd) {
            if (value && value !== '') {
              newItems.push(value);
              setItems(newItems);
            }
          } else {
            const newFilter = newItems.filter((item: string, i: number) =>
              i !== index ? item : undefined
            );

            if (handleDelete && bodyKeys?.includes('files')) {
              handleDelete(index);
            }

            setItems(newFilter);
          }
        }
        break;
      case 'material-input':
        {
          const newItems = items;
          if (isAdd) {
            newItems.push(value);
            setItems(newItems);
          } else {
            const newFilter = newItems.filter((item: string, i: number) =>
              i !== index ? item : undefined
            );
            setItems(newFilter);
          }
        }
        break;
      case 'tax-input':
      case 'addresses':
      case 'generic-input':
        {
          const newItems = items;
          if (isAdd) {
            setItems([...items]);
          } else {
            const newFilter = newItems.filter((item: string, i: number) =>
              i !== index ? item : undefined
            );
            if (handleDelete && bodyKeys?.includes('files')) {
              handleDelete(items[index].fileId);
            } else if (handleDelete) {
              handleDelete(items[index].id);
            }
            setItems(newFilter);
          }
        }

        break;
      default:
        break;
    }
  };

  const handleInputChange = (
    value: any,
    index: number,
    input?: 'cost' | '',
    name?: string
  ) => {
    switch (type) {
      case 'input':
        {
          if (value === '') {
            return;
          }
          const newItems = items;
          newItems[index] = value;
          /* console.log('SortableTable handleInputChange items->', items);
          console.log('SortableTable handleInputChange newItems->', [
            ...newItems
          ]); */
          setItems([...newItems]);
        }
        break;
      case 'material-input':
        if (input === 'cost') {
          if (Number(value) < 0) {
            return;
          }
          const newItems = items;
          newItems[index].cost = Number(value || '0');
          setItems([...newItems]);
        } else {
          const newItems = items;
          newItems[index] = value;
          setItems([...newItems]);
        }
        break;
      case 'generic-input':
      case 'tax-input':
      case 'addresses':
        {
          if (value === '') {
            return;
          }
          const newItems = items;
          newItems[index] = {
            ...newItems[index],
            [name as unknown as string]: value
          };
          if (newItems[index].address && setCopybillingAddress) {
            setCopybillingAddress(false);
          }

          if (copybillingAddress) {
            const updatedItems = newItems.map((item) => {
              if (!item.address) {
                return {
                  ...item,
                  address: billingAddress,
                  timezone: timeZoneBillingAddres
                };
              }
              return item;
            });
            setItems(updatedItems);
          } else {
            setItems([...newItems]);
          }
        }
        break;
      default:
        break;
    }
  };

  const getObjectValue = (objectValue: any, value: string) => {
    if (objectValue) {
      return objectValue[value];
    }
    return '';
  };

  const triggerFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInput = (
    key: string,
    isEmpty: boolean,
    fileName: string
    //   disabled: boolean
  ) => {
    return isEmpty ? (
      <FileBoxContainer key={key}>
        <input
          // disabled
          accept="application/vnd.ms-powerpoint, application/pdf, image/png, image/jpeg"
          className={`custom-file-input-table ${
            bodyKeys?.includes('files') ? 'custom-file-input-table-hidden' : ''
          }`}
          id="files"
          onChange={(fileValue) => {
            if (handleFileChange) {
              handleFileChange(fileValue);
            }
          }}
          ref={fileInputRef}
          type="file"
        />
      </FileBoxContainer>
    ) : (
      <Box
        key={key}
        sx={{
          maxWidth: '230px',
          minWidth: '230px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {fileName}
      </Box>
    );
  };

  const typeFunction = (keyName: string) => {
    switch (keyName) {
      case 'phoneNumber':
      case 'phone':
        return 'phone';
      case 'tax':
        return 'number';
      case 'email':
      case 'validEmails':
        return 'email';
      default:
        return 'text';
    }
  };

  function getValue(
    items: any,
    index: any,
    keyName: any,
    billingAddress: any,
    copybillingAddress: any
  ) {
    if (copybillingAddress) {
      return index === 0 && selectedAddress ? billingAddress : '';
    }
    if (items && items.length) {
      return getObjectValue(items[index], keyName);
    }
    return '';
  }

  const getRowType: React.ElementType = (value: string, index: number) => {
    switch (type) {
      case 'input':
        return bodyKeys?.includes('files') ? (
          <RowElement>
            <RowContainer>
              <>
                {fileInput(
                  `box-service`,
                  items[index]?.fileName
                    ? !items[index]?.fileName
                    : !items[index]?.name,
                  items[index]?.fileName
                    ? items[index]?.fileName
                    : items[index]?.name
                )}
                {addManagement && (
                  <CustomButton
                    disabled={disabledTable}
                    onClick={() => {
                      handleClick(value === '', index, '');
                      if (value === '') {
                        triggerFileInputClick();
                      }
                    }}
                    sx={{ width: 10 }}
                    variant={value === '' ? 'primary' : 'destructive-outlined'}
                  >
                    <Typography
                      sx={{ display: 'flex', width: 26 }}
                      variant="primary-bold"
                    >
                      <i
                        className={`custom-icon 
                        ${value === '' ? 'icon-add' : 'icon-clear'}`}
                        style={{ fontSize: 24 }}
                      />
                    </Typography>
                  </CustomButton>
                )}
              </>
            </RowContainer>
          </RowElement>
        ) : (
          <RowElement>
            <RowContainer>
              <Box
                alignItems="center"
                display="flex"
                gap="8px"
                justifyContent="space-between"
                width="100%"
              >
                {disableSort ? null : <DragHandle />}
                <RegularInput
                  customStyle={{
                    fontSize: '15px',
                    marginBottom: '4px !important',
                    marginTop: '4px !important',
                    width: '100%'
                  }}
                  disabled={disabledTable}
                  name={`${name}-${index}`}
                  onChange={(value) => {
                    handleInputChange(value, index);
                  }}
                  type={bodyKeys ? typeFunction(bodyKeys[0]) : 'text'}
                  value={value}
                />
                {addManagement && (
                  <CustomButton
                    disabled={disabledTable}
                    onClick={() => {
                      handleClick(value === '', index, '');
                    }}
                    sx={{ width: 10 }}
                    variant={value === '' ? 'primary' : 'destructive-outlined'}
                  >
                    <Typography
                      sx={{ display: 'flex', width: 26 }}
                      variant="primary-bold"
                    >
                      <i
                        className={`custom-icon 
                        ${value === '' ? 'icon-add' : 'icon-clear'}`}
                        style={{ fontSize: 24 }}
                      />
                    </Typography>
                  </CustomButton>
                )}
              </Box>
            </RowContainer>
          </RowElement>
        );
      case 'material-input':
        return (
          <RowElement>
            <RowContainer>
              <Box
                alignItems="center"
                display="flex"
                gap="8px"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <AutoCompleteInput
                    closeOnSelect
                    disableClearable
                    disabled={disabledTable}
                    onChange={(event: any, newValue: any | null) => {
                      if (createNestedElement) {
                        if (newValue && newValue.createValue) {
                          handleInputChange(newValue, index);
                          createNestedElement(newValue);
                        } else {
                          handleInputChange(newValue, index);
                        }
                      }
                    }}
                    options={nestedOptions || []}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Name"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            borderStyle: 'hidden'
                          },
                          borderStyle: 'hidden',
                          fontSize: '15px',
                          height: '40px'
                        }}
                        // eslint-disable-next-line
                        {...params}
                      />
                    )}
                    sx={{
                      marginBottom: '4px !important',
                      marginTop: '4px !important'
                    }}
                    value={items[index]}
                  />
                  <RegularInput
                    customStyle={{
                      fontSize: '15px',
                      marginBottom: '4px !important',
                      marginTop: '4px !important',
                      width: '30%'
                    }}
                    disabled={!items[index] || disabledTable}
                    error={Number(items[index]?.cost) < 0}
                    onChange={(value) => {
                      handleInputChange(value || '0', index, 'cost');
                    }}
                    placeholder="Cost"
                    type="number"
                    value={
                      items.length && items[index]?.cost
                        ? items[index]?.cost
                        : 0
                    }
                  />
                </Box>

                {addManagement && (
                  <CustomButton
                    disabled={disabledTable}
                    onClick={() => handleClick(value === '', index, '')}
                    sx={{ width: 10 }}
                    variant={value === '' ? 'primary' : 'destructive-outlined'}
                  >
                    <Typography
                      sx={{ display: 'flex', width: 26 }}
                      variant="primary-bold"
                    >
                      <i
                        className={`custom-icon 
                        ${value === '' ? 'icon-add' : 'icon-clear'}`}
                        style={{ fontSize: 24 }}
                      />
                    </Typography>
                  </CustomButton>
                )}
              </Box>
            </RowContainer>
          </RowElement>
        );
      case 'tax-input':
        return (
          <RowElement>
            <RowContainer>
              <ContentContainer>
                <InputsContainer>
                  {bodyKeys &&
                    bodyKeys.map((keyName) => {
                      return keyName === 'type' ? (
                        <SelectInput
                          disabled={
                            disabledTable ||
                            (bodyKeys.includes('files') &&
                              !items[index]?.fileName)
                          }
                          height="36px"
                          onChange={(event) => {
                            handleInputChange(
                              event.target.value,
                              index,
                              '',
                              keyName
                            );
                          }}
                          selectvariant="secondary"
                          value={items[index]?.type}
                        >
                          {taxTypes.map((taxType: string, index: number) => (
                            <MenuItem
                              key={`${taxType}-${index}`}
                              value={taxType}
                            >
                              {taxType}
                            </MenuItem>
                          ))}
                        </SelectInput>
                      ) : (
                        <GenericInputContainer
                          key={`box-${keyName}`}
                          sx={{
                            alignItems: disableSort ? 'normal' : 'center',
                            flexDirection: disableSort ? 'column' : 'row'
                          }}
                        >
                          {disableSort ? null : <DragHandle />}
                          <RegularInput
                            customStyle={{
                              flexGrow: '3',
                              marginBottom: '4px !important',
                              marginTop: '4px !important'
                            }}
                            disabled={
                              disabledTable ||
                              (bodyKeys.includes('files') &&
                                !items[index]?.fileName)
                            }
                            error={Number(items[index]?.cost) <= 0}
                            name={`${name}${keyName}-${index}`}
                            onChange={(value) => {
                              handleInputChange(value, index, '', keyName);
                            }}
                            placeholder={capitalizeString(keyName)}
                            tooltip={
                              !items[index]?.fileName
                                ? 'You must add first file to edit Name & Description'
                                : ''
                            }
                            type={typeFunction(keyName)}
                            value={
                              items?.length && keyName === 'tax'
                                ? Number(getObjectValue(items[index], keyName))
                                : getObjectValue(items[index], keyName)
                            }
                          />
                        </GenericInputContainer>
                      );
                    })}
                </InputsContainer>

                {addManagement && (
                  <CustomButton
                    disabled={disabledTable}
                    onClick={() => {
                      handleClick(value?.length === 0, index, '');
                    }}
                    sx={{ width: 10 }}
                    variant={
                      value?.length === 0 ? 'primary' : 'destructive-outlined'
                    }
                  >
                    <Typography
                      sx={{ display: 'flex', width: 26 }}
                      variant="primary-bold"
                    >
                      <i
                        className={`custom-icon 
                          ${value?.length === 0 ? 'icon-add' : 'icon-clear'}`}
                        style={{ fontSize: 24 }}
                      />
                    </Typography>
                  </CustomButton>
                )}
              </ContentContainer>
            </RowContainer>
          </RowElement>
        );
      case 'generic-input':
        return (
          <RowElement>
            <RowContainer>
              <ContentContainer>
                <InputsContainer>
                  {bodyKeys &&
                    bodyKeys.map((keyName) => {
                      return keyName === 'files' ? (
                        fileInput(
                          `box-${keyName}`,
                          items[index]?.fileName
                            ? !items[index]?.fileName
                            : !items[index]?.name,
                          items[index]?.fileName
                            ? items[index]?.fileName
                            : items[index]?.name
                        )
                      ) : (
                        <GenericInputContainer
                          key={`box-${keyName}`}
                          sx={{
                            alignItems: disableSort ? 'normal' : 'center',
                            flexDirection: disableSort ? 'column' : 'row'
                          }}
                        >
                          {disableSort ? null : <DragHandle />}
                          <RegularInput
                            customStyle={{
                              flexGrow: '3',
                              marginBottom: '4px !important',
                              marginTop: '4px !important'
                            }}
                            disabled={
                              disabledTable ||
                              (bodyKeys.includes('files') &&
                                !items[index]?.fileName)
                            }
                            error={Number(items[index]?.cost) <= 0}
                            name={`${name}${keyName}-${index}`}
                            onChange={(value) => {
                              handleInputChange(value, index, '', keyName);
                            }}
                            placeholder={capitalizeString(keyName)}
                            tooltip={
                              !items[index]?.fileName
                                ? 'You must add first file to edit Name & Description'
                                : ''
                            }
                            type={typeFunction(keyName)}
                            value={
                              items?.length && keyName === 'tax'
                                ? Number(getObjectValue(items[index], keyName))
                                : getObjectValue(items[index], keyName)
                            }
                          />
                        </GenericInputContainer>
                      );
                    })}
                </InputsContainer>

                {addManagement && (
                  <CustomButton
                    disabled={disabledTable}
                    onClick={() => {
                      handleClick(value?.length === 0, index, '');
                    }}
                    sx={{ width: 10 }}
                    variant={
                      value?.length === 0 ? 'primary' : 'destructive-outlined'
                    }
                  >
                    <Typography
                      sx={{ display: 'flex', width: 26 }}
                      variant="primary-bold"
                    >
                      <i
                        className={`custom-icon 
                        ${value?.length === 0 ? 'icon-add' : 'icon-clear'}`}
                        style={{ fontSize: 24 }}
                      />
                    </Typography>
                  </CustomButton>
                )}
              </ContentContainer>
            </RowContainer>
          </RowElement>
        );
      case 'addresses':
        return (
          <RowElement>
            <RowContainer>
              <ContentContainer sx={{ alignItems: 'center' }}>
                <InputsContainer sx={{ flexWrap: 'wrap' }}>
                  {bodyKeys &&
                    bodyKeys.map((keyName) => {
                      return keyName === 'address' ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop="-20px"
                          width="100%"
                        >
                          <AutoCompletePlaces
                            disabled={disabledTable}
                            keyName={`${keyName}-${index}`}
                            onChange={(values: any) => {
                              handleInputChange(
                                values.value,
                                index,
                                '',
                                keyName
                              );
                              const timezone = getLocationTimeZone(
                                values.coords[1],
                                values.coords[0]
                              );
                              handleInputChange(
                                timezone?.timezone,
                                index,
                                '',
                                'timezone'
                              );
                              handleInputChange(
                                timezone?.timezoneIANA,
                                index,
                                '',
                                'timezoneIANA'
                              );
                              if (copybillingAddress) {
                                handleInputChange(
                                  billingAddress,
                                  index,
                                  '',
                                  'address'
                                );
                              }
                            }}
                            placeHolder="Address"
                            setSelected={setSelected}
                            value={getValue(
                              items,
                              index,
                              keyName,
                              billingAddress,
                              copybillingAddress
                            )}
                          />
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          maxHeight="50px"
                          width="366px"
                        >
                          <RegularInput
                            customStyle={{
                              flexGrow: '3',
                              marginBottom: '4px !important',
                              marginTop: '4px !important'
                            }}
                            disabled={disabledTable}
                            error={Number(items[index]?.cost) <= 0}
                            name={`${keyName}-${index}`}
                            onChange={(value) => {
                              handleInputChange(value, index, '', keyName);
                            }}
                            placeholder={capitalizeString(
                              keyName === 'name' ? 'nickname' : keyName
                            )}
                            type={typeFunction(keyName)}
                            value={
                              items?.length &&
                              getObjectValue(items[index], keyName)
                            }
                          />
                        </Box>
                      );
                    })}
                </InputsContainer>

                {addManagement && (
                  <CustomButton
                    disabled={disabledTable}
                    onClick={() => {
                      handleClick(value?.length === 0, index, '');
                    }}
                    sx={{ marginBottom: '15px', width: 10 }}
                    variant={
                      value?.length === 0 ? 'primary' : 'destructive-outlined'
                    }
                  >
                    <Typography
                      sx={{ display: 'flex', width: 26 }}
                      variant="primary-bold"
                    >
                      <i
                        className={`custom-icon 
                        ${value?.length === 0 ? 'icon-add' : 'icon-clear'}`}
                        style={{ fontSize: 24 }}
                      />
                    </Typography>
                  </CustomButton>
                )}
              </ContentContainer>
            </RowContainer>
          </RowElement>
        );
      default:
        return null;
    }
  };

  const SortableItem = SortableElement<{ i: number; value: string }>(
    ({ value, i }: any) => (
      <>
        {isAddress ? (
          <Box
            sx={{
              borderTop: i !== 0 ? '1px solid #B1B3BC' : '',
              height: '10px !important'
            }}
          />
        ) : null}
        <TableRow
          sx={{
            zIndex: 100
          }}
        >
          {getRowType(value, i)}
        </TableRow>
      </>
    )
  );

  const SortableList = SortableContainer<{ items: any }>(({ items }: any) => (
    <TableContainer>
      <Table>
        {items.map((value: any, index: number) => (
          <SortableItem
            i={index}
            index={index}
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${value}-${index}`}
            value={value}
          />
        ))}
        {!disabledTable && (
          <SortableItem
            i={items.length}
            index={items.length}
            key="item-to-add"
            value=""
          />
        )}
      </Table>
    </TableContainer>
  ));

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  useEffect(() => {
    onChange(items);
  }, [items]);

  useEffect(() => {
    // Effect when we want to fill table wtih value.
    if (
      values.length !== 0 &&
      type === 'addresses' &&
      JSON.stringify(values) !== JSON.stringify(items)
    ) {
      setItems([...values]);
    }
    if (
      values.length !== 0 &&
      type === 'generic-input' &&
      JSON.stringify(values) !== JSON.stringify(items)
    ) {
      setItems(values);
    }
    if (
      values.length !== 0 &&
      type === 'tax-input' &&
      JSON.stringify(values) !== JSON.stringify(items)
    ) {
      setItems(values);
    }
    if (
      values.length !== 0 &&
      type === 'generic-input' &&
      bodyKeys?.includes('files')
    ) {
      setItems(values);
    }
    if (
      values.length !== 0 &&
      type === 'input' &&
      JSON.stringify(values) !== JSON.stringify(items)
    ) {
      setItems(values);
    }
  }, [values]);

  return (
    <Container sx={{ width: '100%' }}>
      <SortableList
        distance={1}
        items={items}
        onSortEnd={onSortEnd}
        shouldCancelStart={(e: any) => {
          if (disableSort) {
            return true;
          }
          const { tagName } = e.target;
          if (tagName && tagName === 'INPUT') {
            return true;
          }
          return false;
        }}
      />
    </Container>
  );
};

SortableTable.defaultProps = {
  billingAddress: '',
  bodyKeys: [''],
  copybillingAddress: false,
  createNestedElement: () => {},
  disableSort: false,
  disabledTable: false,
  handleDelete: () => {},
  handleFileChange: () => {},
  isAddress: false,
  name: '',
  nestedOptions: [],
  nestedValues: { name: '' },
  selectedAddress: false,
  setCopybillingAddress: () => {},
  timeZoneBillingAddres: undefined
};

export default SortableTable;
