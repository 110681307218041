import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Api from '../../api';
import Password from './invitationFlow/Password';
import Welcome from './invitationFlow/Welcome';
import { removeLocalStorage } from '../../utils/authHelper';
import ProfileInfo from './invitationFlow/ProfileInfo';
import MfaRegistration from './invitationFlow/MfaRegistration';
import Success from './invitationFlow/Success';

interface Props {
  isCompanyInvitation: boolean;
  isMobile: boolean;
  login: () => void;
  setShowWelcomeImg: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: (data: any) => void;
}

const Invitation: React.FC<Props> = function Invitation({
  isMobile,
  login,
  setShowWelcomeImg,
  isCompanyInvitation,
  setUser
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { token } = useParams();
  const [fireApiToken, setFireApiToken] = useState<boolean>(false);
  const [errorApi, setErrorApi] = useState<boolean>(false);
  const navigate = useNavigate();

  // It will change the endpoint according if its normal invitation or company invitation
  const validationTokenAPI = isCompanyInvitation
    ? Api.authApi.validCompanyInvitation
    : Api.authApi.validInvitation;

  const { data: dataToken, error: errorToken } = useSWR(
    fireApiToken ? token : null,
    validationTokenAPI
  );

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  useEffect(() => {
    setError(false);
    removeLocalStorage('accessToken');
    if (!token) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invalidToken')}`);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } else {
      setFireApiToken(true);
    }
  }, []);

  useEffect(() => {
    if (dataToken && dataToken.status === 200) {
      setFireApiToken(false);
      if (isCompanyInvitation) {
        navigate(`/company/invitation/accept-invitation/${token}/1`);
      } else {
        navigate(`/invitation/accept-invitation/${token}/1`);
      }
    }
  }, [dataToken]);

  useEffect(() => {
    if (errorToken && errorToken.response) {
      const { data } = errorToken.response;
      setError(true);
      setErrorMessage(`${data.message}`);
      setFireApiToken(false);
      setErrorApi(true);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  }, [errorToken]);

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      {errorApi && getError()}
      {!errorApi && (
        <Routes>
          <Route
            element={
              <Welcome
                isCompany={isCompanyInvitation}
                isMobile={isMobile}
                setShowWelcomeImg={setShowWelcomeImg}
                token={token}
              />
            }
            path="/1"
          />
          <Route
            element={
              <Password
                isCompany={isCompanyInvitation}
                isMobile={isMobile}
                setErrorApi={setErrorApi}
                token={token}
              />
            }
            path="/2"
          />
          <Route
            element={
              <ProfileInfo
                isCompany={isCompanyInvitation}
                isMobile={isMobile}
                setErrorApi={setErrorApi}
                token={token}
              />
            }
            path="/3"
          />
          <Route
            element={
              <MfaRegistration
                isCompany={isCompanyInvitation}
                isMobile={isMobile}
                login={login}
                setErrorApi={setErrorApi}
                setUser={setUser}
                token={token}
              />
            }
            path="/4"
          />
          <Route
            element={
              <Success isMobile={isMobile} login={login} setUser={setUser} />
            }
            path="/5"
          />
        </Routes>
      )}
    </Container>
  );
};

export default Invitation;
