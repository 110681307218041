import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Container,
  Modal,
  Typography,
  SliderProps,
  TextField,
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { isMobile } from 'react-device-detect';
import CustomRadio from '../form/CustomRadio';
import {
  ButtonContainerModal,
  DrawerButton,
  ModalContainer
} from './GlobalConfirmModal';
import bblogo from '../../assets/images/Bluberry_Logo.png';
import Api from '../../api';

interface Props {
  proposalData: any;
  setRejected: React.Dispatch<React.SetStateAction<boolean>>;
  setRejectedProposal?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  setShowRejectModal: React.Dispatch<React.SetStateAction<any>>;
  setWantNewProposal?: React.Dispatch<React.SetStateAction<any>> | undefined;
  values: any;
  view: any;
}
const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-12px'
}));

const getProportion = (baseSize: number, proportion: number) =>
  isMobile ? baseSize * proportion : baseSize;

const RejectProposalModal: React.FC<Props> = function RejectProposalModal({
  proposalData,
  setRejected,
  setShowRejectModal,
  setRejectedProposal,
  setWantNewProposal,
  values,
  view
}) {
  const rejectProposalAction = Api.ProposalApi.rejectProposal;
  const { t } = useTranslation();
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const [sendReject, setSendReject] = useState<boolean>(false);
  const tokenParam = window.location.pathname.split('/')[2];
  const [rejectForm, setRejectForm] = useState<any>({
    getUpdatedProposal: true,
    rejectMessage: ''
  });

  const { data: rejecteProposalResponse, error: rejecteProposalError } = useSWR(
    sendReject
      ? {
          message: rejectForm.rejectMessage,
          requestUpdatedProposal: rejectForm.getUpdatedProposal,
          token: tokenParam
        }
      : null,
    rejectProposalAction
  );

  useEffect(() => {
    if (rejecteProposalResponse && !rejecteProposalError) {
      setRejected(true);
      if (view === 'client') {
        setSendReject(false);
      }
      setAuxManualClose(false);
      setShowRejectModal(false);
      if (view === 'client' && setRejectedProposal) {
        setRejectedProposal(true);
      }
      if (view === 'client' && setWantNewProposal) {
        setWantNewProposal(rejectForm.getUpdatedProposal);
      }
    }
  }, [rejecteProposalResponse]);

  return (
    <Container
      sx={{
        alignItems: 'normal',
        margin: '40px'
        // padding: '40px'
      }}
    >
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowRejectModal(false);
          setAuxManualClose(false);
        }}
        open={auxManualClose}
        sx={{
          alignItems: 'center'
        }}
      >
        <ModalContainer
          sx={{
            '@media (min-width: 600px)': {
              minWidth: '600px'
            },
            alignItems: 'center',
            // padding: '7rem',
            position: 'fixed',
            width: 'auto'
          }}
        >
          <Box
            justifyContent="space-between"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              width: '100%'
            }}
          >
            <Box>
              <img
                alt="bluberry logo"
                src={bblogo}
                style={{
                  height: getProportion(95, 0.8),
                  width: getProportion(320, 0.8)
                }}
              />
            </Box>
          </Box>
          <Box mt={6} sx={{ padding: '0 4rem' }}>
            <Box mb={2} mt={2}>
              <Typography variant="body_300">
                {t('LEAD.CREATE.PROPOSAL.rejectProposalModalText')}
              </Typography>
            </Box>
            <TextField
              multiline
              onChange={(value) => {
                setRejectForm({
                  ...rejectForm,
                  rejectMessage: value.target.value
                });
              }}
              sx={{
                '& fieldset': { border: 'none' },
                minHeight: '112px',
                width: '100%'
              }}
              value={rejectForm?.rejectMessage || ''}
            />
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', padding: '0 4rem', width: '100%' }}
          >
            <Box mt={2}>
              <Typography variant="body_300">
                {t('LEAD.CREATE.PROPOSAL.rejectProposalModalOptionText')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', padding: '0 4rem', width: '100%' }}>
            <Box mb={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(event: any) => {
                    setRejectForm({
                      ...rejectForm,
                      getUpdatedProposal: event.target.value === 'true'
                    });
                  }}
                >
                  <FormControlLabel
                    value
                    checked={rejectForm?.getUpdatedProposal}
                    control={<CustomRadio />}
                    label={
                      <Typography variant="primary">
                        {t('MODAL.confirModalYes')}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    checked={!rejectForm?.getUpdatedProposal}
                    control={<CustomRadio />}
                    label={
                      <Typography variant="primary">
                        {t('MODAL.confirmModalNo')}
                      </Typography>
                    }
                    value={false}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <br />
          <ButtonContainerModal
            sx={{
              alignItems: 'center',
              padding: '0 4rem',
              width: '100%'
            }}
          >
            <DrawerButton
              onClick={() => {
                setSendReject(true);
              }}
              sx={{ height: 46 }}
              variant="cancel"
            >
              <Typography>Reject Proposal</Typography>
            </DrawerButton>
          </ButtonContainerModal>
        </ModalContainer>
      </Modal>
    </Container>
  );
};
RejectProposalModal.defaultProps = {
  setRejectedProposal: undefined,
  setWantNewProposal: undefined
};
export default RejectProposalModal;
