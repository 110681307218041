import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Card, Grid, Typography } from '@mui/material';
import { useUsers, useMessages } from '@pubnub/react-chat-components';
import { DesktopStyle, MobileStyle } from './styles';

import { ChatCard, ChatParticipantsCard } from './components';
import AutoCompleteSearch from '../../components/input/AutoCompleteSearch';
import ChatContext from './ChatContext';

const Conversation: React.FC = function Conversation() {
  const [users, fetchPage, total, error, isLoading] = useUsers();
  const [selectedUser, setSelectedUser] = useState('');
  const [options, setOptions] = useState(users);

  const { createChat, pubnub, setCurrentChannel } = useContext(ChatContext);

  return (
    <Grid container spacing={0.5} sx={isMobile ? MobileStyle : DesktopStyle}>
      <Grid container item spacing={2} sx={{ flexBasis: 0, flexGrow: 1 }}>
        <Grid item height="auto" sx={{ flexBasis: 0.1, flexGrow: 1 }}>
          <Card sx={{ height: '100%', paddingX: 3, paddingY: 3 }}>
            <Typography
              color="primary.main"
              sx={{
                fontSize: isMobile ? 14 : 16,
                fontWeight: 700
              }}
              variant="title"
            >
              New Message
            </Typography>

            <AutoCompleteSearch
              options={options}
              setOptions={(h) => console.log('setOptions', h)}
              setSearch={async (selectedUser) => {
                if (!createChat || !setCurrentChannel) return;

                setOptions(() =>
                  selectedUser.length > 0
                    ? users.filter(({ name }) =>
                        name?.includes(selectedUser as string)
                      )
                    : users
                );

                const user = users.find(({ name }) => selectedUser === name);
                const { data } = await createChat([user?.id as string]);
                const { token, chatId } = data;

                if (token) pubnub?.setToken(token);
                if (chatId) setCurrentChannel(() => chatId);
              }}
              setValueSearched={setSelectedUser}
              value={selectedUser}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container item spacing={2} sx={{ flexGrow: 1 }}>
        <ChatCard />
        <ChatParticipantsCard />
      </Grid>
    </Grid>
  );
};

export default Conversation;
