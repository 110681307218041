/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import '../../style/App.scss';
import {
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  Typography,
  SlideProps,
  styled,
  SliderProps,
  TextField,
  Container,
  Grid
} from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import RegularInput from '../input/RegularInput';
import AutoCompleteInput from '../input/AutoCompleteInput';
import { capitalizeString } from '../../utils/general';
import Scroll from '../form/Scroll';
import api from '../../api';
import Loader from '../shared/Loader';
import ConfirmModal from './ConfirmModal';
import AutoCompleteSearch from '../input/AutoCompleteSearch';
// import { ServiceDTO } from '../../api-client/generated/api';

const ServiceButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  width: '216px'
}));

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  // height: '100%',
  maxHeight: '90%',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-10px'
}));

const SectionContainer = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '10px'
}));

interface ModalProps {
  action: (params: any) => Promise<any>;
  // clients?: Array<any>;
  getSelectedCustomer: () => void;
  handleClose: () => void;
  mutateAction: () => void;
  openValue: boolean;
  type: 'company' | 'user';
}

const SelectClientModal: React.FC<ModalProps> = function SelecetClientModal({
  openValue,
  handleClose,
  getSelectedCustomer,
  action,
  type,
  mutateAction
  // clients
}) {
  // We upperCase arrayType to use it in the placeHolder
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(openValue);
  const [error, setError] = useState<boolean>(false);
  const [valuesAutocomplete, setValuesAutocomplete] = useState<Array<any>>([]);

  const [fireAPIMatchCustomer, setFireAPIMatchCustomer] =
    useState<boolean>(false);

  const {
    data: synchCustomer,
    error: synchCustomerError,
    mutate: synchCustomerMutate
  } = useSWR(
    // fireAPIMatchCustomer ? valuesAutocomplete : null,
    valuesAutocomplete,
    api.IntegrationsApi.matchCustomer
  );
  console.log(valuesAutocomplete);

  const { data: dataGetClient, error: dataClientError } = useSWR(
    'getClients',
    api.ClientApi.getClients
  );

  const getCompanyInviteContent = () => (
    <Box sx={{ marginTop: '30px' }}>
      <SubTitle>{capitalizeString(t('INVITATION.client'))}*</SubTitle>
      <Box sx={{ margin: '0px 0px 5px' }}>
        {/* <AutoCompleteSearch */}
        <AutoCompleteInput
          onChange={(event: any, newValue: any | null) => {
            console.log(newValue);
            setValuesAutocomplete(newValue);
          }}
          options={
            dataGetClient?.map((client) => {
              const customerSelected: any = getSelectedCustomer();
              return {
                id: client.id,
                clientId: client.id,
                customerId: customerSelected.customerId,
                name: `${client.firstName} ${client.lastName} - ${client.businessName}`
              };
            }) ?? []
          }
          renderInput={(params) => (
            <TextField
              // error={value.roles && value.clients.length === 0 && error}
              placeholder={t('INVITATION.client')}
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'hidden'
                },
                borderStyle: 'hidden',
                fontSize: '15px'
              }}
              {...params}
            />
          )}
          value={valuesAutocomplete}
        />
      </Box>
    </Box>
  );

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      // onClose={beforeLeave}
      open={open}
      // sx={{ maxHeight: '100%' }}
    >
      <Fade in={openValue}>
        <ModalContainer>
          <SectionContainer>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 'bolder',
                variant: 'title'
              }}
            >
              Match Client
            </Typography>
            <HighlightOffOutlinedIcon
              sx={{ color: 'mainText.main', cursor: 'pointer' }}
            />
          </SectionContainer>
          <Box
            sx={{
              height: '75%',
              marginTop: 3
            }}
          >
            <Scroll>
              <>{getCompanyInviteContent()}</>
            </Scroll>
          </Box>
          <SectionContainer>
            <ServiceButton
              color="error"
              onClick={() => {
                handleClose();
                setError(false);
              }}
              variant="contained"
            >
              <Typography>{t('MODAL.cancel')}</Typography>
            </ServiceButton>
            <ServiceButton
              color="success"
              // onClick={handleCreate}
              onClick={() => {
                console.log('valuesAutocomplete: ', valuesAutocomplete);
                // setFireAPIMatchCustomer(true);
                // mutateAction();
                // handleClose();
                synchCustomerMutate();
                handleClose();
              }}
              variant="contained"
            >
              <Typography>{t('MODAL.match')}</Typography>
            </ServiceButton>
          </SectionContainer>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

SelectClientModal.defaultProps = {
  // clients: [],
};

export default SelectClientModal;
