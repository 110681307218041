/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  styled,
  SliderProps,
  useTheme,
  Stepper,
  Step,
  StepButton,
  Fab,
  Modal,
  Checkbox,
  Paper,
  Collapse,
  ButtonBase,
  StepLabel
} from '@mui/material';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useReactToPrint } from 'react-to-print';
import Api from '../../../api';
import PageNotFound from '../../../components/shared/PageNotFound';
import ExpiredProposalLink from './components/propoosal/ExpiredProposalLink';
import AcceptedProposal from './components/propoosal/AcceptedProposal';
import useAuthStore from '../../../store/zustand/auth';
import ProposalTitle from '../../../components/proposal/Title';
import ProposalGeneralInformation from '../../../components/proposal/GeneralInformation';
import SummaryService from './components/SummaryService';
import Loader from '../../../components/shared/Loader';
import TableComponent from '../../../components/shared/TableComponent';
import { getCurrency } from '../../../utils/general';
import Chat from './components/Chat';
import RejectProposalModal from '../../../components/modals/RejectProposalModal';
import PdfViewerComponent from '../../../components/shared/container/pdfViewerContainer';
import { ModalContainer } from '../../../components/modals/GlobalConfirmModal';
import ProposalNotFound from './components/propoosal/ProposalNotFound';
import RejectedProposal from './components/propoosal/RejectedProposal';
import CounterOfferMain from './components/propoosal/CounterOfferMain';
import useNavigationStore from '../../../store/zustand/navigation';

const ButtonsContainer = styled(Box)<SliderProps>(() => ({
  display: 'flex',
  float: 'right',
  justifyContent: 'space-between'
}));

const ButtonsContainerCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(3),
  width: '100%'
}));

interface ClientProposalViewProps {
  closeModal?: () => void;
  proposalInfo?: any;
}

const steps = ['General Information', 'Services', 'Billing', 'Legal'];

const ClientProposalView: React.FC<ClientProposalViewProps> =
  function ClientProposalView({ proposalInfo, closeModal }) {
    const admin = false;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigation = useNavigationStore((state) => state);
    const [accessByToken, setAccessByToken] = useState<boolean>(false);
    const [sentFlag, setSentFlag] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [expiredProposal, setExpiredProposal] = useState<boolean>(false);
    const [accepted, setAccepted] = useState<boolean>(false);
    const [rejected, setRejected] = useState<boolean>(false);
    const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [acceptProposal, setAcceptProposal] = useState<boolean>(false);
    const [showChatModal, setShowChatModal] = useState<boolean>(false);
    const [acceptedProposal, setAcceptedProposal] = useState<boolean>(false);
    const [rejectedProposal, setRejectedProposal] = useState<boolean>(false);
    const [proposalId, setProposalId] = useState<string>('');
    const [values, setValues] = useState<any>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [acceptedTerms, setAcceptTerms] = useState<boolean>(false);
    const [termsPdfURL, setTermsPdfURL] = useState<string>('');
    const [brochurePdfURL, setBrochurePdfURL] = useState<string>('');
    const [wantNewProposal, setWantNewProposal] = useState<boolean>(false);
    const [openChat, setOpenChat] = useState<boolean>(true);
    const [counterOfferMode, setCounterOfferMode] = useState<boolean>(false);
    const [counterOfferSubmitted, setCounterOfferSubmitted] =
      useState<boolean>(false);

    const tokenParam = window.location.pathname.split('/')[2];

    const { data: proposalData, mutate: proposalMutate } = useSWR(
      sentFlag ? tokenParam : null,
      Api.ProposalApi.getProposalByToken
    ) as any;

    const acceptProposalAction = Api.ProposalApi.acceptProposal;

    const { data: acceptProposalData, error: acceptProposalDataError } = useSWR(
      acceptProposal ? { token: tokenParam } : null,
      acceptProposalAction
    );

    const [activeStep, setActiveStep] = React.useState(0);

    const [completed, setCompleted] = React.useState<{
      [k: number]: boolean;
    }>({});

    const totalSteps = () => {
      return steps.length;
    };

    const completedSteps = () => {
      return Object.keys(completed).length;
    };

    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };

    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
      setActiveStep(step);
      window.scrollTo(0, 0);
    };

    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };

    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    const getButtons = () => {
      if (values?.counterOfferSubmitted && accessByToken) {
        return (
          <Box>
            <Typography color="primary" variant="primary">
              {t('LEAD.CREATE.PROPOSAL.counterOfferSubmited')}
            </Typography>
          </Box>
        );
      }
      if (!accessByToken) {
        return (
          <ButtonsContainerCentered>
            <Button
              onClick={() => {
                if (closeModal) {
                  closeModal();
                }
              }}
              sx={{ width: 200 }}
              variant="cancel"
            >
              {t('GENERAL.closeButton')}
            </Button>
          </ButtonsContainerCentered>
        );
      }
      return (
        <ButtonsContainer width="100%">
          {accepted && !rejected && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              mb={4}
              mt={-3}
              width="100%"
            >
              <Typography sx={{ fontSize: 18 }} variant="primary">
                {t('LEAD.CREATE.PROPOSAL.buttonContinueTermsConditions')}
              </Typography>
              <Button
                onClick={() => setOpenSuccessful(true)}
                sx={{ m: 2, width: '24%' }}
                variant="primary"
              >
                {t('LEAD.CREATE.PROPOSAL.buttonNext')}
              </Button>
            </Box>
          )}

          {rejected && !accepted && (
            <Box
              alignItems="left"
              display="flex"
              justifyContent="flex-start"
              width="100%"
            >
              <Typography
                sx={{ fontSize: 18, marginRight: 4 }}
                variant="primary"
              >
                {t('LEAD.CREATE.PROPOSAL.rejectedProposalText')}
              </Typography>
            </Box>
          )}

          {!accepted && !rejected && (
            <Box
              display="flex"
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center'
              }}
              width="100%"
            >
              {counterOfferSubmitted && (
                <Box>
                  <Typography color="primary" variant="primary">
                    {t(
                      'LEAD.CREATE.PROPOSAL.COUNTEROFFER_REVIEW.blockActionByCounterOfferSubtitle'
                    )}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  fullWidth
                  disabled={!acceptedTerms || counterOfferSubmitted}
                  onClick={() => setShowRejectModal(true)}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    m: 1,
                    width: 200
                  }}
                  variant="cancel"
                >
                  {t('LEAD.CREATE.PROPOSAL.buttonClientDecline')}
                </Button>
                <Button
                  fullWidth
                  disabled={!acceptedTerms || counterOfferSubmitted}
                  onClick={() => setAcceptProposal(true)}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    m: 1,
                    width: 200
                  }}
                  variant="primary"
                >
                  {t('LEAD.CREATE.PROPOSAL.buttonClientAccept')}
                </Button>
              </Box>
            </Box>
          )}
        </ButtonsContainer>
      );
    };

    const getCounterOfferButton = () => {
      if (
        !values?.allowCounterOffers ||
        values?.counterOfferSubmitted ||
        counterOfferSubmitted
      ) {
        return <Box />;
      }
      return (
        <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <Button
            onClick={() => {
              setCounterOfferMode(true);
              setOpenChat(false);
            }}
            variant="tertiary-outlined"
          >
            <Typography variant="primary">
              {t('LEAD.CREATE.PROPOSAL.makeCounterOffer')}
            </Typography>
          </Button>
        </Box>
      );
    };

    const getServiceTimeSummary = () => {
      const { lead } = values;
      return (
        <Box>
          {getCounterOfferButton()}
          {lead && lead?.serviceTimes && lead?.serviceTimes.length > 0 && (
            <Box width="100%">
              {lead?.serviceTimes?.map((serviceTime: any, index: number) => {
                if (typeof serviceTime.id === 'number') {
                  return (
                    <SummaryService
                      clientView
                      validQuotePrice
                      index={index}
                      key={`service-time${index}`}
                      serviceTime={{ ...serviceTime }}
                      setNewMargin={() => {}}
                      setNewMarginCost={() => {}}
                      setTotalcostProject={() => {}}
                      setTotalcostProjectCost={() => {}}
                      setTotalcostvisit={() => {}}
                      setValidQuotePrice={() => {}}
                      setValues={() => {}}
                      totalcostProject={0}
                      totalcostProjectCost={serviceTime?.sellPrice}
                      totalvisit={
                        serviceTime.availability?.generalInfo
                          ?.daysOfServiceCount || 0
                      }
                      type="proposal"
                      values={lead}
                    />
                  );
                }

                return null;
              })}
            </Box>
          )}
        </Box>
      );
    };

    const pricingSummary = () => {
      const { lead } = values;
      let proposalTotal = 0;
      const data = lead?.serviceTimes?.map((service: any, index: number) => {
        const amount = service?.sellPrice || 0;

        proposalTotal += amount;
        return {
          amount: getCurrency(amount),
          serviceTitle: `${t(
            'LEAD.CREATE.PROPOSAL.tablePricingBillingService'
          )} #${index + 1}: (${service?.frequency} with ${
            service?.availability?.generalInfo?.daysOfServiceCount
          } visits)`
        };
      });
      const data2 = [
        {
          amount: getCurrency(values?.depositAmount || 0),
          detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSecurityDeposit')
        },
        {
          amount: getCurrency(proposalTotal),
          detail: t(
            'LEAD.CREATE.PROPOSAL.tableInvoiceSecurityDepositAlternative'
          )
        },
        // {
        //   amount: `$ ${TotalMisc.current || 0} `,
        //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceTotalMiscelaneoues')
        // },
        // {
        //   amount: `$ ${TotalCosttaxt.current || 0} `,
        //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSalesTaxable')
        // },
        // {
        //   amount: maskData(TotalCosttaxt.current || 0),
        //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceCollectedTax')
        // },
        {
          amount: getCurrency(proposalTotal),
          detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceTotal')
        }
      ];

      const isItemLoaded = (index: any) => {
        if (undefined !== data && undefined !== data[index]) {
          return data[index];
        }
        return false;
      };

      return (
        <Box>
          {getCounterOfferButton()}
          <Box mt={6}>
            <Typography variant="body_500">
              {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable1')}
            </Typography>
          </Box>
          <Box mt={2}>
            <TableComponent
              fullSize
              action={() => {}}
              headKeys={['serviceTitle', 'amount']}
              isItemLoaded={isItemLoaded}
              itemCount={1}
              loadMoreItems={() => {}}
              valuesArray={{ data } as unknown as { data: any[] }}
            />
          </Box>
          <Box mt={6}>
            <Typography variant="body_500">
              {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable2')}
            </Typography>
          </Box>
          <Box mt={2}>
            <TableComponent
              fullSize
              action={() => {}}
              headKeys={['detail', 'amount']}
              isItemLoaded={isItemLoaded}
              itemCount={1}
              loadMoreItems={() => {}}
              valuesArray={{ data: data2 }}
            />
          </Box>
        </Box>
      );
    };

    const parseProposalInfomation = (proposalData: any) => {
      const {
        fileBank,
        proposal: data,
        counterOfferSubmitted: blockProposalActions
      } = proposalData.data;
      setSentFlag(false);
      setProposalId(data?.id);
      setCounterOfferSubmitted(blockProposalActions);
      const { status } = data;
      if (!admin && (status === 'approved' || status === 'rejected')) {
        setNotFound(true);
        setLoading(false);
        return;
      }

      const newServTimes =
        data?.lead?.serviceTimes?.length > 0
          ? data?.lead?.serviceTimes?.map((serviceTime: any) => {
              const newServicesResponse =
                Api.LeadServiceTimesApi.newServicesResponseFunc(
                  serviceTime.services
                );
              let sellPrice = 0;
              data?.proposalServiceTime.forEach((item: any) => {
                if (serviceTime.id === item.leadServiceTime?.id) {
                  sellPrice = item.pricePerVisit;
                }
              });
              return {
                ...serviceTime,
                projectEnd: data?.lead?.projectEndDate,
                projectStart: data?.lead?.projectStartDate,
                sellPrice,
                services: newServicesResponse.newServs,
                totalTime: newServicesResponse.totalTime
              };
            })
          : [];
      const auxNewValues = {
        ...data,
        counterOfferSubmitted,
        lead: {
          ...data.lead,
          client: {
            ...data.lead.client,
            name: `${data?.lead?.client?.firstName} ${data?.lead?.client?.lastName} from ${data?.lead?.client?.businessName}`
          },
          endDate: data?.lead?.projectEndDate,
          profit: data?.lead?.profitPercentage,
          serviceTimes: newServTimes,
          startDate: data?.lead?.projectStartDate
        }
      };
      setValues(auxNewValues);

      if (fileBank && fileBank.length > 0) {
        const termsPdf = fileBank.filter(
          (item: any) => item.metaDescription === 'termsAndConditions'
        );
        setTermsPdfURL(termsPdf[0]?.fileUrl);

        const brochurePdf = fileBank.filter(
          (item: any) => item.metaDescription === 'companyBrouchure'
        );

        setBrochurePdfURL(brochurePdf[0]?.fileUrl);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    const getChatModal = () => {
      if (!showChatModal) {
        return (
          <Fab
            aria-label="add"
            color="primary"
            onClick={() => setShowChatModal(true)}
            sx={{ position: 'absolute', right: 40, top: 260 }}
          >
            <i className="custom-icon icon-chat" />
          </Fab>
        );
      }
      return (
        <Container
          sx={{
            alignItems: 'normal',
            margin: '40px'
            // padding: '40px'
          }}
        >
          <Modal
            onClose={() => {
              setShowChatModal(false);
            }}
            open={showChatModal}
            sx={{
              alignItems: 'center'
            }}
          >
            <ModalContainer
              sx={{
                '@media (min-width: 900px)': {
                  minWidth: '900px'
                },
                alignItems: 'center',
                // padding: '7rem',
                position: 'fixed',
                width: 'auto'
              }}
            >
              <Box
                border={`1px solid ${theme.palette.secondary[100]}`}
                borderRadius={4}
                display="flex"
                maxHeight={600}
                pt={4}
                style={{ overflow: 'hidden' }}
                width="90%"
              >
                <Chat
                  clientView={!admin}
                  id={tokenParam || values.id}
                  messages={values.proposalMessages}
                  updateMessages={(proposalMessages: any) => {
                    setValues({ ...values, proposalMessages });
                  }}
                />
              </Box>
            </ModalContainer>
          </Modal>
        </Container>
      );
    };

    const getPageMargins = () => {
      return `
        @page { 
          margin: 30px 30px 30px 30px !important; 
          size: auto; 
        } 
        @media all {
          .page-break {
            display: none;
          }
        }
        @media print {
          html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
          }
        }
        @media print { 
          .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: auto;
          }
        }
      `;
    };

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
      content: () => componentRef?.current,
      documentTitle: 'BluberryProposal',
      pageStyle: getPageMargins
    });

    const getGeneralNavigation = () => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Button disabled={activeStep === 0} onClick={handleBack}>
              {t('GENERAL.backButton')}
            </Button>
            <Button onClick={handleNext}>{t('GENERAL.nextButton')}</Button>
          </Box>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step completed={completed[index]} key={label}>
                <StepButton color="inherit" onClick={handleStep(index)} />
              </Step>
            ))}
          </Stepper>
        </Box>
      );
    };

    const getDivider = () => {
      return (
        <Box
          sx={{
            borderBottom: `2px solid ${theme.palette.mainText[400]}`,
            marginTop: theme.spacing(4),
            width: '100%'
          }}
        />
      );
    };

    const getProposalBody = () => {
      return (
        <Box>
          {getGeneralNavigation()}
          {values && activeStep === 0 && (
            <ProposalGeneralInformation clientView values={values?.lead} />
          )}
          {isMobile && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <PdfViewerComponent
                file={activeStep === 2 ? termsPdfURL : brochurePdfURL}
                title={
                  activeStep === 2
                    ? `${t('LEAD.CREATE.PROPOSAL.titleTerms')}`
                    : ''
                }
              />
            </Box>
          )}
          {values && activeStep === 1 && getServiceTimeSummary()}
          {values && activeStep === 2 && pricingSummary()}
          {activeStep === 3 && (
            <>
              <Box alignItems="center" display="flex">
                <Checkbox
                  checked={acceptedTerms}
                  onChange={() => setAcceptTerms(!acceptedTerms)}
                  style={{
                    color: theme.palette.primary.main
                  }}
                />
                <Typography>{t('LEAD.CREATE.PROPOSAL.acceptTerms')}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2
                }}
              >
                {getButtons()}
              </Box>
            </>
          )}
        </Box>
      );
    };

    const getCounterOfferComponent = () => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <Typography
              color="primary"
              fontSize={32}
              sx={{ lineHeight: 1 }}
              variant="heading_400"
            >
              {t('LEAD.CREATE.PROPOSAL.counterOfferTitle')}
            </Typography>
          </Box>
          <Box pb={3} pt={3}>
            {values?.lead?.serviceTimes && (
              <CounterOfferMain
                clientView
                mutate={() => {
                  setValues({ ...values, counterOfferSubmitted: true });
                  setCounterOfferSubmitted(true);
                }}
                onClose={() => {
                  setCounterOfferMode(false);
                }}
                serviceTimes={values?.lead?.serviceTimes}
                token={tokenParam}
              />
            )}
          </Box>
        </Box>
      );
    };

    // ======================================================================== LIFECYCLE

    useEffect(() => {
      if (acceptProposalData && !acceptProposalDataError) {
        setAcceptProposal(false);
        setAcceptedProposal(true);
      }
    }, [acceptProposalData]);

    useEffect(() => {
      if (proposalData?.data) {
        setAccessByToken(true);
        if (proposalData?.data?.error) {
          setSentFlag(false);
          setNotFound(true);
          setLoading(false);
        } else {
          parseProposalInfomation(proposalData);
        }
      }
    }, [proposalData]);

    useEffect(() => {
      if (proposalInfo) {
        parseProposalInfomation({ data: proposalInfo });
      }
    }, [proposalInfo]);

    useEffect(() => {
      if (!proposalData && !admin) {
        navigation.setCurrentStepScreen('proposal-client-view');
        setSentFlag(true);
      }
      return () => {
        navigation.setCurrentStepScreen('');
      };
    }, []);

    // ======================================================================== RENDER

    if (loading) {
      return <Loader customColor="primary" customSize={48} />;
    }

    return (
      <Container
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {!notFound &&
          !expiredProposal &&
          !acceptedProposal &&
          !rejectedProposal &&
          values &&
          values?.id && (
            <Box
              p={isMobile ? 1 : 3}
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                height: '100%',
                justifyContent: 'space-around',
                overflow: 'scroll',
                overflowX: 'hidden',
                width: '100%'
              }}
            >
              {!isMobile && (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    width: '50%'
                  }}
                >
                  <PdfViewerComponent
                    file={activeStep === 3 ? termsPdfURL : brochurePdfURL}
                    title={activeStep === 3 ? 'Terms and Conditions' : ''}
                  />
                </Box>
              )}
              <Box
                sx={{
                  height: '100%',
                  paddingX: 1,
                  width: isMobile ? '100%' : '50%'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <ProposalTitle clientView previewFlag values={values?.lead} />
                  <Button onClick={handlePrint}>
                    {t('LEAD.CREATE.PROPOSAL.downloadProposalPDF')}
                  </Button>
                </Box>
                <Paper sx={{ marginTop: 2, maxHeight: 400 }}>
                  <Button
                    onClick={() => {
                      setOpenChat(!openChat);
                    }}
                    sx={{
                      gap: 1,
                      width: '100%'
                    }}
                    variant="text"
                  >
                    <i className="custom-icon icon-chat" />
                    {openChat && t('LEAD.CREATE.PROPOSAL.CHAT.close')}
                    {!openChat && t('LEAD.CREATE.PROPOSAL.CHAT.open')}
                  </Button>
                  <Collapse in={openChat}>
                    <Box sx={{ height: 300, overflow: 'scroll' }}>
                      <Chat
                        clientView={!admin}
                        id={tokenParam || values.id}
                        messages={values.proposalMessages}
                        updateMessages={(proposalMessages: any) => {
                          setValues({ ...values, proposalMessages });
                        }}
                      />
                    </Box>
                  </Collapse>
                </Paper>
                {getDivider()}
                {!counterOfferMode && getProposalBody()}
                {counterOfferMode && getCounterOfferComponent()}
              </Box>
            </Box>
          )}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          {notFound && <ProposalNotFound />}
          {expiredProposal && proposalId && (
            <ExpiredProposalLink proposalId={proposalId} />
          )}
          {showRejectModal && (
            <RejectProposalModal
              proposalData={proposalData}
              setRejected={setRejected}
              setRejectedProposal={setRejectedProposal}
              setShowRejectModal={setShowRejectModal}
              setWantNewProposal={setWantNewProposal}
              values={values}
              view="client"
            />
          )}
          {rejectedProposal && (
            <RejectedProposal wantNewProposal={wantNewProposal} />
          )}
          {acceptedProposal && proposalId && (
            <AcceptedProposal proposalId={proposalId} />
          )}
        </Box>
        {
          // PDF view
        }
        <div style={{ display: 'none' }}>
          <Box ref={componentRef}>
            <ProposalTitle clientView previewFlag values={values?.lead} />
            {values && (
              <ProposalGeneralInformation clientView values={values?.lead} />
            )}

            <Box
              sx={{
                borderBottom: `2px solid ${theme.palette.mainText[400]}`,
                marginBottom: theme.spacing(4),
                marginTop: theme.spacing(1),
                width: '100%'
              }}
            />
            {values && getServiceTimeSummary()}
            {values && pricingSummary()}
          </Box>
        </div>
      </Container>
    );
  };

ClientProposalView.defaultProps = {
  closeModal: () => {},
  proposalInfo: null
};

export default ClientProposalView;
