import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import ManualPricing from './services/ManualPricing';
import ServiceTime from './services/ServiceTime';
import useNavigationStore from '../../../store/zustand/navigation';
import { getFrequencyWithDays } from '../../../utils/general';
import Api from '../../../api';
import { CreateLeadSWR, EditLeadSWR } from './components/LeadSWR';

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setServiceIndex: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const Services: React.FC<Props> = function Services({
  saveStepper,
  sendValues,
  setServiceIndex,
  setValues,
  values
}) {
  const theme = useTheme();
  const [step, setStep] = useState<number>(1);
  const [serviceTimeId, setServiceTimeId] = useState<number>(0);
  const [currentServiceTime, setCurrentServiceTime] = useState<number>(-1);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [createServiceTime, setCreateServiceTime] = useState<boolean>(false);
  const [serviceTimeUpdate, setServiceTimeUpdate] = useState<boolean>(false);
  const [dataresult, setDataresult] = useState<any>(null);
  const [dataresultEdit, setDataresultEdit] = useState<any>(null);
  const [letDeleteServiceTimeId, setLetDeleteServiceTimeId] =
    useState<number>(-1);
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const { id } = values;
  const [serviceTimeApproved, setServiceTimeApproved] =
    useState<boolean>(false);

  useEffect(() => {
    setServiceTimeUpdate(false);
    setCreateServiceTime(false);
    if (values.manualPricing || values.projectType === 'Ad-Hoc') {
      setCurrentServiceTime(0);
      setServiceTimeId(values.serviceTimes[0]?.id);
      setStep(1);
    } else {
      setCurrentServiceTime(-1);
      if (
        !values.serviceTimes ||
        (values.serviceTimes &&
          (values.serviceTimes?.length === 0 || !values.serviceTimes[0]?.id))
      ) {
        setStep(1);
      }
    }
    if (step === 1 && currentServiceTime === -1) {
      if (values?.serviceTimes?.length > 0) {
        const cleanedServicesTimes = values?.serviceTimes?.filter(
          (item: any) => item?.id && typeof item?.id === 'number'
        );
        setValues({ ...values, serviceTimes: cleanedServicesTimes });
      }
    }
  }, []);

  useEffect(() => {
    if (dataresult && dataresult.id) {
      setCreateServiceTime(false);
      setServiceTimeUpdate(false);
      setServiceTimeId(dataresult.id);
      setCurrentServiceTime(dataresult.id);

      const newAuxServiceTimes = values.serviceTimes;

      newAuxServiceTimes[currentServiceTime] = dataresult;

      setValues({
        ...values,
        serviceTimes: newAuxServiceTimes
      });
      setDataresult(null);
      setCurrentServiceTime(-1);
      saveStepper({ next: 1 });
    }
  }, [dataresult]);

  useEffect(() => {
    if (dataresultEdit && dataresultEdit?.id) {
      setCreateServiceTime(false);
      setServiceTimeUpdate(false);
      // setServiceTimeId(values.serviceTimes[currentServiceTime].id);
      setCurrentServiceTime(-1);
      const newAuxServiceTimes = values.serviceTimes;
      newAuxServiceTimes[currentServiceTime] = dataresultEdit;

      setValues({
        ...values,
        serviceTimes: newAuxServiceTimes
      });

      setDataresultEdit(null);
      if (values.manualPricing || values.projectType === 'Ad-Hoc') {
        // setServiceTimeId(values.serviceTimes[0].id);
        saveStepper({ next: 2 });
      } else {
        saveStepper({ next: 1 });
      }
    }
  }, [dataresultEdit]);

  const { data: deleteResult } = useSWR(
    letDeleteServiceTimeId > -1
      ? { id, serviceTimeId: letDeleteServiceTimeId }
      : null,
    (...args) => Api.LeadServiceTimesApi.deleteLeadServicesTime(...args)
  );

  useEffect(() => {
    if (values?.serviceTimes?.length > 0) {
      const cleanedServicesTimes = values?.serviceTimes?.filter(
        (item: any) => item?.id && typeof item?.id === 'number'
      );
      setValues({ ...values, serviceTimes: cleanedServicesTimes });
    }
    setLetDeleteServiceTimeId(-1);
  }, [deleteResult]);

  function getManualPricing() {
    return (
      <ManualPricing
        saveStepper={saveStepper}
        setServiceTimeUpdate={setServiceTimeUpdate}
        setValues={setValues}
        values={values}
      />
    );
  }

  const getScreen = () => {
    if (currentServiceTime !== -1) {
      switch (step) {
        case 1:
          return (
            <ServiceTime
              indexServiceTime={currentServiceTime}
              projectEnd={values.projectEndDate}
              projectStart={values.projectStartDate}
              projectType={values.projectType || ''}
              saveStepper={saveStepper}
              serviceTimeId={serviceTimeId}
              setCreateServiceTime={setCreateServiceTime}
              setCurrentServiceTime={setCurrentServiceTime}
              setServiceTimeUpdate={setServiceTimeUpdate}
              setValues={(newValues) => {
                const { serviceTimes } = values;
                serviceTimes[currentServiceTime] = newValues;
                setValues({ ...values, serviceTimes });
              }}
              values={values}
            />
          );

        default:
          return 'Loading';
      }
    }
    return <Box />;
  };

  const getServices = (serviceTime: any) => {
    const { services } = serviceTime;

    if (services && services.length > 0) {
      let auxServices = '';
      services.map((service: any) => {
        if (service?.name && service?.name !== '') {
          if (auxServices === '') {
            auxServices = service.name;
          } else {
            auxServices = `${auxServices}, ${service.name}`;
          }
        }

        return null;
      });

      if (auxServices !== '') {
        return auxServices;
      }
    }

    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getIndividualTasks = (serviceTime: any) => {
    const { individualTasks } = serviceTime || {};
    if (
      individualTasks &&
      individualTasks?.tasks &&
      individualTasks?.tasks?.length > 0
    ) {
      return `${individualTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const deleteServiceTime = (index: number) => {
    setConfirmModal(
      () => {
        const { serviceTimes } = values;
        setLetDeleteServiceTimeId(serviceTimes[index].id);
        serviceTimes.splice(index, 1);
        setValues({ ...values, serviceTimes });
      },
      `${t('GENERAL.confirmButton')}`,
      () => {},
      `${t('GENERAL.cancelButton')}`,
      <Box display="flex" flexDirection="column" pl={8} pr={8}>
        <Box p={2}>
          <i className="custom-icon icon-info" style={{ fontSize: 48 }} />
        </Box>
        <Typography variant="primary">
          {t('LEAD.CREATE.SERVICES.SUMMARY.confirmDelete')}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    let auxServiceTimeApproved = true;
    if (values?.serviceTimes && values?.serviceTimes?.length > 0) {
      values.serviceTimes.forEach((serviceTime: any) => {
        if (!serviceTime?.scheduled && !serviceTime?.isScheduled) {
          auxServiceTimeApproved = false;
        }
      });
    } else {
      auxServiceTimeApproved = false;
    }
    setServiceTimeApproved(auxServiceTimeApproved);
  }, [values]);

  const getStatusColor = (status: string) => {
    return values.status !== 'Converted'
      ? `${theme.palette.success.main}`
      : `${theme.palette.success[300]}`;
  };

  const getSummay = () => {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="title">
          {t('LEAD.CREATE.SERVICES.SUMMARY.title')}
        </Typography>
        <Typography variant="body_200">
          {t('LEAD.CREATE.SERVICES.SUMMARY.subtitle')}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={values?.readOnly}
            onClick={() => {
              const { serviceTimes } = values;
              if (
                values &&
                values?.serviceTimes[0] &&
                values?.serviceTimes[0]?.id
              ) {
                serviceTimes.push({});
              }
              setValues({ ...values, serviceTimes });
              setServiceTimeId(-1);
              setCurrentServiceTime(
                values?.serviceTimes?.length > 0 && values?.serviceTimes[0]?.id
                  ? (values?.serviceTimes?.length || 0) - 1
                  : 0
              );
            }}
            variant="primary"
          >
            <Typography>
              {t('LEAD.CREATE.SERVICES.SUMMARY.addServiceTimeButton')}
            </Typography>
          </Button>
        </Box>
        {values?.serviceTimes && values?.serviceTimes.length > 0 && (
          <Box width="100%">
            {values.serviceTimes
              .sort((a: any, b: any) => a.id - b.id)
              .map((serviceTime: any, index: number) => {
                if (serviceTime?.id && typeof serviceTime?.id === 'number') {
                  return (
                    <Box
                      key={`min-box-${index + 1}`}
                      sx={{
                        border: 1,
                        borderColor: theme.palette.secondary[400],
                        borderRadius: 1,
                        mt: 5,
                        p: 2
                      }}
                      width="100%"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Box>
                          <Box mb={2}>
                            <Typography variant="body_500">
                              {`${t(
                                'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                              )} #${index + 1}`}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography variant="primary-bold">
                              {values.projectType !== 'Ad-Hoc'
                                ? t('LEAD.CREATE.SERVICES.SUMMARY.frequency')
                                : 'Service Day'}
                              :
                            </Typography>
                            &nbsp;
                            <Typography variant="primary">
                              {values.projectType !== 'Ad-Hoc'
                                ? getFrequencyWithDays(serviceTime, t)
                                : serviceTime.startDate}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography variant="primary-bold">
                              {t('LEAD.CREATE.SERVICES.SUMMARY.services')}:
                            </Typography>
                            &nbsp;
                            <Typography variant="primary">
                              {getServices(serviceTime)}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography variant="primary-bold">
                              {t('LEAD.CREATE.SERVICES.SUMMARY.adhocTasks')}:
                            </Typography>
                            &nbsp;
                            <Typography variant="primary">
                              {getIndividualTasks(serviceTime)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Box
                            display="flex"
                            gap={1}
                            justifyContent="space-between"
                            p={1}
                          >
                            <Button
                              disabled={values?.readOnly}
                              onClick={() => {
                                setCreateServiceTime(false);
                                setCurrentServiceTime(index);
                                setServiceTimeId(serviceTime.id);
                                setServiceTimeUpdate(false);
                              }}
                              sx={{ width: 100 }}
                              variant="primary"
                            >
                              {t('GENERAL.editButton')}
                            </Button>
                            <Button
                              disabled={values?.readOnly}
                              onClick={() => {
                                deleteServiceTime(index);
                              }}
                              sx={{ width: 100 }}
                              variant="cancel"
                            >
                              {t('GENERAL.deleteButton')}
                            </Button>
                          </Box>
                          <Box
                            alignItems="center"
                            borderRadius={2}
                            display="flex"
                            height={40}
                            justifyContent="center"
                            onClick={() => {
                              if (!values?.readOnly) {
                                setServiceIndex(index);
                                saveStepper({ next: 2 });
                              }
                            }}
                            sx={{
                              backgroundColor:
                                serviceTime.scheduled || serviceTime.isScheduled
                                  ? getStatusColor(values.status)
                                  : theme.palette.warning?.main,
                              cursor: values?.readOnly ? 'default' : 'pointer'
                            }}
                            width={210}
                          >
                            <Typography color="mainText.100" variant="primary">
                              {serviceTime.scheduled || serviceTime.isScheduled
                                ? t('LEAD.CREATE.SERVICES.SUMMARY.scheduled')
                                : t('LEAD.CREATE.SERVICES.SUMMARY.pending')}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                }

                return null;
              })}
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mt={2}
        >
          ¨
          {!serviceTimeApproved && (
            <Box
              alignItems="center"
              display="flex"
              m={2}
              p={2}
              sx={{
                backgroundColor: theme.palette.warning?.[100],
                border: 3,
                borderColor: theme.palette.warning?.[400],
                borderRadius: 1
              }}
            >
              <Box pl={2} pr={3}>
                <i
                  className="custom-icon icon-info"
                  style={{ color: theme.palette.warning?.[400], fontSize: 34 }}
                />
              </Box>
              <Typography>
                {t('LEAD.CREATE.SERVICES.SUMMARY.warning')}
              </Typography>
            </Box>
          )}
          <Box>
            <Button
              disabled={!serviceTimeApproved || values?.readOnly}
              onClick={() => {
                saveStepper({ next: 3 });
              }}
              sx={{ width: 300 }}
              variant="primary"
            >
              <Typography variant="primary">
                {t('LEAD.CREATE.SERVICES.SUMMARY.continueButton')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (currentServiceTime === -1) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [currentServiceTime]);

  useEffect(() => {
    if (`${showSummary}` === 'true') {
      if (values?.serviceTimes?.length > 0) {
        const cleanedServicesTimes = values?.serviceTimes?.filter(
          (item: any) => item?.id && typeof item?.id === 'number'
        );
        setValues({ ...values, serviceTimes: cleanedServicesTimes });
      }
    }
  }, [showSummary]);

  return (
    <Box>
      {createServiceTime && (
        <CreateLeadSWR
          createServiceTime={createServiceTime}
          currentServiceTime={currentServiceTime}
          id={id}
          setDataresult={setDataresult}
          values={values}
        />
      )}
      {serviceTimeUpdate && (
        <EditLeadSWR
          currentServiceTime={currentServiceTime}
          editServiceTime={serviceTimeUpdate}
          leadId={id}
          serviceTimeId={serviceTimeId}
          setDataresultEdit={setDataresultEdit}
          values={values}
        />
      )}
      {values.manualPricing &&
      (values.projectType === 'adhoc' || values.projectType === 'Ad-Hoc') ? (
        getManualPricing()
      ) : (
        <Box>
          {showSummary ? (
            getSummay()
          ) : (
            <Box>{!values.manualPricing && getScreen()}</Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Services;
