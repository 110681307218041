/* eslint-disable import/no-cycle */
import React from 'react';
import { Box, Grid } from '@mui/material';

import ServicesTimeContainer from './components/ServicesTimeContainer';

export interface Props {
  dayChecked: string;
  detailProject: any;
  projectType: string;
  typeView: string;
  utcUpadtedAt: string;
}

const InvoiceServiceTimes: React.FC<Props> = function InvoiceServiceTimes({
  detailProject,
  projectType,
  typeView,
  dayChecked,
  utcUpadtedAt
}) {
  return (
    <Box
      style={{ width: '100%' }}
      sx={{
        display: 'flex',
        flexGrow: 1,
        padding: '5px'
      }}
    >
      <Grid container spacing={1}>
        <ServicesTimeContainer
          dayChecked={dayChecked}
          detailProject={detailProject}
          projectType={projectType}
          typeView={typeView}
          utcUpadtedAt={utcUpadtedAt}
        />
      </Grid>
    </Box>
  );
};

export default InvoiceServiceTimes;
