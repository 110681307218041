import React from 'react';
import '../../style/App.scss';
import { Box, MenuItem, Typography } from '@mui/material';
import SelectInput from '../form/SelectInput';

interface FilterProp {
  arrayInput: string[];
  disabled?: boolean;
  selectInput: string;
  setSelectInput: (value: React.SetStateAction<string>) => void;
  title: string;
}

const Filter: React.FC<FilterProp> = function Filter({
  arrayInput,
  selectInput,
  setSelectInput,
  title,
  disabled
}) {
  return (
    <Box alignItems="center" display="flex">
      <Box marginRight="16px" width="136px">
        <SelectInput
          displayEmpty
          disabled={disabled}
          labelId="filter-label"
          onChange={(value) => {
            setSelectInput(value.target.value as unknown as string);
          }}
          value={selectInput}
        >
          <MenuItem disabled sx={{ color: 'black' }} value="">
            <Typography color="secondary.400" variant="body_200">
              {title}
            </Typography>
          </MenuItem>
          {arrayInput.map((value) => (
            <MenuItem key={value} value={value}>
              <Typography color="secondary.400" variant="body_200">
                {value}
              </Typography>
            </MenuItem>
          ))}
        </SelectInput>
      </Box>
    </Box>
  );
};

Filter.defaultProps = {
  disabled: false
};

export default Filter;
