/* eslint-disable no-unused-vars */
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import Api from '../../api';
import { removeLocalStorage } from '../../utils/authHelper';

interface UserState {
  active: boolean;
  block: boolean;
  changeTenantClient: () => void;
  email: string | undefined;
  firstName: string | undefined;
  id: string | undefined;
  isAuthenticated: boolean;
  lastName: string | undefined;
  login: () => void;
  logout: () => void;
  mfa: boolean;
  phoneNumber?: string;
  roles: string[] | [];
  setUser: (data: any) => void;
  skills?: string[] | [];
}

const useAuthStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        active: false,
        block: false,
        changeTenantClient: () =>
          set(() => {
            return {
              isAuthenticated: false
            };
          }),
        email: undefined,
        firstName: undefined,
        id: undefined,
        isAuthenticated: false,
        lastName: undefined,
        login: () => set(() => ({ isAuthenticated: true })),
        logout: () =>
          set(() => {
            removeLocalStorage('accessToken');
            removeLocalStorage('keepSessionOpen');
            removeLocalStorage('accessTokenImpersonate');
            Api.authApi.logout();
            return {
              active: false,
              block: false,
              email: undefined,
              firstName: undefined,
              isAuthenticated: false,
              lastName: undefined,
              mfa: false,
              role: undefined
            };
          }),
        mfa: false,
        phoneNumber: undefined,
        roles: [],
        setUser: (data) =>
          set(() => {
            const {
              email,
              roles,
              mfa,
              block,
              active,
              firstName,
              lastName,
              phoneNumber,
              skills,
              id
            } = data;
            return {
              active,
              block,
              email,
              firstName,
              id,
              lastName,
              mfa,
              phoneNumber,
              roles,
              skills
            };
          })
      }),
      {
        getStorage: () => localStorage,
        name: 'user-storage'
      }
    )
  )
);

export default useAuthStore;
