import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { AUTHORIZED_VIEW_NAMES } from '../../helpers/routesConstants';
import ListingChats from './ListingChats';
import TeamProject from './TeamProject';
import Conversation from './Conversation';
import useChatHandler from './useChatHandler';
import ChatContext from './ChatContext';

const ChatsView: React.FC = function ChatsView() {
  // Fetch the chat token using the custom hook
  const pubnubData = useChatHandler();

  return (
    <ChatContext.Provider value={pubnubData}>
      {pubnubData.pubnub ? (
        <PubNubProvider client={pubnubData.pubnub as Pubnub}>
          <Routes>
            {/* PubNubProvider is a part of the PubNub React SDK and allows you to access PubNub instance
      in components down the tree. */}
            <Route
              element={<ListingChats />}
              path={AUTHORIZED_VIEW_NAMES.CHATS_LIST}
            />
            <Route
              element={<TeamProject />}
              path={AUTHORIZED_VIEW_NAMES.CHATS_PROJECTS}
            />
            <Route
              element={<Conversation />}
              path={AUTHORIZED_VIEW_NAMES.CHAT_CONVERSATION}
            />
          </Routes>
        </PubNubProvider>
      ) : (
        <>Loading...</>
      )}
    </ChatContext.Provider>
  );
};

export default ChatsView;
