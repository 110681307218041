import React, { useEffect, useState } from 'react';

import useSWR from 'swr';
import Api from '../../../../api';

interface CreateLeadSWRProps {
  createServiceTime: boolean;
  currentServiceTime: number;
  id: string;
  setDataresult: React.Dispatch<React.SetStateAction<any>>;
  values: {
    serviceTimes: string[];
  };
}

export function CreateLeadSWR({
  id,
  values,
  createServiceTime,
  currentServiceTime,
  setDataresult
}: CreateLeadSWRProps) {
  const [setear, setSetear] = useState<boolean>(false);
  const fetcher = (...args: any[]) =>
    Api.LeadServiceTimesApi.createLeadServicesTime(args[0], args[1]).then(
      (response) => {
        setSetear(true);
        return response;
      }
    );

  const { data: dataresult, error } = useSWR(
    createServiceTime ? [id, values.serviceTimes[currentServiceTime]] : null,
    fetcher
  );

  if (error) {
    return <div>Ocurrió un error: {error.message}</div>;
  }
  useEffect(() => {
    setDataresult(dataresult);
  }, [dataresult]);

  return null;
}

interface EditLeadSWRProps {
  currentServiceTime: number;
  editServiceTime: boolean;
  leadId: string;
  serviceTimeId: number;
  setDataresultEdit: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

export function EditLeadSWR({
  leadId,
  values,
  editServiceTime,
  currentServiceTime,
  serviceTimeId,
  setDataresultEdit
}: EditLeadSWRProps) {
  const fetcher = (...args: any[]) =>
    Api.LeadServiceTimesApi.updateLeadServicesTime(leadId, serviceTimeId, {
      ...values?.serviceTimes[currentServiceTime],
      id: serviceTimeId
    }).then((response) => {
      return response;
    });

  const { data: dataresult, error } = useSWR(
    editServiceTime
      ? [
          leadId,
          serviceTimeId,
          {
            ...values.serviceTimes[currentServiceTime],
            id: serviceTimeId,
            type: values.type
          }
        ]
      : null,
    fetcher
  );

  if (error) {
    return <div>Ocurrió un error: {error.message}</div>;
  }
  useEffect(() => {
    setDataresultEdit(dataresult);
  }, [dataresult]);

  return null;
}
