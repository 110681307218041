import React, { useEffect, useState } from 'react';
import {
  Card as MuiCard,
  CardContent,
  Typography,
  Grid,
  Box
} from '@mui/material';
import dayjs from 'dayjs';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {
  capitalizeString,
  getCurrency,
  statusStyle
} from '../../../../utils/general';
import { mainColors, secondaryColors } from '../../../../theme/themeConfig';

interface Props {
  cardData: {
    amount: string;
    dueDate: string;
    invoiceNumber: string;
    sendDate: string;
    status: string;
  };
  handleCardClick: (value: any) => void;
  handleOpenModal?: (value: any) => void;
  isActive: boolean;
  // setValueDrawer: React.Dispatch<React.SetStateAction<any>>;
}

const Card: React.FC<Props> = function Card({
  cardData,
  // setValueDrawer,
  handleCardClick,
  handleOpenModal,
  isActive
}) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    handleCardClick(cardData);
    setIsClicked(true);
  };

  useEffect(() => {
    setIsClicked(isActive);
  }, [isActive]);

  const combinedStyle = {
    ...statusStyle(cardData.status, 'invoice'),
    borderRadius: '24px !important',
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: '0',
    padding: '12px',
    textAlign: 'center'
  };

  return (
    <Box>
      <MuiCard
        onClick={handleClick}
        style={{
          backgroundColor: 'white',
          border: `1px solid ${
            isClicked
              ? `${mainColors.MAIN_1}`
              : `${secondaryColors.SECONDARY_1}`
          }`,
          boxShadow: isClicked
            ? `0px 0px 10px 0px  ${mainColors.MAIN_1}`
            : 'none',
          cursor: 'pointer',
          height: 'fit-content',
          marginLeft: '-32px',
          marginTop: '-39px',
          transition: 'border 0.3s, box-shadow 0.3s'
        }}
      >
        <CardContent
          style={{
            backgroundColor: 'white',
            marginBottom: '-16px',
            marginTop: '-9px'
            // paddingBottom: '0px !important'
          }}
        >
          <Grid container spacing={1}>
            <Grid xs={7}>
              <Typography
                color={mainColors.MAIN}
                style={{ fontSize: '13px' }}
                variant="secondary"
              >{`Invoice number: #${cardData.invoiceNumber}`}</Typography>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={5}>
              <Typography
                onClick={() =>
                  handleOpenModal && handleOpenModal(cardData.status)
                }
                style={{
                  borderRadius: '24px !important',
                  display: 'inline-block',
                  fontSize: '11px',
                  marginLeft: 'auto',
                  marginRight: '0',
                  padding: '1px',
                  paddingInline: '9px',
                  textAlign: 'center'
                }}
                sx={{ ...combinedStyle }}
                variant="primary"
              >
                {cardData.status === 'PAID' && (
                  <CheckOutlinedIcon
                    style={{ fontSize: '13px', marginRight: '4px' }}
                  />
                )}
                {cardData.status === 'PENDING'
                  ? 'Pending pay'
                  : capitalizeString(cardData.status)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: secondaryColors.SECONDARY,
                  display: 'flex',
                  fontSize: '13px',
                  marginLeft: '-9px',
                  marginTop: '-14px'
                }}
                variant="primary-bold"
              >
                {`Date: ${dayjs(cardData.sendDate).format('MMM,DD YYYY')}`}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between" xs={12}>
              <Typography
                style={{
                  color: secondaryColors.SECONDARY,
                  fontSize: '13px',
                  marginLeft: '-9px',
                  marginTop: '-9px'
                }}
                variant="primary-bold"
              >
                {`Due in ${dayjs(cardData.dueDate).diff(
                  dayjs(cardData.sendDate),
                  'day'
                )} days`}
              </Typography>
              <Typography
                style={{ color: secondaryColors.SECONDARY, marginTop: '-12px' }}
                variant="primary-bold"
              >
                {`${getCurrency(cardData.amount)}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </MuiCard>
    </Box>
  );
};

export default Card;

Card.defaultProps = {
  handleOpenModal: undefined
};
