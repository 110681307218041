/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useLocation, useParams } from 'react-router-dom';
import ClientForm from '../../components/client/ClientForm';
import InvoiceInformation from '../../components/client/InvoiceInformation';
import '../../style/App.scss';
import { FormMainContainer } from '../../components/shared/container/containerParts';
import useUserRole from '../../hooks/useUserRoles';
import { ROLES } from '../../helpers/roles';
import useAuthStore from '../../store/zustand/auth';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`
  };
}

const EditClient = function EditClient() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const { clientId } = useParams();
  const isClient = useUserRole([ROLES.CLIENT]);
  const { id: profileId } = useAuthStore((state) => state);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <FormMainContainer isMobile={isMobile}>
      <Tabs
        aria-label="basic tabs example"
        onChange={handleChange}
        sx={{ marginBottom: '-8px', marginTop: '-8px' }}
        value={value}
      >
        <Tab label={t('CLIENT.clientInformation')} {...a11yProps(0)} />
        <Tab label={t('CLIENT.invoiceInformation')} {...a11yProps(1)} />
      </Tabs>
      <TabPanel index={0} value={value}>
        <ClientForm
          clientId={isClient ? profileId : clientId}
          isClient={isClient}
          type="edit"
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <InvoiceInformation />
      </TabPanel>
    </FormMainContainer>
  );
};

export default EditClient;
