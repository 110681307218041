/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, styled, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import PasswordInput from '../../input/PasswordInput';
import api from '../../../api';
import { validRegexStringRules } from '../../../utils/general';

interface Props {
  isCompany: boolean;
  isMobile: boolean;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | undefined;
}

interface ButtonProps {
  platform: string;
}

const CustomBottom = styled(Button)<ButtonProps>(({ theme, platform }) => ({
  color: theme.palette.mainText?.main,
  width: platform === 'mobile' ? '100%' : '70%'
}));

const Password: React.FC<Props> = function Password({
  token,
  isMobile,
  setErrorApi,
  isCompany
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pass, setPass] = useState<string>('');
  const [pass2, setPass2] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [fireApiPassword, setFireApiPassword] = useState<boolean>(false);
  const [fireApiCompanyPassword, setFireApiCompanyPassword] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [rules, setRules] = useState<Array<any>>([]);

  const { data: dataPassword, error: errorPassword } = useSWR(
    fireApiPassword ? { password: pass, token } : null,
    api.authApi.setAcceptInvitation
  );

  const { data: dataPasswordCompany, error: errorPasswordCompany } = useSWR(
    fireApiCompanyPassword
      ? { companyDescription: description, password: pass, token }
      : null,
    api.authApi.setAcceptCompanyInvitation
  );

  const { data: rulesResponse, error: rulesError } = useSWR(
    !rules.length ? 'api/getPasswordRules' : null,
    api.ConfigApi.getPasswordRules
  );

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const confirmInvitation = () => {
    if (pass === '' || pass2 === '') {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationErrorEmpty')}`);
      return;
    }
    if (pass !== pass2) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationError')}`);
      return;
    }
    if (token) {
      if (isCompany) {
        setFireApiCompanyPassword(true);
      } else {
        setFireApiPassword(true);
      }
    }
  };

  useEffect(() => {
    if (dataPassword && dataPassword.accessToken) {
      navigate(`/invitation/accept-invitation/${token}/3`);
    }
  }, [dataPassword]);

  useEffect(() => {
    if (dataPasswordCompany && dataPasswordCompany.accessToken) {
      navigate(`/company/invitation/accept-invitation/${token}/3`);
    }
  }, [dataPasswordCompany]);

  useEffect(() => {
    if (rulesResponse) {
      setRules(rulesResponse.data);
    }
  }, [rulesResponse]);

  useEffect(() => {
    if (errorPassword || rulesError) {
      setError(true);
      setErrorMessage(
        `${
          errorPassword && errorPassword.message
            ? errorPassword.message
            : t('LOGIN.invitationErrorEndpoint')
        }`
      );
      setFireApiPassword(false);
      setErrorApi(true);
    }
  }, [errorPassword, rulesError]);

  useEffect(() => {
    if (errorPasswordCompany || rulesError) {
      setError(true);
      setErrorMessage(
        `${
          errorPasswordCompany && errorPasswordCompany.message
            ? errorPasswordCompany.message
            : t('LOGIN.invitationErrorEndpoint')
        }`
      );
      setFireApiPassword(false);
      setErrorApi(true);
    }
  }, [errorPasswordCompany, rulesError]);

  useEffect(() => {
    if (pass) {
      const validInput = validRegexStringRules(rules, pass);
      setError(validInput.error);
      setErrorMessage(validInput.errorMessage);
    }
  }, [pass]);

  useEffect(() => {
    if (pass2) {
      setError(pass2 !== pass);
      setErrorMessage(`${pass2 !== pass ? t('LOGIN.invitationError') : ''}`);
    }
  }, [pass2]);

  return (
    <>
      <Typography
        color="primary.main"
        sx={{ textAlign: 'center', width: '100%' }}
        variant="primary-bold"
      >
        {t('LOGIN.invitationTitle')}
      </Typography>
      <br />
      <Box className="Form" sx={{ textAlign: 'left', width: '100%' }}>
        <Box sx={{ marginBottom: '-12px' }}>
          <Typography color="mainText.main" variant="primary-bold">
            {t('LOGIN.resetPasswordNew')}
          </Typography>
        </Box>
        <PasswordInput
          customStyle={{ width: '100%' }}
          error={error}
          onChange={(value) => setPass(value)}
          submitEvent={confirmInvitation}
        />
        <Box sx={{ marginBottom: '-12px' }}>
          <Typography color="mainText.main" variant="primary-bold">
            {t('LOGIN.resetPasswordConfirm')}
          </Typography>
        </Box>
        <PasswordInput
          customStyle={{ width: '100%' }}
          error={error}
          onChange={(value) => setPass2(value)}
          submitEvent={confirmInvitation}
        />
        {isCompany ? (
          <>
            <Typography
              color={`${error ? 'alert.main' : 'mainText.main'}`}
              variant="primary-bold"
            >
              {t('LOGIN.companyDescription')}
            </Typography>
            <TextField
              multiline
              onChange={(value) => setDescription(value.target.value)}
              sx={{ '& fieldset': { border: 'none' }, width: '100%' }}
            />
          </>
        ) : null}
      </Box>
      {getError()}
      <Container
        className="button-accept-container"
        sx={{ display: 'flex', justifyContent: 'center', mt: isMobile ? 4 : 4 }}
      >
        <CustomBottom
          color="primary"
          onClick={() => {
            confirmInvitation();
          }}
          platform={isMobile ? 'mobile' : 'desktop'}
          variant="contained"
        >
          <Typography color="mainText.100" variant="primary-bold">
            {t('GENERAL.nextButton')}
          </Typography>
        </CustomBottom>
      </Container>
    </>
  );
};

export default Password;
