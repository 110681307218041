import React, { useState } from 'react';
import '../../../style/App.scss';
import {
  Container,
  Typography,
  Box,
  styled,
  Button,
  SliderProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../input/RegularInput';
import { ACCOUNT_INFO_FIELDS } from '../../../helpers/profile-data';

const ButtonContainer = styled(Container)<SliderProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2)
}));

interface Props {
  cancelAction: (value: React.SetStateAction<boolean>) => void;
  confirmCode: any;
  getError: () => false | JSX.Element;
  methodSelected: number;
  setDataToSend: React.Dispatch<any>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateField: React.FC<Props> = function UpdateField({
  getError,
  setError,
  cancelAction,
  confirmCode,
  methodSelected,
  setDataToSend
}) {
  const { t } = useTranslation();
  const [formatError, setFormatError] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<string>('');

  const handleChange = (value: string) => {
    if (methodSelected === 1) {
      setDataToSend({ phoneNumber: value });
    } else {
      setDataToSend({ email: value });
    }
    setFieldValue(value);
  };

  const handleSubmit = () => {
    if (
      !ACCOUNT_INFO_FIELDS[2].pattern.test(fieldValue) &&
      methodSelected === 2
    ) {
      setFormatError(true);
      return;
    }
    if (
      !ACCOUNT_INFO_FIELDS[3].pattern.test(fieldValue) &&
      methodSelected === 1
    ) {
      setFormatError(true);
      return;
    }
    setFormatError(false);
    confirmCode();
  };

  return (
    <Container sx={{ display: 'flex !important' }}>
      <Typography
        color="secondaryText.main"
        sx={{ fontSize: 16, textAlign: 'center', width: '100%' }}
        variant="primary-bold"
      >
        {methodSelected === 1
          ? t('LOGIN.mfaUpdatePhoneSubtitle')
          : t('LOGIN.mfaUpdateEmailSubtitle')}
      </Typography>
      <br />
      {getError()}
      <RegularInput
        customStyle={{ width: '100%' }}
        inputInvalid={setError}
        onChange={(value) => handleChange(value)}
        type={methodSelected === 1 ? 'phone' : 'email'}
      />
      {formatError && (
        <Typography
          color="alert.main"
          sx={{ fontSize: 14, marginTop: '-10px' }}
          variant="primary-bold"
        >
          {methodSelected === 1 ? t('LOGIN.validPhone') : t('LOGIN.validEmail')}
        </Typography>
      )}
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <Button
          onClick={() => cancelAction(false)}
          sx={{ width: '100%' }}
          variant="outlined"
        >
          {/* CANCEL OR BACK, SEGUN SI ES PRIMERA VEZ. */}
          <Typography variant="primary-bold">Cancel</Typography>
        </Button>
        <Box sx={{ height: 16, width: 24 }} />
        <Button
          color="primary"
          onClick={() => handleSubmit()}
          sx={{ width: '100%' }}
          variant="contained"
        >
          <Typography variant="primary-bold">
            {t('GENERAL.nextButton')}
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default UpdateField;
