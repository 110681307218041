/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps, useState, useEffect } from 'react';
import {
  Autocomplete,
  MenuItem,
  Select,
  Typography,
  Box,
  TextField,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import Api from '../../api';
import useDebouncedSearch from '../../hooks/useDebounceSearch';

interface VariantProps extends ComponentProps<typeof Select> {
  // eslint-disable-next-line react/require-default-props
  selectvariant?: 'secondary' | 'default';
}

const GlobalSearch: React.FC<VariantProps> = function GlobalSearch({
  children,
  ...props
}) {
  const [search, setSearch] = useState<string>('');
  const [requestSearch, setRequestSearch] = useState<boolean>(false);
  const debouncedSearchQuery = useDebouncedSearch(search, 1000);
  const [valueSearch, setValueSearched] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const navigate = useNavigate();

  const { data: searchResponse } = useSWR(
    requestSearch ? { q: debouncedSearchQuery } : null,
    Api.SearchApi.getSearch
  );

  useEffect(() => {
    if (debouncedSearchQuery !== '') {
      setRequestSearch(true);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (valueSearch?.id) {
      switch (valueSearch.parent) {
        case 'users':
          navigate(`/app/resources/users/user/${valueSearch.id}`);
          break;
        case 'companies':
          navigate(`/app/companies/companies/company/${valueSearch.id}`);
          break;
        default:
          navigate(
            `/app/service-catalog/${valueSearch.parent}/${valueSearch.id}`
          );
          break;
      }
    }
  }, [valueSearch]);

  useEffect(() => {
    if (searchResponse) {
      const newArray = Object.entries(searchResponse)
        .map(([key, array]) =>
          array.map((obj: any) => ({
            id: obj.id,
            name: obj.name,
            parent: key
          }))
        )
        .flat();
      setOptions(newArray);
      setRequestSearch(false);
    }
  }, [searchResponse]);

  return (
    <Box sx={{ display: 'flex', position: 'fixed', right: '352px' }}>
      <Select
        {...props}
        IconComponent={ExpandMoreOutlinedIcon}
        sx={{
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '.MuiSelect-icon': {
            color: '#7D8090'
          },
          backgroundColor: '#F7F6F9',
          border: 'none',
          borderRadius: '0.5em 0px 0px 0.5em',
          boxShadow: 'none',
          fontWeight: 'bold',
          height: '48px !important',
          width: '40%'
        }}
        value={10}
      >
        <MenuItem value={10}>
          <Typography
            sx={{
              color: '#7D8090',
              fontSize: '14px'
            }}
          >
            All
          </Typography>
        </MenuItem>
        <MenuItem value={20}>
          <Typography sx={{ color: '#7D8090', fontSize: '14px' }}>
            Docs
          </Typography>
        </MenuItem>
      </Select>
      <Divider
        flexItem
        orientation="vertical"
        sx={{ backgroundColor: '#F7F6F9', height: '32px', marginTop: '24px' }}
        variant="middle"
      />
      <Autocomplete
        autoComplete
        disablePortal
        filterSelectedOptions
        freeSolo
        includeInputInList
        filterOptions={(x) => x}
        getOptionLabel={(option: any) => option?.name! ?? option}
        id="combo-box-search"
        noOptionsText="No results"
        onChange={(event: any, newValue: any | null) => {
          setOptions(newValue ? [newValue, options] : options);
          setValueSearched(newValue);
        }}
        onInputChange={(event: any, newValue: any | null) => {
          setSearch(newValue);
        }}
        options={options}
        renderInput={(params) => (
          <Box display="flex">
            <TextField
              {...params}
              placeholder="search"
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'hidden'
                },
                backgroundColor: '#F7F6F9 !important',
                border: 0,
                borderStyle: 'hidden',
                fontWeight: 'bold',
                height: '48px !important'
              }}
            />
            <SearchIcon
              fontSize="small"
              sx={{
                color: '#7D8090',
                marginRight: '12px',
                marginTop: '12px'
              }}
            />
          </Box>
        )}
        renderOption={(props, option: any) => (
          <li {...props}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <Typography variant="body_200">{option?.name}</Typography>
              </Box>
              <Box marginTop="-8px">
                <Typography variant="body_100">{option?.parent}</Typography>
              </Box>
            </Box>
          </li>
        )}
        sx={{
          '& input': {
            fontSize: '16px',
            height: '16px'
          },
          backgroundColor: '#F7F6F9 !important',
          borderRadius: '0px 0.5em 0.5em 0px',
          width: 368
        }}
        value={valueSearch}
      />
    </Box>
  );
};

export default GlobalSearch;
