/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import '../../style/App.scss';
import {
  Container,
  Typography,
  Box,
  Button,
  Modal,
  styled,
  SliderProps,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import api from '../../api';
import RegularInput from '../input/RegularInput';
import { ACCOUNT_INFO_FIELDS } from '../../helpers/client-data';
import ButtonFormGroup from '../form/ButtonFormGroup';
import SortableTable from '../list/sortableTable';
import AutoCompletePlaces from '../input/AutoCompletePlaces';
import {
  FlexBoxAutoComplete,
  FlexContainer,
  FormLabel,
  GridBoxInput,
  GridContainer,
  WhiteBoxContainer,
  HeaderFormContainer,
  TitleFormBox
} from '../form/parts';
import ViewLoader from '../shared/ViewLoader';
import EditContent from '../form/EditContent';
import { cleanPhoneNumber, getLocationTimeZone } from '../../utils/general';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 40px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 350
};
interface clientInterface {
  clientId?: string;
  isClient?: boolean;
  type: string;
}

const clientStyle = {
  alignItems: 'flex-start !important',
  display: 'flex',
  flexDirection: 'row !important',
  flexWrap: 'wrap',
  gap: '24px',
  justifyContent: 'flex-start !important',
  marginBottom: '20px',
  width: '100% !important'
};

const ButtonContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
  pl: theme.spacing(1),
  pr: theme.spacing(8),
  width: '100%'
}));

const ClientForm: React.FC<clientInterface> = function ClientForm({
  type,
  clientId,
  isClient = false
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: params } = useLocation();
  const [updateFlag, setUpdateFlag] = useState<boolean>(type === 'edit');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [billingAddres, setBillingAddres] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [addressDeleteId, setAddressDeleteId] = useState<string>('');
  const [contactDeleteId, setContactDeleteId] = useState<string>('');
  const [clientCreated, setClientCreated] = useState<any>();
  const [fireDeleteContactApi, setFireDeleteContactApi] =
    useState<boolean>(false);
  const [fireDeleteAddressApi, setFireDeleteAddressApi] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([
    'Something go wrong'
  ]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [formFunction, setFormFunction] = useState<string>('');
  const [errorMsgs, setErrorMsgs] = useState<string[]>(['']);
  const reference = useRef(0);
  //  ---------------- API Section ----------------
  const [fireAPICreateClient, setFireAPICreateClient] =
    useState<boolean>(false);
  const [fireAPIGetClient, setFireAPIGetClient] = useState<boolean>(false);
  const [fireGetClientInformation, setFireGetClientInformation] =
    useState<boolean>(false);
  const [updateClientInformation, setUpdateClientInformation] =
    useState<boolean>(false);
  const [fireAPIUpdateClient, setFireAPIUpdateClient] =
    useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false);
  const [copybillingAddress, setCopybillingAddress] = useState<boolean>(false);
  const [timeZoneBillingAddres, setTimeZoneBillingAddres] = useState<any>(null);
  const [formValue, setFormValue] = useState<any>({
    addresses: [],
    billingAddress: '',
    billingEmail: [],
    businessName: '',
    contacts: [],
    email: '',
    firstName: '',
    jobTitle: '',
    lastName: '',
    notes: '',
    phone: '',
    timeZone: '',
    workOrderEmails: []
  });

  //  ---------------- API Request ----------------
  const { data: dataCreatedClient, error: createdClientError } = useSWR(
    fireAPICreateClient ? formValue : null,
    api.ClientApi.createClient
  );

  const { data: dataGetClient, error: getClientError } = useSWR(
    fireAPIGetClient ? clientId : null,
    api.ClientApi.getClient
  );

  const { data: dataUpdateClient, error: updateClientError } = useSWR(
    fireAPIUpdateClient ? formValue : null,
    api.ClientApi.updateClient
  );

  const {
    data: deleteAddress,
    isValidating: isValidatingDeleteAddress,
    error: deleteAddressError
  } = useSWR(
    fireDeleteAddressApi
      ? {
          clientId: formValue.id,
          deleteAddressBodyDTO: { ids: [addressDeleteId] }
        }
      : null,
    api.ClientApi.deleteAddress
  );

  const {
    data: deleteContact,
    isValidating: isValidatingDeleteContact,
    error: deleteContactError
  } = useSWR(
    fireDeleteContactApi
      ? {
          clientId: formValue.id,
          deleteContactBodyDTO: { ids: [contactDeleteId] }
        }
      : null,
    api.ClientApi.deleteContact
  );

  const { data: dataInformation } = useSWR(
    fireGetClientInformation ? 'clients-portal/information' : null,
    api.ClientPageApi.getClientInformation
  );

  const { data: dataUpdateInformation } = useSWR(
    updateClientInformation ? formValue : null,
    api.ClientPageApi.onboardingClient
  );

  //  ---------------- Functions ----------------

  const onChange = (name: string, value: string | any[]) => {
    setFormValue({
      ...formValue,
      [name]: value
    });
  };

  const setTimeZone = () => {
    setFormValue({
      ...formValue,
      timeZone: dayjs.utc().format().toString()
    });
  };

  const checkErrorFields = () => {
    const inputs = document.querySelectorAll('input');
    const auxFormValue = formValue;

    inputs.forEach((input) => {
      const auxArrayName = input.name.split('-');

      if (auxArrayName[1] && input.value !== '') {
        if (auxArrayName[0] === 'address') {
          auxFormValue.addresses[auxArrayName[1]] = {
            ...auxFormValue.addresses[auxArrayName[1]],
            address: input.value
          };

          const auxAddress = formValue.addresses;

          auxAddress[auxArrayName[1]] = {
            ...auxAddress[auxArrayName[1]],
            address: input.value
          };

          setFormValue({
            ...formValue,
            addresses: auxAddress
          });
        } else if (auxArrayName[0] === 'phone') {
          auxFormValue.addresses[auxArrayName[1]] = {
            ...auxFormValue.addresses[auxArrayName[1]],
            phone: cleanPhoneNumber(input.value)
          };
          const auxAddress = formValue.addresses;
          auxAddress[auxArrayName[1]] = {
            ...auxAddress[auxArrayName[1]],
            phone: cleanPhoneNumber(input.value)
          };
          setFormValue({
            ...formValue,
            addresses: auxAddress
          });
        } else if (auxArrayName[0] === 'contactsphoneNumber') {
          auxFormValue.contacts[auxArrayName[1]] = {
            ...auxFormValue.contacts[auxArrayName[1]],
            phoneNumber: cleanPhoneNumber(input.value)
          };

          const auxContacts = formValue.contacts;

          auxContacts[auxArrayName[1]] = {
            ...auxContacts[auxArrayName[1]],
            phoneNumber: cleanPhoneNumber(input.value)
          };

          setFormValue({
            ...formValue,
            contacts: auxContacts
          });
        } else if (auxArrayName[0] === 'name') {
          auxFormValue.addresses[auxArrayName[1]] = {
            ...auxFormValue.addresses[auxArrayName[1]],
            name: input.value
          };

          const auxAddress = formValue.addresses;

          auxAddress[auxArrayName[1]] = {
            ...auxAddress[auxArrayName[1]],
            name: input.value
          };

          setFormValue({
            ...formValue,
            addresses: auxAddress
          });
        } else if (auxArrayName[0] === 'contactsname') {
          auxFormValue.contacts[auxArrayName[1]] = {
            ...auxFormValue.contacts[auxArrayName[1]],
            name: input.value
          };

          const auxContacts = formValue.contacts;

          auxContacts[auxArrayName[1]] = {
            ...auxContacts[auxArrayName[1]],
            name: input.value
          };

          setFormValue({
            ...formValue,
            contacts: auxContacts
          });
        }
      } else if (
        undefined !== auxFormValue[input?.name] &&
        formValue[input?.name] === '' &&
        input.value !== '' &&
        input.name !== ''
      ) {
        let auxValue = input.value;

        if (input.name === 'phone') auxValue = cleanPhoneNumber(input.value);

        auxFormValue[input?.name] = auxValue;
        setFormValue({
          ...formValue,
          [input?.name]: auxValue
        });
      }
    });

    const temporalErrorArray: string[] = [];
    const labelErrorArray: string[] = [];
    ACCOUNT_INFO_FIELDS.forEach((value) => {
      const test = value;
      if (!test.pattern.test(auxFormValue[value?.name]) && test.required) {
        temporalErrorArray.push(value?.name);
        labelErrorArray.push(value?.validationLabel);
      }
    });
    auxFormValue.billingEmail.forEach((email: string) => {
      const test = ACCOUNT_INFO_FIELDS[2];
      if (!test.pattern.test(email) && test.required) {
        temporalErrorArray.push('billingEmail');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[2].validationLabel);
      }
    });
    auxFormValue.workOrderEmails.forEach((email: string) => {
      const test = ACCOUNT_INFO_FIELDS[2];
      if (!test.pattern.test(email) && test.required) {
        temporalErrorArray.push('workOrderEmails');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[2].validationLabel);
      }
    });
    if (auxFormValue.billingAddress === '') {
      temporalErrorArray.push('billingAddress');
      labelErrorArray.push('Billing address is required');
    } else if (selectedAddress === false && billingAddres) {
      temporalErrorArray.push('billingAddress');
      labelErrorArray.push(
        'Billing address must be selected from the list of options'
      );
    }
    // if (formValue.notes === '') {
    //   temporalErrorArray.push('notes');
    //   labelErrorArray.push('(*) is required');
    // }
    if (
      auxFormValue.billingEmail.length === 0 ||
      auxFormValue.billingEmail[0] === ''
    ) {
      temporalErrorArray.push('billingEmail');
      labelErrorArray.push(
        'Billing email should have the format example@mail.com'
      );
    }
    if (
      auxFormValue?.workOrderEmails.length === 0 ||
      auxFormValue?.workOrderEmails[0] === ''
    ) {
      temporalErrorArray.push('workOrderEmails');
      labelErrorArray.push(
        'Work order email should have the format example@mail.com'
      );
    }
    if (auxFormValue.addresses.length === 0) {
      temporalErrorArray.push('placeValue');
      labelErrorArray.push('(*) is required');
    }
    auxFormValue.addresses.forEach((value: any) => {
      if (value?.phone === '' || !value?.phone) {
        temporalErrorArray.push('placeValue');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[3].validationLabel);
      }
      if (value?.name === '' || !value?.name) {
        temporalErrorArray.push('placeValue');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[0].validationLabel);
      }
      if (value?.address === '' || !value?.address) {
        temporalErrorArray.push('placeValue');
        labelErrorArray.push('address is required');
      }
    });
    if (auxFormValue.contacts.length === 0) {
      temporalErrorArray.push('contacts');
      labelErrorArray.push('(*) is required');
    }
    auxFormValue.contacts.forEach((value: any) => {
      if (value?.phoneNumber === '') {
        temporalErrorArray.push('placeValue');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[3].validationLabel);
      }
      if (value?.name === '') {
        temporalErrorArray.push('placeValue');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[0].validationLabel);
      }
    });
    setErrorFields(temporalErrorArray);
    // Remove duplicated error values.
    const newLabelErrorArray = labelErrorArray.filter((ele, index) => {
      return labelErrorArray.indexOf(ele) === index;
    });
    setErrorMsgs(newLabelErrorArray);
    return newLabelErrorArray;
  };
  const handleCheckboxChange = (event: any) => {
    setCopybillingAddress(event.target.checked);
  };

  const handleSubmit = () => {
    const labelError = checkErrorFields();
    // Check if ErrorArray is empty
    if (labelError.length === 0) {
      if (formFunction === 'add') {
        setTimeZone();
        setFireAPICreateClient(true);
      } else if (isClient) {
        setUpdateClientInformation(true);
      } else {
        setFireAPIUpdateClient(true);
      }
    } else {
      setError(true);
    }
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          {errorMessage?.map((error, index) => (
            <span key={`error-${index + 1}`}>
              &bull; &nbsp;
              {error}
              <br />
            </span>
          ))}
        </Typography>
      </Container>
    );

  // ---------------- UseEffect ----------------

  useEffect(() => {
    if (dataCreatedClient) {
      setError(false);
      setFireAPICreateClient(false);
      setOpenModal(true);
      setIsLoading(false);
      setClientCreated(dataCreatedClient);
    }
  }, [dataCreatedClient]);

  useEffect(() => {
    window.localStorage.removeItem('client-process');
    setFormFunction(type);
  }, []);

  useEffect(() => {
    if (dataUpdateClient) {
      setError(false);
      setFireAPIUpdateClient(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [dataUpdateClient]);

  useEffect(() => {
    if (dataUpdateInformation) {
      setError(false);
      setUpdateClientInformation(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [dataUpdateInformation]);

  useEffect(() => {
    if (deleteAddress) {
      setError(false);
      setFireDeleteAddressApi(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [deleteAddress]);

  useEffect(() => {
    if (deleteContact) {
      setError(false);
      setFireDeleteContactApi(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [deleteContact]);

  useEffect(() => {
    if (dataGetClient) {
      const originalObject = dataGetClient;
      const clonedObject = cloneDeep(originalObject);
      setFormValue({ ...clonedObject });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [dataGetClient]);

  useEffect(() => {
    if (dataInformation) {
      const originalObject = dataInformation;
      const clonedObject = cloneDeep(originalObject);
      setFormValue({ ...clonedObject });
      setIsLoading(false);
      setFireAPIGetClient(false);
    }
  }, [dataInformation]);

  // Control if Edit or Add view.
  useEffect(() => {
    // If add set timezone.
    if (type === 'add') {
      dayjs.extend(utc);
      setUpdateFlag(true);
      setIsLoading(false);
    } else if (isClient) {
      setFireGetClientInformation(true);
    } else {
      setFireAPIGetClient(true);
      setSelectedAddress(true);
    }
  }, []);

  useEffect(() => {
    if (addressDeleteId) {
      setFireDeleteAddressApi(true);
    }
  }, [addressDeleteId]);

  useEffect(() => {
    if (contactDeleteId) {
      setFireDeleteContactApi(true);
    }
  }, [contactDeleteId]);

  // Prepopulate Billing email, just one time at begining,
  useEffect(() => {
    if (
      formValue.email !== '' &&
      reference.current === 0 &&
      formValue.billingEmail.length === 0 &&
      formValue.workOrderEmails.length === 0
    ) {
      setTimeout(() => {
        const emails = formValue.billingEmail;
        emails.push(formValue.email);
        setFormValue({
          ...formValue,
          billingEmail: emails,
          workOrderEmails: emails
        });
        reference.current = 1;
      }, 2000);
    }
  }, [formValue.email]);

  useEffect(() => {
    const newObject = JSON.parse(
      window.localStorage.getItem('client-process') as unknown as string
    );
    if (newObject) {
      switch (newObject.process) {
        case 'address':
          setFormFunction('edit');
          setFormValue({ ...formValue, id: newObject.response.idClient });
          break;
        case 'contacts':
          setFormFunction('edit');
          setFormValue({ ...formValue, id: newObject.response.idClient });
          break;
        default:
          break;
      }
    }
    if (createdClientError || updateClientError) {
      const error = [createdClientError, updateClientError].find(
        (param) => param !== undefined
      );
      const response = error?.response?.data?.message;
      const errors = error?.response?.data?.errors;
      const errorArray =
        typeof response === 'string'
          ? [response, errors]
          : response.push(errors);
      setErrorMessage(errorArray);
      setFireAPICreateClient(false);
      setFireDeleteContactApi(false);
      setFireDeleteAddressApi(false);
      setFireAPIUpdateClient(false);
      setOpenModal(true);
      setError(true);
      setIsLoading(false);
    }
    if (getClientError) {
      setFireAPIGetClient(false);
      setOpenModal(true);
      setError(true);
      setIsLoading(false);
    }
  }, [createdClientError, getClientError, updateClientError]);

  useEffect(() => {
    if (deleteAddressError || deleteContactError) {
      const error = [deleteAddressError, deleteContactError].find(
        (param) => param !== undefined && param !== null
      );
      const response = error?.response?.data?.message;
      const errorArray = [response];
      setErrorMessage(errorArray);
      setFireAPICreateClient(false);
      setFireDeleteContactApi(false);
      setFireDeleteAddressApi(false);
      setFireAPIUpdateClient(false);
      setOpenModal(true);
      setError(true);
      setFireGetClientInformation(false);
      setIsLoading(false);
    }
  }, [deleteAddressError, deleteContactError]);

  if (isLoading) {
    return <ViewLoader />;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function Autocomplete() {
    return (
      <AutoCompletePlaces
        disabled={!updateFlag}
        error={errorFields.includes('billingAddress')}
        keyName="billingAddress"
        onChange={(values: any) => {
          const timezone = getLocationTimeZone(
            values.coords[1],
            values.coords[0]
          );
          setTimeZoneBillingAddres(timezone);
          onChange(values.keyName, values.value);
          setBillingAddres(values);
        }}
        setSelected={setSelectedAddress}
        value={formValue.billingAddress}
      />
    );
  }

  // ---------------- Return ----------------
  return (
    <Container>
      <Box sx={{ padding: '0px 0px 8px 0px', width: '100%' }}>
        <HeaderFormContainer isMobile={isMobile}>
          {/* ---------------- Header ---------------- */}
          <Box display="flex" flexDirection="row">
            {params?.lastPage && (
              <Button
                onClick={() => {
                  navigate(params.lastPage, { state: params });
                }}
              >
                <i className="custom-icon icon-arrow-left"> </i>
              </Button>
            )}
            <TitleFormBox isMobile={isMobile}>
              <Typography sx={{ marginBottom: '4px' }} variant="title">
                {type === 'add'
                  ? t('CLIENT.createClient')
                  : t('CLIENT.clientInformation')}
              </Typography>
              <Typography color="secondary.400" variant="body_300">
                {type === 'add'
                  ? t('CLIENT.createSubtitle')
                  : t('CLIENT.subtitle')}
              </Typography>
            </TitleFormBox>
          </Box>
          <ButtonContainer
            sx={{
              justifyContent: updateFlag && type !== 'add' ? '' : 'flex-end',
              marginBottom: isMobile ? '0px' : '16px'
            }}
          >
            {type === 'add' ? null : (
              <ButtonFormGroup
                actionButtonMsg={t('PROFILE.saveInformation')}
                cancelAction={() => {
                  setUpdateFlag(false);
                  const originalObject = isClient
                    ? dataInformation
                    : dataGetClient;
                  const clonedObject = cloneDeep(originalObject);
                  setFormValue({ ...clonedObject });
                  setErrorMsgs(['']);
                  setErrorFields([]);
                  setError(false);
                }}
                enableEditAction={() => setUpdateFlag(true)}
                enableEditButtonMsg={<EditContent />}
                isMobile={isMobile}
                mainAction={() => {
                  handleSubmit();
                }}
                updateFlag={updateFlag}
              />
            )}
          </ButtonContainer>
        </HeaderFormContainer>
      </Box>
      <Container sx={clientStyle}>
        <Box>
          {/* ---- Personal Information ---- */}
          <WhiteBoxContainer isMobile={isMobile}>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr 1fr',
                width: '100%'
              }}
            >
              {ACCOUNT_INFO_FIELDS.map((value) => (
                <GridBoxInput
                  isMobile={isMobile}
                  key={value?.name}
                  sx={{ width: isMobile ? '100%' : '256px' }}
                >
                  <FormLabel
                    label={value?.required ? `${value?.label}*` : value?.label}
                  />
                  <RegularInput
                    customStyle={{
                      width: '100%'
                    }}
                    disabled={!updateFlag}
                    error={errorFields.includes(value?.name) && value?.required}
                    name={value?.name}
                    onChange={(inputValue) => onChange(value.name, inputValue)}
                    type={value?.type}
                    value={formValue[value?.name]}
                  />
                </GridBoxInput>
              ))}
            </GridContainer>
          </WhiteBoxContainer>
        </Box>

        {/* ---- Billing Section ---- */}
        <WhiteBoxContainer isMobile={isMobile}>
          <FlexContainer>
            {/* ---- Billing Address ---- */}
            <Box mt={1}>
              <FormLabel label={`${t('CLIENT.billingAddress')}*`} />
              <FlexBoxAutoComplete>{Autocomplete()}</FlexBoxAutoComplete>
            </Box>

            {type === 'add' ? (
              <FormControlLabel
                value
                checked={copybillingAddress ? selectedAddress : false}
                control={<Checkbox />}
                disabled={!selectedAddress}
                label={
                  <Typography fontSize={12} variant="primary-bold">
                    {t('MODAL.usePrimaryServicesAddress')}
                  </Typography>
                }
                onChange={handleCheckboxChange}
                style={{
                  marginTop: '-21px'
                }}
              />
            ) : null}

            {/* ---- Valid work order emails ---- */}
            <Box
              sx={{
                columnGap: '24px',
                display: 'grid',
                gridTemplateColumns: isMobile ? '100%' : '50% 50%',
                marginY: '8px',
                width: '100%'
              }}
            >
              <Box>
                <FormLabel label={`${t('CLIENT.validWorkOrderLabel')}*`} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    marginTop: '16px',
                    width: '376px'
                  }}
                >
                  <SortableTable
                    addManagement
                    disableSort
                    bodyKeys={['validEmails']}
                    disabledTable={!updateFlag}
                    name="workOrderEmails"
                    onChange={(workOrderEmails: any) => {
                      setFormValue({
                        ...formValue,
                        workOrderEmails: [...workOrderEmails]
                      });
                    }}
                    type="input"
                    values={formValue.workOrderEmails}
                  />
                </Box>
              </Box>

              {/* ---- Billing Email ---- */}
              <Box>
                <FormLabel label={`${t('CLIENT.billingEmail')}*`} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    marginTop: '16px',
                    width: '376px'
                  }}
                >
                  <SortableTable
                    addManagement
                    disableSort
                    bodyKeys={['email']}
                    disabledTable={!updateFlag}
                    name="billingEmail"
                    onChange={(newEmail: any) => {
                      setFormValue({
                        ...formValue,
                        billingEmail: [...newEmail]
                      });
                    }}
                    type="input"
                    values={formValue.billingEmail}
                  />
                </Box>
              </Box>
            </Box>

            {/* ---- Client Addresses ---- */}
            <Box sx={{ marginTop: '8px' }}>
              <FormLabel
                error={errorFields.includes('placeValue')}
                label={t('LEAD.CREATE.GENERAL_INFORMATION.address')}
              />
              <Box sx={{ display: 'flex', marginTop: '4px' }}>
                <SortableTable
                  addManagement
                  disableSort
                  isAddress
                  billingAddress={formValue.billingAddress}
                  bodyKeys={['name', 'phone', 'address']}
                  copybillingAddress={copybillingAddress}
                  disabledTable={!updateFlag}
                  handleDelete={setAddressDeleteId}
                  name="addresses"
                  onChange={(newAddresses: any) => {
                    setFormValue({
                      ...formValue,
                      addresses: newAddresses
                    });
                  }}
                  selectedAddress={selectedAddress}
                  setCopybillingAddress={setCopybillingAddress}
                  timeZoneBillingAddres={timeZoneBillingAddres || undefined}
                  type="addresses"
                  values={formValue.addresses}
                />
              </Box>
            </Box>

            {/* ---- Client Contacts ---- */}
            <Box>
              <FormLabel
                error={errorFields.includes('contacts')}
                label={t('CLIENT.contactsLabel')}
              />
              <Box mt={2}>
                <SortableTable
                  addManagement
                  disableSort
                  bodyKeys={['name', 'phoneNumber']}
                  disabledTable={!updateFlag}
                  handleDelete={setContactDeleteId}
                  name="contacts"
                  onChange={(newContactList: any) => {
                    setFormValue({ ...formValue, contacts: newContactList });
                  }}
                  type="generic-input"
                  values={formValue.contacts}
                />
              </Box>
            </Box>

            {/* ----  Notes ---- */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginY: '16px'
              }}
            >
              <FormLabel
                error={errorFields.includes('notes')}
                label={t('CLIENT.notes')}
              />
              <RegularInput
                multiline
                disabled={!updateFlag}
                onChange={(notes) => {
                  setFormValue({ ...formValue, notes });
                }}
                value={formValue.notes}
              />
              {/* ERROR SECTION */}
              <Typography
                color="alert.main"
                sx={{
                  display:
                    errorFields.length !== 0 && errorFields[0] !== ''
                      ? 'inline'
                      : 'none',
                  marginTop: '10px'
                }}
                variant="body_100"
              >
                {errorMsgs.map((error, index) => (
                  <span key={`error-${index + 1}`}>
                    &bull; &nbsp;
                    {error}
                    <br />
                  </span>
                ))}
              </Typography>
            </Box>

            {/* ---- Button Section ---- */}
            {type === 'add' ? (
              <Box
                sx={{
                  alignItems: 'center',
                  display: isMobile ? 'block' : 'flex',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={() => handleSubmit()}
                  sx={{
                    lineHeight: 'normal',
                    marginLeft: '10px',
                    width: '22%'
                  }}
                  variant="primary"
                >
                  {t('CLIENT.addClientButton')}
                </Button>
              </Box>
            ) : null}
          </FlexContainer>
        </WhiteBoxContainer>

        {/* ---- Modal Section ---- */}
        <Modal
          aria-describedby="modal-modal-description-profile"
          aria-labelledby="modal-modal-title-profile"
          onClose={() => setOpenModal(false)}
          open={openModal}
        >
          <Box sx={style}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                {error ? (
                  <>
                    <Typography
                      color="alert.main"
                      sx={{ fontSize: 22 }}
                      variant="primary-bold"
                    >
                      Error!
                    </Typography>
                    {getError()}
                  </>
                ) : (
                  <Typography
                    color="success.main"
                    sx={{ fontSize: 22 }}
                    variant="primary-bold"
                  >
                    {type === 'add'
                      ? t('CLIENT.modalMsgSuccesfuly')
                      : t('CLIENT.modalMsgUpdateSuccesfuly')}
                  </Typography>
                )}
              </Box>
              <br />
              <Button
                onClick={() => {
                  if (error) {
                    setOpenModal(false);
                    navigate('/app/clients/listing-page', { replace: true });
                  } else {
                    setOpenModal(false);
                    if (params && params.lastPage) {
                      navigate(params.lastPage, {
                        state: { clientCreated }
                      });
                    } else if (isClient) {
                      setUpdateFlag(false);
                      setFireGetClientInformation(true);
                    } else {
                      navigate('/app/clients/listing-page', { replace: true });
                    }
                  }
                }}
                sx={{ width: isMobile ? '100%' : '50%' }}
                variant="contained"
              >
                <Typography variant="primary-bold">
                  {error ? t('GENERAL.closeButton') : t('GENERAL.done')}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Container>
  );
};

export default ClientForm;
