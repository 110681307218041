import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  styled,
  SliderProps,
  Avatar
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../input/RegularInput';
import { formatDateFull } from '../../utils/general';
import api from '../../api';

const MsgContainer = styled(Box)<SliderProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2)
}));

interface Props {
  apiToGetMessage: (values: any) => void;
  apiToPostMessage: (values: any) => void;
  clientView?: boolean;
  // can be the proposal id or the token
  id: string;
  updateMessages?: (values: any) => void;
}

const Chat: React.FC<Props> = function Chat({
  id,
  clientView,
  updateMessages,
  apiToPostMessage,
  apiToGetMessage
}) {
  const { t } = useTranslation();

  const [postMessageApi, setPostMessageApi] = useState<boolean>(false);
  const [getMessageApi, setGetMessageApi] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const {
    data: postMessageData,
    mutate: postMessageMutate,
    error: postMessageError
  } = useSWR(
    postMessageApi ? { id, message, url: 'postMessage' } : null,
    apiToPostMessage
  );

  const {
    data: messageData,
    mutate: getMessageMutate,
    error: messageDataError
  } = useSWR(getMessageApi ? { id } : null, apiToGetMessage);

  const clearApiResponse = () => {
    postMessageMutate(undefined, true);
    getMessageMutate(undefined, true);
  };

  useEffect(() => {
    setGetMessageApi(true);
  }, []);

  useEffect(() => {
    if (messageData) {
      setMessages(messageData);
    }
  }, [messageData]);

  useEffect(() => {
    if (postMessageData) {
      setGetMessageApi(true);
      getMessageMutate(undefined, true);
    }
  }, [postMessageData]);

  // We only can reset the component content disabled enable it
  const inputBox = () => {
    return (
      <Box alignItems="center" display="flex" gap={2} pl={2} pr={2}>
        <Box display="flex" justifyContent="center" width="100%">
          {postMessageApi && (
            <RegularInput
              disabled
              customStyle={{ height: '46px', width: '100%' }}
            />
          )}
          {!postMessageApi && (
            <RegularInput
              customStyle={{ height: '46px', width: '100%' }}
              onChange={(value: any) => {
                setMessage(value);
              }}
              value={message}
            />
          )}
        </Box>
        <Button
          disabled={error}
          onClick={() => {
            clearApiResponse();
            setPostMessageApi(true);
          }}
          sx={{ width: isMobile ? 50 : 150 }}
          variant="primary"
        >
          <Typography
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
            variant="primary-bold"
          >
            {!isMobile && t('LEAD.CREATE.PROPOSAL.CHAT.button')}{' '}
            <SendIcon fontSize="small" />
          </Typography>
        </Button>
      </Box>
    );
  };

  const messageItem = (
    name: string,
    date: string,
    text: string,
    sendByType: string
  ) => {
    const order = sendByType === 'operator' ? 0 : 1;
    return (
      <MsgContainer>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
        >
          <Box
            alignItems="center"
            display="flex"
            gap={2}
            order={order}
            width="100%"
          >
            <Box alignItems="center" display="flex" gap={2} order={order}>
              <Avatar />
            </Box>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              width="100%"
            >
              <Typography color="primary" order={order} variant="heading_300">
                {name}
              </Typography>
              <Typography color="secondary" variant="body_200">
                {date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2} textAlign={order ? 'right' : 'left'} width="100%">
          <Typography variant="body_200">{text}</Typography>
        </Box>
      </MsgContainer>
    );
  };

  useEffect(() => {
    if (postMessageData) {
      setMessage('');
      setPostMessageApi(false);
    }
  }, [postMessageData]);

  useEffect(() => {
    if (postMessageError) {
      setPostMessageApi(false);
      setError(true);
    }
  }, [postMessageError]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginRight="20px"
      pt={2}
      sx={{
        backgroundColor: 'white',
        border: 'solid #CBCCD3 1px',
        borderRadius: '8px'
      }}
      width="90%"
    >
      <Typography pl={2} variant="heading_400">
        {clientView
          ? t('LEAD.CREATE.PROPOSAL.CHAT.clientTitle')
          : t('LEAD.CREATE.PROPOSAL.CHAT.operatorTitle')}
      </Typography>

      {inputBox()}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        height="80%"
        overflow="scroll"
        padding={2}
        style={{ overflowX: 'hidden' }}
      >
        {error && (
          <Typography color="alert.main" variant="caption">
            {t('ERROR.general')}
          </Typography>
        )}
        {messages?.length > 0 &&
          messages.map((item: any) => {
            const name = item?.operator
              ? `${item?.operator?.firstName} ${item?.operator?.lastName}`
              : `${item?.client?.firstName} ${item?.client?.lastName}`;
            return messageItem(
              name,
              formatDateFull(item?.sentAt),
              item?.message,
              item?.sendByType
            );
          })}
      </Box>
    </Box>
  );
};

Chat.defaultProps = {
  clientView: false,
  updateMessages: () => {}
};

export default Chat;
