import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegularInput from './RegularInput';

interface TimeInput {
  hours: string;
  minutes: string;
}

interface TimeProps {
  disabled?: boolean;
  readOnly?: boolean;
  setTime: (time: number) => void;
  short?: boolean;
  time: number;
}

const TimeComponent: React.FC<TimeProps> = function TimeComponent({
  time,
  setTime,
  disabled,
  short,
  readOnly
}) {
  const [localTime, setLocalTime] = useState<TimeInput>({
    hours: '00',
    minutes: '00'
  });
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (time) {
      const decimalTimeString = `${time}`;
      let decimalTime = parseFloat(decimalTimeString);
      decimalTime = decimalTime * 60 * 60;
      const hours = Math.floor(decimalTime / (60 * 60));
      decimalTime -= hours * 60 * 60;
      const minutes = Math.floor(decimalTime / 60);
      decimalTime -= minutes * 60;
      setLocalTime({
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`
      });
    }
  }, [time]);

  useEffect(() => {
    if (localTime.hours !== '00' || localTime.minutes !== '00') {
      const stringTime = `${localTime.hours}:${localTime.minutes}`;
      const t = stringTime.split(':');
      const t2 = `${parseInt(t[0], 10) + parseInt(t[1], 10) / 60}`;
      if (t2 && t2 !== '0') {
        setTime(parseFloat(t2));
      }
    }
  }, [localTime]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      sx={{ justifyContent: short ? 'center' : 'inherit' }}
    >
      <Box
        alignItems="center"
        display="flex"
        sx={{ justifyContent: short ? 'center' : 'inherit' }}
      >
        {!readOnly && (
          <RegularInput
            customStyle={{
              backgroundColor: 'transparent !important',
              border: 1,
              borderColor: theme.palette.secondary[100],
              mr: 2,
              width: 70
            }}
            disabled={disabled}
            onChange={(value: string) => {
              if (value.length > 1) {
                setLocalTime({ ...localTime, hours: value });
              } else {
                setLocalTime({ ...localTime, hours: `0${value}` });
              }
            }}
            type="number"
            value={localTime.hours}
          />
        )}
        <Typography variant="primary">
          {readOnly && <span>{localTime.hours}</span>}
          <Typography variant="body_100">
            {`${!short ? t('LEAD.CREATE.SERVICES.hours') : ' H'}`}
            &nbsp;
            {readOnly && t('LEAD.CREATE.SERVICES.timeAnd')}
          </Typography>
        </Typography>
      </Box>
      &nbsp;&nbsp;
      <Box>
        {!readOnly && (
          <RegularInput
            customStyle={{
              backgroundColor: 'transparent !important',
              border: 1,
              borderColor: theme.palette.secondary[100],
              mr: 2,
              width: 70
            }}
            disabled={disabled}
            onChange={(value: string) => {
              if (value.length > 1) {
                setLocalTime({ ...localTime, minutes: value });
              } else {
                setLocalTime({ ...localTime, minutes: `${value}` });
              }
            }}
            type="number"
            value={localTime.minutes}
          />
        )}
        <Typography variant="primary">
          {readOnly && <span>{localTime.minutes}</span>}
          <Typography variant="body_100">
            {!short ? t('LEAD.CREATE.SERVICES.minutes') : ' M'}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

TimeComponent.defaultProps = {
  disabled: undefined,
  readOnly: undefined,
  short: false
};

export default TimeComponent;
