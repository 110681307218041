class Config {
  constructor() {
    // Config variables should go here:
    this.version = process.env.REACT_APP_VERSION;
    this.platform = process.env.REACT_APP_PLATFORM;
    this.baseURL = process.env.REACT_APP_BASE_URL;
    this.appId = process.env.REACT_APP_APP_ID;
    this.underMaintenance = process.env.REACT_APP_MAINTENANCE;
    this.geomapApiKey = process.env.REACT_APP_GEOMAP_API_KEY;
  }

  setItem(key, value) {
    this.globals[key] = value;
  }

  isVoiceEnabled() {
    return this.globals.voiceGuide;
  }
}

const configInstance = new Config();
Object.freeze(configInstance);

export default configInstance;
