import React from 'react';
import BasePageMFA from '../../shared/mfaForms/BasePageMFA';

interface Props {
  isCompany: boolean;
  isMobile: boolean;
  login: () => void;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: (data: any) => void;
  token: string | undefined;
}

const MfaRegistration: React.FC<Props> = function MfaRegistration({
  token,
  isMobile,
  setErrorApi,
  login,
  isCompany,
  setUser
}) {
  return (
    <BasePageMFA
      isCompany={isCompany}
      isMobile={isMobile}
      login={login}
      setErrorApi={setErrorApi}
      setUser={setUser}
      token={token}
    />
  );
};

export default MfaRegistration;
