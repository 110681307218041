import { Box, Card, Grid, Typography } from '@mui/material';
import { ChannelList, useChannels } from '@pubnub/react-chat-components';
import { useContext } from 'react';
import ChatContext from '../ChatContext';

export const ChatParticipantsCard: React.FC = function ChatParticipantsCard() {
  const { currentChannel, setCurrentChannel } = useContext(ChatContext);
  const [channels, fetchPage, total, error, isLoading] = useChannels();

  return (
    <Grid container item xs={4}>
      <Card sx={{ flexGrow: 1 }}>
        <Box>
          <Typography color="primary.main" fontWeight="bold">
            Channels
          </Typography>
          <ChannelList
            channels={channels}
            onChannelSwitched={setCurrentChannel}
          />
        </Box>

        <Box>
          <Typography color="primary.main" fontWeight="bold">
            Private Chats
          </Typography>
          <ChannelList channels={[]} onChannelSwitched={setCurrentChannel} />
        </Box>
      </Card>
    </Grid>
  );
};

export default ChatParticipantsCard;
