import { CategoryApi, CreateCategoryDTO } from '../api-client/generated/api';

const apiCaller = new CategoryApi();

interface Pagination {
  items: number;
  page: number;
}

const getCategories = async (params: Pagination) => {
  const response = await apiCaller.categoryGetAllPaginated(params);
  const { data } = response;
  return data;
};

const getAllCategories = async () => {
  const response = await apiCaller.categoryGetAll();
  const { data } = response;
  return data;
};

const getCategory = async (param: any) => {
  if (!param?.id) {
    return null;
  }

  const response = await apiCaller.categoryGetOneById(param);
  const { data } = response;
  return data;
};

const createCategory = async (params: CreateCategoryDTO) => {
  const response = await apiCaller.categoryCreate({
    createCategoryDTO: {
      description: params.description,
      name: params.name
    }
  });
  const { data } = response;
  return data;
};

const updateCategory = async (param: any) => {
  if (!param.id) {
    return null;
  }
  const response = await apiCaller.categoryUpdate({
    id: param.id,
    updateCategoryDTO: {
      description: param.description,
      name: param.name
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteCategory = async (params: { id: string; url: string }) => {
  if (!params.id) {
    return null;
  }
  const response = await apiCaller.categoryDelete({ id: params.id });
  const { status, statusText } = response;
  return { status, statusText };
};

export default {
  createCategory,
  deleteCategory,
  getAllCategories,
  getCategories,
  getCategory,
  updateCategory
};
