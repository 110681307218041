import appConfig from '../config';

// eslint-disable-next-line consistent-return
const searchDirection = async (searchValue: string) => {
  try {
    const resp = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchValue}.json?proximity=ip&access_token=${appConfig.geomapApiKey}`
    );
    const data = await resp.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
  }
};

export default {
  searchDirection
};
