import React, { useState } from 'react';
import '../../style/App.scss';
import {
  Typography,
  Box,
  Select,
  MenuItem,
  TextField,
  InputAdornment
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import TableComponent from '../shared/TableComponent';
import { HeaderFormContainer, TitleFormBox } from '../form/parts';

const InvoiceInformation = function InvoiceInformation() {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [formValue, setFormValue] = useState<any>({
    addresses: [
      {
        address: '',
        name: '',
        phone: ''
      }
    ],
    billingAddress: '',
    companyName: '',
    email: '',
    firstName: '',
    jobTitle: '',
    lastName: '',
    phoneNumber: '',
    timeZone: ''
  });

  const data: any[] = [
    {
      date: '23-1-23',
      id: '1',
      nameInvoice: 'Contract project X',
      status: 'Paid',
      total: '1000$'
    }
  ];

  const isItemLoaded = (index: any) => !!data[index];

  return (
    <Box width="940px">
      <HeaderFormContainer isMobile={isMobile}>
        <TitleFormBox isMobile={isMobile}>
          <Typography sx={{ marginBottom: '4px' }} variant="title">
            {t('CLIENT.clientInformation')}
          </Typography>
        </TitleFormBox>
      </HeaderFormContainer>

      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? '20px' : '100px',
          marginBottom: '50px',
          paddingX: isMobile ? '20px' : '80px',
          paddingY: '20px'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">$ 2,500</Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CLIENT.totalInvoices')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">$ 500</Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CLIENT.pendingInvoices')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">23</Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CLIENT.totalInvoices')}
          </Typography>
        </Box>
      </Box>

      <>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '10px',
            marginBottom: '50px',
            paddingX: isMobile ? '20px' : '80px',
            paddingY: '20px'
          }}
        >
          <Select
            sx={{
              '.MuiOutlinedInput-notchedOutline': { border: 0 },

              '.MuiSelect-icon': {
                color: 'secondaryText.5'
              },
              backgroundColor: '#EBF2F6',
              borderRadius: '0.5em',
              boxShadow: '',
              color: 'secondaryText.5',
              fontFamily: '',
              fontSize: '16px',
              height: '40px',
              marginY: '20px',
              width: isMobile ? '100%' : '20%'
            }}
          >
            <MenuItem value={11}>2022</MenuItem>
            <MenuItem value={22}>2023</MenuItem>
          </Select>
          <Select
            sx={{
              '.MuiOutlinedInput-notchedOutline': { border: 0 },

              '.MuiSelect-icon': {
                color: 'secondaryText.5'
              },
              backgroundColor: '#EBF2F6',
              borderRadius: '0.5em',
              boxShadow: '',
              color: 'secondaryText.5',
              fontFamily: '',
              fontSize: '16px',
              height: '40px',
              marginY: '20px',
              width: isMobile ? '100%' : '20%'
            }}
          >
            {JSON.parse(t('CLIENT.searchByMonth')).map(
              (value: string, index: number) => (
                <MenuItem value={index}>{value}</MenuItem>
              )
            )}
          </Select>
          {/* TODO update Autocomplete to re use here */}
          <TextField
            hiddenLabel
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i
                    className="custom-icon icon-search"
                    style={{ fontSize: 15 }}
                  />
                </InputAdornment>
              ),
              style: {
                color: 'black',
                fontSize: '16px',
                outline: '0px',
                padding: '2px 20px 0px 6px'
              }
            }}
            placeholder={`${t('CLIENT.searchInvoicesInput')}`}
            size="small"
            sx={{
              '.MuiOutlinedInput-notchedOutline': { borderStyle: 'hidden' },
              backgroundColor: 'input.main',
              border: 0,
              borderRadius: '0.5em',
              borderStyle: 'hidden',
              height: '40px',
              width: isMobile ? '100%' : '60%'
            }}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '50px',
            paddingX: isMobile ? '20px' : '80px',
            paddingY: '20px'
          }}
        >
          <Box sx={{ marginY: '10px' }}>
            <TableComponent
              action={() => {}}
              headKeys={['id', 'nameInvoice', 'date', 'total', 'status']}
              isItemLoaded={isItemLoaded}
              itemCount={1}
              loadMoreItems={false}
              valuesArray={{ data } as unknown as { data: any[] }}
            />
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default InvoiceInformation;
