import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

type BoxProps = {
  isMobile: boolean;
};

export const GridBox = styled(Box)<BoxProps>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '8px',
  paddingLeft: isMobile ? '24px' : '16px',
  paddingRight: isMobile ? '24px' : '16px',
  width: isMobile ? '100%' : 'auto'
}));

export const GridBoxContainer = styled(Box)<BoxProps>(({ isMobile }) => ({
  backgroundColor: 'white',
  display: 'grid',
  gridAutoRows: isMobile ? '104px 40px' : '96px 72px',
  gridTemplateColumns: isMobile ? '100%' : '50% 50%',
  rowGap: '8px'
}));

type SpanProps = {
  disabled?: boolean;
  isMobile: boolean;
};

export const DayCheckBox = styled('span')<SpanProps>(
  ({ theme, isMobile, disabled }) => ({
    alignItems: 'center',
    backgroundColor: disabled
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    border: `1px solid`,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: isMobile ? '30px' : '40px',
    justifyContent: 'center',
    opacity: '0.5',
    width: isMobile ? '30px' : '40px'
  })
);

DayCheckBox.defaultProps = {
  disabled: undefined
};

export const DayCheckBoxSelected = styled('span')<SpanProps>(
  ({ theme, isMobile, disabled }) => ({
    alignItems: 'center',
    backgroundColor: disabled
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: isMobile ? '30px' : '40px',
    justifyContent: 'center',
    width: isMobile ? '30px' : '40px'
  })
);

DayCheckBoxSelected.defaultProps = {
  disabled: undefined
};
