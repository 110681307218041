/* eslint-disable react/require-default-props */
import {
  Button,
  Box,
  Container,
  Typography,
  styled,
  SlideProps
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';

import RegularInput from '../input/RegularInput';
import Api from '../../api';
import { FormLabel } from '../form/parts';
import Loader from '../shared/Loader';

interface Props {
  isMobile: boolean;
  setCurrentProcess: React.Dispatch<React.SetStateAction<string>>;
  tokenExpired?: boolean;
}

const LoginButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.['100']
}));

const ClientLogin: React.FC<Props> = function ClientLogin({
  isMobile,
  setCurrentProcess,
  tokenExpired = false
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [tokenExpiredBanner, setTokenExpiredBanner] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [shouldAuth, setShouldAuth] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const { mutate } = useSWRConfig();

  // ======================================== API
  const {
    data: authentication,
    isValidating: isValidatingAuthentication,
    error: errorAuth
  } = useSWR(
    shouldAuth ? { email } : null,
    Api.ClientPageApi.sentEmailWithAuthLogin
  );

  const clearCache = () => mutate({ email }, null, true);

  const handleClientLogin = () => {
    if (email !== '') {
      clearCache();
      setShouldAuth(true);
    } else {
      setSuccess(false);
      setError(true);
      setErrorMessage(`${t('GENERAL.inputRequired')}`);
    }
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {`${errorMessage}`}
        </Typography>
      </Container>
    );

  const getTokenExpired = () =>
    !tokenExpiredBanner ? (
      false
    ) : (
      <Box
        sx={{
          alignItems: 'flex-start !important',
          backgroundColor: 'error.main',
          borderRadius: 2,
          marginBottom: '20px',
          padding: 2
        }}
      >
        <Typography
          color="mainText.100"
          sx={{ fontSize: 16 }}
          variant="primary-bold"
        >
          {t('LOGIN.linkExpiredTitle')}
        </Typography>
        <br />
        <Typography
          color="mainText.100"
          sx={{ fontSize: 16 }}
          variant="primary-semi"
        >
          {t('LOGIN.linkExpiredDescription')}
        </Typography>
      </Box>
    );

  const getSuccess = () =>
    !success ? (
      false
    ) : (
      <Box
        sx={{
          alignItems: 'flex-start !important',
          backgroundColor: 'success.main',
          borderRadius: 2,
          marginBottom: '20px',
          padding: 2
        }}
      >
        <Typography
          color="mainText.100"
          sx={{ fontSize: 16 }}
          variant="primary-bold"
        >
          {t('LOGIN.sendLinkAuthTitle')}
        </Typography>
        <br />
        <Typography
          color="mainText.100"
          sx={{ fontSize: 16 }}
          variant="primary-semi"
        >
          {t('LOGIN.sendLinkAuthDescription')}
        </Typography>
      </Box>
    );

  useEffect(() => {
    if (errorAuth) {
      const { response } = errorAuth;
      setError(true);
      setSuccess(false);
      setShouldAuth(false);
      if (response && response.data) {
        const { data } = response;
        setErrorMessage(data.message);
      } else {
        setErrorMessage(errorAuth.message);
      }
    }
  }, [errorAuth]);

  useEffect(() => {
    if (authentication && authentication.status === 201) {
      setError(false);
      setTokenExpiredBanner(false);
      setSuccess(true);
      setShouldAuth(false);
    }
  }, [authentication]);

  useEffect(() => {
    setError(false);
    setSuccess(false);
    setShouldAuth(false);
    clearCache();
    if (tokenExpired) {
      setTokenExpiredBanner(true);
    }
  }, []);

  return (
    <>
      {getSuccess()}
      {getTokenExpired()}
      <FormLabel error={false} label="Email" />
      <RegularInput
        autofill
        customStyle={{ width: '100%' }}
        error={error}
        onChange={(user) => setEmail(user)}
        type="email"
      />
      {getError()}
      <LoginButton onClick={() => handleClientLogin()} variant="primary">
        {isValidatingAuthentication ? (
          <Loader />
        ) : (
          <Typography color="mainText.100" variant="primary-bold">
            {t('LOGIN.requestLogin')}
          </Typography>
        )}
      </LoginButton>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '20px'
        }}
      >
        <Typography
          color="informative.main"
          onClick={() => setCurrentProcess('login')}
          sx={{ ':hover': { cursor: 'pointer' } }}
          variant="primary-bold"
        >
          {t('LOGIN.backToLogin')}
        </Typography>
      </Box>
    </>
  );
};

export default ClientLogin;
