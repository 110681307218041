/* eslint-disable no-loop-func */
import {
  Box,
  Button,
  Collapse,
  SliderProps,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import useSWR from 'swr';
import { ServiceCollapse } from '../../views/leads/steps/components/ServiceCollapse';
import TableComponentEditable from '../shared/TableComponentEditable';
import {
  capitalizeString,
  getVisitInfo,
  getCurrency,
  getFrequency
} from '../../utils/general';
import {
  parserLeadCalculator,
  parserServiceCalculator,
  getServiceCost,
  getTimeCost,
  getResourceCost,
  getTotalPerVisit,
  totalValueServiceTime,
  getTravelCost
} from '../../utils/calculatorHelper';
// import Api from '../../api';

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

interface Props {
  index: number;
  onServiceTimeCostChange: (newCost: number) => void;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  totalServiceTimeCost: any;
  values: any;
}

const ServiceTimeCalculator: React.FC<Props> = function ServiceTimeCalculator({
  index,
  values,
  totalServiceTimeCost,
  setValues,
  onServiceTimeCostChange
}) {
  const { t } = useTranslation();
  const [servicesList, setServicesList] = useState<any>([]);
  const [individualTasksList, setIndividualTasksList] = useState<any>([]);
  const prevServiceTimeCostRef = useRef<number | null>(null);

  const travelTime: any =
    values.travelTime !== undefined ? values.travelTime : 0.0;
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const getVisits = () => {
    return getVisitInfo(values).visits;
  };

  const getNumericService1Length = () => {
    return values?.availability?.generalInfo?.daysOfServiceCount || 0;
  };
  useEffect(() => {
    if (values?.services) {
      setServicesList(values.services);
    }
    if (values?.individualTasks) {
      setIndividualTasksList(values.individualTasks);
    }
  }, [values?.services, values?.individualTasks]);

  useEffect(() => {
    const newServiceTimeCost =
      getTotalPerVisit(values, travelTime) * getNumericService1Length();

    if (newServiceTimeCost !== prevServiceTimeCostRef.current) {
      onServiceTimeCostChange(newServiceTimeCost);
      prevServiceTimeCostRef.current = newServiceTimeCost;
    }
  }, [values?.services, values?.individualTasks]);

  const getServiceTimeCost = () => {
    return `${getCurrency(
      getTotalPerVisit(parserLeadCalculator(values, t), travelTime)
    )} = ${getCurrency(
      getTotalPerVisit(parserLeadCalculator(values, t), travelTime) *
        parseInt(values?.availability?.generalInfo?.daysOfServiceCount || 1, 10)
    )}`;
  };

  const transportationTime = [
    {
      cost: getTravelCost(values, travelTime).toFixed(2),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.travelTimeCost')}
        </Typography>
      )
    },
    {
      cost: getTimeCost(individualTasksList, servicesList, 'setup').toFixed(2),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.setupTimeCost')}
        </Typography>
      )
    },
    {
      cost: getTimeCost(individualTasksList, servicesList, 'teardown').toFixed(
        2
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.teardownTimeCost')}
        </Typography>
      )
    },
    {
      cost: getResourceCost(
        values.supervisor,
        values.supervisorTime?.toString()
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.supervisorTimeCost')}
        </Typography>
      )
    }
  ];

  const totalCostPerService = [
    {
      detail: (
        <Typography variant="primary-bold">{`${getCurrency(
          getTotalPerVisit(parserLeadCalculator(values, t), travelTime)
        )}`}</Typography>
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.totalCostPerVisit')}
        </Typography>
      )
    }
  ];

  const summary = [
    {
      detail: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.visitsInPeriod')}
        </Typography>
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.serviceFrequency')}
        </Typography>
      )
    },
    {
      detail: parseInt(
        values?.availability?.generalInfo?.daysOfServiceCount,
        10
      ),
      name: (
        <Typography variant="primary">
          {capitalizeString(getFrequency(values, t))}
        </Typography>
      )
    },
    {
      detail: (
        <Typography variant="primary-bold">{`${getCurrency(
          getTotalPerVisit(parserLeadCalculator(values, t), travelTime) *
            parseInt(values?.availability?.generalInfo?.daysOfServiceCount, 10)
        )}`}</Typography>
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.grandTotal')}
        </Typography>
      )
    }
  ];

  useEffect(() => {
    if (servicesList || individualTasksList) {
      if (getVisits() === 0) {
        // setValues({
        //   ...values,
        //   serviceTimeCost: getTotalPerVisit(values, travelTime)
        // });
      } else {
        const calculatedCost =
          getTotalPerVisit(values, travelTime) * getVisits();
        if (values.serviceTimeCost !== calculatedCost) {
          setValues({
            ...values,
            serviceTimeCost: calculatedCost
          });
        }
      }
    }
  }, [values]);

  return (
    <Box border={1} borderColor={theme.palette.grey[300]}>
      <TableHead
        sx={{
          '.MuiTableHead-root': { boxShadow: 0 },
          backgroundColor: '#F5F5F5',
          display: 'flex',
          width: '100%'
        }}
      >
        <TableRow
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '56px',
            width: '100%'
          }}
        >
          <RowElement onClick={() => {}}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Button onClick={() => setOpen(!open)}>
                  <i
                    className="custom-icon icon-select"
                    style={{
                      fontSize: 18,
                      transform: `rotate(${open ? '180deg' : '0deg'})`
                    }}
                  />
                </Button>
                <Typography variant="primary">
                  {t('LEAD.CREATE.COST_CALCULATOR.serviceTime')} #{index + 1}
                </Typography>
              </Box>
              <Box>
                {`${
                  values?.availability?.generalInfo?.daysOfServiceCount || 0
                } ${t(
                  'LEAD.CREATE.COST_CALCULATOR.visits'
                )} x ${getServiceTimeCost()}`}
              </Box>
            </Box>
          </RowElement>
        </TableRow>
      </TableHead>
      {/* ----------------- COLLAPSE BODY ----------------- */}
      <Collapse in={open}>
        <Box p={2}>
          <Box>
            <Typography variant="primary">
              <Box mb={2}>
                <Typography variant="body_500">
                  {t('LEAD.CREATE.COST_CALCULATOR.servicesCost')}
                </Typography>
              </Box>
              {servicesList.map((service: any, index: number) => {
                return (
                  <ServiceCollapse
                    editableFields={[]}
                    headKeys={['task', 'totalCost', 'calculator']}
                    manualPricing={values.manualPricing}
                    screen="calculator"
                    service={parserServiceCalculator(
                      service,
                      values.company,
                      t
                    )}
                    setService={(newValue: any) => {
                      const newService = servicesList;
                      newService[index] = newValue;
                      setValues({ ...values, services: newService });
                    }}
                  />
                );
              })}
            </Typography>
          </Box>
          {individualTasksList && individualTasksList?.length > 0 && (
            <Box pt={5}>
              <ServiceCollapse
                editableFields={[]}
                headKeys={['task', 'totalCost', 'calculator']}
                manualPricing={values.manualPricing}
                screen="calculator"
                service={{
                  ...individualTasksList,
                  company: values.company,
                  name: 'Individual Tasks'
                }}
                setService={(newValue: any) => {
                  setValues({ ...values, individualTasks: newValue });
                }}
              />
            </Box>
          )}
          <Box p={5}>
            <TableComponentEditable
              disableAdd
              hideTableHeader
              action={() => {}}
              handleOpenDraw={() => {}}
              headKeys={['name', 'cost']}
              onChange={() => {}}
              valuesArray={{
                data: transportationTime
              }}
            />
          </Box>
          <Box p={5} pt={0}>
            <TableComponentEditable
              disableAdd
              hideTableHeader
              action={() => {}}
              handleOpenDraw={() => {}}
              headKeys={['name', 'detail']}
              onChange={() => {}}
              valuesArray={{
                data: totalCostPerService
              }}
            />
          </Box>
          <Box p={5} pt={0}>
            <TableComponentEditable
              disableAdd
              hideTableHeader
              action={() => {}}
              handleOpenDraw={() => {}}
              headKeys={['name', 'detail']}
              onChange={() => {}}
              valuesArray={{
                data: summary
              }}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ServiceTimeCalculator;
