import React, { useEffect, useState } from 'react';
import { Box, Container, useTheme } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HandshakeIcon from '@mui/icons-material/Handshake';
import blueBerryLogoLeters from '../../../../../assets/images/bluberry.png';
import blueBerryLogoMini from '../../../../../assets/images/bluberry_logo_mini.png';

interface Props {
  proposalId: string;
}

const AcceptedProposal: React.FC<Props> = function AcceptedProposal({
  proposalId
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            mt: 2,
            width: '100%'
          }}
        >
          <Box>
            <img
              alt="BlueBerry Logo"
              className="logo-image-mini"
              src={blueBerryLogoMini}
            />
            <img
              alt="BlueBerry leters"
              className="logo-image-letters"
              src={blueBerryLogoLeters}
            />
          </Box>
        </Box>
        <Box
          mt={6}
          padding={4}
          sx={{
            border: `4px solid ${theme.palette.grey[300]}`,
            maxWidth: '600px'
          }}
        >
          <Box
            mb={2}
            mt={2}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography variant="title">
              {t('LEAD.CREATE.PROPOSAL.acceptedProposalTitle')}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <HandshakeIcon
              sx={{ color: 'mainText.main', height: '230px', width: '230px' }}
            />
          </Box>
          <Box
            borderColor={theme.palette.grey[300]}
            mt={2}
            sx={{
              alignItems: 'center',
              display: 'block',
              justifyContent: 'center',
              justifyItems: 'center',
              padding: '0 4rem',
              textAlign: 'center'
            }}
          >
            <Typography variant="body_400">
              {t('LEAD.CREATE.PROPOSAL.acceptedProposalText')}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AcceptedProposal;
