import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../store/zustand/auth';
import { getLocalStorage } from '../utils/authHelper';

function ProtectedRoute() {
  const userState = useAuthStore();

  if (!getLocalStorage('accessToken')) {
    return <Navigate to="/login" />;
  }
  if (userState.isAuthenticated) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
}

export default ProtectedRoute;
