/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from '@toast-ui/react-calendar';
import { isMobile } from 'react-device-detect';
import {
  Container,
  Box,
  Typography,
  MenuItem,
  IconButton,
  Avatar,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useSWR from 'swr';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from 'react-calendar-timeline';
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { getMonthString } from '../../utils/general';
import SelectInput from '../../components/form/SelectInput';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import CalendarDetailPopup from '../../components/calendar/CalendarDetailPopup';
import useCalendarStore from '../../store/zustand/calendar';
import api from '../../api';
import { weekDays } from '../../helpers/weekDays';
import { IGroup, IItem } from '../../helpers/assignments-interfaces';
import { gorentalsMock } from '../../helpers/mock_data';
import ItemRenderer from './ItemView';
import AssignmentsTask from './AssignmentsTask';

const DesktopStyle = {
  marginX: `40px`,
  marginY: `20px`,
  width: 'auto'
};

const keys = {
  groupIdKey: 'id',
  groupLabelKey: 'title',
  groupRightTitleKey: 'rightTitle',
  groupTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemIdKey: 'id',
  itemTimeEndKey: 'end_time',
  itemTimeStartKey: 'start_time',
  itemTitleKey: 'title'
};

const AssignmentsView: React.FC = function AssignmentsView() {
  const [projectFilter, setProjectFilter] = useState('project');
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [customerFilter, setCustomerFilter] = useState('customer');
  const [taskFilter, setTaskFilter] = useState('task');
  const [typeFilter, setTypeFilter] = useState('type');
  const [statusFilter, setStatusFilter] = useState('all');
  const [timeView, setTimeView] = useState('months');
  const { t } = useTranslation();
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>(
    {}
  );
  const [taskData, setTaskData] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const [defaultTimeStart] = useState(moment().startOf('month').toDate());
  const [defaultTimeEnd] = useState(
    moment().startOf('day').add(2, 'month').toDate()
  );

  const groups = [
    { id: '1', level: 0, parent: null, title: 'Service Time #1' },
    { id: '1:1', level: 1, parent: '1', title: 'Service 1' },
    {
      details: ' - Hours: 2 - Req. Resources: 1',
      id: '1:1:1',
      level: 2,
      parent: '1',
      title: 'Task 1  Task name full'
    },
    {
      id: '1:1:1:1',
      level: 3,
      parent: '1',
      rate: '10',
      title: 'Name Technician 1'
    },
    {
      details: ' - Hours: 2 - Req. Resources: 2',
      id: '1:1:2',
      level: 2,
      parent: '1',
      title: 'Task 2  Task name full'
    },
    {
      id: '1:1:2:1',
      level: 3,
      parent: '1',
      rate: '15',
      title: 'Name Technician 2'
    },
    {
      id: '1:1:2:2',
      level: 3,
      parent: '1',
      rate: '15',
      title: 'Name Technician 3'
    },
    { id: '1:2', level: 1, parent: '1', title: 'Service 2' },
    {
      details: ' - Hours: 2 - Req. Resources: 1',
      id: '1:2:1',
      level: 2,
      parent: '1',
      title: 'Task 3 - Task name full'
    },
    {
      id: '1:2:1:1',
      level: 3,
      parent: '1',
      rate: '20',
      title: 'Name Technician 4'
    },
    { id: '2', level: 0, parent: null, title: 'Service Time #2' },
    { id: '2:3', level: 1, parent: '2', title: 'Service 3' },
    {
      details: ' - Hours: 2 - Req. Resources: 1',
      id: '2:3:4',
      level: 2,
      parent: '2',
      title: 'Task 4 - Task name full'
    },
    {
      id: '2:3:4:1',
      level: 3,
      parent: '2',
      rate: '20',
      title: 'Name Technician 5'
    }
  ];

  const items = [
    {
      end_time: moment().add(3, 'days'),
      group: '1:1:1:1',
      id: '1',
      start_time: moment(),
      title: 'Technician 1 name'
    },
    {
      end_time: moment().add(7, 'days'),
      group: '1:1:1:1',
      id: '20',
      start_time: moment().add(4, 'days'),
      title: 'Technician 1 name'
    },
    {
      end_time: moment().add(11, 'days'),
      group: '1:1:1:1',
      id: '21',
      start_time: moment().add(8, 'days'),
      title: 'Technician 1 name'
    },
    {
      end_time: moment().add(3, 'days'),
      group: '1:1:1:1',
      id: '8',
      second: true,
      start_time: moment(),
      title: 'Technician 1 name'
    },
    {
      end_time: moment().add(-1, 'days'),
      group: '1:1:2:1',
      id: '6',
      start_time: moment().add(-3, 'days'),
      title: 'Technician 2 name'
    },
    {
      end_time: moment().add(6, 'days'),
      group: '1:1:2:1',
      id: '23',
      start_time: moment().add(4, 'days'),
      title: 'Technician 2 name'
    },
    {
      end_time: moment().add(6, 'days'),
      group: '1:1:2:1',
      id: '9',
      second: true,
      start_time: moment().add(3, 'days'),
      title: 'Technician 2 name'
    },
    {
      end_time: moment().add(3, 'days'),
      group: '1:1:2:2',
      id: '2',
      start_time: moment().add(1, 'days'),
      title: 'Technician 3 name'
    },
    {
      end_time: moment().add(5, 'days'),
      group: '1:1:2:2',
      id: '10',
      second: true,
      start_time: moment().add(-2, 'days'),
      title: 'Technician 3 name'
    },
    {
      end_time: moment().add(5, 'days'),
      group: '1:2:1:1',
      id: '3',
      start_time: moment().add(-2, 'days'),
      title: 'Technician 4 name'
    },
    {
      end_time: moment().add(5, 'days'),
      group: '1:2:1:1',
      id: '11',
      second: true,
      start_time: moment().add(-2, 'days'),
      title: 'Technician 4 name'
    },
    {
      end_time: moment().add(6, 'days'),
      group: '2:3:4:1',
      id: '4',
      start_time: moment().add(2, 'days'),
      title: 'Technician 5 name'
    },
    {
      end_time: moment().add(6, 'days'),
      group: '2:3:4:1',
      id: '12',
      second: true,
      start_time: moment().add(2, 'days'),
      title: 'Technician 5 name'
    }
  ];

  const itemRenderer = ({ item, itemContext, getItemProps }: any) => {
    const auxProps = getItemProps({
      style: {
        alignItems: 'center',
        background: '#0077c0',
        border: `3px solid ${itemContext.selected ? '#0077c0' : 'transparent'}`,
        borderRadius: '12.5px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0 0.3rem 0.6rem',
        display: 'flex'
      }
    });
    return (
      <Box
        {...auxProps}
        className={`item-box-container-assignments ${
          item?.second ? 'hide-item-assignments' : 'double-item-assignments'
        }`}
        onClick={() => setOpenDetail(true)}
      >
        <Box
          className="item-box-container-assignments-content"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            left: '0',
            overflow: 'hidden',
            padding: '0',
            position: 'sticky',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          <Avatar
            alt="Technician Name"
            src=""
            sx={{
              backgroundColor: '#0eac51',
              position: 'relative'
            }}
          >
            TN
          </Avatar>
          <Typography fontSize={16} variant="primary">
            {itemContext.title}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleToggleGroup = (group: any) => {
    setExpandedGroups((prev) => {
      if (prev[group.id] && group.level === 0) {
        return Object.entries(prev).reduce((acc, [groupId, expanded]) => {
          if (groupId.startsWith(group.id)) {
            return acc;
          }

          return {
            ...acc,
            [groupId]: expanded
          };
        }, {});
      }

      // Toggle expansion for that group (treeLevel 1 or 2)
      return {
        ...prev,
        [group.id]: !prev[group.id]
      };
    });
  };

  const generateNestedView = useCallback(
    (group: any) => {
      if (group.level === 0) {
        return (
          <Box
            className="group-service-time-assignments"
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'block' }}>
              <Box sx={{ display: 'flex', ml: '0.5rem', mt: '0.5rem' }}>
                <Typography variant="secondary">{group.title}</Typography>
                {!open && (
                  <Box sx={{ display: 'flex', ml: '0.5rem' }}>
                    <Box sx={{ display: 'flex', height: '25px' }}>
                      <i className="custom-icon icon-clock" />
                    </Box>
                    <Typography sx={{ ml: '0.5rem' }} variant="body_200">
                      08:00 AM - 05:00 PM
                    </Typography>
                  </Box>
                )}
              </Box>
              {!open && (
                <Box sx={{ display: 'block' }}>
                  <Box sx={{ display: 'flex', ml: '0.5rem', mt: '0.25rem' }}>
                    <Typography variant="body_200">
                      Weekly - Tuesday, Monday, Wednesday, Thursday, Friday,
                      Saturday
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <IconButton onClick={() => handleToggleGroup(group)}>
              <KeyboardArrowDownIcon
                style={{
                  transform: expandedGroups[group.id]
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }}
              />
            </IconButton>
          </Box>
        );
      }
      let auxPaddingLeft = 12;

      if (group.level === 2) {
        auxPaddingLeft = 24;
      }

      if (group.level === 3) {
        auxPaddingLeft = 48;
      }

      const auxClassName =
        open && group.level === 3 ? 'assignments-item-task-row' : '';

      return (
        <Box
          // onClick={() => handleToggleGroup(group)}
          className={
            !open && group.level !== 0 ? 'group-service-assignments' : ''
          }
          style={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: auxPaddingLeft,
            paddingRight: '1rem'
          }}
        >
          {group.title} {group.rate && !open && ` - Rate: $${group.rate}`}{' '}
          {!open && group.details}
          {open && group.level === 3 && (
            <Box sx={{ width: '40px' }}>
              <Avatar
                alt="Technician Name"
                src=""
                sx={{
                  backgroundColor: '#0eac51',
                  marginRight: '-20px',
                  position: 'relative'
                }}
              >
                TN
              </Avatar>
            </Box>
          )}
        </Box>
      );
    },
    [expandedGroups, open]
  );

  const transformedGroups = useMemo(() => {
    return groups
      .filter(
        (group) => group.level === 0 || expandedGroups[group.parent as string]
      )
      .map((group) => {
        return { ...group, title: generateNestedView(group) };
      });
  }, [groups, expandedGroups]);

  return (
    <Container sx={DesktopStyle}>
      <Box>
        <Box key="assignments-title" sx={{ mt: '8px', width: '50%' }}>
          <Typography variant="title">{`${t(
            `GENERAL.assignments`
          )}`}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="1.5rem"
        >
          <Box display="flex" gap="0.5rem">
            <Box width="120px">
              <SelectInput
                fullWidth
                onChange={(event: any) =>
                  setProjectFilter(event.target.value as string)
                }
                placeholder="Project"
                value={projectFilter}
              >
                <MenuItem
                  selected={projectFilter === 'project'}
                  value="project"
                >
                  Project
                </MenuItem>
                {projectsList &&
                  projectsList?.length > 0 &&
                  projectsList.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
              </SelectInput>
            </Box>
            <Box width="120px">
              <SelectInput
                fullWidth
                onChange={(event: any) =>
                  setCustomerFilter(event.target.value as string)
                }
                value={customerFilter}
              >
                <MenuItem disabled value="customer">
                  Customer
                </MenuItem>
              </SelectInput>
            </Box>
            <Box width="120px">
              <SelectInput
                fullWidth
                onChange={(event: any) =>
                  setTaskFilter(event.target.value as string)
                }
                value={taskFilter}
              >
                <MenuItem disabled value="task">
                  Technician
                </MenuItem>
              </SelectInput>
            </Box>
            <Box width="120px">
              <SelectInput
                fullWidth
                onChange={(event: any) =>
                  setTypeFilter(event.target.value as string)
                }
                value={typeFilter}
              >
                <MenuItem disabled value="type">
                  Type
                </MenuItem>
              </SelectInput>
            </Box>
            <Box width="120px">
              <SelectInput
                displayEmpty
                fullWidth
                onChange={(event: any) =>
                  setStatusFilter(event.target.value as string)
                }
                value={statusFilter}
              >
                <MenuItem disabled value="">
                  Status
                </MenuItem>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="assigned">Assigned</MenuItem>
                <MenuItem value="unassigned">Unassigned</MenuItem>
              </SelectInput>
            </Box>
            {/* <Box>
              <ToggleButtonGroup
                exclusive
                aria-label="Time View"
                color="primary"
                onChange={(event: any) =>
                  setTimeView(event.target.value as string)
                }
                value={timeView}
              >
                <ToggleButton value="month">Months</ToggleButton>
                <ToggleButton value="year">Years</ToggleButton>
              </ToggleButtonGroup>
              </Box> */}
          </Box>
        </Box>
        <Timeline
          stackItems
          className={
            open ? 'calendar-header-blue-open' : 'calendar-header-blue'
          }
          defaultTimeEnd={defaultTimeEnd}
          defaultTimeStart={defaultTimeStart}
          groups={transformedGroups}
          /* horizontalLineClassNamesForGroup={(group) => {
            if (open && group?.level === 3) {
              return ['assignments-item-task-row'];
            }

            return [];
          }} */
          itemHeightRatio={0.9}
          itemRenderer={itemRenderer}
          items={open ? items : []}
          lineHeight={open ? 30 : 65}
          maxZoom={3 * 365.24 * 86400 * 1000}
          minZoom={2.5 * 86400 * 1000 * 7 * 3}
          scrollRef={(el) => {
            console.log('el->', el);
          }}
          sidebarWidth={open ? 360 : 700}
          timeSteps={{
            day: 1,
            hour: 1,
            minute: 1,
            month: 1,
            second: 1,
            year: 1
          }}
        >
          <TimelineHeaders
            calendarHeaderClassName={open ? '' : 'timeline-header-detail-blue'}
            className={open ? '' : 'timeline-header-blue'}
            style={{ backgroundColor: '#0077c0' }}
          >
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <Box {...getRootProps()} sx={{ backgroundColor: 'white' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0.5rem 1rem 0rem 1rem'
                      }}
                    >
                      <Box>
                        <Box sx={{ display: 'block' }}>
                          <Typography sx={{ mr: '0.5rem' }} variant="secondary">
                            Project Uno - Cliente 1
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'block' }}>
                          <Typography variant="body_200">
                            Start: 01/01/2024 To: 01/01/2025
                          </Typography>
                        </Box>
                        {!open && (
                          <Box sx={{ display: 'block' }}>
                            <Typography
                              sx={{ mr: '0.5rem' }}
                              variant="body_200"
                            >
                              Address: Chicago Street Pub, 75 N Chicago St,
                              Joliet, Illinois 60432, United States
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <IconButton onClick={() => setOpen(!open)}>
                        <KeyboardArrowDownIcon
                          style={{
                            transform: open ? 'rotate(90deg)' : 'rotate(-90deg)'
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                );
              }}
            </SidebarHeader>
            <DateHeader
              className="primaryHeader12"
              height={60}
              unit="primaryHeader"
            />
            <DateHeader className="primaryHeader22" />
          </TimelineHeaders>
        </Timeline>
      </Box>
      {openDetail && (
        <AssignmentsTask setOpenDetail={setOpenDetail} taskData={taskData} />
      )}
    </Container>
  );
};

export default AssignmentsView;

export interface projects {
  id: true;
  proposal: {
    id: true;
    lead: {
      address: {
        address: true;
        id: true;
        name: true;
      };
      client: {
        businessName: true;
        firstName: true;
        id: true;
        lastName: true;
        phone: true;
      };
      contact: {
        id: true;
        name: true;
        phoneNumber: true;
      };
      id: true;
      leadServiceTimes: {
        // se consulta por lead
        endDate: true;
        endTime: true;
        frequency: true;
        frequencyDays: true;
        frequencyWeek: true;
        id: true;
        rate: true;
        services: {
          id: true;
          name: true;
          tasks: {
            assignmentPercentage: true; // number
            firstName: true;
            id: true;
            measure: true;
            measurePerHour: true;
            minimumResources: true;
            name: true;
            quantity: true;
            taskTechnicians: {
              technician: {
                availableDays: true;
                availableTimeEnd: true;
                availableTimeStart: true;
                email: true;
                fileBank: {
                  /* se debe hacer el join con el filtro para traer la imagen de perfil
                    .leftJoinAndSelect('noteUsers.fileBank', 'usersFileBank',
                      `usersFileBank.fileType = '${FileTypes.IMAGE}' AND usersFileBank.photoSize = 'small'`
                    )
                  */
                  fileName: true;
                  fileUrl: true;
                  id: true;
                };
                firstName: true;
                ftsFullNameEn: true;
                id: true;
                jobTitle: true;
                lastName: true;
                phoneNumber: true;
                rate: true;
              };
            };
            technicians: true;
          };
        };
        startDate: true;
        startTime: true;
        supervisor: {
          email: true;
          firstName: true;
          ftsFullNameEn: true;
          id: true;
          jobTitle: true;
          lastName: true;
          phoneNumber: true;
          rate: true;
        };
      };
      name: true;
      projectEndDate: true;
      projectStartDate: true;
      projectType: true;
    };
  };
}

export interface leadServiceTimes {
  // se consulta por lead
  endDate: true;
  endTime: true;
  frequency: true;
  frequencyDays: true;
  frequencyWeek: true;
  id: true;
  rate: true;
  services: {
    id: true;
    name: true;
    tasks: {
      firstName: true;
      id: true;
      leadTaskTechnicians: {
        id: true;
        technician: {
          availableDays: true;
          availableTimeEnd: true;
          availableTimeStart: true;
          email: true;
          fileBank: {
            /* se debe hacer el join con el filtro para traer la imagen de perfil
              .leftJoinAndSelect('noteUsers.fileBank', 'usersFileBank',
                `usersFileBank.fileType = '${FileTypes.IMAGE}' AND usersFileBank.photoSize = 'small'`
              )
            */
            fileName: true;
            fileUrl: true;
            id: true;
          };
          firstName: true;
          ftsFullNameEn: true;
          id: true;
          jobTitle: true;
          lastName: true;
          phoneNumber: true;
          rate: true;
        };
      };
      measure: true;
      measurePerHour: true;
      minimumResources: true;
      name: true;
      quantity: true;
      serviceTime: {
        id: true;
      };
      technicians: true;
    };
  };
  startDate: true;
  startTime: true;
  supervisor: {
    email: true;
    firstName: true;
    ftsFullNameEn: true;
    id: true;
    jobTitle: true;
    lastName: true;
    phoneNumber: true;
    rate: true;
  };
}

export interface calendarEvents {
  // Se debe consultar por project, lead, leadServiceTime
  end: true;
  id: true;
  leadService: {
    id: true;
  };
  leadServiceTime: {
    id: true;
    lead: {
      id: true;
    };
  };
  project: {
    id: true;
  };
  start: true;
  state: true;
  taskIterations: {
    id: true;
    leadServiceTask: {
      id: true;
    };
    scheduledOn: true;
    status: true;
    technician: {
      availableDays: true;
      availableTimeEnd: true;
      availableTimeStart: true;
      email: true;
      fileBank: {
        /* se debe hacer el join con el filtro para traer la imagen de perfil
          .leftJoinAndSelect('noteUsers.fileBank', 'usersFileBank',
            `usersFileBank.fileType = '${FileTypes.IMAGE}' AND usersFileBank.photoSize = 'small'`
          )
        */
        fileName: true;
        fileUrl: true;
        id: true;
      };
      firstName: true;
      ftsFullNameEn: true;
      id: true;
      jobTitle: true;
      lastName: true;
      phoneNumber: true;
      rate: true;
    };
  };
  title: true;
}
