import React from 'react';
import {
  Grid,
  Toolbar,
  styled,
  Divider,
  Box,
  SliderProps,
  Typography,
  Button
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from 'react-router-dom';
import { height } from '@mui/system';
import { useTranslation } from 'react-i18next';
import MenuComponent from './Menu';
import {
  navBarHeight,
  sideBarExpandedWidth,
  sideBarNormalWidth
} from '../../helpers/constants';
import GlobalSearch from './GlobalSearch';
import useUserRole from '../../hooks/useUserRoles';
import { ROLES } from '../../helpers/roles';

interface Props {
  expandSidebar: (param: boolean) => void;
  isImpersonated: boolean;
  open: boolean;
  tokenImpersonated: string;
  // permanentSidebar: boolean;
  // setPermanentSidebar: (param: boolean) => void;
}

const dividerStyle = {
  backgroundColor: '#F7F6F9',
  height: '32px',
  marginTop: '24px',
  position: 'fixed',
  right: '280px',
  top: '8px'
};

interface AppBarProps extends MuiAppBarProps {
  isImpersonated?: boolean;
  open?: boolean;
}

const SidebarIconBox = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary[100],
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(0.5)
}));

const SidebarIconContainer = styled(Box)<SliderProps>(({ theme }) => ({
  color: theme.palette.mainText?.[400],
  cursor: 'pointer',
  left: '-10px',
  position: 'absolute'
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open, isImpersonated }) => ({
  boxShadow: '4px 2px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  height: isImpersonated ? '110px' : `${navBarHeight}px`,
  justifyContent: isImpersonated ? 'flex-end' : 'center',
  left: sideBarNormalWidth,
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp
  }),
  width: `calc(100% - ${sideBarNormalWidth})`,
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: sideBarExpandedWidth - sideBarNormalWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: `calc(100% - ${sideBarExpandedWidth}px)`
  })
}));

const NavBar: React.FC<Props> = function NavBar({
  open,
  isImpersonated,
  tokenImpersonated,
  // setPermanentSidebar,
  // permanentSidebar,
  expandSidebar
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isClient = useUserRole([ROLES.CLIENT]);

  return (
    <AppBar isImpersonated={isImpersonated} open={open} position="fixed">
      <Toolbar>
        <Grid container alignItems="center" direction="row">
          <SidebarIconContainer>
            {open ? (
              <SidebarIconBox
                onClick={() => {
                  // setPermanentSidebar(!permanentSidebar);
                  expandSidebar(false);
                }}
              >
                <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '18px' }} />
              </SidebarIconBox>
            ) : (
              <SidebarIconBox
                onClick={() => {
                  // setPermanentSidebar(!permanentSidebar);
                  expandSidebar(true);
                }}
              >
                <KeyboardDoubleArrowRightIcon sx={{ fontSize: '18px' }} />
              </SidebarIconBox>
            )}
          </SidebarIconContainer>
          {!isClient && <GlobalSearch />}
          {!isClient && (
            <NotificationsNoneIcon
              sx={{ color: '#7D8090', position: 'fixed', right: '304px' }}
            />
          )}

          {!isClient && (
            <Divider
              flexItem
              orientation="vertical"
              sx={dividerStyle}
              variant="middle"
            />
          )}

          <MenuComponent />
        </Grid>
      </Toolbar>
      {isImpersonated && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            height: '60px',
            justifyContent: 'left',
            paddingLeft: '8px',
            width: 'auto'
          }}
        >
          <Button
            onClick={() =>
              navigate('/app/impersonate', {
                state: {
                  isGoingBack: true,
                  requestTwoFactor: false,
                  token: tokenImpersonated
                }
              })
            }
            sx={{
              display: 'flex',
              maxHeight: '30px'
            }}
            variant="cancel"
          >
            {t('GENERAL.stopImpersonating')}
          </Button>
        </Box>
      )}
    </AppBar>
  );
};

export default NavBar;
