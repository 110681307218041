/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Checkbox,
  Avatar,
  Tooltip,
  Modal,
  TableHead,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  styled,
  Backdrop,
  MenuItem
} from '@mui/material';
import dayjs from 'dayjs';
import SendIcon from '@mui/icons-material/Send';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import {
  secondaryColors,
  surfaceColors,
  textColors,
  themePalette
} from '../../../theme/themeConfig';
import {
  TaskStatus,
  TaskStyle,
  capitalizeString,
  convertTo12HourFormat,
  getPluralMeasure,
  getTimeFromISO8601,
  resourseColors,
  taskInteractionStyles
} from '../../../utils/general';
import Chat from '../../leads/steps/components/Chat';
import {
  convertTimeFormat,
  formatDate,
  formatSumTime
} from '../../../utils/calendarHelper';
import Api from '../../../api';
import { TaskIterationStatusStatusEnum } from '../../../api-client/generated/api';
import {
  DrawerButton,
  ModalContainer
} from '../../../components/modals/GlobalConfirmModal';
import ImageViewer from '../../../components/ImageViewer';
import SelectInput from '../../../components/form/SelectInput';
import ReassignTechnicianModal from '../../../components/modals/ReassignTechnicianModal';
import ProjectTaskDetailsModal from './ProjectTaskDetailsModal';

export interface NoteResponseDTO {
  createdAt: string;
  id: string;
  noteText: string;
  updatedAt: string;
  user: object;
}

const ListItem = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  border: 'solid',
  borderColor: secondaryColors.SECONDARY,
  borderRadius: '2px',
  borderWidth: '1px',
  display: 'flex',
  marginTop: '0.5rem',
  width: '100%'
}));

const ListHead = styled(TableHead)(({ theme }) => ({
  '.MuiTableHead-root': { boxShadow: 0 },
  display: 'flex',
  width: '100%'
}));

const ListTitle = styled(Typography)(({ theme }) => ({
  color: textColors.DARK_TEXT,
  fontFamily: 'Lexend',
  fontSize: '28px',
  fontWeight: '600',
  marginLeft: '1rem'
}));

const TaskImageListContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1.5),
  gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(7)}, 2fr))`,
  padding: theme.spacing(1.5)
}));

const TaskImageContainer = styled(Box)(({ theme }) => ({
  '&:hover': {
    transform: 'scale(1.1)'
  },
  alignContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.secondary[100]}`,
  cursor: 'pointer',
  display: 'flex',
  height: theme.spacing(7),
  justifyContent: 'center',
  marginRight: '5px',
  overflow: 'hidden',
  transition: '1s ease',
  width: theme.spacing(7)
}));

interface IServiceTableBlockCheck {
  isCheked: boolean;
  text: string;
}

interface IServiceTableBlock {
  checklist?: Array<IServiceTableBlockCheck> | [];
  images?: any;
  materials?: any;
  note?: any;
  taskIterationID?: any;
  title: string;
  type: string;
}
type VariantType = 'secondary-bold' | 'secondary';

interface IServiceTableRowTD {
  children?: any;
  colorH: string;
  fontSize: string;
  text?: string;
  typeTx?: VariantType;
  width: number;
}

interface Props {
  calendarEvenState: string | undefined;
  dashboard: any;
  mutateAction: () => void;
  onlyTask: boolean;
  serviceInfo: any;
  typeView: string;
}

const ServiceTable: React.FC<Props> = function ServiceTable({
  dashboard,
  mutateAction,
  onlyTask,
  serviceInfo,
  calendarEvenState,
  typeView
}) {
  const [reassignFlag, setReassignFlag] = useState<boolean>(false);
  const [openTaskDetails, setOpenTaskDetails] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>();

  const getTaskStyle = (task: TaskStatus): TaskStyle => {
    return taskInteractionStyles[task] || taskInteractionStyles.awaiting;
  };

  function ServiceTableBlockCheck({ isCheked, text }: IServiceTableBlockCheck) {
    return (
      <Box
        style={{
          alignContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start'
        }}
      >
        <Checkbox
          disabled
          checked={isCheked}
          onChange={() => {
            console.log('click here');
          }}
          size="small"
        />
        <Typography align="left" style={{ color: '#4B4D56', fontSize: '12px' }}>
          {text}
        </Typography>
      </Box>
    );
  }

  function ServiceTableBlock({
    title,
    type,
    materials,
    taskIterationID,
    checklist,
    note,
    images
  }: IServiceTableBlock) {
    const [modalOpen, setModalOpen] = useState(false); // Estado para controlar si el modal está abierto
    const [selectedNote, setSelectedNote] = useState<NoteResponseDTO[]>([]);
    const [fireCreateNote, setFireCreateNote] = useState<boolean>(false);
    const [noteText, setNoteText] = useState<string>('');
    const [currentTaskDetails, setCurrentTaskDetails] = useState<any>({});
    const [imgData, setImgData] = useState('');
    const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

    const { data: createTaskNoteData, error: createTaskNoteDataError } = useSWR(
      fireCreateNote && noteText !== '' && taskIterationID
        ? {
            entity: 'taskIteration',
            entityId: `${taskIterationID}`,
            noteText: `${noteText}`,
            taskIterationId: Number(taskIterationID) ?? 0
          }
        : null,
      Api.CalendarApi.noteCreate
    );

    // Función para manejar el clic en el icono de agregar
    const handleAddIconClick = (note: any) => {
      setSelectedNote(note); // Establece la nota seleccionada
      setModalOpen(true); // Abre el modal
    };

    const filteredDashboard = dashboard.filter((task: any) => {
      // Verificar si el objeto task tiene un array taskIteration
      if (task.taskIteration && task.taskIteration.length > 0) {
        // Verificar si algún elemento en el array taskIteration tiene un id igual a taskIterationId
        return task.taskIteration.some(
          (iteration: any) => iteration.id === taskIterationID
        );
      }
      return false; // Si no hay taskIteration o está vacío, devolver falso
    });
    useEffect(() => {
      if (createTaskNoteData && !createTaskNoteDataError) {
        const auxNotes = [...selectedNote, createTaskNoteData];
        setSelectedNote(auxNotes);
        setNoteText('');
        setFireCreateNote(false);
      }
    }, [createTaskNoteData, createTaskNoteDataError, selectedNote, noteText]);

    return (
      <Box
        maxWidth="34%"
        minWidth="25%"
        style={{
          backgroundColor: `${secondaryColors.SECONDARY_6}`,
          borderRight: `solid 1px ${secondaryColors.SECONDARY_1}`
        }}
        width="34%"
      >
        <Box
          style={{
            borderBottom: `solid 1px ${secondaryColors.SECONDARY_1}`,
            padding: '12px'
          }}
        >
          <Typography
            style={{
              color: `${secondaryColors.SECONDARY_5}`,
              fontSize: '11px'
            }}
            variant="secondary-bold"
          >
            {title}
          </Typography>
        </Box>

        {type === 'materials' && (
          <Box style={{ padding: '12px' }}>
            {materials &&
              materials?.map((index: any, key: number) => (
                <Typography
                  component="li"
                  key={key}
                  style={{
                    color: `${secondaryColors.SECONDARY_5}`,
                    fontSize: '12px'
                  }}
                  variant="secondary"
                >
                  {index?.name}
                </Typography>
              ))}
          </Box>
        )}

        {type === 'note' && (
          <>
            <Box style={{ padding: '12px' }}>
              {note && note.length > 0 && (
                <Typography
                  component="li"
                  style={{
                    color: `${secondaryColors.SECONDARY_5}`,
                    fontSize: '12px'
                  }}
                  variant="secondary"
                >
                  {note[0].noteText}
                </Typography>
              )}
              <Box
                style={{
                  display: 'flex',
                  marginBottom: '-24px',
                  paddingTop: '20px'
                }}
              >
                {note && note.length > 0 && (
                  <DrawerButton
                    onClick={() => handleAddIconClick(note)}
                    sx={{
                      height: '28px !important'
                    }}
                    variant="primary"
                  >
                    <Typography fontSize={11}>Open Notes</Typography>
                  </DrawerButton>
                )}
              </Box>
            </Box>
            <Box>
              {modalOpen && (
                <Modal
                  onClose={() => {
                    setModalOpen(false);
                    mutateAction();
                  }}
                  open={modalOpen}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Box
                    sx={{
                      '.textAreaInput-taskDetails': {
                        // backgroundColor: `${textColors.TEXT} !important`,
                        // backgroundColor: 'red',
                        fontSize: '16px !important',
                        padding: '0px !important'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: surfaceColors.SURFACE_6,

                        borderRadius: 2,
                        height: 600,
                        p: 4,
                        width: 800
                      }}
                    >
                      {
                        <Box>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'grid',
                              padding: '0.5rem 2rem 1.5rem 2rem;'
                            }}
                          >
                            <ListHead>
                              <Box>
                                <ListTitle variant="primary">Notes</ListTitle>
                              </Box>
                            </ListHead>
                            <Box
                              sx={{
                                border: 'solid 1px rgba(203, 204, 211, 0.7)',
                                borderRadius: '3px',
                                marginTop: '22px',
                                maxHeight:
                                  selectedNote && selectedNote.length > 3
                                    ? '350px'
                                    : 'auto',
                                overflowY:
                                  selectedNote && selectedNote.length > 3
                                    ? 'auto'
                                    : 'visible',
                                padding: '13px'
                              }}
                            >
                              <List dense sx={{ borderRadius: '8px' }}>
                                {selectedNote &&
                                  selectedNote?.length > 0 &&
                                  selectedNote
                                    .sort(
                                      (a, b) =>
                                        new Date(b.updatedAt).getTime() -
                                        new Date(a.updatedAt).getTime()
                                    )

                                    .map((noteData: any, index: number) => {
                                      const initials =
                                        `${noteData?.user?.firstName} ${noteData?.user?.lastName}`
                                          .toUpperCase()
                                          .split(' ')
                                          .slice(0, 2)
                                          .map((word: string) =>
                                            word[0]?.toUpperCase()
                                          )
                                          .join('');
                                      const auxKey = `avatar-technician-${index}`;
                                      return (
                                        <ListItem
                                          key={`task-details-note-listItem-${noteData.id}`}
                                          sx={{
                                            borderColor: `${secondaryColors.SECONDARY_1} !important`,
                                            borderRadius: '4px',
                                            width: '100% !important'
                                          }}
                                        >
                                          <ListItemButton
                                            sx={{
                                              backgroundColor: 'white',
                                              display: 'ruby-text !important',
                                              width: 'fit-content !important'
                                            }}
                                          >
                                            <Box sx={{ display: 'flex' }}>
                                              <ListItemAvatar
                                                style={{ paddingTop: '5px' }}
                                              >
                                                <Avatar
                                                  alt={`${noteData?.user?.firstName} ${noteData?.user?.lastName}`}
                                                  key={auxKey}
                                                  src={`${
                                                    noteData?.user?.fileBank
                                                      .length > 0
                                                      ? noteData?.user
                                                          ?.fileBank[0]?.fileUrl
                                                      : ''
                                                  }`}
                                                  sx={{
                                                    marginRight: '-20px'
                                                  }}
                                                >
                                                  {noteData?.user?.fileBank[0]
                                                    ?.fileUrl
                                                    ? null
                                                    : initials}
                                                </Avatar>
                                              </ListItemAvatar>
                                              <ListItemText
                                                id={`${noteData?.id}-title`}
                                                primary={`${
                                                  noteData?.user?.firstName
                                                } ${
                                                  noteData?.user?.lastName
                                                  // eslint-disable-next-line no-undef
                                                } - ${formatDate(
                                                  new Date(noteData?.createdAt)
                                                )} ${convertTimeFormat(
                                                  new Date(
                                                    noteData?.createdAt
                                                  ).toLocaleTimeString(
                                                    undefined
                                                  )
                                                )}`}
                                                sx={{
                                                  '.MuiListItemText-primary': {
                                                    color: `${secondaryColors.SECONDARY} !important`,
                                                    fontSize: '14px !important',
                                                    textTransform:
                                                      'capitalize !important'
                                                  }
                                                }}
                                              />
                                            </Box>
                                            <ListItemText
                                              id={`${noteData?.id}-text`}
                                              primary=""
                                              secondary={`${noteData.noteText}`}
                                              sx={{
                                                '.MuiListItemText-secondary': {
                                                  color: `${secondaryColors.SECONDARY} !important`,
                                                  fontSize: '1rem !important'
                                                },
                                                textAlign: 'justify'
                                              }}
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                      );
                                    })}
                              </List>
                            </Box>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                paddingTop: '17px'
                              }}
                            >
                              <TextField
                                fullWidth
                                multiline
                                className="textAreaInput-taskDetails"
                                disabled={
                                  currentTaskDetails?.status ===
                                  TaskIterationStatusStatusEnum.Created
                                }
                                id="outlined-multiline-static"
                                label=""
                                maxRows={2}
                                onChange={(textField) => {
                                  setNoteText(textField.target.value);
                                }}
                                placeholder="Write here"
                                rows={1}
                                sx={{
                                  backgroundColor: 'white !important',
                                  fontSize: '16px',
                                  minHeight: 'auto',
                                  padding: '0rem 2rem 1.5rem 2rem !important',
                                  width: '100%'
                                }}
                                value={noteText}
                                variant="outlined"
                              />
                              <Button
                                fullWidth
                                onClick={() => {
                                  setFireCreateNote(true);
                                  // setModalOpen(false);
                                }}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'transparent'
                                  },
                                  alignItems: 'center',
                                  backgroundColor: 'transparent',
                                  margin: '0 !important',
                                  minWidth: '2.5rem',
                                  padding: '0 !important',
                                  width: 'auto'
                                }}
                                variant="primary"
                              >
                                <SendIcon
                                  sx={{
                                    color: secondaryColors.SECONDARY_3,
                                    fontSize: '2.25rem',
                                    margin: '0px',
                                    padding: '0px'
                                  }}
                                />
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Modal>
              )}
            </Box>
          </>
        )}

        {type === 'checklist' && (
          <Box style={{ padding: '12px' }}>
            {checklist &&
              checklist?.map((index: any, key: number) => {
                return (
                  <ServiceTableBlockCheck
                    isCheked={index?.checked}
                    key={key}
                    text={index?.name}
                  />
                );
              })}
          </Box>
        )}

        {type === 'images' && (
          <>
            <TaskImageListContainer>
              {images.map((image: any, key: number) => {
                return (
                  <Box
                    style={{
                      alignContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <TaskImageContainer
                      onClick={() => {
                        setIsImageViewerOpen(true);
                        setImgData(image?.large || image?.fileUrl);
                      }}
                    >
                      <img
                        alt={image.fileName}
                        key={`taskIteration-image-${image.id}}`}
                        src={image.small || image.fileUrl}
                      />
                    </TaskImageContainer>
                  </Box>
                );
              })}
            </TaskImageListContainer>
            <Box>
              {isImageViewerOpen && imgData !== '' && (
                <ImageViewer
                  isOpen={isImageViewerOpen}
                  setIsOpen={setIsImageViewerOpen}
                  url={imgData}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    );
  }

  // eslint-disable-next-line prettier/prettier
  function ServiceTableRowTD({
    width,
    fontSize,
    colorH,
    typeTx,
    text,
    children
  }: IServiceTableRowTD) {
    return (
      <Box
        style={{
          border: 'solid 0.1px rgba(203, 204, 211, 0.7)',
          // color: `${secondaryColors.SECONDARY}`,
          height: '100%',
          padding: '8px',
          width: `${width}%`
        }}
      >
        <Typography color={colorH} fontSize={fontSize} variant={typeTx}>
          {text}
          {children}
        </Typography>
      </Box>
    );
  }

  function ServiceTableHeader() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '35px',
          width: '100%'
        }}
      >
        <ServiceTableRowTD
          colorH={`${themePalette.TITLE}`}
          fontSize="12px"
          text="TASK NAME"
          typeTx="secondary-bold"
          width={23}
        />
        <ServiceTableRowTD
          colorH={`${themePalette.TITLE}`}
          fontSize="12px"
          text="ESTIMATED TIME"
          typeTx="secondary-bold"
          width={typeView === 'serviceTime' ? 17.5 : 14}
        />
        {/* typeView === 'serviceSetup' && (
          <ServiceTableRowTD
            colorH={`${themePalette.TITLE}`}
            fontSize="12px"
            text="RE-ASSIGN"
            typeTx="secondary-bold"
            width={12}
          />
        ) */}
        {typeView === 'serviceTime' && (
          <ServiceTableRowTD
            colorH={`${themePalette.TITLE}`}
            fontSize="12px"
            text="QUANTITY"
            typeTx="secondary-bold"
            width={14}
          />
        )}
        {typeView === 'serviceTime' && (
          <ServiceTableRowTD
            colorH={`${themePalette.TITLE}`}
            fontSize="12px"
            text="TOTAL TIME"
            typeTx="secondary-bold"
            width={15}
          />
        )}
        {typeView === 'serviceTime' && (
          <ServiceTableRowTD
            colorH={`${themePalette.TITLE}`}
            fontSize="12px"
            text="TEAM"
            typeTx="secondary-bold"
            width={12}
          />
        )}
        {typeView === 'serviceTime' && (
          <ServiceTableRowTD
            colorH={`${themePalette.TITLE}`}
            fontSize="12px"
            text="STATUS"
            typeTx="secondary-bold"
            width={15}
          />
        )}
        {typeView === 'serviceTime' && (
          <ServiceTableRowTD
            colorH={`${secondaryColors.SECONDARY}`}
            fontSize="13px"
            text={' '}
            typeTx="secondary-bold"
            width={5.5}
          />
        )}
      </Box>
    );
  }

  const taskStatusBox = (
    taskIterationLists: any[],
    countTechnicians: number
  ) => {
    const completed = taskIterationLists.filter(
      (taskIte: any) => taskIte.status === 'completed'
    );
    const created = taskIterationLists.filter(
      (taskIte: any) => taskIte.status === 'created'
    );
    const inProgress = taskIterationLists.filter(
      (taskIte: any) => taskIte.status === 'inProgress'
    );
    let auxStatus: TaskStatus = 'awaiting';

    if (
      completed.length > 0 &&
      inProgress.length === 0 &&
      created.length === 0 &&
      countTechnicians === completed.length
    ) {
      auxStatus = 'completed';
    }

    if (
      inProgress.length > 0 ||
      (created.length > 0 && completed.length > 0) ||
      (completed.length > 0 && completed.length < countTechnicians)
    ) {
      auxStatus = 'inProgress';
    }

    return (
      <Box
        style={{
          backgroundColor: getTaskStyle(auxStatus)?.backgroundColor,
          border: `solid 2px ${getTaskStyle(auxStatus)?.borderColor}`,
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-4px',
          padding: '4px 8px'
        }}
      >
        <Typography
          fontSize={11}
          style={{
            color: getTaskStyle(auxStatus).textColor
          }}
        >
          {calendarEvenState === 'Created'
            ? 'Awaiting'
            : capitalizeString(auxStatus)}
        </Typography>
      </Box>
    );
  };

  function ServiceTableBody({ data, taskIteration, onlyTask }: any) {
    const [isOpen, setIsOpen] = useState(false);
    const totalTimeInMinutes = data?.totalTimeInHours * 60;
    const hours = Math.floor(totalTimeInMinutes / 60);
    const minutes = totalTimeInMinutes % 60;
    const formattedTime = dayjs()
      .set('hour', hours)
      .set('minute', minutes)
      .format('HH:mm');

    const formatStatus = (task: any) => {
      if (
        (task.status === 'completed' && calendarEvenState === 'Started') ||
        calendarEvenState === 'Completed'
      ) {
        return `${getTimeFromISO8601(task.startTime)} - ${getTimeFromISO8601(
          task.endTime
        )}`;
      }
      if (task.status === 'created' && calendarEvenState === 'Started') {
        return 'In comming';
      }
      if (task.status === 'inProgress' && calendarEvenState === 'Started') {
        return `${getTimeFromISO8601(task.startTime)} -`;
      }
      return 'Awaiting';
    };

    return (
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '45px',
            width: '100%'
          }}
        >
          <ServiceTableRowTD
            colorH={`${secondaryColors.SECONDARY}`}
            fontSize="12px"
            text={capitalizeString(data?.name)}
            typeTx="secondary"
            width={23}
          />

          {typeView === 'serviceSetup' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize={
                onlyTask || typeView === 'serviceSetup' ? '9px' : '12px'
              }
              text={
                onlyTask === true || typeView === 'serviceSetup'
                  ? `${
                      serviceInfo?.formatedStartTime ||
                      convertTo12HourFormat(
                        serviceInfo.startTime,
                        serviceInfo.timezone
                      )
                    } - ${
                      serviceInfo?.formatedEndTime ||
                      convertTo12HourFormat(
                        serviceInfo.endTime,
                        serviceInfo.timezone
                      )
                    } `
                  : `${formattedTime}`
              }
              typeTx="secondary"
              width={14}
            />
          )}

          {typeView === 'serviceTime' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize={onlyTask ? '10px' : '12px'}
              text={
                onlyTask && serviceInfo?.startTime && serviceInfo?.endTime
                  ? `${
                      serviceInfo?.formatedStartTime ||
                      convertTo12HourFormat(
                        serviceInfo.startTime,
                        serviceInfo.timezone
                      )
                    } - ${
                      serviceInfo?.formatedEndTime ||
                      convertTo12HourFormat(
                        serviceInfo.endTime,
                        serviceInfo.timezone
                      )
                    }`
                  : onlyTask
                  ? 'No time'
                  : `${formattedTime}`
              }
              typeTx="secondary"
              width={17.5}
            />
          )}

          {typeView === 'serviceTime' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize="10px"
              text={
                onlyTask
                  ? 'Awaiting'
                  : `${data?.quantity} ${getPluralMeasure(data?.measure)}`
              }
              typeTx="secondary"
              width={14}
            />
          )}

          {typeView === 'serviceTime' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize="12px"
              text=""
              typeTx="secondary"
              width={15}
            >
              {taskIteration.length > 0 ? (
                <Typography
                  color={`${secondaryColors.SECONDARY}`}
                  fontSize={onlyTask ? '10px' : '12px'}
                  style={{
                    alignItems: 'center'
                  }}
                  variant="secondary"
                >
                  {formatSumTime(parseFloat(data.workedTime))}
                </Typography>
              ) : (
                <Typography
                  color={`${secondaryColors.SECONDARY}`}
                  fontSize={12}
                  variant="secondary"
                >
                  {capitalizeString('awaiting')}
                </Typography>
              )}
            </ServiceTableRowTD>
          )}

          {typeView === 'serviceTime' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize="12px"
              typeTx="secondary"
              width={12}
            >
              <Box display="flex">
                {onlyTask
                  ? data?.technicians?.map((technician: any, index: any) => {
                      const initials = technician.name
                        ?.split(' ')
                        ?.slice(0, 2)
                        ?.map((word: string) => word[0]?.toUpperCase())
                        ?.join('');
                      const auxKey = `detail-project-team-${technician.id}`;
                      return (
                        <Box
                          key={auxKey}
                          sx={{
                            marginLeft: '-6px',
                            marginTop: '-9px',
                            padding: '7px'
                          }}
                        >
                          {initials && (
                            <Tooltip arrow title={technician.name}>
                              <Avatar
                                alt={technician.name}
                                sx={{
                                  backgroundColor: resourseColors[index],
                                  cursor: 'pointer',
                                  fontSize: '13px',
                                  height: 30,
                                  marginRight: '-20px',
                                  width: 30
                                }}
                              >
                                {technician.profilePic ? null : initials}
                              </Avatar>
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })
                  : data?.technicians?.map((technician: any, index: any) => {
                      const initials =
                        `${technician.firstName} ${technician.lastName}`
                          ?.split(' ')
                          ?.slice(0, 2)
                          ?.map((word: string) => word[0]?.toUpperCase())
                          ?.join('');
                      const auxKey = `detail-project-team-${technician.id}`;
                      return (
                        <Box
                          key={auxKey}
                          sx={{
                            marginLeft: '-6px',
                            marginTop: '-9px',
                            padding: '7px'
                          }}
                        >
                          {initials && (
                            <Tooltip
                              arrow
                              title={`${technician.firstName} ${technician.lastName}`}
                            >
                              <Avatar
                                alt={technician.firstName}
                                sx={{
                                  backgroundColor: resourseColors[index],
                                  cursor: 'pointer',
                                  fontSize: '13px',
                                  height: 30,
                                  marginRight: '-20px',
                                  width: 30
                                }}
                              >
                                {technician.profilePic ? null : initials}
                              </Avatar>
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })}
              </Box>
            </ServiceTableRowTD>
          )}

          {typeView === 'serviceTime' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize="12px"
              typeTx="secondary"
              width={15}
            >
              {taskIteration.length > 0 ? (
                taskStatusBox(taskIteration, data?.technicians?.length)
              ) : (
                <Box
                  style={{
                    // Estilos para tareas 'awaiting' cuando no hay tareas
                    backgroundColor: getTaskStyle('awaiting').backgroundColor,
                    border: `solid 2px ${getTaskStyle('awaiting').borderColor}`,
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '-4px',
                    padding: '4px 8px'
                  }}
                >
                  <Typography
                    fontSize={13}
                    style={{
                      color: getTaskStyle('awaiting').textColor
                    }}
                  >
                    {/* Mostrar 'awaiting' si no hay tareas */}
                    {capitalizeString('awaiting')}
                  </Typography>
                </Box>
              )}
            </ServiceTableRowTD>
          )}

          {typeView === 'serviceTime' && (
            <ServiceTableRowTD
              colorH={`${secondaryColors.SECONDARY}`}
              fontSize="12px"
              typeTx="secondary"
              width={5.5}
            >
              {onlyTask === false && taskIteration.length > 0 && (
                <Box
                  height={30}
                  onClick={() => {
                    setTaskData(data);
                    setOpenTaskDetails(true);
                    // setIsOpen(!isOpen);
                  }}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden'
                  }}
                  width={30}
                >
                  {/* {data.taskIteration.length > 0 ||
                  (data.materials.length > 0 && ( */}
                  <Button
                    size="small"
                    style={{
                      alignItems: 'center',
                      // backgroundColor: 'red',
                      border: 'none',
                      display: 'flex',
                      height: '30px',
                      justifyContent: 'center',
                      marginLeft: '-5px',
                      padding: 0,
                      width: '30px' // Ajustar el margen izquierdo
                    }}
                  >
                    {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </Button>
                  {/* // ))} */}
                </Box>
              )}
            </ServiceTableRowTD>
          )}
        </Box>
        {isOpen && (
          <Box style={{ display: 'flex', flexDirection: 'row' }} width="100%">
            {data?.materials && (
              <ServiceTableBlock
                materials={data?.materials}
                title="MATERIALS"
                type="materials"
              />
            )}
            {/* Si taskInteraction está vacío, mostrar los elementos de data.checklist */}
            {!taskIteration || taskIteration.length === 0 ? (
              <ServiceTableBlock
                checklist={data.checklist}
                title="CHECKLIST"
                type="checklist"
              />
            ) : (
              // Si taskInteraction no está vacío, mostrar los elementos de taskInteraction
              taskIteration &&
              taskIteration?.map((iteration: any) => (
                <React.Fragment key={iteration?.id}>
                  {iteration?.checkList && (
                    <ServiceTableBlock
                      checklist={iteration?.checkList}
                      title="CHECKLIST"
                      type="checklist"
                    />
                  )}
                </React.Fragment>
              ))
            )}

            {taskIteration.map((iteration: any) => (
              <React.Fragment key={iteration?.id}>
                <ServiceTableBlock
                  note={iteration?.notes}
                  taskIterationID={iteration?.id}
                  title="NOTES"
                  type="note"
                />
              </React.Fragment>
            ))}

            {taskIteration?.map((iteration: any) => (
              <React.Fragment key={iteration?.id}>
                <ServiceTableBlock
                  images={iteration?.fileBank}
                  title="IMAGES"
                  type="images"
                />
              </React.Fragment>
            ))}
          </Box>
        )}
      </Box>
    );
  }

  const getReassignModal = () => {
    return (
      <ReassignTechnicianModal
        data={taskData}
        mutate={mutateAction}
        setShowModal={setReassignFlag}
      />
    );
  };

  return (
    <Box
      style={{
        borderTop: 'solid #CBCCD3 1px'
      }}
    >
      {reassignFlag && getReassignModal()}
      {openTaskDetails && (
        <ProjectTaskDetailsModal
          calendarEvenState={calendarEvenState}
          dashboard={dashboard}
          data={taskData}
          onlyTask={onlyTask}
          serviceInfo={serviceInfo}
          setShowModal={setOpenTaskDetails}
        />
      )}
      <ServiceTableHeader />
      {dashboard.map((index: any, key: number) => {
        return (
          <ServiceTableBody
            dashboard={dashboard}
            data={index}
            key={key}
            onlyTask={onlyTask}
            taskIteration={onlyTask === true ? index : index.taskIteration}
          />
        );
      })}
    </Box>
  );
};

export default ServiceTable;
