interface weekDaysProps {
  abr: string;
  name: string;
}
interface weekDaysIndexProps {
  [key: string]: number;
}

interface weekNumberIndexProps {
  [key: string]: number;
}

export const weekDays: weekDaysProps[] = [
  { abr: 'S', name: 'Sunday' },
  { abr: 'M', name: 'Monday' },
  { abr: 'T', name: 'Tuesday' },
  { abr: 'W', name: 'Wednesday' },
  { abr: 'T', name: 'Thursday' },
  { abr: 'F', name: 'Friday' },
  { abr: 'S', name: 'Saturday' }
];

export const weekDaysIndex: weekDaysIndexProps = {
  Friday: 5,
  Monday: 1,
  Saturday: 6,
  Sunday: 0,
  Thursday: 4,
  Tuesday: 2,
  Wednesday: 3
};

export const weekNumberIndex: weekNumberIndexProps = {
  First: 0,
  Fourth: 3,
  Second: 1,
  Third: 2
};

export default {
  weekDays
};
