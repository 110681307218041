import {
  InvitationApi,
  RolesAndPermissionsApi,
  SkillApi
} from '../../api-client/generated/api';

const apiCaller = new InvitationApi();
const rolesCaller = new RolesAndPermissionsApi();
const skillCaller = new SkillApi();

interface Pagination {
  items: number;
  page: number;
}

const getUserInvitations = async () => {
  const response = await apiCaller.invitationGetAll();
  const { data } = response;
  return Array.isArray(data) ? data : [];
};

const getUserInvitationsPaginated = async (params: Pagination) => {
  const response = await apiCaller.invitationListInvitations(params);
  const { data } = response;
  return data;
};

const resendUserInvitation = async (email: string) => {
  const response = await apiCaller.invitationResendInvitation({ email });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteUserInvitation = async (id: string) => {
  const response = await apiCaller.invitationDeleteInvitation({ id });
  const { status, statusText } = response;
  return { status, statusText };
};

const sendInvitation = async (params: any) => {
  const {
    email,
    firstName,
    lastName,
    jobTitle,
    rate,
    roleIds,
    skillIds,
    phoneNumber
  } = params;
  const response = await apiCaller.invitationCreate({
    createInvitationDTO: {
      email,
      firstName,
      jobTitle,
      lastName,
      phoneNumber,
      rate,
      roleIds,
      skillIds
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const getRoles = async () => {
  const response = await rolesCaller.rolesGetRoles();
  return response.data;
};

const getSkills = async () => {
  const response = await skillCaller.skillGetAll();
  return response.data;
};

const getRolesByType = async (params: any) => {
  const { roles } = params;
  const type = roles ? roles[0] : null;
  const response = await rolesCaller.rolesGetRolesByUserType({ type });
  const { data } = response;
  return data as unknown as Array<any>;
};

export default {
  deleteUserInvitation,
  getRoles,
  getRolesByType,
  getSkills,
  getUserInvitations,
  getUserInvitationsPaginated,
  resendUserInvitation,
  sendInvitation
};
