import { getLocalStorage, setLocalStorage } from '../../utils/authHelper';

const getAllDraft = async () => {
  return getLocalStorage('draft') || [];
};
const initDraft = async () => {
  const createId = new Date();
  const getDrafts = await getAllDraft();
  setLocalStorage('draft', [...getDrafts, { id: createId }]);
  return createId.getTime();
};

const deleteDraft = async (id: string) => {
  return { id };
};

const setDraft = async (params: any) => {
  return { params };
};

const setStep1 = async (params: any) => {
  const getDrafts = await getAllDraft();
  if (params.id) {
    const temp = getDrafts.map((item: any) => {
      if (params.id === item.id) {
        return params;
      }
      return item;
    });
    setLocalStorage('draft', temp);
    return { ...params };
  }
  const id = new Date();
  setLocalStorage('draft', [{ ...params, id }, ...getDrafts]);
  return { ...params, id };
};

const convertToLead = async (id: string) => {
  return { id };
};

const getDraft = async (params: any) => {
  if (!params) {
    return {};
  }
  const { id } = params;
  const drafts = await getAllDraft();
  return [...drafts].find((item) => item.id === id);
};

export default {
  convertToLead,
  deleteDraft,
  getAllDraft,
  getDraft,
  initDraft,
  setDraft,
  setStep1
};
