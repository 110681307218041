import React from 'react';
import '../../style/App.scss';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ResendButtonProp {
  resendFn: any;
}

const ResendButton: React.FC<ResendButtonProp> = function ResendButton({
  resendFn
}) {
  const { t } = useTranslation();
  return (
    <Button onClick={resendFn} variant="solo">
      <Typography variant="primary-bold">
        {t('LISTING_PAGES.resend')}
      </Typography>
    </Button>
  );
};

export default ResendButton;
