import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AUTHORIZED_VIEW_NAMES } from '../../helpers/routesConstants';
import ListingProjects from './ListingProjects';
import DetailProject from './DetailProject';

const ProjectsView: React.FC = function ProjectsView() {
  return (
    <Routes>
      <Route
        element={<ListingProjects />}
        path={AUTHORIZED_VIEW_NAMES.PROJECTS_LIST}
      />
      <Route
        element={<DetailProject />}
        path={AUTHORIZED_VIEW_NAMES.PROJECTS_DETAIL}
      />
    </Routes>
  );
};

export default ProjectsView;
