/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */

import { Box, Button, Paper, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { isMobile } from 'react-device-detect';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFDocumentWrapper = styled('div')(() => ({
  canvas: {
    height: 'auto !important',
    width: '100% !important'
  }
}));

export default function PdfViewerComponent(props: any) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onDocumentError(event: any): void {
    console.error(event);
    setError(true);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (error) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginY: isMobile ? 2 : 0,
          width: '90%'
        }}
      >
        <Typography>{t('LEAD.CREATE.PROPOSAL.pdfErrorMessage')}</Typography>
        <ReportIcon sx={{ color: '#d3455b', fontSize: '200px' }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginY: isMobile ? 2 : 0,
        width: '90%'
      }}
    >
      <Paper>
        {props.title && props.title !== '' && (
          <Typography
            alignSelf="flex-start"
            color="primary"
            sx={{ fontSize: 24, paddingLeft: 4, paddingTop: 2 }}
            variant="heading_400"
          >
            {props.title}
          </Typography>
        )}
        <PDFDocumentWrapper>
          <Document
            file={props.file}
            onLoadError={onDocumentError}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              className="pdf-viewer"
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </PDFDocumentWrapper>
        {
          //
        }
        {numPages ? (
          <Box
            sx={{
              alignContent: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              pt: 2,
              width: '90%'
            }}
          >
            <Button
              color="inherit"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </Button>
            <Typography
              sx={{
                alignContent: 'center',
                marginX: 2
              }}
              variant="body_300"
            >
              {`Page ${pageNumber || (numPages ? 1 : '--')} of ${
                numPages || '--'
              }`}
            </Typography>
            <Button disabled={pageNumber >= numPages} onClick={nextPage}>
              Next
            </Button>
          </Box>
        ) : (
          <Box height={600} width={640} />
        )}
      </Paper>
    </Box>
  );
}
