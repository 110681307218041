import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface OpenDetailButtonProps {
  handleOpenDraw: any;
  icon?: String;
  tooltip?: String;
}

const OpenDetailButton: React.FC<OpenDetailButtonProps> =
  function OpenDetailButton({ handleOpenDraw, tooltip, icon }) {
    return (
      <Tooltip arrow title={tooltip}>
        <Button
          onClick={handleOpenDraw}
          style={{ minWidth: '45px' }}
          variant="table"
        >
          <Typography color="secondary.main" variant="primary-bold">
            <i className={`custom-icon icon-${icon}`} />
          </Typography>
        </Button>
      </Tooltip>
    );
  };

OpenDetailButton.defaultProps = {
  icon: 'eye',
  tooltip: ''
};

export default OpenDetailButton;
