/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './style/index.scss';
// import * as Sentry from '@sentry/node';
// import { RewriteFrames } from '@sentry/integrations';
import reportWebVitals from './reportWebVitals';
import './controllers/i18n';
import App from './App';
import ThemeConfig from './theme/themeConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const environment = process.env.REACT_APP_ENVIRONMENT;
// const isProd = environment === 'PROD';

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   tracesSampleRate: isProd ? 0.1 : 1,
//   integrations: [
//     new RewriteFrames({
//       root: global.__dirname,
//     }),
//   ],

//   release: process.env.REACT_APP_SENTRY_RELEASE,
//   environment: environment || 'DEV',
//   sampleRate: isProd ? 0.25 : 1,
//   initialScope: {
//     tags: {
//     // adding tags
//     },
//   },
// });

root.render(
  <React.StrictMode>
    <Router>
      <ThemeConfig>
        <App />
      </ThemeConfig>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
