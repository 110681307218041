/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

interface Props {
  data: any;
}
const ServicesSetup: React.FC<Props> = function ServicesSetup({ data }) {
  return (
    <Box key={1} style={{ margin: '-40px' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          backgroundColor: '#F5F5F5',
          borderTop: '1px solid #C7CDD2',
          display: 'flex',
          marginBottom: '19px',
          marginLeft: '0px',
          marginTop: '47px',
          padding: '33px',
          paddingTop: '18px'
        }}
        width="100%"
      >
        <Box
          style={{
            marginBottom: '-13px',
            marginLeft: '30px'
          }}
        >
          <Box display="flex">
            <Typography
              color="secondary.400"
              sx={{ fontSize: 14 }}
              variant="primary-bold"
            >
              Service Frequency:
            </Typography>
            &nbsp;
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              {data.frequency || 'N/A'}
            </span>
          </Box>
          &nbsp;
          <Box display="flex">
            <Typography
              color="secondary.400"
              sx={{ fontSize: 14 }}
              variant="primary-bold"
            >
              Start Date:
            </Typography>
            &nbsp;
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              {`${dayjs(data.startDate || 'N/A').format('DD-MM-YYYY')}`}
            </span>
          </Box>
          &nbsp;
          <Box display="flex">
            <Typography
              color="secondary.400"
              sx={{ fontSize: 14 }}
              variant="primary-bold"
            >
              Days of Week:
            </Typography>
            &nbsp;
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              {data.frequencyDays.length > 1
                ? data.frequencyDays.join(' - ')
                : data.frequencyDays}
            </span>
          </Box>
          &nbsp;
        </Box>

        <Box
          style={{
            marginRight: '50px'
          }}
        >
          <Box display="flex">
            <Typography
              color="secondary.400"
              sx={{ fontSize: 14 }}
              variant="primary-bold"
            >
              {' '}
              Travel time (round trip):
            </Typography>
            &nbsp;
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              {data.travelTime || 'N/A'}
            </span>
          </Box>
          &nbsp;
          <Box display="flex">
            <Typography
              color="secondary.400"
              sx={{ fontSize: 14 }}
              variant="primary-bold"
            >
              {' '}
              End date:
            </Typography>
            &nbsp;
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>
              {`${dayjs(data.endDate || 'N/A').format('DD-MM-YYYY')}`}
            </span>
          </Box>
          &nbsp;
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesSetup;
