import axios from './axios';

const postExample = async (params: object) => {
  if (!params) {
    return null;
  }
  const formdata = new FormData();
  // add three variable to form
  formdata.append('imdbid', '1234');
  formdata.append('token', 'd48a3c54948b4c4edd9207151ff1c7a3');
  formdata.append('rate', '4');
  const response = await axios.post('/api/save_rate', formdata);

  const { data } = response;

  return data;
};

const getExample = async (params: object) => {
  if (!params) {
    return null;
  }
  // const response = await axios.get('/deals');
  const response = await axios.get('/deals', { params: { ...params } });
  const { data } = response;
  return data;
};

export default {
  getExample,
  postExample
};
