import axiosInstance from './axios';

const setProfilePhoto = async (file: any) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxNDk1Yzg5Ny05YWNmLTRhOWUtOTVjNC0zYzg0YjgzMjk4YWYiLCJlbWFpbCI6ImFyaWVsKzFAaGVsbG9pY29uaWMuY29tIiwiZmlyc3ROYW1lIjoiQXJpZWwtaXRvIiwibGFzdE5hbWUiOiJNIiwiY29tcGFueSI6IkNvbXBhbnkgVGVzdDIiLCJjb21wYW55SWQiOiIyMWNhODdlYS0yMGE3LTQ2ZDMtOGRkMy1kOWEzZjlhNzdhYTciLCJyb2xlcyI6WyJBZG1pbmlzdHJhdG9yIl0sInBlcm1pc3Npb25zIjpbIkFkbWluaXN0cmF0b3IiLCJNb2RpZnlDb25maWd1cmF0aW9uIiwiVmlld2RvY3VtZW50IiwiU2VhcmNoZG9jdW1lbnQiLCJDaGF0IiwiQ3JlYXRlQ2hhdEdyb3VwIiwiTWFuYWdlVXNlcnMiLCJDcmVhdGVVc2VycyIsIkNyZWF0ZUNhdGVnb3J5IiwiRWRpdENhdGVnb3J5IiwiRGVsZXRlQ2F0ZWdvcnkiLCJWaWV3Q2F0ZWdvcnkiLCJWaWV3Q29tcGFueSIsIkVkaXRDb21wYW55IiwiQ3JlYXRlU2VydmljZSIsIkVkaXRTZXJ2aWNlIiwiRGVsZXRlU2VydmljZSIsIlZpZXdTZXJ2aWNlIiwiQ3JlYXRlVGFzayIsIkRlbGV0ZVRhc2siLCJWaWV3VGFzayIsIkVkaXRUYXNrIiwiQ3JlYXRlU2tpbGwiLCJFZGl0U2tpbGwiLCJEZWxldGVTa2lsbCIsIlZpZXdTa2lsbCIsIkNyZWF0ZU1hdGVyaWFsIiwiRWRpdE1hdGVyaWFsIiwiRGVsZXRlTWF0ZXJpYWwiLCJWaWV3TWF0ZXJpYWwiLCJWaWV3Q2xpZW50IiwiRWRpdENsaWVudCIsIkRlbGV0ZUNsaWVudCIsIkNyZWF0ZUNsaWVudCJdLCJ0d29GYWN0b3JBdXRoZW50aWNhdGVkIjp0cnVlLCJpYXQiOjE2NzgyMzA5OTcsImV4cCI6MTY3ODI1OTc5N30.H3m6ky4Jb44LtiW1PnNyR7xdJi9TBwSq-Ga_0SyfVUI'
  );
  const formdata = new FormData();
  formdata.append('file', file, 'profilepicture.jpeg');
  //   const requestOptions = {
  //     body: formdata,
  //     headers: myHeaders,
  //     method: 'POST'
  //   };

  const { data } = await axiosInstance.post(
    '/storage/upload/user-profile-photo',
    formdata
  );
  return data;
};

export default { setProfilePhoto };
