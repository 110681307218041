import React from 'react';
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  MobileStepper,
  useTheme
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  images: any;
  openViewer: (a: boolean) => void;
  setImageUrlViewer: (a: string) => void;
}
const ImageCarousel: React.FC<Props> = function ImageCarousel({
  images,
  openViewer,
  setImageUrlViewer
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Math.ceil(images.length / 9);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (!images?.length) {
    return <Box>{t('GENERAL.noImages')}</Box>;
  }

  return (
    <Box height={250}>
      <ImageList cols={3} gap={8} sx={{ height: 184, margin: 0 }}>
        {images.map((image: any, index: number) => {
          if (index >= activeStep * 9 && index < activeStep * 9 + 9) {
            return (
              <Box
                onClick={() => {
                  openViewer(true);
                  setImageUrlViewer(image.large || image.fileURL);
                }}
                sx={{ cursor: 'pointer' }}
              >
                <ImageListItem
                  key={image.fileName}
                  sx={{ height: 56, width: 56 }}
                >
                  <img
                    alt={image.title}
                    loading="lazy"
                    src={`${image.small}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${image.small}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  />
                </ImageListItem>
              </Box>
            );
          }
          return '';
        })}
      </ImageList>
      <MobileStepper
        activeStep={activeStep}
        backButton={
          <Button disabled={activeStep === 0} onClick={handleBack} size="small">
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {t('GENERAL.backButton')}
          </Button>
        }
        nextButton={
          <Button
            disabled={activeStep === maxSteps - 1}
            onClick={handleNext}
            size="small"
          >
            {t('GENERAL.nextButton')}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        position="static"
        steps={maxSteps}
        sx={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}
        variant="text"
      />
    </Box>
  );
};

export default ImageCarousel;
