/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button, SlideProps, styled, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import PasswordInput from '../input/PasswordInput';
import api from '../../api';
import { validRegexStringRules } from '../../utils/general';

interface Props {
  isMobile: boolean;
}

const CustomBottom = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  width: '70%'
}));

const ResetPassword: React.FC<Props> = function ResetPassword({ isMobile }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [pass, setPass] = useState<string>('');
  const [pass2, setPass2] = useState<string>('');
  const [fireApiPassword, setFireApiPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [rules, setRules] = useState<Array<any>>([]);

  const { data: dataPassword, error: errorPassword } = useSWR(
    fireApiPassword ? { password: pass, token } : null,
    api.authApi.resetPassword
  );

  const { data: rulesResponse, error: rulesError } = useSWR(
    !rules.length ? 'api/getPasswordRules' : null,
    api.ConfigApi.getPasswordRules
  );

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const confirmInvitation = () => {
    setError(false);
    if (pass === '' || pass !== pass2) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationError')}`);
      return;
    }
    if (token) {
      setFireApiPassword(true);
    }
  };

  useEffect(() => {
    if (dataPassword && dataPassword.status === 201) {
      navigate('/login');
    }
  }, [dataPassword]);

  useEffect(() => {
    if (errorPassword || rulesError) {
      setError(true);
      if (errorPassword) {
        const { response } = errorPassword;
        setErrorMessage(`${response.data.message}`);
      }
      if (rulesError) {
        setErrorMessage(rulesError.message);
      }
      setFireApiPassword(false);
    }
  }, [errorPassword, rulesError]);

  useEffect(() => {
    if (rulesResponse) {
      setRules(rulesResponse.data);
    }
  }, [rulesResponse]);

  useEffect(() => {
    if (pass) {
      const validInput = validRegexStringRules(rules, pass);
      setError(validInput.error);
      setErrorMessage(validInput.errorMessage);
    }
  }, [pass]);

  useEffect(() => {
    if (pass2) {
      setError(pass2 !== pass);
      setErrorMessage(`${pass2 !== pass ? t('LOGIN.invitationError') : ''}`);
    }
  }, [pass2]);

  return (
    <>
      <Typography
        color="primary.main"
        sx={{ textAlign: 'center', width: '100%' }}
        variant="primary-bold"
      >
        {t('LOGIN.invitationTitle')}
      </Typography>
      <br />
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('LOGIN.resetPasswordNew')}
      </Typography>
      <PasswordInput
        customStyle={{ width: '100%' }}
        error={error}
        onChange={(value) => setPass(value)}
      />
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('LOGIN.resetPasswordConfirm')}
      </Typography>
      <PasswordInput
        customStyle={{ width: '100%' }}
        error={error}
        onChange={(value) => setPass2(value)}
      />
      {getError()}
      <Container
        className="button-accept-container"
        sx={{ display: 'flex', mt: isMobile ? 4 : 4 }}
      >
        <CustomBottom
          color="primary"
          onClick={() => {
            confirmInvitation();
          }}
          variant="contained"
        >
          <Typography color="mainText.100" variant="primary-bold">
            {t('GENERAL.nextButton')}
          </Typography>
        </CustomBottom>
      </Container>
    </>
  );
};

export default ResetPassword;
