import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { UNAUTHORIZED_VIEW_NAMES, GLOBAL } from '../helpers/routesConstants';
import ClientProposalView from '../views/leads/steps/ClientProposalView';
import ClientInvoiceView from '../views/projects/invoices/client/ClientInvoiceView';
import ClientProcess from '../views/ClientProcess';
import ClientContainerUnauthenticated from '../views/ClientContainerUnauthenticated';
import useNavigationStore from '../store/zustand/navigation';
import GlobalConfirmationModal from '../components/modals/GlobalConfirmModal';

const AuthProcess = lazy(() => import('../views/AuthProcess'));

function UnauthenticatedApp() {
  const { confirmModalStatus } = useNavigationStore((state) => state);
  return (
    <Box>
      <Routes>
        <Route
          element={<Navigate to="/login" />}
          path={UNAUTHORIZED_VIEW_NAMES.DEFAULT}
        />
        <Route element={<AuthProcess />} path={UNAUTHORIZED_VIEW_NAMES.LOGIN} />
        <Route
          element={<AuthProcess startProcess="changePassword" />}
          path={UNAUTHORIZED_VIEW_NAMES.CHANGE_PASSWORD}
        >
          <Route
            element={<AuthProcess startProcess="changePassword" />}
            path=":token"
          />
        </Route>
        <Route
          element={<AuthProcess startProcess="resetPassword" />}
          path={UNAUTHORIZED_VIEW_NAMES.RESET_PASSWORD}
        >
          <Route
            element={<AuthProcess startProcess="resetPassword" />}
            path=":token"
          />
        </Route>
        <Route
          element={<AuthProcess startProcess="forgotPassword" />}
          path={UNAUTHORIZED_VIEW_NAMES.FORGOT_PASSWORD}
        >
          <Route
            element={<AuthProcess startProcess="forgotPassword" />}
            path=":token"
          />
        </Route>
        <Route path={GLOBAL.ADMIN_INVITATION}>
          <Route
            element={
              <AuthProcess isCompanyInvitation startProcess="invitation" />
            }
            path=":token/*"
          />
        </Route>
        <Route path={GLOBAL.INVITATION}>
          <Route
            element={<AuthProcess startProcess="invitation" />}
            path=":token/*"
          />
        </Route>
        <Route
          element={<ClientProposalView />}
          path={`${GLOBAL.CLIENT_PROPOSAL}/:token`}
        />
        <Route
          element={<ClientInvoiceView />}
          path={`${GLOBAL.CLIENT_INVOICE}/:token`}
        />
        <Route
          element={<ClientProposalView />}
          path={`${GLOBAL.CLIENT_PROPOSAL}/:token`}
        />
        <Route
          element={<ClientInvoiceView />}
          path={`${GLOBAL.CLIENT_INVOICE}/:token`}
        />
        <Route element={<ClientProcess />} path={`${GLOBAL.APP_LINK}/:token`} />
        <Route
          element={<ClientContainerUnauthenticated />}
          path={`${GLOBAL.CLIENT_PAGE}/*`}
        />
        <Route
          element={<Navigate to="/login" />}
          path={UNAUTHORIZED_VIEW_NAMES.INVALID}
        />
        <Route
          element={<Navigate to="/login" />}
          path={UNAUTHORIZED_VIEW_NAMES.INVALID}
        />
      </Routes>
      {confirmModalStatus && <GlobalConfirmationModal />}
    </Box>
  );
}

export default UnauthenticatedApp;
