import React from 'react';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import PasswordUpdateForm from '../shared/forms/PasswordUpdateForm';

interface Props {
  isMobile: boolean;
}

const ChangePassword: React.FC<Props> = function ChangePassword({ isMobile }) {
  const navigate = useNavigate();

  return (
    <Container sx={{ alignItems: 'flex-start !important' }}>
      <PasswordUpdateForm
        action={() => navigate('/app/profile')}
        isMobile={isMobile}
        isProfileForm={false}
      />
    </Container>
  );
};

export default ChangePassword;
